import { actions } from '@peoplefund/actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { SendParam } from '@peoplefund/actions/sms-verification';

export interface SmsVerificationState {
	readonly sendParam?: SendParam;

	/** 거래고유번호 */
	readonly svcTxSeqno?: string;

	/** SMS 발송 여부 */
	readonly sent: boolean;
	/** 본인인증 확인 여부 */
	readonly verified: boolean;
	readonly verifyFailed: boolean;

	/** error */
	readonly error?: InAPICommonError;
}

export const smsVerificationInitialState: SmsVerificationState = {
	sendParam: undefined,
	svcTxSeqno: undefined,
	sent: false,
	verified: false,
	verifyFailed: false,
};

export default {
	smsVerification: reducerWithInitialState<SmsVerificationState>(smsVerificationInitialState)
		.cases([actions.common.init, actions.smsVerification.init], (state) =>
			Immutable(state, {
				$set: smsVerificationInitialState,
			})
		)
		.case(actions.smsVerification.smsSendRrn7.started, (state, params) => {
			return Immutable(state, {
				sendParam: { $set: params },
				sent: { $set: false },
				error: { $set: undefined },
				verifyFailed: { $set: false },
			});
		})
		.case(actions.smsVerification.smsSendRrn7.done, (state, { result }) => {
			return Immutable(state, {
				svcTxSeqno: { $set: result.svcTxSeqno },
				sent: { $set: true },
			});
		})
		.case(actions.smsVerification.smsSendRrn7.failed, (state, { error }) => {
			return Immutable(state, {
				sendParam: { $set: undefined },
				svcTxSeqno: { $set: undefined },
				sent: { $set: false },
				error: { $set: error },
			});
		})
		.case(actions.smsVerification.resetVerify, (state) => {
			return Immutable(state, {
				verified: { $set: false },
				verifyFailed: { $set: false },
				svcTxSeqno: { $set: undefined },
				sent: { $set: false },
				error: { $set: undefined },
			});
		}),
};
