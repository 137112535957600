export enum InformationType {
	H2 = 'h2', //h2: 타이틀 사이즈
	H3 = 'h3', //h3: 내용 사이즈
	H3BNP = 'h3bnp', //h3 + bold
	H3B = 'h3b', //내용사이즈 +  bold
	H3O = 'h3o', // 내용사이즈 + 회색
}

export interface Information {
	type: InformationType;
	content: string;
}
export interface FinancialProductDetail {
	readonly information: Information[];
}

export interface NotificationInfo {
	readonly contents: string[];
	readonly highlights: string[];
	readonly title: string;
}
