import actionCreatorFactory from 'typescript-fsa';
import { ActionTypes } from '@peoplefund/constants/action-types';
import { EmptyResult } from '@peoplefund/actions/common';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { SendParam, SendResult, VerifyParam } from '@peoplefund/actions/sms-verification';
import { Action } from 'redux';

export type VerifySignupParam = Omit<VerifyParam, 'needUpdateCssAccount'> & {
	loanType: 'personal' | 'mortgage';
	postVerifyAction?: Action;
};

const actionCreator = actionCreatorFactory('LS-AUTHENTICATION');

export default {
	init: actionCreator(ActionTypes.LS_AUTH_INIT),
	initVerify: actionCreator(ActionTypes.LS_AUTH_APPLY_INIT_VERIFY),
	errorReset: actionCreator(ActionTypes.LS_AUTH_ERROR_RESET),
	verifySendSMS: actionCreator.async<SendParam, SendResult, AlertCommonError>(ActionTypes.LS_AUTH_VERIFY_SEND_SMS),
	verifySignUp: actionCreator.async<VerifySignupParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_AUTH_VERIFY_SIGNUP
	),
	verifyPartnerDone: actionCreator(ActionTypes.LS_AUTH_VERIFY_PARTNER_DONE),
	verifyComplete: actionCreator(ActionTypes.LS_AUTH_VERIFY_COMPLETE),
};
