import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { EmptyResult } from './common';

export interface RegistorInvestorParam {
	/** 투자 가능 기간 */
	readonly max_invest_period: string;
	/** 투자 지식 수준 */
	readonly knowledge_level: string;
	/** 수입원 */
	readonly source_of_income: string;
	/** 감수 손실 수준 */
	readonly affordable_loss: string;
	/** 투자 성향 */
	readonly propensity: string;
}

const actionCreator = actionCreatorFactory('UNSECURED_INVESTOR_REGISTRATION');

export default {
	init: actionCreator(ActionTypes.UNSECURED_INVESTOR_REGISTRATION_INIT),
	resetError: actionCreator(ActionTypes.UNSECURED_INVESTOR_REGISTRATION_RESET_ERROR),
	send: actionCreator.async<RegistorInvestorParam, EmptyResult, InAPICommonError>(
		ActionTypes.UNSECURED_INVESTOR_REGISTRATION_SEND
	),
	error: actionCreator(ActionTypes.UNSECURED_INVESTOR_REGISTRATION_ERROR),
};
