import { css } from '@emotion/react';
import { basicColors } from '@pfct/purple';
import 'react-slick/lib/';
import 'slick-carousel/slick/slick-theme.css';
export const THROTTLE_KEYWORD = 'throttle';
export const GlobalStyle = css`
	html {
		/* 여기에서만 전체 스크롤 컨트롤 */
		/* 다만, window 객체에서 스크롤 인식 - ios wkwebview scrollview*/

		height: max-content;

		.${THROTTLE_KEYWORD} {
			overflow-y: hidden;
		}

		&.isWebview {
			-webkit-touch-callout: none;
			-webkit-tap-highlight-color: transparent;
			-webkit-user-select: none;
			user-select: none;

			-webkit-text-size-adjust: none;
			text-size-adjust: none;
		}
	}
	body {
		height: 100%;
		margin: 0;
		font-family: 'Pretendard', 'Lato', 'Noto Sans KR', sans-serif;
		background-color: ${basicColors.gray25};
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: auto;

		@media (max-width: 767px) {
			background-color: ${basicColors.white};
		}
	}
	#__next {
		height: 100%;
	}
	* {
		touch-action: manipulation;
		box-sizing: border-box;
	}
	i {
		font-style: normal;
	}
	input,
	button {
		font-family: inherit;
	}
	button {
		cursor: pointer;

		&:disabled {
			cursor: not-allowed;
		}
	}
	/**
		https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
	*/
	input:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 1000px transparent inset;
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		transition: background-color 5000s ease-in-out 0s;
	}
`;