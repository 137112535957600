import styled from '@emotion/styled';

export const Wrapper = styled.section`
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 20px;
`;

export const TemplateSmallWrapper = styled.div`
	display: flex;
	gap: 10px;
`;

export const TemplateSmallWrapperTextSection = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const OverlineCaptionSection = styled.section`
	display: flex;
	justify-content: space-between;
	height: 22px;
`;

export const TemplateImageImageSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const TemplateImageImage = styled.img``;
