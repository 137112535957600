import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'communityPreview';

export type CommunityPreviewItem = {
	id: number;
	title: string;
	content: null | string;
	attachment_url: string | null;
	author: {
		id: number;
		nickname: string;
		badge: {
			name: string;
			name_color: string;
			background_color: string;
		} | null;
		is_deleted: boolean;
		is_self: boolean;
	};
	meta: {
		liked: boolean;
		likeCount: number;
		replyCount: number;
		viewCount: number;
		createdAt: number;
	};
};

type CommunityPreviews = {
	items: CommunityPreviewItem[];
	nextCursor: number | null | undefined;
};

export interface CommunityPreviewState {
	previews: CommunityPreviews[];
	userPreviews: {
		[userId: number]: {
			[type: string]: CommunityPreviews;
		};
	};
	scrollY: number;
	subCategoryId: number;
}

export const CommunityPreviewInitialState: CommunityPreviewState = {
	previews: [],
	userPreviews: {},
	scrollY: 0,
	subCategoryId: 0,
};

const communityPreviewSlice = createSlice({
	name: SLICE_NAME,
	initialState: CommunityPreviewInitialState,
	reducers: {
		initialize: (state) => {
			state.previews = [];
			state.userPreviews = {};
			state.scrollY = 0;
			state.subCategoryId = 0;
		},
		deletePreview: (state, { payload }: PayloadAction<number>) => {
			state.previews.forEach((preview) => {
				const itemIndex = preview.items.findIndex((item) => item.id === payload);
				if (itemIndex !== -1) {
					preview.items.splice(itemIndex, 1);
				}
			});
		},
		addNewPreview: (state, { payload }: PayloadAction<{ subCategoryId: number; item: CommunityPreviewItem }>) => {
			state.previews[payload.subCategoryId].items.unshift(payload.item);
			state.previews[0].items.unshift(payload.item);
		},
		addUserPreview: (
			state,
			{ payload }: PayloadAction<{ userId: number; type: string; preview: CommunityPreviews }>
		) => {
			if (!state.userPreviews[payload.userId]) {
				state.userPreviews[payload.userId] = {
					posts: {
						items: [],
						nextCursor: undefined,
					},
					replies: {
						items: [],
						nextCursor: undefined,
					},
				};
			}
			const userPreview = state.userPreviews[payload.userId][payload.type];
			if (userPreview) {
				userPreview.items = userPreview.items.concat(payload.preview.items);
				userPreview.nextCursor = payload.preview.nextCursor;
			}
		},
		initializeUserPreview: (state) => {
			state.userPreviews = {};
		},
		addPreviews: (state, { payload }: PayloadAction<{ subCategoryId: number; preview: CommunityPreviews }>) => {
			const index = payload.subCategoryId;

			const existingItemIds = new Set(state.previews[index].items.map((item) => item.id));
			const newItems = payload.preview.items.filter((item) => !existingItemIds.has(item.id));

			state.previews[index].items = state.previews[index].items.concat(newItems);
			state.previews[index].nextCursor = payload.preview.nextCursor;
		},
		addReplyCount: (state, { payload }: PayloadAction<{ subCategoryId: number; index: number }>) => {
			const index = state.previews[payload.subCategoryId].items.findIndex((item) => item.id === payload.index);
			if (index === -1) {
				return;
			}
			state.previews[payload.subCategoryId].items[index].meta.replyCount += 1;
		},
		updateLike: (
			state,
			{ payload }: PayloadAction<{ subCategoryId: number; id: number; liked: boolean; likeCount: number }>
		) => {
			const index = state.previews[payload.subCategoryId].items.findIndex((item) => item.id === payload.id);
			if (index === -1) {
				return;
			}
			state.previews[payload.subCategoryId].items[index].meta.liked = payload.liked;
			state.previews[payload.subCategoryId].items[index].meta.likeCount = payload.likeCount;
		},
		updateViewCount: (state, { payload }: PayloadAction<{ subCategoryId: number; id: number; viewCount: number }>) => {
			const subCategory = state.previews[payload.subCategoryId];
			if (!subCategory || !subCategory.items) {
				return;
			}
			const index = state.previews[payload.subCategoryId].items.findIndex((item) => item.id === payload.id);
			if (index === -1) {
				return;
			}
			state.previews[payload.subCategoryId].items[index].meta.viewCount = payload.viewCount;
		},
		initializePreviews: (state, { payload }: PayloadAction<number>) => {
			if (state.previews.length >= payload) {
				return;
			}

			state.previews = Array.from({ length: payload }, () => ({
				items: [],
				nextCursor: undefined,
			}));
		},
		setSubCategoryId: (state, { payload }: PayloadAction<number>) => {
			if (state.previews.length <= payload) {
				state.previews = Array.from({ length: payload + 1 }, () => ({
					items: [],
					nextCursor: undefined,
				}));
			}
			state.subCategoryId = payload;
		},
	},
});

export const {
	initialize,
	addPreviews,
	deletePreview,
	updateLike,
	addReplyCount,
	addNewPreview,
	updateViewCount,
	setSubCategoryId,
	addUserPreview,
	initializePreviews,
	initializeUserPreview,
} = communityPreviewSlice.actions;

export default communityPreviewSlice.reducer;
