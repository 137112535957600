import Cookies, { Cookie, CookieSetOptions } from 'universal-cookie';

export interface IStorage {
	get: (key: string) => string;
	set: (key: string, value: string) => void;
	// TODO: clear, remove
}

export class CookieStorage implements IStorage {
	private storage: Cookie;
	private cookieOptions: CookieSetOptions;

	constructor() {
		this.storage = new Cookies();
		this.cookieOptions = {
			path: '/',
			secure: process.env.NODE_ENV === 'production',
			// httpOnly: true,
			sameSite: 'strict',
		};
	}

	public get = (key: string) => {
		return this.storage.get(key, this.cookieOptions);
	};
	public set = (key: string, value: string) => {
		this.storage.set(key, value, this.cookieOptions);
	};
}

export class SessionStorage implements IStorage {
	private storage: Storage | null;

	constructor() {
		if (typeof window !== 'object') {
			this.storage = null;
		} else {
			this.storage = window.sessionStorage;
		}
	}

	public get = (key: string) => {
		return this.storage?.getItem(key) || '';
	};
	public set = (key: string, value: string) => {
		this.storage?.setItem(key, `${value}`);
	};
}
