// [관련 페이지](https://www.notion.so/Mobile-New-46fa5c0fe7234aaeaf0356010ec6977f#172aff3b9fac4f9bb9ac81a5ae37859e)

import { NextRouter } from 'next/router';
import { IStorage, SessionStorage } from './Storage';
import { getBrowserInfo } from '@peoplefund/hooks/useBrowserInfo';
import ObjectUtil from '@peoplefund/utils/object.util';

type ReturnObj = {
	[key: string]: string;
};

const UTM_TYPES: string[] = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
const AVAILABLE_QUERY_STRING = [...UTM_TYPES, 'user_segment'];

export const BASE_DYNAMIC_LINK = 'https://peoplefund.page.link/'; // 끝 back slash 필수
export const APP_DOWNLOAD_PAGE_LINK = 'https://www.peoplefund.co.kr/app/open';

const DYNAMIC_LINK_PATH_TARGET: Record<string, string> = {
	loan: 'loan_home', // 대출홈탭
	invest: 'invest_home', // 투자홈탭
	more: 'more_home', // 더보기탭
};

interface IUtmModule {
	setRouter: (router: NextRouter) => IUtmModule;
	getUtm: () => ReturnObj;
	getAvailableQueryStrings: () => ReturnObj;
	setQueryString: (isReadyRouter: boolean) => void;
	getDynamicLink: (param: { url?: string; targetHomeKey?: string }) => string;
	getCreated: () => string;
}

class UtmModule implements IUtmModule {
	private storage: IStorage;
	private router: NextRouter | null;
	private created: string;

	constructor() {
		this.storage = new SessionStorage(); // 인자보다 고정으로 선택하도록
		// TODO: singletone 한번만 정의할 수 있도록
		this.router = null;
		this.created = new Date().toLocaleTimeString();
	}

	public getCreated = () => {
		return this.created;
	};

	public setRouter = (router: NextRouter) => {
		this.router = router;

		return this;
	};

	public getUtm = () => {
		const result: ReturnObj = {};

		UTM_TYPES.forEach((key) => {
			const value = this.storage.get(key);
			if (value) {
				result[key] = String(value);
			}
		});
		return result;
	};

	public getAvailableQueryStrings = () => {
		const result: ReturnObj = {};

		AVAILABLE_QUERY_STRING.forEach((key) => {
			const value = this.storage.get(key);
			if (value) {
				result[key] = String(value);
			}
		});
		return result;
	};

	public setQueryString = (isReadyRouter: boolean) => {
		if (!isReadyRouter) {
			return;
		} else {
			const result = this.extractUtmFromUrl();
			AVAILABLE_QUERY_STRING.forEach((utmKey) => {
				const value = result[utmKey] || '';
				this.storage.set(utmKey, value);
			});
		}
	};

	private extractUtmFromUrl = () => {
		const result = this.getUtm(); // 기존에 갖고 있던 값들 베이스로

		if (this.router !== null) {
			const router = this.router;
			AVAILABLE_QUERY_STRING.forEach((key) => {
				if (Boolean(router.query[key])) {
					result[key] = String(router.query[key]);
				}
			});
		}

		return result;
	};

	private getGa4Cid = (): string => {
		let cid = '';

		// TODO document 객체로 그대로 활용하는 것은 좋지 않음. 상단에서 parameter 로 전달해 주어야 함.
		if (typeof document === 'undefined') {
			return cid;
		}

		const cookieRawData = document.cookie;
		const splitted = cookieRawData.split('; ');
		const cookieObj: ReturnObj = {};
		for (let i = 0; i < splitted.length; i++) {
			const item = splitted[i];
			const [key, value]: string[] = item.split('=');
			cookieObj[key] = value;
		}

		const ga4Data = cookieObj['_ga']; // ex: _ga=GA1.1.1144614790.1641795469
		if (ga4Data) {
			const ga4SplittedData = ga4Data.split('.');
			cid = ga4SplittedData.slice(2).join('.'); // ex: cid=1144614790.1641795469
		}

		return cid;
	};

	private getDeepLink = ({ targetHome }: { targetHome: string }): string => {
		const baseDeepLink = `https://www.peoplefund.co.kr/${targetHome}/`;

		const utmObj = this.getAvailableQueryStrings();
		const cid = this.getGa4Cid();

		const queryStringObj = utmObj;

		if (cid) {
			queryStringObj['web_user_pseudo_id'] = cid;
		}

		const additionalQueryString = Object.keys(queryStringObj).reduce((acc, key) => {
			const temp = `${key}=${queryStringObj[key]}`;
			return Boolean(acc) ? `${acc}&${temp}` : temp;
		}, '');

		const decodeURL = `${baseDeepLink}${additionalQueryString ? '?' + additionalQueryString : ''}`;

		return encodeURIComponent(decodeURL);
	};

	private isDynamicLink = (url: string): boolean => {
		return url.includes(BASE_DYNAMIC_LINK);
	};

	public getDynamicLink = ({ url = BASE_DYNAMIC_LINK, targetHomeKey = 'loan' }) => {
		const apn = 'kr.co.peoplefund.investment';
		const ibi = 'kr.co.peoplefund.investment';
		const isi = 1498399929;

		const deepLinkTargetHome = DYNAMIC_LINK_PATH_TARGET?.[targetHomeKey] || DYNAMIC_LINK_PATH_TARGET.loan; // 혹시 잘못된 값이 넘어오면 대출홈 기본값

		let result = url;

		if (this.isDynamicLink(url)) {
			// https://firebase.google.com/docs/dynamic-links/create-manually
			// dynamic_link = sub_domain + params(deep_link, package_name etc)

			const baseURL = BASE_DYNAMIC_LINK;

			const queryParams: Record<string, string> = ObjectUtil.filterUndefined({
				link: this.getDeepLink({ targetHome: deepLinkTargetHome }),
				apn,
				ibi,
				isi,
				ofl: !getBrowserInfo().isMobile() && APP_DOWNLOAD_PAGE_LINK,
			});

			const queryString = Object.entries(queryParams)
				.map(([key, value]) => `${key}=${value}`)
				.join('&');

			result = `${baseURL}?${queryString}`;
		}

		return result;
	};
}

interface IUtmFactory {
	instance: IUtmModule;
}

const UtmFactory = ((): IUtmFactory => {
	let instance: IUtmModule;

	return {
		get instance() {
			if (!Boolean(instance)) {
				instance = new UtmModule();
				instance.constructor = () => {
					//console.log('constructor 못 건들게 LOCK');
				};
			}
			return instance;
		},
	};
})();

export default UtmFactory;
