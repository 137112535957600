import { ofAction } from '@peoplefund/utils/redux.util';
import * as VoteSlice from '@peoplefund/slices/vote';
import { CometEpic } from '../constants.util';
import { catchError, concat, mergeMap, of, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { FetchListServerProps, VoteDetailServerProps } from './index.model';
import { convertVoteInfo, convertVoteItem } from '@peoplefund/epics/vote/index.util';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { gtagVoteEvent } from '@peoplefund/utils/marketing-script/gtag.util';
import LOG_EVENT_NAME from '@peoplefund/constants/log-event';
import { actions } from '@peoplefund/actions';

const fetchOngoingListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(VoteSlice.fetchOngoingList),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return cometAjax.inapi.get(`/vote/surveys?status=진행중&limit=10000000000000000`, { token }).pipe(
					map((response: FetchListServerProps) =>
						VoteSlice.setOngoingListResult({ result: convertVoteItem(response) })
					),
					catchError((error) =>
						of(
							VoteSlice.setError(
								new AlertCommonError(
									'화면을 새로고침 후 다시 시도해주세요',
									error.code,
									'설문 리스트를 불러오지 못했어요'
								)
							)
						)
					)
				);
			}
		)
	);
};

const fetchClosedListEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(VoteSlice.fetchClosedList),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
					vote: {
						closedListPage: { limit },
					},
				},
			]) => {
				return cometAjax.inapi
					.get(`/vote/surveys?status=종료&limit=${limit}&offset=${limit * payload.page}`, {
						token,
					})
					.pipe(
						map((response: FetchListServerProps) => {
							return VoteSlice.setClosedListResult({
								count: response.count,
								result: convertVoteItem(response),
							});
						}),
						catchError((error) =>
							of(
								VoteSlice.setError(
									new AlertCommonError(
										'화면을 새로고침 후 다시 시도해주세요.',
										error.code,
										'설문 리스트를 불러오지 못했어요'
									)
								)
							)
						)
					);
			}
		)
	);
};

const fetchInfoEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(VoteSlice.fetchInfo),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return cometAjax.inapi
					.get(`/vote/sub-loans/${payload.id}`, {
						token,
					})
					.pipe(
						map((response: VoteDetailServerProps) => {
							return VoteSlice.setInfoResult({ ...payload, info: convertVoteInfo(response) });
						}),
						catchError((error) => {
							gtagVoteEvent(LOG_EVENT_NAME.VoteDetail_View_Error, {
								vote_loan_id: payload.id,
							});

							return of(
								VoteSlice.setError(
									new AlertCommonError(
										'화면을 새로고침 후 다시 시도해주세요',
										error.code,
										'설문 상세 정보를 불러오지 못했어요'
									)
								)
							);
						})
					);
			}
		)
	);
};

const submitVoteEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(VoteSlice.submitVote),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.patch(`/vote/sub-loans/${payload.id}`, {
							body: {
								answer: payload.answer,
							},
							token,
						})
						.pipe(
							map(() => VoteSlice.setSubmitVoteResult(true)),
							catchError((error) => {
								gtagVoteEvent(LOG_EVENT_NAME.VoteDetail_Next_Error, {
									vote_loan_id: payload.id,
								});

								return of(
									VoteSlice.setError(
										new AlertCommonError(
											'화면을 새로고침 후 다시 시도해주세요',
											error.code,
											'응답을 정상적으로 완료하지 못했어요'
										)
									)
								);
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [fetchOngoingListEpic, fetchClosedListEpic, fetchInfoEpic, submitVoteEpic];
