import { MortgageInvestProductListItem } from '@peoplefund/slices/ml-investing';
import dayjs from 'dayjs';

export const getProductCountAmountYieldRate = (
	productList: MortgageInvestProductListItem[]
): { count: number; amount: number; yieldRate: number } => {
	const count = productList.length;
	const amount = productList.map((product) => product.amount).reduce((prev, curr) => prev + curr, 0);

	const selectedProductYieldRate = () => {
		/**
		 * 가중평균 수익률로 구한값.
		 */

		if (!amount) {
			return 0;
		}
		const calculatedYieldRate = productList
			.map((product) => {
				return product.yieldRate * product.amount;
			})
			.reduce((prev, curr) => prev + curr, 0);

		return Number((calculatedYieldRate / amount).toFixed(2));
	};

	return {
		count,
		amount,
		yieldRate: selectedProductYieldRate(),
	};
};

export const getMainButtonDisabled = (props: {
	agreed: boolean;
	deposit: number;
	selectedCartProductAmountSum: number;
	overMaxInvestableAmount: boolean;
	isDisabledToUseBankingService: boolean;
}): boolean => {
	const { agreed, deposit, selectedCartProductAmountSum, overMaxInvestableAmount, isDisabledToUseBankingService } =
		props;

	if (isDisabledToUseBankingService) {
		return true;
	}

	if (deposit < selectedCartProductAmountSum) {
		return false;
	}

	if (!agreed || selectedCartProductAmountSum === 0 || overMaxInvestableAmount) {
		return true;
	}

	return false;
};

export const getCartItemStatusDisplay = ({
	status,
	currentDatetime,
	fundingStartDatetime,
}: {
	status: MortgageInvestProductListItem['status'];
	currentDatetime: string;
	fundingStartDatetime: string;
}) => {
	if (status === '투자가능') {
		return '투자 가능';
	}
	if (status === '마감') {
		return '마감';
	}
	if (status === '오픈예정') {
		const currentDatetimeDayjsFormat = dayjs(currentDatetime);
		const fundingStartDatetimeDayjsFormat = dayjs(fundingStartDatetime);

		const minitue = fundingStartDatetimeDayjsFormat.minute();
		const openTime = fundingStartDatetimeDayjsFormat.format(minitue ? 'HH시 mm분' : 'HH시');
		const openDate = fundingStartDatetimeDayjsFormat.format('MM월 DD일');

		if (fundingStartDatetimeDayjsFormat.date() === currentDatetimeDayjsFormat.date()) {
			return `오늘 ${openTime} 오픈`;
		} else {
			return `${openDate} ${openTime} 오픈`;
		}
	}

	return '마감';
};

export const checkProductWaitingStatus = ({
	selectedProductList,
	waitingProductList,
	liveProductList,
}: {
	selectedProductList: MortgageInvestProductListItem[];
	waitingProductList: MortgageInvestProductListItem[];
	liveProductList: MortgageInvestProductListItem[];
}): { isAllWaitingProduct: boolean; isSomeWaitingProduct: boolean } => {
	const response = {
		isAllWaitingProduct: false,
		isSomeWaitingProduct: false,
	};

	if (selectedProductList.length === 0 || waitingProductList.length === 0) {
		return response;
	}

	if (selectedProductList.length === waitingProductList.length) {
		response.isAllWaitingProduct = true;
	} else if (selectedProductList.length === liveProductList.length + waitingProductList.length) {
		response.isSomeWaitingProduct = true;
	}

	return response;
};

export const getInvestableCartItem = (product: MortgageInvestProductListItem) =>
	product.status === '투자가능' || product.status === '오픈예정';

export const maxInvestableAmountWithUnit = (amount: number) =>
	`${amount === Infinity ? '무제한' : amount.toLocaleString() + '만원'}`;

export const getMaxInvestableAmountAndRealEstateAmount = ({
	maxInvestableAmount,
	maxInvestableAmountRealEstate,
}: {
	maxInvestableAmount: number;
	maxInvestableAmountRealEstate: number;
}): {
	maxInvestableAmount: number;
	maxInvestableAmountRealEstate: number;
} => {
	/**
	 * 1. 두개의 인자중 값이 -1이면 투자 가능한 값이 무제한이므로 함수 내에서 Infinity로 간주하고 다른 값과 비교한다.
	 * 2. 위 모든 케이스가 아닌 경우 인자로 받은 둘 중 작은 값을 리턴한다.
	 */
	const alteredMaxInvestableAllAmount = maxInvestableAmount === -1 ? Infinity : maxInvestableAmount;
	const alteredMaxInvestableRealEstate =
		maxInvestableAmountRealEstate === -1 ? Infinity : maxInvestableAmountRealEstate;

	return {
		maxInvestableAmount: alteredMaxInvestableAllAmount,
		maxInvestableAmountRealEstate: alteredMaxInvestableRealEstate,
	};
};

export const getCartItemStatus = ({
	is_canceled,
	is_closed,
	is_investable,
}: {
	is_canceled: boolean;
	is_closed: boolean;
	is_investable: boolean;
}): MortgageInvestProductListItem['status'] => {
	let status: MortgageInvestProductListItem['status'] = '마감';

	if (is_canceled || is_closed) {
		status = '마감';
	} else if (is_investable) {
		status = '투자가능';
	} else {
		status = '오픈예정';
	}

	return status;
};
