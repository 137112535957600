import '@peoplefund/types/window';
import WebviewBridgeAction, {
	InvalidTokenAction,
	NextAction,
	NextActionType,
	OpenAppAction,
	SaveAction,
	SendEventAction,
	SendEventActionData,
	ShareAction,
	SystemSettingAction,
} from './bridge.type';
import { checkIsOverAppVersionFromBase } from '../cple/checkAppVersion';
import { WebviewHandler } from './index.util';

const scriptHandlerByPlatform = (params: WebviewBridgeAction) => {
	const result = JSON.stringify(params);

	if (window.ScriptHandler) {
		// 안드로이드
		window.ScriptHandler.postMessage(result);
		console.log(`window.ScriptHandler.postMessage(params) 호출완료! with param`, result);
	} else if (window.webkit) {
		// 아이폰
		window.webkit?.messageHandlers.scriptHandler.postMessage(result);
	} else {
		console.log('[bridge] scriptHandler.postMessage', result);
	}
};

export type WebviewBridgeMethod = {
	openLink: (link: string, errorHandler?: (errorMessage: unknown) => void) => void; // TODO: 앱 강업 이후 제거예정
	nextAction: (type: NextActionType, params?: any) => void;
	sendEvent: (eventData: SendEventActionData) => void;
	invalidToken: (data: { access_token: string; refresh_token: string }) => void;
	systemSetting: (data: SystemSettingAction['data']) => void;
	openApp: (
		data:
			| { app: 'creditplanet' | 'peoplefund' | 'instagram' | 'kakao' }
			| { app: 'undefined'; package: string | null; appstoreId: string | null }
	) => void;
	share: (data: {
		platform: 'kakao' | 'os' | 'instagram';
		url: string;
		text: string;
		image_url?: string;
		contents?: { [key: string]: string };
	}) => void;
	save: (data: { contents: { [key: string]: string } }) => void;
};

export const bridge: WebviewBridgeMethod = {
	openLink: (link, errorHandler) => {
		// TODO: 구 컨벤션 정리 필요
		try {
			if (typeof window !== 'undefined') {
				if (window.ScriptHandler) {
					window.ScriptHandler.openLink(link);
				} else if (window.webkit?.messageHandlers?.scriptHandler) {
					const message = JSON.stringify({
						link,
					});
					window.webkit.messageHandlers.scriptHandler.postMessage(message);
				} else {
					throw '앱뷰 환경이 아닙니다';
				}
			}
		} catch (e) {
			errorHandler?.(e);
		}
	},
	nextAction: (type, _params) => {
		/**
		 * 강업 전까지 open 함수가 없는 앱도 존재할 수 있으므로, 버전업에 유의하여 분기를 합니다.
		 * 강업되면 close 관련 분기하는 코드는 삭제되어도 됩니다.
		 */
		const webviewHandler = WebviewHandler();
		const { appType, osType } = webviewHandler;

		const isTypeOpen = type === 'open';

		let tempType: NextActionType = isTypeOpen
			? 'close'
			: type; /** 기본값을 현재 호환이 모두 가능한 close로 정합니다. */

		const isAndroidCanUseOpenType = osType === 'android' && checkIsOverAppVersionFromBase({ base: [2, 25, 0] });
		const isiOSCanUseOpenType = osType === 'iOS' && checkIsOverAppVersionFromBase({ base: [2, 26, 0] });

		if (isTypeOpen && appType === 'Million' && (isAndroidCanUseOpenType || isiOSCanUseOpenType)) {
			tempType = 'open';
		}

		const params: NextAction = {
			action: 'nextAction',
			data: { type: tempType, params: _params },
		};
		scriptHandlerByPlatform(params);
	},
	sendEvent: (eventData) => {
		const params: SendEventAction = {
			action: 'sendEvent',
			data: { name: eventData.name, params: eventData.params ?? null },
		};
		scriptHandlerByPlatform(params);
	},
	invalidToken: (data) => {
		const params: InvalidTokenAction = {
			action: 'invalidToken',
			data,
		};
		scriptHandlerByPlatform(params);
	},
	systemSetting: (data) => {
		const params: SystemSettingAction = {
			action: 'systemSetting',
			data,
		};
		scriptHandlerByPlatform(params);
	},
	openApp: (data) => {
		const params: OpenAppAction = {
			action: 'openApp',
			data,
		};
		scriptHandlerByPlatform(params);
	},
	share: (data) => {
		const params: ShareAction = {
			action: 'share',
			data,
		};
		scriptHandlerByPlatform(params);
	},
	save: (data) => {
		const params: SaveAction = {
			action: 'save',
			data,
		};
		scriptHandlerByPlatform(params);
	},
};
