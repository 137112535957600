import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { BaseLoanApplication, Unknown } from '@peoplefund/slices/loans/type';
import { BankInfo } from '@peoplefund/components/loans/@common/embeded-finance/contract/@types';
import {
	AgreementContractFile,
	ContractFile,
} from '@peoplefund/components/loans/@common/embeded-finance/contract/Contract/ContractComponent';

export enum StockLoanApplicationStatus {
	모름 = '',
	가심사완료 = 'pre_screening_approved',
	이미지제출필요 = 'document_scanning_in_progress',
	대기 = 'manual_screening_in_progress',
	온라인계약중 = 'contract_in_progress',
	입금대기중 = 'contract_completed',
}

export type StockLoanApplication = BaseLoanApplication<StockLoanApplicationStatus> & {
	readonly compatibilityInfo: { readonly question: string; readonly answer: string }[];
	readonly code: string;
	readonly availableAutoTransferDays: number[];
	readonly purpose: string;
	readonly bankAccount?: BankInfo;
	readonly loanStatus?: string;
};

export interface StockLoansState {
	error?: AlertCommonError;
	loanApplication: {
		id: string;
	};
	loanProductApplication: StockLoanApplication;
	contractDocuments: {
		hasDocument: boolean;
		main: ContractFile[];
		sub: AgreementContractFile[];
	};
}

export const stockLoansInitialState: StockLoansState = {
	loanApplication: {
		id: Unknown,
	},
	loanProductApplication: {
		code: '',
		title: '',
		iconUrl: '',
		id: Unknown,
		status: StockLoanApplicationStatus.모름,
		interestRate: 0,
		applyAmount: 0,
		maxAmount: 0,
		condition: {
			featured: [],
			common: [],
			detail: [],
			comments: [],
		},
		compatibilityInfo: [],
		availableAutoTransferDays: [],
		purpose: '',
	},
	contractDocuments: {
		hasDocument: false,
		main: [],
		sub: [],
	},
};

const SLICE_NAME = 'stockLoans';

const stockLoansSlice = createSlice({
	name: SLICE_NAME,
	initialState: stockLoansInitialState,
	reducers: {
		// reset 겸용
		initialize: (state) => {
			state.error = undefined;
			state.loanApplication = stockLoansInitialState.loanApplication;
			state.loanProductApplication = stockLoansInitialState.loanProductApplication;
		},
		setError: (state, action: PayloadAction<AlertCommonError | undefined>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setLoanApplicationId: (state, { payload }: PayloadAction<number>) => {
			state.loanApplication.id = `${payload}`;
		},
		setLoanProductApplication: (state, { payload }: PayloadAction<StockLoanApplication>) => {
			state.loanProductApplication = payload;
		},
		setContractDocuments: (state, { payload }: PayloadAction<StockLoansState['contractDocuments']>) => {
			state.contractDocuments = payload;
		},
		resetContractDocuments: (state) => {
			state.contractDocuments = stockLoansInitialState.contractDocuments;
		},
	},
});

export const {
	initialize,
	setLoanApplicationId,
	setLoanProductApplication,
	setContractDocuments,
	resetContractDocuments,
	setError,
	resetError,
} = stockLoansSlice.actions;

export default stockLoansSlice.reducer;
