import { AlertCommonError } from '@peoplefund/constants/error/type';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvestedProduct, InvestmentProduct, ProductV2 } from './common-investing.model';
import {
	CategoryItemDetailServerResponse,
	CategoryListServerResponseInCamelCase,
	ProductFilterListServerResponseInCamelCase,
	ProductListV2ServerResponse,
	ProductType,
	PromotionDetailServerResponse,
} from '@peoplefund/epics/investing.model';
import { WithNetworkStatus } from '@peoplefund/reducers';
import { InvestmentEntryPoint } from './ml-investing.model';
import { ParsedUrlQuery } from 'querystring';

const SLICE_NAME = 'commonInvesting';

type FETCH_STATUS = 'idle' | 'loading' | 'success' | 'fail';

export interface PageData {
	componentType: string;
	isVisible?: boolean;
	property?: { [key: string]: any };
}

export interface BankAccountInfo {
	bankLogo: string;
	bankCode: string;
	bankName: string;
	bankNumber: string;
	accountStatus: string;
	status: FETCH_STATUS;
}

interface InvestedProductListInfo {
	list: InvestedProduct[];
	status: FETCH_STATUS;
}

export type BankInfo = Omit<BankAccountInfo, 'accountStatus' | 'bankNumber' | 'status'> & { bankType: string };
export interface CommonInvesting {
	bankAccountInfo: BankAccountInfo;
	bankListInfo: {
		list: BankInfo[];
		status: FETCH_STATUS;
	};
	changeBankAccount: {
		status: FETCH_STATUS;
	};
	error?: AlertCommonError;
	investedProductListInfo: InvestedProductListInfo;
}

export const commonInvestingInitialState: CommonInvesting = {
	bankAccountInfo: {
		bankLogo: '',
		bankCode: '',
		bankName: '',
		bankNumber: '',
		accountStatus: '',
		status: 'idle',
	},
	bankListInfo: {
		list: [],
		status: 'idle',
	},
	changeBankAccount: {
		status: 'idle',
	},
	investedProductListInfo: {
		list: [],
		status: 'idle',
	},
};

export const changeBankAccountAction = createAction<{ bankCode: string; bankAccountNumber: string }>(
	`${SLICE_NAME}/changeBankAccountAction`
);
export const startInvestment = createAction<{
	entryPoint: InvestmentEntryPoint;
	investmentProductList: InvestmentProduct[];
}>(`${SLICE_NAME}/startInvestment`);

const finishFetchPromotions = createAction(`${SLICE_NAME}/finishFetchPromotions`);

export const fetchCategories = createAction<{
	action: (promotions: WithNetworkStatus<{ list: CategoryListServerResponseInCamelCase }>) => void;
}>(`${SLICE_NAME}/fetchCategories`);

export const finishFetchCategories = createAction(`${SLICE_NAME}/finishFetchCategories`);

export const fetchCategoryItemDetail = createAction<{
	categoryType: ProductType;
	action: (value: WithNetworkStatus<{ value: CategoryItemDetailServerResponse | null }>) => void;
}>(`${SLICE_NAME}/fetchCategoryItemDetail`);

export const finishFetchCategoryItemDetail = createAction(`${SLICE_NAME}/finishFetchCategoryItemDetail`);

export const fetchProductListV2 = createAction<{
	query?: ParsedUrlQuery;
	action: (promotions: WithNetworkStatus<{ listInfo: ProductListV2ServerResponse | null }>) => void;
}>(`${SLICE_NAME}/fetchProductListV2`);

export const finishFetchProductListV2 = createAction(`${SLICE_NAME}/finishFetchProductListV2`);

export const fetchFilters = createAction<{
	query?: { type: ProductType };
	action: (promotions: WithNetworkStatus<{ list: ProductFilterListServerResponseInCamelCase }>) => void;
}>(`${SLICE_NAME}/fetchFilters`);

const finishFetchFilters = createAction(`${SLICE_NAME}/finishFetchFilters`);

export const fetchProductInfo = createAction<{
	id: number;
	action: (props: WithNetworkStatus<{ pagedatas: PageData[] }>) => void;
	productInfoAction: (props: WithNetworkStatus<{ value: ProductV2 | null }>) => void;
}>(`${SLICE_NAME}/fetchProductInfo`);

export const finishFetchProductInfo = createAction(`${SLICE_NAME}/finishFetchProductInfo`);

export const fetchPromotionDetail = createAction<{
	type: string;
	action: (props: WithNetworkStatus<{ promotionDetail: PromotionDetailServerResponse | null }>) => void;
}>(`${SLICE_NAME}/fetchPromotionDetail`);

export const finishFetchPromotionDetail = createAction(`${SLICE_NAME}/finishFetchPromotionDetail`);
export const withdraw = createAction<{
	props: {
		amount: number;
		date: string;
		comment: string;
		platform: string;
	};
	action: (props: WithNetworkStatus<object>) => void;
}>(`${SLICE_NAME}/withdraw`);
export const finishWithdraw = createAction(`${SLICE_NAME}/finishWithdraw`);

const commonInvestingSlice = createSlice({
	name: SLICE_NAME,
	initialState: commonInvestingInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, commonInvestingInitialState),
		initState: (state) => {
			state.bankAccountInfo = commonInvestingInitialState.bankAccountInfo;
			state.bankListInfo = commonInvestingInitialState.bankListInfo;
			state.changeBankAccount = commonInvestingInitialState.changeBankAccount;
			state.error = undefined;
		},
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		getBankAccountInfoAction: (state) => {
			state.bankAccountInfo.status = 'loading';
		},
		setBankAccountInfoResult: (state, action: PayloadAction<BankAccountInfo>) => {
			const { bankCode, bankName, bankNumber, bankLogo, status, accountStatus } = action.payload;

			state.bankAccountInfo.bankLogo = bankLogo;
			state.bankAccountInfo.bankCode = bankCode;
			state.bankAccountInfo.bankName = bankName;
			state.bankAccountInfo.bankNumber = bankNumber;
			state.bankAccountInfo.status = status;
			state.bankAccountInfo.accountStatus = accountStatus;
		},

		setChangingBankAccountResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;

			state.changeBankAccount.status = status;
		},

		setInvestedProductList: (state, action: PayloadAction<InvestedProductListInfo>) => {
			state.investedProductListInfo = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(changeBankAccountAction, (state) => {
			state.changeBankAccount.status = 'loading';
		});
		builder.addCase(startInvestment, (state) => {
			state.error = undefined;
			state.investedProductListInfo = {
				list: [],
				status: 'loading',
			};
		});
		builder.addCase(finishFetchPromotions, (state) => {
			state.error = undefined;
		});
		builder.addCase(fetchCategories, (state) => {
			state.error = undefined;
		});
		builder.addCase(finishFetchCategories, (state) => {
			state.error = undefined;
		});
		builder.addCase(fetchFilters, (state) => {
			state.error = undefined;
		});
		builder.addCase(finishFetchFilters, (state) => {
			state.error = undefined;
		});
		builder.addCase(fetchProductInfo, (state) => {
			state.error = undefined;
		});
		builder.addCase(finishFetchProductInfo, (state) => {
			state.error = undefined;
		});
		builder.addCase(fetchPromotionDetail, (state) => {
			state.error = undefined;
		});
		builder.addCase(finishFetchPromotionDetail, (state) => {
			state.error = undefined;
		});
		builder.addCase(withdraw, (state) => {
			state.error = undefined;
		});
		builder.addCase(finishWithdraw, (state) => {
			state.error = undefined;
		});
	},
});

export const {
	initialize,
	initState,
	setError,
	resetError,
	getBankAccountInfoAction,
	setBankAccountInfoResult,
	setChangingBankAccountResult,
	setInvestedProductList,
} = commonInvestingSlice.actions;

export default commonInvestingSlice.reducer;
