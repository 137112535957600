const BaseUrl = '/loans/personal-loans';

const LoansPersonalLoansUrls = {
	INTRO: `${BaseUrl}`,

	APPLY_PARTNER_AGREEMENT: `${BaseUrl}/apply/partner-agreement`,
	APPLY_VERIFY: `${BaseUrl}/apply/verify`,
	APPLY_VERIFY_AGREEMENT: `${BaseUrl}/apply/verify-agreement`,
	APPLY_JOBINFO_SELECT: `${BaseUrl}/apply/jobInfo`,
	APPLY_JOBINFO_DETAIL: `${BaseUrl}/apply/jobInfo#detail`,
	APPLY_EXTRA_INFO: `${BaseUrl}/apply/extraInfo`,
	APPLY_REFINANCE: `${BaseUrl}/apply/refinance`,
	APPLY_CALCUATING: `${BaseUrl}/apply/calculating`,
	APPLY_QUICK_APPLY: `${BaseUrl}/apply/quick-apply`,

	/** 가조회 결과 */
	LOAN: `${BaseUrl}/loan`,
	REJECT: `${BaseUrl}/reject`,
	APP_DOWNLOAD: `${BaseUrl}/app-download`,

	/** 추가서류제출 상품 */
	ADDITIONAL_DOCS: `${BaseUrl}/additional-docs`,
	ADDITIONAL_DOCS_SUBMIT: `${BaseUrl}/additional-docs/submit`,
} as const;

export default LoansPersonalLoansUrls;
