type LoanApplicationInformationDescription = {
	readonly title: string;
	readonly description: string;
	readonly tip?: string;
};

type LoanApplicationWithIconInformationDescription = LoanApplicationInformationDescription & {
	readonly iconUrl: string;
};

export type BaseLoanApplication<LoanStatus> = {
	title: string;
	iconUrl: string;
	id: string;
	status: LoanStatus;
	interestRate: number;
	applyAmount: number;
	maxAmount: number;
	condition: {
		featured: LoanApplicationWithIconInformationDescription[];
		common: LoanApplicationInformationDescription[];
		detail: LoanApplicationInformationDescription[];
		comments: string[];
	};
};

export const Unknown = '';
