import {
	BlogArticle,
	ProductCommissionInformation,
	SetFinancialProductDetailParam,
} from '@peoplefund/actions/ls-financial-product';
import { Information } from '@peoplefund/constants/ls-financial-detail-types';

interface FinancialProductCommissionDetailResponse {
	articles: { date: string; link: string; title: string; thumbnail: string }[];
	disclaimer: string[];
	information: {
		main: { title: string; description: string }[];
		sub: { title: string; description: string }[];
	};
}

type FinancialProductResponse = {
	//쓰지 않는 서버 값은 모두 생략
	id: string;
	info: {
		informations: { type: string; content: 'string' }[];
	};
	process_type: {
		type: 'internal' | 'external';
		url: string | null;
	};
};

export const covertFPCommissionDetailResult = ({
	articles,
}: FinancialProductCommissionDetailResponse): ProductCommissionInformation => {
	const article: BlogArticle[] = articles.map((item: unknown) => item as BlogArticle);
	const filteredArticleTitles = [
		`[기고] '온투 대출'이라 쓰고 '중금리 대출'이라 읽힐까`,
		`[보도자료] 애큐온저축은행-PFCT, 중금리대출 협력 본격화`,
	];

	return {
		blogArticles: article.filter((item) => {
			return !filteredArticleTitles.includes(item.title);
		}),
	};
};

export const convertFinancialProductResponse = (response: FinancialProductResponse): SetFinancialProductDetailParam => {
	return {
		id: response.id,
		information: response.info.informations.map((item) => item as Information),
	};
};
