import { AppType, cookieParser } from './cookieParser.util';
import { bridge, WebviewBridgeMethod } from './bridge.util';
import { CookieStorage } from '../marketing-script/Storage';

const webviewPathCheckList = ['appview', 'app'];

type WebviewHandlerType = {
	isWebview: boolean;
	appType: AppType;
	bridge: WebviewBridgeMethod;
	appVersion: string;
	pfctDeviceId: string;
	osType: 'android' | 'iOS' | 'web';
};

export const WebviewHandler = (): WebviewHandlerType => {
	const cookieStorage = new CookieStorage();
	const cookieData = cookieParser(cookieStorage);

	const appType = cookieData.appType;
	const appVersion = cookieData.appVersion;
	const pfctDeviceId = cookieData.pfctDeviceId;

	const isWebview = (() => {
		const currentPath = typeof window === 'object' ? window.location.href : '';
		const paths = currentPath.split('/');
		const condition1 = webviewPathCheckList.some((target) => paths.includes(target));

		const condition2 = cookieData.isWebview;

		return condition1 || condition2;
	})();

	const osType: () => WebviewHandlerType['osType'] = () => {
		if (typeof window !== 'object') {
			return 'web';
		}

		if (window.ScriptHandler) {
			// 안드로이드
			return 'android';
		} else if (window.webkit) {
			// 아이폰
			return 'iOS';
		} else {
			return 'web';
		}
	};

	return {
		isWebview,
		appType,
		bridge,
		appVersion,
		pfctDeviceId,
		osType: osType(),
	};
};

export default WebviewHandlerType;
