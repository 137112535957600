import { KRWUnit } from '@peoplefund/utils/format.util';

export type PartialRecord<K extends keyof any, T> = {
	[P in K]?: T;
};

export enum TABLE_SORT_OPTIONS {
	// 서로 다음 option 을 부르도록 의도함.
	origin = 'decrease',
	decrease = 'increase',
	increase = 'origin',
}

export enum INVEST_PRODUCT_STATE {
	RUNNING = '모집 중',
	CLOSE = '모집 마감',
	CANCLE_AVAILABLE = '취소 가능',
}

// * 주의 * API 요청될 queryKey 입니다.
export enum QUERY_KEY {
	ID = 'id',
	INTEREST_RATE = 'interest_rate',
	INVEST_TERM = 'loan_application_term',
	PF_GRADE = 'pf_grade',
	CREDIT_SCORE = 'nice_credit_score',
	FUNDING_RATE = 'funding_rate',
	TARGET_AMOUNT = 'loan_amount',
	INVESTED_AMOUNT = 'user_investment_amount',
}

interface TableHeadItemProps {
	label: string;
	key: QUERY_KEY;
	withSortingIcon?: boolean;
}

export const TABLE_HEAD_LIST: TableHeadItemProps[] = [
	{ label: '투자상품', key: QUERY_KEY.ID },
	{ label: '수익률', key: QUERY_KEY.INTEREST_RATE, withSortingIcon: true },
	{ label: '투자기간', key: QUERY_KEY.INVEST_TERM, withSortingIcon: true },
	{ label: 'PF등급', key: QUERY_KEY.PF_GRADE, withSortingIcon: true },
	{ label: '신용점수', key: QUERY_KEY.CREDIT_SCORE, withSortingIcon: true },
	{ label: '모집금액', key: QUERY_KEY.TARGET_AMOUNT, withSortingIcon: true },
	{ label: '모집률', key: QUERY_KEY.FUNDING_RATE, withSortingIcon: true },
	{ label: '투자금액', key: QUERY_KEY.INVESTED_AMOUNT, withSortingIcon: true },
];

export enum INVESTOR_TYPE {
	NONE = '',
	INDIVIDUAL = 'INDIVIDUAL', //개인
	ACCREDITED = 'ACCREDITED', //소득적격
	PROFESSIONAL = 'PROFESSIONAL', //전문투자
	CORPORATE = 'CORPORATE', //법인
	INSTITUTE = 'INSTITUTE', //기관
}

export enum INVESTOR_STATUS {
	NONE = 'NONE',
	PENDING = 'PENDING', //대기중
	APPROVED = 'APPROVED', //승인
	REJECTED = 'REJECTED', //거절
	ACCOUNT_NOT_REGISTERED = 'ACCOUNT_NOT_REGISTERED', //담보채권 계좌 등록 미시행
	/**
	 * FYI.
	 * AML
	 * - step1 : WLF
	 * - step2 : (2+1)
	 */
	AML_CHECK_NEEDED = 'AML_CHECK_NEEDED', // WLF 조차 진행하지 않음
	AML_1_WLF_FAILED = 'AML_1_WLF_FAILED', // WLF 에서 실패함
	AML_1_KYC_EXPIRED = 'AML_1_KYC_EXPIRED', // KYC 재이행 필요
	AML_1_WLF_PASSED = 'AML_1_WLF_PASSED', // WLF 만 통과
	AML_2_TWO_PLUS_ONE_NEEDED = 'AML_2_TWO_PLUS_ONE_NEEDED', // (2+1) 필요
	AML_2_TWO_PLUS_ONE_FAILED = 'AML_2_TWO_PLUS_ONE_FAILED', // (2+1) 에서 실패함
	AML_ALL_PASSED = 'AML_ALL_PASSED', // WLF, (2+1) 모두 통과함

	// 투자자 정보 조회 - 특수 에러도 상태로 저장
	SYSTEM_UNDER_MAINTENANCE = 'SYSTEM_UNDER_MAINTENANCE', // 은행 공동망 점검 시간
}

export enum INVEST_TYPE {
	TOTAL_AMOUNT,
	SAME_RATE,
	SAME_AMOUNT,
}

// 전문/법인 투자자: 동일차입자 한도 = 모집금액 * 40%
export const FOURTY_PERCENT = -1;

export type ProcessStatus = 'ready' | 'processing' | 'done';

// 현재는, 최소 투자금액 고정.
// TODO: 서버로부터 받기
export const MINIMUM_INVESTMENT_AMOUNT = 10 * KRWUnit.만;

export enum INPUT_UNIT {
	TEN_MAN_WON,
	MAN_WON,
	PERCENT,
}

export const InputUnit = {
	[INPUT_UNIT.TEN_MAN_WON]: {
		label: '0 만원',
		value: KRWUnit.십만,
		suffixWidth: 45,
		leftMargin: 0,
	},
	[INPUT_UNIT.MAN_WON]: {
		label: '만원',
		value: KRWUnit.만,
		suffixWidth: 40,
		leftMargin: 2,
	},
	[INPUT_UNIT.PERCENT]: {
		label: '%',
		value: 0.01,
		suffixWidth: 15,
		leftMargin: 2,
	},
};

export const DEFAULT_INPUT_UNIT = INPUT_UNIT.TEN_MAN_WON;
