import { actions } from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import {
	BankInfo,
	changeBankAccountAction,
	getBankAccountInfoAction,
	setBankAccountInfoResult,
	setChangingBankAccountResult,
	setError,
} from '@peoplefund/slices/common-investing';
import { concat, Observable, of } from 'rxjs';
import { CometAjax } from './ajax.util';
import { getUserId } from '@peoplefund/utils/jwt.util';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { bankMaintenanceTime } from '@peoplefund/utils/date.util';
import { fetchBankListObservable } from './bankCode.util';
import { IMAGE_DEFAULT_BANK_LOGO } from './bankCode.constant';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';

export interface BankCodeInfoServerResponse {
	bank_code: string;
	bank_name: string;
	full_name: string;
	bank_type: string;
	logo_url: string;
	password_verification_available: boolean;
	is_active: boolean;
}

const getBankAccountInfo: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getBankAccountInfoAction),
		withLatestFrom(state$),
		mergeMap(
			([
				//eslint-disable-next-line @typescript-eslint/no-unused-vars
				_,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					fetchBankListObservable(cometAjax, false).pipe(
						mergeMap((bankCodes: BankInfo[]) => {
							if (!token) {
								return of(
									setError(new AlertCommonError('로그인이 필요한 기능입니다.', PhoenixErrorCode.LOGIN_REQUIRED))
								);
							}
							return concat(
								cometAjax.inapi
									.get(`/account/users/:user_id/`, {
										token,
									})
									.pipe(
										map((bankAccountInfo) => {
											return setBankAccountInfoResult({
												bankLogo:
													bankCodes.find((bankCode) => bankCode.bankCode === bankAccountInfo.account_showcase_bank_code)
														?.bankLogo ?? IMAGE_DEFAULT_BANK_LOGO,
												bankCode: bankAccountInfo.account_showcase_bank_code,
												bankName: bankAccountInfo.account_showcase_bank_name,
												bankNumber: bankAccountInfo.account_showcase_num,
												accountStatus: bankAccountInfo.account_showcase_status,
												status: 'success',
											});
										})
									)
							);
						}),
						catchError((error) =>
							of(
								setError(error),
								setBankAccountInfoResult({
									bankLogo: '',
									bankCode: '',
									bankName: '',
									bankNumber: '',
									accountStatus: '',
									status: 'fail',
								})
							)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const checkingUserBankAccountObservable = (
	cometAjax: CometAjax<BankCodeInfoServerResponse[]>,
	body: {
		bank_code: string;
		account_number: string;
		user_id: number;
	}
): Observable<BankCodeInfoServerResponse[]> => {
	return cometAjax.inapi
		.post('/verification/bank-account/', {
			body,
		})
		.pipe(
			map((response) => {
				return response;
			})
		);
};

const changingBankAccount: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(changeBankAccountAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const userId = getUserId(token ?? '') ?? -1;

				return concat(
					of(actions.layout.startLoading()),
					checkingUserBankAccountObservable(cometAjax, {
						bank_code: payload.bankCode,
						account_number: payload.bankAccountNumber,
						user_id: userId,
					}).pipe(
						mergeMap(() => {
							return concat(
								cometAjax.inapi
									.put(`/account/users/:user_id/bank-account-registration/`, {
										token,
										body: {
											bank_code: payload.bankCode,
											account_number: payload.bankAccountNumber,
										},
									})
									.pipe(
										map(() => {
											return setChangingBankAccountResult({
												status: 'success',
											});
										})
									)
							);
						}),
						catchError((error) => {
							let title = '계좌 변경에 실패했어요';
							let message = '서버와의 통신이 불안정해요\n새로고침 후 다시 시도해 주세요';

							if (bankMaintenanceTime()) {
								title = '은행 점검 시간(23:00~00:30)에는\n계좌를 변경할 수 없어요';
								message = '';
							}

							if (error.message) {
								const originErrorMessage = Array.isArray(error.message) ? error.message[0] : error.message;

								switch (originErrorMessage) {
									case '변경하려는 지정계좌가 현재 등록된 지정계좌와 동일합니다.':
										message = '입력하신 계좌는 현재 등록된 계좌예요';
										break;
									case '본인 소유의 계좌를 입력해 주세요.':
										message = '본인 명의의 계좌를 입력해 주세요';
										break;
								}
							}
							return of(
								setError(new AlertCommonError(message, error.code, title)),
								setChangingBankAccountResult({
									status: 'fail',
								})
							);
						})
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [getBankAccountInfo, changingBankAccount];
