import { AlertCommonError } from '@peoplefund/constants/error/type';
import { ARSVerifyParams, ARSVerifyStatus, BankAccountChangeParam, CMSLoan } from './index.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankInfo } from '@peoplefund/slices/common-investing';
import { IAgreement } from '@peoplefund/constants/agreement/type';

const SLICE_NAME = 'loansAutopay';

export interface LoansAutopay {
	error?: AlertCommonError;
	loanList: CMSLoan[];
	bankList: BankInfo[];
	currLoan?: CMSLoan;
	verifyStatus: ARSVerifyStatus;
	verificationId: string;
	agreements: IAgreement[];
	bankAccountChangeCompleted: boolean;
}

export const loanAutopaySliceInitialState: LoansAutopay = {
	error: undefined,
	loanList: [],
	bankList: [],
	currLoan: undefined,
	verifyStatus: 'NONE',
	verificationId: '',
	agreements: [],
	bankAccountChangeCompleted: false,
};

const loanAutopaySlice = createSlice({
	name: SLICE_NAME,
	initialState: loanAutopaySliceInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, loanAutopaySliceInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		loadList: (state) => {
			state.error = undefined;
			state.currLoan = undefined;
			state.verifyStatus = 'NONE';
			state.verificationId = '';
			state.bankAccountChangeCompleted = false;
		},
		setLoanList: (state, action: PayloadAction<{ list: CMSLoan[] }>) => {
			state.loanList = action.payload.list;
		},
		setBankList: (state, action: PayloadAction<{ list: BankInfo[] }>) => {
			state.bankList = action.payload.list;
		},
		setCurrLoan: (state, action: PayloadAction<CMSLoan>) => {
			state.currLoan = action.payload;
		},
		resetCurrLoan: (state) => {
			state.currLoan = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestARSVerify: (state, _: PayloadAction<ARSVerifyParams>) => {
			state.error = undefined;
			state.verifyStatus = 'NONE';
		},
		setARSVerifyVerificationId: (state, action: PayloadAction<string>) => {
			state.verificationId = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getARSVerifyStatus: (state, _: PayloadAction<{ verificationKey: string; txNumber: string }>) => {
			state.error = undefined;
		},
		setARSVerifyStatus: (state, action: PayloadAction<ARSVerifyStatus>) => {
			state.verifyStatus = action.payload;
		},
		initARSVerify: (state) => {
			state.verifyStatus = 'NONE';
			state.verificationId = '';
		},
		setAgreement: (state, action: PayloadAction<IAgreement[]>) => {
			state.agreements = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestChangeBankInfo: (state, _: PayloadAction<BankAccountChangeParam>) => {
			state.error = undefined;
			state.bankAccountChangeCompleted = false;
		},
		updateBankAccountInfo: (state, action: PayloadAction<BankAccountChangeParam>) => {
			state.bankAccountChangeCompleted = true;
			const { currLoan } = state;
			const { bankCode, bankAccountNumber } = action.payload;
			if (currLoan) {
				currLoan.cmsAccountBankCode = bankCode;
				currLoan.cmsAccountNumber = bankAccountNumber;
			}
		},
		resetBankInfoStatus: (state) => {
			state.bankAccountChangeCompleted = false;
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	loadList,
	setLoanList,
	setBankList,
	requestARSVerify,
	setARSVerifyVerificationId,
	getARSVerifyStatus,
	setARSVerifyStatus,
	initARSVerify,
	setCurrLoan,
	resetCurrLoan,
	setAgreement,
	requestChangeBankInfo,
	updateBankAccountInfo,
	resetBankInfoStatus,
} = loanAutopaySlice.actions;

export default loanAutopaySlice.reducer;
