import { ColorType } from '@pfct/purple';

export interface VerifyIdCardCommonProps {
	readonly colorType: ColorType;
	readonly onButtonClick: () => void;
	readonly isLoading?: boolean;
}

export const VERIFY_TYPE = {
	ID_CARD: '주민등록증',
	DRIVERS_LICENSE: '운전면허증',
} as const;
export type VerifyType = (typeof VERIFY_TYPE)[keyof typeof VERIFY_TYPE];

export const VerifyTypeToCode = {
	[VERIFY_TYPE.ID_CARD]: '01',
	[VERIFY_TYPE.DRIVERS_LICENSE]: '02',
} as const;

export const VerifyTypeToCodeV2 = {
	[VERIFY_TYPE.ID_CARD]: 'registration_card',
	[VERIFY_TYPE.DRIVERS_LICENSE]: 'driver_license',
} as const;

export const IdOptions = [VERIFY_TYPE.ID_CARD, VERIFY_TYPE.DRIVERS_LICENSE].map((item) => ({
	value: item,
	label: item,
}));

export type VerifyTwoPlusOneIdCardSetting = Pick<VerifyIdCardCommonProps, 'colorType'> & {
	readonly moveToNext: () => void;
};
