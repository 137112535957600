export enum DetailedLoanType {
	UNKNOWN = '',
	개인신용분산투자선순위 = '개인신용분산투자선순위',
	개인신용분산투자후순위 = '개인신용분산투자후순위',
	개인신용디딤돌 = '개인신용디딤돌',
	CLSA = 'CLSA',
	아파트담보 = '아파트담보',
	부동산담보 = '부동산담보',
	부동산PF = '부동산PF',
	브릿지론 = '브릿지론',
	ABL = 'ABL',
	이커머스선정산 = '이커머스선정산',
	온라인몰선정산 = '온라인몰선정산',
	임팩트투자 = '임팩트투자',
	중소기업 = '중소기업',
	문화컨텐츠 = '문화컨텐츠',
	홈쇼핑 = '홈쇼핑',
	부동산분산투자선순위 = '부동산분산투자선순위',
	부동산분산투자후순위 = '부동산분산투자후순위',
	가상채권 = '가상채권',
}

export interface MortgageLoanInfo {
	amount_loan_for_repayment: number;
	appraised_highest_price: number;
	appraised_lowest_price: number;
	apt_name: string;
	apt_size: string;
	auction_price_location: string;
	auction_price_months: number;
	auction_price_ratio: number;
	capital_preservation_insurance: boolean;
	certified_copy_of_register: null;
	conditions_of_location: string;
	corp_appraised_price: number;
	date_of_construction: string;
	deposit_for_lease: number;
	exclusive_area_m2: number;
	exclusive_area_pyeong: number;
	floor: number;
	loan_purpose: string;
	location: string;
	molit_price: number;
	molit_price_base_date: string;
	monthly_rent_deposit: number;
	npl_letter_of_intent: boolean;
	num_of_households: null;
	pf_appraised_price: number;
	residence_status: boolean;
	security_type: string;
	source_of_repayment: string;
	underlying_mortgage_loan_amount: number;
}

export interface UnderlyingAsset {
	annual_income: number;
	loan_application_amount: number;
	loan_application_id: number;
	credit_score: number;
	nice_grade: number;
}

export interface LoanApplication {
	alternative_schedules: [];
	close_date_time: null;
	corporate_amount: number;
	corporate_count: number;
	etc_data_01: null;
	etc_data_02: null;
	etc_data_03: null;
	etc_data_04: null;
	etc_data_05: null;
	executed_datetime: null;
	expected_profit: Record<string, number>;
	extended_loan_title: null;
	fund_end_datetime: string | null;
	fund_pre_start_datetime: string;
	fund_start_datetime: string;
	fund_waiting_datetime: string;
	grace_month: number;
	individual_amount: number;
	individual_count: number;
	institute_amount: number;
	institute_count: number;
	interest_rate: number;
	interest_term_type: string;
	investor_amount: number;
	is_parent_loan_for_regeneration: boolean;
	loan_application_amount: number;
	loan_application_id: number;
	loan_application_term: number;
	loan_id_text: string;
	loan_ordering: string;
	loan_section: string;
	loan_type: string;
	note: null;
	commission: string;
	repayment_type: string;
	seo_description: null;
	seo_title: null;
	uri: string;
	closed: boolean;
	loan_application_status: LoanApplicationStatus;
	detailed_loan_type: DetailedLoanType;
	net_interest_rate?: number;
	expected_interest_rate?: number;
	is_invest_limit: boolean;
	sub_index: number;
	first_uri: string;
	title: string;
	sub_title: string;
	index: number;
	total_loan_amount: number;
	parent_loan_id: number;
	tranche_id: number;
	underlying_asset_count?: number;
	average_annual_income?: number;
	average_credit_grade?: number;
	average_credit_score?: number;
	average_loan_amount?: number;
	mortgage_loan_info?: MortgageLoanInfo;
	borrower_name: string;
	borrower_info: null;
	seq_count: number;
	underlying_loans: [];
	underlying_assets: UnderlyingAsset[];
	max_investment_amount: number;
	min_investment_amount: number;
	platform_commission?: string;
}

export enum LoanApplicationStatus {
	UNKNOWN = '',
	마감대기중 = '마감대기중',
	매각완료 = '매각완료',
	본인취소 = '본인취소',
	상환완료 = '상환완료',
	상환중 = '상환중',
	상환지연 = '상환지연',
	실행대기중 = '실행대기중',
	연체중 = '연체중',
	장기연체 = '장기연체',
	정보등록완료 = '정보등록완료',
	채권종결 = '채권종결',
	투자모집마감 = '투자모집마감',
	투자모집중 = '투자모집중',
	투자모집중내부테스트 = '정보등록완료',
	투자모집실패 = '투자모집실패',
}

export enum Platform {
	PF = 'pf',
	KP = 'kp',
}
