import actionCreatorFactory from 'typescript-fsa';
import { ActionTypes } from '@peoplefund/constants/action-types';
import { EmptyParam, EmptyResult } from '@peoplefund/actions/common';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { CorporateInfo, InvestorInfo, UserInfo, UserInvestorStatus } from '@peoplefund/reducers/account/index.model';
import { AccountState } from '@peoplefund/reducers/account';

interface SignInByEmailParam {
	readonly email: string;
	readonly password: string;
}

export interface SignInResult {
	readonly expiredAt: number;
	readonly accessToken: string;
	readonly refreshToken: string;
}

interface SignInParam extends SignInResult {
	readonly temporaryPasswordUsed?: boolean;
}

export type FetchUserInfoResult = UserInfo & {
	readonly userFinanceStatus?: UserInvestorStatus;
	readonly hasRRN: boolean;
	readonly corporateInfo?: CorporateInfo;
};

type UpdateInvestorInfoResult = Pick<InvestorInfo, 'accountBalance' | 'remainP2PInvestableAmount'>;

export type AmlStatusParam = Pick<AccountState['amlStatus'], 'fetched' | 'status' | 'kycExpireDate'>;

const actionCreator = actionCreatorFactory('ACCOUNT');

export default {
	init: actionCreator(ActionTypes.ACCOUNT_INIT),
	errorReset: actionCreator(ActionTypes.ACCOUNT_ERROR_RESET),
	signIn: actionCreator<SignInParam>(ActionTypes.ACCOUNT_SIGN_IN),
	setToken: actionCreator<Omit<SignInResult, 'expiredAt'>>(ActionTypes.ACCOUNT_SET_TOKEN),
	resetToken: actionCreator(ActionTypes.ACCOUNT_RESET_TOKEN),
	signInByEmail: actionCreator.async<SignInByEmailParam, EmptyResult, InAPICommonError>(
		ActionTypes.ACCOUNT_SIGN_IN_BY_EMAIL
	),
	signOut: actionCreator.async<EmptyParam, EmptyResult, InAPICommonError>(ActionTypes.ACCOUNT_SIGN_OUT),
	fetchUserInfo: actionCreator.async<EmptyParam, FetchUserInfoResult, InAPICommonError>(
		ActionTypes.ACCOUNT_FETCH_USER_INFO
	),
	setCorporateInfo: actionCreator<CorporateInfo>(ActionTypes.ACCOUNT_SET_CORPORATE_INFO),
	setAmlStatus: actionCreator<AmlStatusParam>(ActionTypes.ACCOUNT_SET_AML_STATUS),
	setEmail: actionCreator<string>(ActionTypes.ACCOUNT_SET_EMAIL),
	setInvestorInfo: actionCreator<InvestorInfo>(ActionTypes.ACCOUNT_SET_INVESTOR_INFO),
	checkInvestorRegisterAvailable: actionCreator.async<EmptyParam, EmptyResult, InAPICommonError>(
		ActionTypes.ACCOUNT_CHECK_INVESTOR_REGISTER_AVAILABLE
	),
	updateInvestorInfo: actionCreator.async<EmptyParam, UpdateInvestorInfoResult, InAPICommonError>(
		ActionTypes.ACCOUNT_UPDATE_INVESTOR_INFO
	),
	initAMLFlow: actionCreator(ActionTypes.ACCOUNT_INIT_AML_FLOW),
	fetchAMLStatus: actionCreator.async<EmptyParam, AmlStatusParam, InAPICommonError>(
		ActionTypes.ACCOUNT_FETCH_AML_STATUS
	),
	setPhoneNumber: actionCreator<string>(ActionTypes.ACCOUNT_SET_PHONE_NUMBER),
};
