import { SelectOptionProps } from '@pfct/purple';
import { ConvertInformationsServerResponse, InstitutionServerProps } from '@peoplefund/epics/ls-common.model';
import { Institution, SelectOption } from '@peoplefund/constants/ls-loan-types';
import { map, mergeMap } from 'rxjs/operators';
import { FinancialProductApplicationResponse, LoanInProgressResponse } from '@peoplefund/epics/ls-loan.model';
import { CometAjax } from '@peoplefund/epics/ajax.util';
import { Observable } from 'rxjs';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { LoadLoanResult } from '@peoplefund/actions/ls-loan';
import { convertFinancialProductResult, convertLoanResult, fpaRejected } from '@peoplefund/epics/ls-loan.util';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { ProductInformationProps } from '@peoplefund/components/common/ProductInformation';
import { ProductInformationItem } from '@peoplefund/actions/ls-financial-product';
import { InformationType } from '@peoplefund/constants/ls-financial-detail-types';

export const convertInstitutionResult = (result: InstitutionServerProps[]): Institution[] =>
	result.map(({ id, name, logo_url, max_amount, max_interest_rate, staple_product }: InstitutionServerProps) => ({
		id,
		name,
		logoUrl: logo_url,
		maxAmount: max_amount,
		maxInterestRate: max_interest_rate,
		stapleProduct: staple_product,
	}));

export const convertEnumsToSelectOptionProps = <ValueType>(
	result: SelectOption<ValueType>[]
): SelectOptionProps<ValueType>[] => result.map(({ key, content }) => ({ label: content, value: key }));

export const getLoanResult = (
	cometAjax: CometAjax<any>,
	token: string,
	includeFailure: 'true' | 'false'
): Observable<LoadLoanResult> => {
	const getLastLoan = (
		cometAjax: CometAjax<LoanInProgressResponse | FinancialProductApplicationResponse>,
		token: string
	): Observable<LoadLoanResult> => {
		return cometAjax.loan
			.get('/v1/personal-loan/loan-application/in-progress', {
				token,
			})
			.pipe(
				mergeMap((response) => {
					const { loan_application, financial_product_application } = response as LoanInProgressResponse;

					const hasApprovedFPA = Boolean(financial_product_application?.id);
					if (!loan_application?.id) {
						throw new AlertCommonError('진행중인 대출이 없습니다.', PhoenixErrorCode.EMPTY_LOANS);
					} else if (financial_product_application?.id) {
						throw new AlertCommonError(
							'앱에서 이어서 진행해주세요.',
							PhoenixErrorCode.HAS_LOAN_APPLICATION_IN_PROGRESS,
							'계약중인 대출이 있어요!'
						);
					} else {
						return cometAjax.loan
							.get(
								`/v1/personal-loan/loan-applications/${loan_application.id}/loan-product-applications?include_failures=${includeFailure}`,
								{
									token,
								}
							)
							.pipe(
								map((response) => {
									const loanResult = convertLoanResult(loan_application, hasApprovedFPA);
									const convertedResponse = response as FinancialProductApplicationResponse;

									//api 결과 파싱
									const fpas = {
										...convertFinancialProductResult(convertedResponse),
										loanStatus: fpaRejected(convertedResponse)
											? LSLoanStatus.REJECTED
											: loan_application.last_status.status,
									};
									//최종 response type인 LoadLoanResult 반환
									return {
										...loanResult,
										...fpas,
									};
								})
							);
					}
				})
			);
	};

	return getLastLoan(cometAjax, token).pipe(
		map((response) => {
			return response as LoadLoanResult;
		})
	);
};

export const convertInformations = ({ informations }: ConvertInformationsServerResponse): ProductInformationProps => {
	const SIMSAPIL = '심사필';

	/**
	 * 단순 배열을 title 키와 description 배열 값으로 만들어줍니다.
	 */
	const makeTitleDescriptionsMap = ({
		informations,
	}: {
		readonly informations: ConvertInformationsServerResponse['informations'];
	}): [string, string[]][] => {
		const ITEM_TYPE_MIDDLE_TITLE = InformationType.H3B;
		const ITEM_TYPE_NORMAL_TEXT = InformationType.H3;
		const ITEM_TYPE_SIMSAPIL_TEXT = InformationType.H3O;

		const titleDescriptionsMap: { [key: string]: string[] } = {};

		let title = '';

		for (let i = 0; i < informations.length; i++) {
			const item = informations[i];

			if (item.type === ITEM_TYPE_MIDDLE_TITLE) {
				title = item.content;

				if (!titleDescriptionsMap[title]) {
					titleDescriptionsMap[title] = [];
				}
			} else if (item.type === ITEM_TYPE_NORMAL_TEXT) {
				titleDescriptionsMap[title].push(item.content || '');
			} else if (item.type === ITEM_TYPE_SIMSAPIL_TEXT) {
				/**
				 * 심사필은 상품 정보에 1개밖에 없다고 가정함. (여러개 와도 가장 마지막에 심사필 스타일로 적용된 텍스트만 반영함)
				 * 참고: 제휴사 상품 정보 수정 CMS - https://dev-cms.dev.peoplefund.co.kr/product-notice/affiliate-product-notice/PF0002
				 */
				titleDescriptionsMap[SIMSAPIL] = [item.content];
			}
		}

		return Object.entries(titleDescriptionsMap);
	};

	const makeMainSubDisclaimerMap = (titleDescriptionsTuple: readonly [string, string[]][]): ProductInformationProps => {
		const DISCLAIMER_TITLE = '기타 안내사항';

		const tableList: ProductInformationItem[] = [];
		const disclaimer: string[] = [];

		titleDescriptionsTuple.forEach((tuple) => {
			const [title, descriptions] = tuple;

			/** 인트로 페이지에서 심사필 문구도 기타 안내사항 섹션에 포함되어 보여져야 함 */
			if (title === DISCLAIMER_TITLE || title === SIMSAPIL) {
				disclaimer.push(...descriptions.map((item) => item.replace('■ ', '')));
			} else {
				tableList.push({ title, description: descriptions.join('\n') });
			}
		});

		return {
			main: tableList?.slice(0, 4) || [],
			sub: tableList?.slice(4) || [],
			disclaimer,
		};
	};

	return makeMainSubDisclaimerMap(makeTitleDescriptionsMap({ informations }));
};
