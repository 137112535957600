export const PhoenixErrorCode = {
	MOVE: 'MOVE',
	HAS_LOAN_APPLICATION_IN_PROGRESS: 'PHOENIX_LS_ERROR_001',
	HAS_LOAN_APPLICATION: 'PHOENIX_LS_ERROR_004',
	HAS_LOAN_APPLICATION_DIFFERENT_FLOW: 'HAS_LOAN_APPLICATION_DIFFERENT_FLOW',
	EMPTY_LOANS: 'PHOENIX_LS_ERROR_003',
	LOGIN_REQUIRED: 'PHOENIX_LS_ERROR_005',
	INVESTOR_STATUS_ACCOUNT_NOT_REGISTERED: 'PHOENIX_LS_ERROR_006',
	INVESTOR_STATUS_AML_CHECK_NEEDED: 'PHOENIX_LS_ERROR_007',
	INVESTOR_STATUS_AML_1_WLF_FAILED: 'PHOENIX_LS_ERROR_008',
	INVESTOR_REGISTERED: 'PHOENIX_LS_ERROR_009',
	MOVE_TO_MORTGAGE_LOAN_APP_DOWNLOAD: 'PHOENIX_LS_ERROR_010',
	MOVE_TO_MORTGAGE_LOAN_PEOPLEFUND: 'PHOENIX_LS_ERROR_011',
	KFTC_SYSTEM_UNDER_MAINTENANCE: 'INAPI:KFTC_SYSTEM_UNDER_MAINTENANCE',
	IS_NOT_INVESTOR: 'INAPI:IS_NOT_INVESTOR',
	DEALPAGE_NOT_FOUND: 'DEALPAGE_NOT_FOUND',
	EMAIL_NOT_FOUND: 'PFUSER:EMAIL_NOT_FOUND',
	SMS_VERIFY_TIME_EXPIRED: 'SMS_VERIFY_TIME_EXPIRED',
	SMS_VERIFY_INVALID_INFO: 'SMS_VERIFY_INVALID_INFO',
	CHANGE_USING_PHONE_NUMBER: 'CHANGE_USING_PHONE_NUMBER',
	CREDIT_CHECK_BLOCKED: 'CREDIT_CHECK_BLOCKED',
	REUSABLE_MATERIAL_NOT_EXIST: 'REUSABLE_MATERIAL_NOT_EXIST',
	CMS_ARS_VERIFICATION_FAILED: 'CMS_ARS_VERIFICATION_FAILED',
	CMS_NOT_REGISTRABLE: 'CMS_NOT_REGISTRABLE',
	EMPTY_CMS_LOAN_LIST: 'EMPTY_CMS_LOAN_LIST',
	CMS_CHANGE_BANK_ACCOUNT_FAILED: 'CMS_CHANGE_BANK_ACCOUNT_FAILED',
	CMS_CHANGE_BANK_ACCOUNT_CHANGE_REQUIRED: 'CMS_CHANGE_BANK_ACCOUNT_CHANGE_REQUIRED',
	EMPTY_APPLY_MATERIAL: 'EMPTY_APPLY_MATERIAL',
	INVESTING_REGISTRATION_COMPLETED: 'INVESTING_REGISTRATION_COMPLETED',
	USERINFO_NOT_FETCHED: 'USERINFO_NOT_FETCHED',
	UNKNOWN_INVESTMENT_PRODUCT_URI: 'UNKNOWN_INVESTMENT_PRODUCT_URI',
	VERIFY_ID_CARD_OCR_FAILED: 'VERIFY_ID_CARD_OCR_FAILED',
	VERIFY_ID_CARD_OCR_FAILED_IS_NOT_IDCARD: 'VERIFY_ID_CARD_OCR_FAILED_IS_NOT_IDCARD',
	VERIFY_ID_CARD_MANUAL_FAILED: 'VERIFY_ID_CARD_MANUAL_FAILED',
	VERIFY_ACCOUNT_FAILED: 'VERIFY_ACCOUNT_FAILED',
	CAMERA_NOT_AVAILABLE: 'CAMERA_NOT_AVAILABLE',
	CORP_USER_NOT_AVAILABLE: 'CORP_USER_NOT_AVAILABLE',
	UNKNOWN: 'UNKNOWN',
} as const;

export const PFUserErrorCode = {
	INVALID_PARAMETER: 'COMMON:INVALID_PARAMETER',
	UNEXPECTED_ERROR: 'COMMON:UNEXPECTED_ERROR',
	USER_NOT_FOUND: 'USER:USER_NOT_FOUND',
	RRN_VALIDATION_FAILED: 'USER:RRN_VALIDATION_FAILED',
	NOT_MATCH_USER_INFO: 'USER:NOT_MATCH_USER_INFO',
	INTEG_NOT_FOUND_EXTERNAL_USER: 'USER:INTEG_NOT_FOUND_EXTERNAL_USER',
	AUTHENTICATION_EMAIL_OR_PASSWORD_NOT_MATCHED: 'AUTH:AUTHENTICATION_EMAIL_OR_PASSWORD_NOT_MATCHED',
	AUTHENTICATION_PASSWORD_NOT_CHANGED: 'AUTH:AUTHENTICATION_PASSWORD_NOT_CHANGED',
	AUTHENTICATION_IS_LOCKED: 'AUTH:AUTHENTICATION_IS_LOCKED',
	AUTHORIZATION_FAILED: 'AUTH:AUTHORIZATION_FAILED',
	OVER_AGE_19_INVALID: 'AUTH:OVER_AGE_19_INVALID',
	SEND_IDENTITY_VERIFICATION_CODE_FAILED: 'AUTH:SEND_IDENTITY_VERIFICATION_CODE_FAILED',
	IDENTITY_VERIFICATION_FAILED: 'AUTH:IDENTITY_VERIFICATION_FAILED',
	AUTHENTICATION_EMAIL_DUPLICATED: 'AUTH:AUTHENTICATION_EMAIL_DUPLICATED',
	AUTHENTICATION_USER_ALREADY_REGISTERED: 'AUTH:AUTHENTICATION_USER_ALREADY_REGISTERED',
	AUTHENTICATION_EXPIRED_PASSWORD: 'AUTH:AUTHENTICATION_EXPIRED_PASSWORD',
	USER_RESIGN_CONSTRAINT_ERROR: 'USER:USER_RESIGN_CONSTRAINT_ERROR',
	USER_LEAVE_CONSTRAINT_LOAN: 'USER:USER_LEAVE_CONSTRAINT_LOAN',
	USER_LEAVE_CONSTRAINT_INVEST: 'USER:USER_LEAVE_CONSTRAINT_INVEST',
	USER_LEAVE_CONSTRAINT_REPAYMENT: 'USER:USER_LEAVE_CONSTRAINT_REPAYMENT',
	AML_NOT_PROCEED: 'AML:NOT_PROCEED',
} as const;
