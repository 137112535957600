import LOG_EVENT_NAME from '@peoplefund/constants/log-event';
import { GtagVoteCategory, GtagVoteStatus } from '@peoplefund/slices/vote/index.model';
import UtmFactory from './UtmModule';
import { OPEN_STATUS_OPTION_LIST } from '@peoplefund/components/investing/hooks/constant';
import { WebviewHandler } from '../webview-handler/index.util';

type GtagInvestmentOpenStatus = (typeof OPEN_STATUS_OPTION_LIST)[number]['label'];
export enum GtagEventCategory {
	COMMON = 'common',
	PFLOAN = 'pfloan',
	MORTGAGE = 'mortgage loan',
	INVEST = 'invest',
	LOANSHOT = 'compare',
	MKT_LANDING = 'mkt_landing',
	LOANS_AUTOPAY = 'loans_autopay',
	LOANS_MYPAGE = 'loans_mypage',
	VOTE = 'vote',
	USER = 'user',
	AML = 'aml',
	EMBEDDED_FINANCE = 'embedded_finance',
	CREDIT_PLANET = 'credit_planet',
}

type GtagCMSStatus = 'registering' | 'registered' | 'new';

export interface GtagEventParams {
	/** --- default --- */
	button_type?: string;
	pageType?: string;

	/** pfloan, mortgage 등 필수로 입력해야합니다. */
	event_category: GtagEventCategory;
	segment?: string;
	loan_type?: 'PF' | 'ML' | 'LS' | 'MLLS' /** TODO: 추후 event_category 랑 정리 필요 */;
	// "PF" (개신대PF) or "ML" (주담대PF) or "LS" (개신대LS) or “MLLS” (주담대LS)
	product_category_type?: string; // 투자 상품 타입
	guide_type?: '피플펀드투자' | '크플투자' | '아파트투자' | 'initialguide' | '2ndsalary' | 'aptguide';

	/** --- optional --- */
	loan_application_id?: string;
	house?: string;
	insurance?: string;
	loanPurpose?: string;
	product_code?: string;
	errorMessage?: string;
	loanAmount?: number;
	job?: string;
	income?: number;
	realEstateNo?: string;
	tooltip?: '조건변경' | '플랫폼이용료';
	target?: string;
	deposit?: number;
	type?: string;
	reuse?: 'true' | 'false';
	registration_no?: string;
	affiliate?: string;
	search_type?: 'select' | 'add';
	trial_count?: number;
	search_keyword?: string;
	jibun_address?: string;
	road_address?: string;
	query?: string;

	/** 🚨  브릿지용 입니다. */
	loanId?: number;
	platform?: string;
	lpa_id?: number;

	/** 투자 - 딜페이지용입니다. */
	product_status?: '오픈예정' | '모집중' | '모집마감' | '';
	investment_status?: '신청완료' | '미신청';

	/** 투자 - 투자flow */
	count?: number;
	uri?: string;
	point?: number;
	investment_type?: string;
	product_uri_list?: string;
	success_product_uri_list?: string;
	fail_product_uri_list?: string;
	total_amount?: number;
	amount?: number;
	success_amount?: number;
	flow?: string;
	remaining_account?: number;
	point_applied?: number;
	point_available?: number;
	action?: 'on' | 'off';
	apply_type?: 'account' | 'point';

	/** 투자 - 주담투 리스트 */
	si_do?: string;
	si_gun_gu?: string;
	min_ltv?: number;
	max_ltv?: number;
	tag_type?: string[];
	invest_period?: number;
	collateral_type?: string[];
	min_recruit_rate?: number;
	max_recruit_rate?: number;
	min_interest_rate?: number;
	max_interest_rate?: number;
	order_type?: string;
	order_value?: string;
	list_type?: string;

	/** 밀리언 사전 신청 용입니다. */
	userName?: string;
	userMobile?: string;

	/** 대출 자동이체 등록 */
	loan_id?: string;
	status?: GtagVoteStatus | GtagCMSStatus | GtagInvestmentOpenStatus;

	/** 투자자 투표 */
	vote_loan_id?: number;
	category?: GtagVoteCategory;

	platformcode?: string;

	kyc_expiration_date?: string;
	account?: string;
	purpose?: string;
	source?: string;
	industry?: string;
	country?: string;
	postcode?: string;
	tel_number?: string;

	count_loan_in_progress?: number;
	count_loan_ended?: number;
	is_cms_activated?: boolean;

	app_type?: string;

	result?: string;
	is_investor?: boolean;
	event_key?: string;
	key?: string;

	is_revisit?: boolean;
	tarottopic?: string;
	card?: string;
	user_status?: string;

	value?: string;
	page?: string;
	profit?: string;
	current_index?: number;
	propensity_type?: string;

	// 투자탭 '금액 숨김' 클릭
	// on (체크활성화), off (체크비활성화)
	setting?: 'on' | 'off';
}

interface Gtag {
	/** 사용시 오픈하겠습니다. */
	// (command: 'config', targetId: string, config?: ControlParams | EventParams | ConfigParams | CustomParams): void;
	// (command: 'set', targetId: string, config: CustomParams | boolean | string): void;
	// (command: 'set', config: CustomParams): void;
	// (command: 'js', config: Date): void;
	// 	(command: 'get', targetId: string, fieldName: FieldNames | string, callback?: (field: string) => any): void;
	// (command: 'consent', consentArg: ConsentArg | string, consentParams: ConsentParams): void;

	(command: 'event', eventName: LOG_EVENT_NAME, eventParams: GtagEventParams): void;

	/**
	 * Gtag.Gtag 에서 정의한 타입은 항상 command 를 받고 있습니다.
	 * 예로, gtag('event', ....)
	 *
	 * 하지만, dataLayer에 바로 push 하는 경우도 필요합니다.
	 * 예로, gtag({user_id : value})
	 */

	(customParams: { user_id?: number }): void;
}

export type GtagFuncType = (eventName: LOG_EVENT_NAME, eventParams?: Omit<GtagEventParams, 'event_category'>) => void;

type ServiceMode = string;
export type EventType = {
	EventName: LOG_EVENT_NAME;
	EventParams: GtagEventParams;
	Prefix_EventName: `${ServiceMode}_${LOG_EVENT_NAME}` | LOG_EVENT_NAME;
};

const addServiceModeToEventName = (eventName: EventType['EventName']): EventType['Prefix_EventName'] => {
	const serviceMode = process.env.NEXT_PUBLIC_STAGE_MODE;
	let serviceModeEventName: EventType['Prefix_EventName'];
	if (!Boolean(serviceMode)) {
		serviceModeEventName = `local_${eventName}`;
	} else if (serviceMode === 'prd') {
		serviceModeEventName = eventName;
	} else {
		serviceModeEventName = `${serviceMode}_${eventName}`;
	}

	return serviceModeEventName;
};

export const gtag = function (command, eventName, eventParams) {
	// BUILD_ENV 는 AWS 환경변수에서 세팅된다.
	// gtag event fired 시 망별로 분리하길 바라는데,
	// prd 망에서는 prefix 없이
	// 그 외 local, dev, qa 에서는 prefix 를 붙이기로한다.

	if (command !== 'event') {
		window?.dataLayer?.push?.(command);
		return;
	}

	const serviceModeEventName = addServiceModeToEventName(eventName);
	//우선 보내야할 arguments 형태와 비슷하게 객체로 보냄.
	const utmModule = UtmFactory.instance;
	const utmObj = utmModule?.getUtm();
	const webviewHandler = WebviewHandler();

	const pfClientID = webviewHandler.appType;
	const isWebview = webviewHandler.isWebview;
	const bridge = webviewHandler.bridge;

	const newEventParams = { pf_client_id: pfClientID, ...eventParams, ...utmObj };

	if (isWebview) {
		bridge.sendEvent({
			name: eventName,
			params: newEventParams,
		});
	} else {
		window?.dataLayer?.push?.({
			0: command,
			1: serviceModeEventName,
			2: newEventParams,
			event: serviceModeEventName,
			eventModel: newEventParams,
		});
	}
} as Gtag;

export const lazyChangeLocationHref = ({ url, millisec = 500 }: { url: string; millisec?: number }) => {
	setTimeout(() => {
		location.href = url;
	}, millisec);
};

export const gtagForMortgageLoan: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.MORTGAGE,
		loan_type: 'ML',
		...eventParams,
	});
};

export const gtagForCompareMortgageLoan: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.LOANSHOT, // TODO
		loan_type: 'MLLS',
		...eventParams,
	});
};

export const gtagVoteEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.VOTE,
		...eventParams,
	});
};

export const gtagCommonEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.COMMON,
		...eventParams,
	});
};

export const gtagAMLEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.AML,
		...eventParams,
	});
};

export const gtagPFPersonalLoanEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.PFLOAN,
		...eventParams,
	});
};

export const gtagCpleTarotEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.CREDIT_PLANET,
		...eventParams,
	});
};

export const gtagCpleCommunityEvent: GtagFuncType = (eventName, eventParams) => {
	gtag('event', eventName, {
		event_category: GtagEventCategory.CREDIT_PLANET,
		...eventParams,
	});
};
