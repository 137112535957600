import common from '@peoplefund/actions/common';
import account from '@peoplefund/actions/account';
import smsVerification from '@peoplefund/actions/sms-verification';
import lsCommon from '@peoplefund/actions/ls-common';
import lsAuthentication from '@peoplefund/actions/ls-authentication';
import lsApplyInfo from '@peoplefund/actions/ls-apply-info';
import lsLoan from '@peoplefund/actions/ls-loan';
import lsFinancialProduct from '@peoplefund/actions/ls-financial-product';
import lsFinancialProductApplication from '@peoplefund/actions/ls-financial-product-application';
import layout from '@peoplefund/actions/layout';
import unsecuredInvestorRegistration from '@peoplefund/actions/unsecured-investor-registration';

export const actions = {
	common,
	account,
	smsVerification,
	lsCommon,
	lsAuthentication,
	lsApplyInfo,
	lsLoan,
	lsFinancialProduct,
	lsFinancialProductApplication,
	layout,
	unsecuredInvestorRegistration,
};
