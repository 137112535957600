import { RangeStep } from '@peoplefund/components/common/DoubleRangeSlider';
import { KRWUnit } from '@peoplefund/utils/format.util';
import { QUERY_KEY } from '@peoplefund/constants/pl-investing';

export type FilterField =
	| 'INTEREST_RATE'
	| 'INVEST_TERM'
	| 'PF_GRADE'
	| 'CREDIT_SCORE'
	| 'TARGET_AMOUNT'
	| 'FUNDING_RATE';

export type FilterValue = {
	start: string;
	end: string;
	queryKey: QUERY_KEY;
	changed: boolean;
};

type DefaultFilter = {
	label: string;
	field: FilterField;
	queryKey: QUERY_KEY;
	unit?: string;
	rangeSteps: RangeStep[];
};

// fyi) only for number
export const stepMaker = (range: [number, number] | undefined, step: number, unit = ''): RangeStep[] => {
	if (!range) {
		return [];
	}

	const [start, end] = range;
	return Array(Math.ceil((end + step - start) / step))
		.fill(start)
		.map((x, y) => ({
			label: `${x + y * step}${unit}`,
			value: `${x + y * step}`,
		}));
};

const MAX_LOAN_AMOUNT = KRWUnit.억;

export const defaultFilters: DefaultFilter[] = [
	{
		label: '수익률',
		queryKey: QUERY_KEY.INTEREST_RATE,
		field: 'INTEREST_RATE',
		unit: '%',
		rangeSteps: stepMaker([3, 20], 1, '%'),
	},
	{
		label: '투자기간',
		queryKey: QUERY_KEY.INVEST_TERM,
		field: 'INVEST_TERM',
		unit: '개월',
		rangeSteps: stepMaker([0, 120], 12, '개월'),
	},
	{
		label: 'PF등급',
		queryKey: QUERY_KEY.PF_GRADE,
		field: 'PF_GRADE',
		rangeSteps: ['D1', 'C3', 'C2', 'C1', 'B3', 'B2', 'B1', 'A3', 'A2', 'A1'].map(
			(item) => ({ value: item, label: item } as RangeStep)
		),
	},
	{
		label: '신용점수',
		queryKey: QUERY_KEY.CREDIT_SCORE,
		field: 'CREDIT_SCORE',
		unit: '점',
		rangeSteps: stepMaker([200, 1000], 100, '점'),
	},
	{
		label: '모집금액',
		queryKey: QUERY_KEY.TARGET_AMOUNT,
		field: 'TARGET_AMOUNT',
		unit: '만원',
		rangeSteps: stepMaker([0, 10000], 1000, '만원').map((step, idx, steps) => {
			if (idx === steps.length - 1) {
				//맨 마지막 값만 조절이 필요함
				return {
					label: '1억원',
					value: `${MAX_LOAN_AMOUNT}`,
				};
			} else {
				return {
					label: step.label,
					value: `${Number(step.value) * KRWUnit.만}`,
				};
			}
		}),
	},
	{
		label: '모집률',
		queryKey: QUERY_KEY.FUNDING_RATE,
		field: 'FUNDING_RATE',
		unit: '%',
		rangeSteps: stepMaker([0, 100], 10, '%'),
	},
];

export const defaultFilterState = defaultFilters.reduce((result, filter) => {
	const { field, rangeSteps, queryKey } = filter;
	const start = `${rangeSteps[0].value}`;
	const end = `${rangeSteps[rangeSteps.length - 1].value}`;

	result[field] = {
		start,
		end,
		queryKey,
		changed: false,
	};
	return result;
}, {} as Record<FilterField, FilterValue>);
