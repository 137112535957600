import {
	Badge,
	CommissionTypeCode,
	FinancialProductApplication,
	FinancialProductApplications,
	FinancialProducts,
} from '@peoplefund/constants/ls-loan-types';
import {
	FailedFinancialProductApplication,
	FinancialProductApplicationResult,
	LoanResult,
} from '@peoplefund/actions/ls-loan';
import {
	FailedFinancialProductApplicationServerProps,
	FinancialProductApplicationResponse,
	FinancialProductApplicationServerProps,
	LoanApplicationServerProps,
} from '@peoplefund/epics/ls-loan.model';
import { LSFinancialProductApplicationStatus } from '@peoplefund/constants/ls-loan-status';
import StatusManager from '@peoplefund/utils/ls-status.util';
import { uuid } from '@peoplefund/utils/uuid.util';

export const convertLoanResult = (response: LoanApplicationServerProps, hasApprovedFPA: boolean): LoanResult => {
	const status = response.last_status.status;
	return {
		id: response.id,
		status: status,
		expire: response.expire,
		pfOnly: response.is_pf_only,
		hasApprovedFPA,
	};
};

export const convertFPAResponse = (fpa: FinancialProductApplicationServerProps): FinancialProductApplication => {
	const {
		id,
		loan_product,
		loan_product: { is_pf, minimum_applied_amount, unit, info, notification },
		applied_amount,
		interest_rate,
		interest_rate_special_discount,
		commission_rate,
		integrated_interest_rate,
		maximum_amount,
		status: { status },
		badges,
		stage_display,
		pf_pre_screening,
		labels,
	} = fpa;

	const pfloan = is_pf;
	return {
		id,
		pfloan,
		financialProductId: loan_product.id,
		status: status as LSFinancialProductApplicationStatus,
		amount: applied_amount,
		maxAmount: maximum_amount,
		commissionRate: commission_rate,
		interestRate: interest_rate,
		integratedInterestRate: Number(integrated_interest_rate),
		interestRateDiscount: Number(interest_rate_special_discount),
		applyCompleted: false,
		applyAmountUnit: unit ?? 1,
		minimumApplyAmount: minimum_applied_amount ?? 0,
		processType: pfloan ? 'internal' : 'external',
		badges: badges as Badge[],
		applyInProcess: Boolean(stage_display),
		interestRateInfo: pf_pre_screening
			? {
					label: pf_pre_screening.interest_rate_label,
					toolTip: pf_pre_screening.interest_rate_tooltip,
					tip: pf_pre_screening.interest_rate_tip,
					disclaimer: pf_pre_screening.additional_descriptions,
					integratedToolTip: pf_pre_screening.integrated_interest_rate_tooltip,
			  }
			: undefined,
		labels,
		informations: info?.informations ?? [],
		notification: notification,
	};
};

export const fpaRejected = ({ loan_product_applications }: FinancialProductApplicationResponse): boolean =>
	loan_product_applications.length === 0 ||
	loan_product_applications.every((item) =>
		StatusManager.rejected(item.status.status as LSFinancialProductApplicationStatus)
	);

export const convertFailedFPAResponse = (
	fpa: FailedFinancialProductApplicationServerProps
): FailedFinancialProductApplication => {
	const {
		failure_type,
		description,
		loan_product: {
			name,
			institution: { name: institution, image_url },
		},
	} = fpa;

	return {
		id: uuid(),
		name,
		institution,
		iconUrl: image_url,
		failureType: failure_type,
		description: description || '',
	};
};

export const convertFinancialProductResult = ({
	loan_product_applications,
}: FinancialProductApplicationResponse): Omit<FinancialProductApplicationResult, 'loanStatus'> => {
	const fpa: FinancialProductApplications = {};
	const fp: FinancialProducts = {};

	loan_product_applications.forEach((item) => {
		const loanProduct = item.loan_product;
		const inst = loanProduct.institution;
		fp[loanProduct.id] = {
			id: loanProduct.id,
			name: loanProduct.name,
			code: loanProduct.code,
			commissionTypeCode: loanProduct.code as CommissionTypeCode,
			institution: {
				logoUrl: inst.image_url,
				name: inst.name,
			},
			information: [],
		};
		fpa[item.id] = convertFPAResponse(item);
	});

	return {
		fpa,
		fp,
	};
};
