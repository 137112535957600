type PostMessage = (message: string) => void;

interface IOSBridgeHandler {
	messageHandlers: {
		scriptHandler: {
			postMessage: PostMessage;
		};
	};
}

interface AndroidBridgeHandler {
	openLink(link: string): void;
	postMessage: PostMessage;
}

declare global {
	interface Window {
		// gtag 이벤트
		dataLayer: unknown[];

		// 카카오맵
		kakao: any;

		// 웹뷰 브릿지
		webkit: IOSBridgeHandler;
		ScriptHandler: AndroidBridgeHandler;
	}
}

export default {};
