import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { EmptyParam, EmptyResult } from '@peoplefund/actions/common';
import { AlertCommonError } from '@peoplefund/constants/error/type';

import { EMPLOYMENT_TYPE_CODE, FlowType, INCOME_TYPE_CODE, SelectOption } from '@peoplefund/constants/ls-loan-types';
import { IAgreement } from '@peoplefund/constants/agreement/type';
import { BlogArticle } from '@peoplefund/actions/ls-financial-product';
import { SelectOptionProps } from '@pfct/purple';

interface FetchAgreementsResult {
	id: string;
	title: string;
	name: string;
	disagreements: IAgreement[];
	agreements: IAgreement[];
	tip?: string[];
}

type LoanshotAgreement = Record<
	string,
	{
		agreed: boolean;
		disagreements: IAgreement[];
		agreements: IAgreement[];
		tip?: string[];
	}
>;

export type ILoanshotAgreements = Record<string, never> | LoanshotAgreement;

interface SendAgreementsParam {
	sceneName: string;
	code: string;
	agreementIds: string[];
	disagreementIds: string[];
}

export interface LoanStatistic {
	totalLoanAmount: number;
	totalLoanCount: number;
	interestSavings: number;
}

interface FetchEnumsResult {
	incomeType?: SelectOption[];
	loanPurposeType?: SelectOption[];
	mortgageLoanPurposeType?: SelectOption[];
	employmentType?: SelectOption[];
	medicalInsuranceType?: SelectOption[];
	houseOwnershipType?: SelectOption[];
	sectorsType?: SelectOption[];
	institutionType?: SelectOption[];
	incomeTypeOptions?: SelectOptionProps<INCOME_TYPE_CODE>[];
	loanPurposeOptions?: SelectOptionProps[];
	employmentTypeOptions?: SelectOptionProps<EMPLOYMENT_TYPE_CODE>[];
	medicalInsuranceTypeOptions?: SelectOptionProps[];
	houseOwnershipTypeOptions?: SelectOptionProps[];
	sectorsTypeOptions?: SelectOptionProps[];
	institutionTypeOptions?: SelectOptionProps[];
}

export interface FetchMetaResult {
	key: string;
	tags: string[];
	value: string;
}

export interface IntroInfo {
	/** 블로그 글*/
	readonly article: BlogArticle[];
	/** 고지사항 */
	readonly notice: string[];
	/** 심사필 */
	readonly certificationNumber: string;
}

const actionCreator = actionCreatorFactory('LS-COMMON');

export default {
	init: actionCreator(ActionTypes.LS_COMMON_INIT),
	setFlowType: actionCreator<FlowType>(ActionTypes.LS_COMMON_SET_FLOWTYPE),
	setMainPath: actionCreator<string>(ActionTypes.LS_COMMON_SET_MAIN_PATH),
	resetError: actionCreator(ActionTypes.LS_COMMON_RESET_ERROR),
	fetchEnums: actionCreator.async<EmptyParam, FetchEnumsResult, AlertCommonError>(ActionTypes.LS_COMMON_FETCH_ENUMS),
	intro: actionCreator.async<EmptyParam, LoanStatistic, AlertCommonError>(ActionTypes.LS_COMMON_INTRO),
	fetchAgreements: actionCreator.async<string, FetchAgreementsResult, AlertCommonError>(
		ActionTypes.LS_COMMON_FETCH_AGREEMENTS
	),
	sendAgreements: actionCreator.async<SendAgreementsParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_COMMON_SEND_AGREEMENTS
	),
	fetchMetas: actionCreator.async<EmptyParam, FetchMetaResult[], AlertCommonError>(ActionTypes.LS_COMMON_FETCH_META),
	loadLoansCompareIntro: actionCreator.async<EmptyParam, IntroInfo, AlertCommonError>(
		ActionTypes.LS_COMMON_LOAD_LOANS_COMPARE_INTRO
	),
	resetChannelPlatformCode: actionCreator(ActionTypes.LS_COMMON_RESET_CHANNEL_PLATFORM_CODE),
	setChannelPlatformCode: actionCreator<string>(ActionTypes.LS_COMMON_SET_CHANNEL_PLATFORM_CODE),
};
