import HeaderStrategy, { Headers } from '@peoplefund/utils/axios/HeaderStrategy';
import { AxiosInstance, AxiosResponse } from 'axios';
import AxiosTokenStrategy, { RefreshResponse } from '@peoplefund/utils/axios/TokenStrategy/AxiosTokenStrategy';
import { WebviewHandler } from '@peoplefund/utils/webview-handler/index.util';
import { KEY_PF_CLIENT_ID, KEY_PFCT_DEVICE_ID } from '@peoplefund/utils/webview-handler/cookieParser.util';

export default class WebviewHeaderStrategy extends HeaderStrategy<AxiosInstance, AxiosResponse<RefreshResponse>> {
	getHeaders(tokenStrategy: AxiosTokenStrategy, headers?: Headers): Headers {
		const { getAccessToken, defaultAccessToken } = tokenStrategy;
		const returnHeader: Headers = {};
		const authorization = headers?.['Authorization'];

		if (Boolean(authorization)) {
			if (authorization && authorization.indexOf('anonymous') < 0) {
				returnHeader['Authorization'] = authorization;
			} else {
				const accessToken = getAccessToken();
				returnHeader['Authorization'] = `Bearer ${accessToken}`;
			}
		} else {
			if (defaultAccessToken) {
				returnHeader['Authorization'] = `Bearer ${defaultAccessToken}`;
			}
		}

		const { isWebview, pfctDeviceId, appType } = WebviewHandler();
		const isMillion = appType === 'Million';
		const pfClientId = isMillion ? 'Million' : 'Peoplefund';

		returnHeader[KEY_PF_CLIENT_ID] = isWebview ? pfClientId : 'PFWeb';
		returnHeader[KEY_PFCT_DEVICE_ID] = pfctDeviceId;

		return returnHeader;
	}
}
