import { Action, ActionCreator, AnyAction } from 'typescript-fsa';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

/**
 * Custom implementation of the ofAction function.
 * Filters an Observable of actions, returning only those that match the specified action type.
 *
 * @param actionCreator The action creator function for the action type to filter by.
 * @returns A function that takes an Observable of AnyAction and returns an Observable of actions of type P.
 */
export function ofAction<P>(actionCreator: ActionCreator<P>) {
	return (source: Observable<AnyAction>): Observable<Action<P>> => {
		return source.pipe(filter((action: AnyAction): action is Action<P> => action.type === actionCreator.type));
	};
}
