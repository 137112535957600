import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';

export type SmsVerifyStatus = 'initial' | 'code-requested' | 'complete';

export interface SmsVerify {
	/** 상태: 기본, 코드발송, 완료 */
	readonly state: SmsVerifyStatus;

	/** 사용자 입력 값 */
	readonly name: string;
	readonly rrn: string;
	readonly mobile: string;
	readonly provider: string;
	readonly code: string;

	/** 거래고유번호 */
	readonly svcTxSeqno: string;
	readonly error?: AlertCommonError;
}

export const smsVerifyInitialState: SmsVerify = {
	state: 'initial',
	name: '',
	rrn: '',
	mobile: '',
	provider: '',
	svcTxSeqno: '',
	code: '',
	error: undefined,
};

export const smsVerifySlice = createSlice({
	name: 'smsVerify',
	initialState: smsVerifyInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, smsVerifyInitialState),
		setError: (state, action: PayloadAction<AlertCommonError | InAPICommonError>) => {
			const error = action.payload;
			if (error instanceof InAPICommonError) {
				state.error = convertInAPICommonErrorToAlertCommonError(error);
			} else {
				state.error = error;
			}
		},
		resetError: (state) => {
			state.error = undefined;
		},
		updateField: (state, { payload: { key, value } }: PayloadAction<{ key: string; value: string }>) => {
			switch (key) {
				case 'name': {
					state.name = value;
					break;
				}
				case 'rrn': {
					state.rrn = value;
					break;
				}
				case 'mobile': {
					state.mobile = value;
					break;
				}
				case 'provider': {
					state.provider = value;
					break;
				}
				case 'code': {
					state.code = value;
					break;
				}
			}
		},
		requestCodeWithRRN: (state, { payload }: PayloadAction<{ reRequest: boolean } | undefined>) => {
			state.error = undefined;
			state.svcTxSeqno = '';

			if (!payload?.reRequest) {
				state.state = 'initial';
			}
		},
		requestCode: (state, { payload }: PayloadAction<{ reRequest: boolean } | undefined>) => {
			state.error = undefined;
			state.svcTxSeqno = '';

			if (!payload?.reRequest) {
				state.state = 'initial';
			}
		},
		setRequestCodeResult: (state, { payload }: PayloadAction<string>) => {
			state.svcTxSeqno = payload;
			state.state = 'code-requested';
		},
		requestVerify: (state) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestVerifyAndSignUp: (state, _: PayloadAction<{ updateRRN?: string }>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestVerifyAndSignIn: (state, _: PayloadAction<{ updateRRN?: string }>) => {
			state.error = undefined;
		},
		setVerifyStatusComplete: (state) => {
			state.state = 'complete';
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestUpdateRRN: (state, _: PayloadAction<{ updateRRN: string; token: string }>) => {
			state.error = undefined;
		},
		setChangePhoneNumberDefaultValue: (
			state,
			{ payload: { name, rrn7 } }: PayloadAction<{ name: string; rrn7: string }>
		) => {
			state.name = name;
			state.rrn = rrn7;
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	updateField,
	requestCode,
	requestCodeWithRRN,
	setRequestCodeResult,
	requestVerify,
	requestVerifyAndSignUp,
	requestVerifyAndSignIn,
	setVerifyStatusComplete,
	requestUpdateRRN,
	setChangePhoneNumberDefaultValue,
} = smsVerifySlice.actions;

export default smsVerifySlice.reducer;
