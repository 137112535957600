import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { EmptyParam, EmptyResult } from '@peoplefund/actions/common';
import { AlertCommonError } from '@peoplefund/constants/error/type';

export interface UpdateApplyExtraInfoParam {
	readonly houseOwnership: string;
	readonly loanPurpose: string;
	readonly medicalInsurance: string;
	readonly partnerCode: string;
	readonly carNumber: string;
	readonly reuseMaterial: boolean;
}

interface CreateCurrentLoanParam {
	readonly balance: number;
	readonly institutionType: string;
}

export interface CurrentLoanItem {
	/** 대환 정보 id */
	readonly id: string;
	/** 대출 받은 곳 분류 */
	readonly organizationName: string;
	/** 전체 대출 금액 */
	readonly totalAmount: number;
	/** 잔액 */
	readonly balance: number;
	/** 대환정보 직접 입력한 경우인지 아닌지 */
	readonly directInfo: boolean;
	/** 생성일 */
	readonly openDate?: string;
	/** 업권 */
	readonly financialSector?: string;
	/** [ui] 선택여부 */
	readonly checked?: boolean;

	readonly unsecuredLoanApplicationId: number;
	readonly organizationCode: string;
	readonly organizationLogoUrl?: string;
	readonly accountStatus?: string;
	readonly branchName?: string;
	readonly selectedRevolvingLoan?: boolean;
}

interface UpdateCurrentLoanParam {
	readonly id: string;
}

interface UpdateRefinanceLoanParam {
	readonly ids: string[];
}

interface FetchExistingLoanResult {
	readonly list: CurrentLoanItem[];
}

interface GetJobInfoFromCretopParam {
	companyName: string;
}

export type CompanyInfoState = {
	/** KED코드 */
	readonly kedcd: string;
	/** 기업체명 */
	readonly companyName: string;
	/** 기업체 TradeName */
	readonly companyTradeName: string;
	/** 기업체 영문명 */
	readonly companyEnglishName: string;
	/** 사업자번호 */
	readonly businessNo: string;
	/** 전화번호 */
	readonly companyContactNumber: string;
	/** 도로명 소재지 우편번호 주소 */
	readonly companyAddress: string;
	/** 도로명 소재지 우편번호 이하주소 */
	readonly companyDetailAddress: string;
	/** 도로명 소재지 우편번호 */
	readonly companyPostCode: string;
	/** 소재지 우편번호 주소 */
	readonly companyJibunAddress: string;
	/** 소재지 우편번호 이하주소 */
	readonly companyJibunDetailAddress: string;
	/** 소재지 우편번호 */
	readonly companyJibunPostCode: string;
	/** 상시근로자수 (합계) */
	readonly laborerSum: number;
};

interface GetJobInfoFromCretopResult {
	result: CompanyInfoState[];
}

const actionCreator = actionCreatorFactory('LS_APPLY_INFO');

export default {
	init: actionCreator(ActionTypes.LS_APPLY_INIT),
	resetError: actionCreator(ActionTypes.LS_APPLY_RESET_ERROR),
	agree: actionCreator<{ code: string; customAgreeList: string[]; customDisagreeList: string[] }>(
		ActionTypes.LS_APPLY_AGREE
	),
	updateExtraInfo: actionCreator<UpdateApplyExtraInfoParam>(ActionTypes.LS_APPLY_UPDATE_EXTRA_INFO),
	updatePurpose: actionCreator<string>(ActionTypes.LS_APPLY_UPDATE_PURPOSE),
	/** 직장정보 검색 **/
	getJobInfoFromCretop: actionCreator.async<GetJobInfoFromCretopParam, GetJobInfoFromCretopResult, AlertCommonError>(
		ActionTypes.LS_APPLY_GET_JOB_INFO_FROM_CRETOP
	),
	resetJobInfoFromCretop: actionCreator(ActionTypes.LS_APPLY_RESET_JOB_INFO_FROM_CRETOP),
	/** 대환 **/
	fetchExistingLoan: actionCreator.async<EmptyParam, FetchExistingLoanResult, AlertCommonError>(
		ActionTypes.LS_APPLY_FETCH_EXISTING_LOAN
	),
	createExistingLoan: actionCreator.async<CreateCurrentLoanParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_APPLY_CREATE_EXISTING_LOAN
	),
	deleteExistingLoan: actionCreator.async<UpdateCurrentLoanParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_APPLY_DELETE_EXISTING_LOAN
	),
	updateRefinanceLoans: actionCreator<UpdateRefinanceLoanParam>(ActionTypes.LS_APPLY_UPDATE_REFINANCE_LOANS),
	/** 가심사 구간 접근 가능한지 체크 */
	checkApplyAvailable: actionCreator.async<EmptyParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_APPLY_CHECK_APPLY_AVAILABLE
	),
	loadReuseMaterial: actionCreator.async<EmptyParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_APPLY_LOAD_REUSE_MATERIAL
	),
};
