import {
	getCartItemStatus,
	getCartItemStatusDisplay,
} from '@peoplefund/components/investing/hooks/useMortgageInvestCartClient/index.logic';
import { MortgageInvestProductListItem } from '@peoplefund/slices/ml-investing';
import { GetCartProductListServerResponse, Product } from '@peoplefund/slices/ml-investing.model';
import { TODAY, formatDate } from '@peoplefund/utils/date.util';
import { KRWUnit } from '@peoplefund/utils/format.util';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

interface InvestmentItem {
	investment_amount: number;
	loss_amount: number;
	remain_amount: number;
	point_amount: number;
}

interface MortgageLoanInfo {
	certified_copy_of_register: string;
	certified_copy_of_register_after_disburse: string;
	location: string;
}

/**
 * TODO: types 폴더 만들어서 공통으로 관리하면 좋을 듯 싶음.
 */
export interface MortgageInvestmentProductServerProps {
	loan_application_id: number;
	loan_title: string;
	closed: boolean;
	investments?: InvestmentItem[];
	loan_application_amount: number;
	loan_application_term: number;
	mortgage_loan_info: MortgageLoanInfo;
	interest_rate: number;
	fund_start_datetime: string;
	uri: string;
	investor_amount: number;
	product_type: string;
}

export const convertProductResponse = (response: MortgageInvestmentProductServerProps): Product => {
	const {
		loan_application_id,
		closed,
		fund_start_datetime,
		investments,
		interest_rate,
		loan_application_term,
		loan_application_amount,
		investor_amount,
		mortgage_loan_info,
		uri,
		loan_title,
		product_type,
	} = response;

	const fundStartTime = dayjs(fund_start_datetime);

	const result: Product = {
		legacyId: loan_application_id,
		fundingClosed: investor_amount === loan_application_amount || closed, // 자채권의 모집마감상태 변수를 알기 전까지
		fundingWaiting: dayjs().isBefore(fundStartTime) ? true : false,
		invested: Boolean(investments?.length),
		investedAmount: investments?.reduce((acc, cur) => acc + cur.investment_amount, 0) || 0,
		pointAmount: investments?.reduce((acc, cur) => acc + cur.point_amount, 0) || 0,
		interestRate: interest_rate,
		investTerm: loan_application_term,
		targetAmount: loan_application_amount,
		accumulatedAmount: investor_amount,
		certifiedCopyOfRegister: mortgage_loan_info.certified_copy_of_register,
		certifiedCopyOfRegisterAfterDisburse: mortgage_loan_info.certified_copy_of_register_after_disburse,
		uri,
		location: mortgage_loan_info.location,
		title: loan_title,
		name: loan_title,
		fundingStartDatetime: fund_start_datetime,
		productType: product_type,
	};
	return result;
};

export const convertCartItemToDisplayCartItem = (
	cartItemList: GetCartProductListServerResponse['results']
): MortgageInvestProductListItem[] => {
	const fundingProductList = cartItemList.filter((item) => item.status === '모집중');
	const waitingProductList = cartItemList.filter((item) => item.status === '모집대기');
	const endedProductList = cartItemList.filter((item) => item.status === '모집완료' || item.status === '모집취소');

	fundingProductList.sort((a, b) => b.invest_amount - a.invest_amount);

	const list = [...fundingProductList, ...waitingProductList, ...endedProductList];
	return list.map((cartItem) => {
		const {
			legacy_id,
			product_id,
			uri,
			title,
			name,
			yield_rate: yieldRate,
			term,
			ltv_ratio: ltvRatio,
			funding_amount: fundingAmount,
			invest_amount: investAmount,
			loan_application_id: loanApplicationId,
			funding_start_datetime: fundingStartDatetime,
			product_type: productType,
			is_canceled,
			is_closed,
			is_investable,
			is_notified_open_product,
			limit_type,
		} = cartItem;

		const status = getCartItemStatus({ is_canceled, is_closed, is_investable });

		return {
			cartId: legacy_id,
			productId: product_id,
			uri,
			name,
			status,
			statusName: getCartItemStatusDisplay({
				status,
				currentDatetime: formatDate(TODAY, 'YYYY-MM-DD HH:mm:ss'),
				fundingStartDatetime,
			}),
			yieldRate,
			investmentTerm: term,
			ltv: ltvRatio,
			targetAmount: fundingAmount / KRWUnit.만,
			amount: investAmount / KRWUnit.만,
			fundingStartDatetime,
			loanApplicationId,
			title,
			productType,
			isNotifiedOpenProduct: is_notified_open_product,
			limitType: limit_type,
		};
	});
};
