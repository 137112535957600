import JSEncrypt from 'jsencrypt';
import CryptoJS from 'crypto-js';

const CODE_AT_INDEX = 0; //https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/String/charCodeAt

/** 임의 값 16자리  secret key로 활용 (from php repo)*/
export const randomCode = (stringLength = 16, onlyNumber?: boolean) => {
	const chars = `0123456789${onlyNumber ? '' : 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'}`;
	let randomstring = '';
	for (let i = 0; i < stringLength; i++) {
		const rnum = Math.floor(Math.random() * chars.length);
		randomstring += chars.substring(rnum, rnum + 1);
	}
	return randomstring;
};

// RSA encrypt using JSEncrypt
// TODO: JSEncrypt 라이브러리 import 구문으로 불러올 수 있다면 함수로 class 전달하는 부분 삭제
export const getEncryptMessage = (encryptModule: typeof JSEncrypt, publicKey: string, message: string) => {
	const newPublicKey = '-----BEGIN PUBLIC KEY-----\n' + publicKey + '\n' + '-----END PUBLIC KEY-----\n';
	const encrypt = new encryptModule({ default_key_size: '2048' });
	encrypt.setPublicKey(newPublicKey);
	const msg = encrypt.encrypt(message);
	const encryptedMsg = msg || 'non-string';

	return encryptedMsg;
};

// AES encrypt using crypto-js
export const encAesMessage = (secretKey: string, message: string) => {
	//서버에서 복호화 하기 쉽게 iv값은 secret key와 동일하게
	const iv = secretKey;

	// utf-8로 인코딩(uni string -> byte seq) == Buffer.from(string, 'utf-8')
	const encodedKey = CryptoJS.enc.Utf8.parse(secretKey);
	const encodedIv = CryptoJS.enc.Utf8.parse(iv);

	// cipheriv : aes-128-cbc
	const encMsg = CryptoJS.AES.encrypt(message, encodedKey, {
		iv: encodedIv,
		mode: CryptoJS.mode.CBC,
	}).toString();

	return encMsg;
};

/**
 * 문자 암호화(문자열 길이 일정)
 *
 * @param message
 */

export const encryptString = (message: string): string => {
	const strLength = message.length;
	if (strLength < 2) {
		return message;
	}

	const result = message.split('');

	// 각 문자 양 옆의 문자와의 연관성을 바탕으로 암호화 치환
	let variant = 0;

	for (let i = 0; i < strLength; i++) {
		switch (i) {
			case 0:
				variant = result[i + 1].charCodeAt(CODE_AT_INDEX) % 10;
				break;
			case strLength - 1:
				variant = result[i - 1].charCodeAt(CODE_AT_INDEX) % 10;
				break;
			default:
				variant = (result[i - 1].charCodeAt(CODE_AT_INDEX) + result[i + 1].charCodeAt(CODE_AT_INDEX)) % 10;
				break;
		}

		result[i] = String.fromCharCode(result[i].charCodeAt(CODE_AT_INDEX) - variant);
	}

	// 연관성을 없애주기 위해 위치 변경
	// 홀수번째 index는 앞으로
	const startOddIndex = 1;
	const startOdd = result[startOddIndex];
	for (let j = 1; j < strLength; j += 2) {
		result[j] = j + 2 >= strLength ? startOdd : result[j + 2];
	}

	// 짝수번째 index는 뒤로 shifting
	const endEvenIndex = strLength % 2 === 0 ? strLength - 2 : strLength - 1;
	const endEven = result[endEvenIndex];
	for (let k = endEvenIndex; k >= 0; k -= 2) {
		result[k] = k - 2 < 0 ? endEven : result[k - 2];
	}

	return result.join('');
};

/**
 * 문자 복호화(문자열 길이 일정)
 *
 * @param message
 */
export const decryptString = (message: string) => {
	const strLength = message.length;
	if (strLength < 2) {
		return message;
	}

	const result = message.split('');

	// 연관성 찾기위해 위치 원래대로 복원(암호화 순서바꾸기 반대로)
	// 홀수번째 index는 뒤로 shift
	const endOddIndex = strLength % 2 !== 0 ? strLength - 2 : strLength - 1;
	const endOdd = result[endOddIndex];
	for (let i = endOddIndex; i >= 0; i -= 2) {
		result[i] = i - 2 < 0 ? endOdd : result[i - 2];
	}
	// 짝수번째 index는 앞으로로 shifting
	const startEvenIndex = 0;
	const startEven = result[startEvenIndex];
	for (let j = 0; j < strLength; j += 2) {
		result[j] = j + 2 >= strLength ? startEven : result[j + 2];
	}

	// 각 문자 양 옆의 문자와의 연관성을 바탕으로 복호화
	let variant = 0;
	for (let k = strLength - 1; k >= 0; k--) {
		switch (k) {
			case 0:
				variant = result[k + 1].charCodeAt(CODE_AT_INDEX) % 10;
				break;
			case strLength - 1:
				variant = result[k - 1].charCodeAt(CODE_AT_INDEX) % 10;
				break;
			default:
				variant = (result[k - 1].charCodeAt(CODE_AT_INDEX) + result[k + 1].charCodeAt(CODE_AT_INDEX)) % 10;
				break;
		}

		result[k] = String.fromCharCode(result[k].charCodeAt(CODE_AT_INDEX) + variant);
	}

	return result.join('');
};
