import { concat, of } from 'rxjs';
import { actions } from '@peoplefund/actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { encryptObservable } from './index.util';
import {
	requestPinRegister,
	requestPinVerify,
	setError,
	setPinRegisterResult,
	setPinVerifyResult,
} from '@peoplefund/slices/authentication';
import { convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';

export const pinVerifyEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(requestPinVerify),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							ext_auth_content: payload,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/ext-auth/pin/verify', {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return setPinVerifyResult({ status: 'verified' });
									}),
									catchError(() => of(setPinVerifyResult({ status: 'error' })))
								)
						),
						catchError((error) => of(setError(convertInAPICommonErrorToAlertCommonError(error))))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export const pinRegisterEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(requestPinRegister),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					encryptObservable(
						cometAjax,
						{
							ext_auth_content: payload,
						},
						token
					).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/auth/v1/ext-auth/pin', {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map(() => {
										return setPinRegisterResult({ status: 'verified' });
									}),
									catchError(() => of(setPinRegisterResult({ status: 'error' })))
								)
						),
						catchError((error) => of(setError(convertInAPICommonErrorToAlertCommonError(error))))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};
