import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { TrackEventProperties, UserTraits } from '@peoplefund/utils/logrocket.util';
import { LogRocketEventType } from '@peoplefund/constants/log-rocket';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyParam {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface EmptyResult {}

interface LogRocketIdentifyParam {
	readonly userId: number;
	readonly traits?: UserTraits;
}

interface LogRocketTrackParam {
	readonly type: LogRocketEventType;
	readonly properties?: TrackEventProperties;
}

const actionCreator = actionCreatorFactory('COMMON');

export default {
	/** 모든 state 초기화 */
	init: actionCreator(ActionTypes.INIT),
	/** 계정 관련 state 초기화 */
	authInit: actionCreator(ActionTypes.ACCOUNT_INIT),
	/** PFLoan 관련 state 초기화 */
	logRocketIdentify: actionCreator<LogRocketIdentifyParam>(ActionTypes.LOG_ROCKET_IDENTIFY),
	logRocketTrack: actionCreator<LogRocketTrackParam>(ActionTypes.LOG_ROCKET_TRACK),
};
