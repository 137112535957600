import WebviewHeaderStrategy from '@peoplefund/utils/axios/HeaderStrategy/WebviewHeaderStrategy';
import ApiClient from '@peoplefund/utils/axios/ApiClient';
import { Store } from 'redux';
import { State } from '@peoplefund/reducers';
import PhoenixTokenStrategy from '@peoplefund/utils/axios/TokenStrategy/PhoenixTokenStrategy';
import { WebviewHandler } from '../webview-handler/index.util';

export const PhoenixApiClient = (() => {
	const tokenStrategy = new PhoenixTokenStrategy('anonymous');
	const headerStrategy = new WebviewHeaderStrategy();

	const apiClient = new ApiClient(
		`${process.env.NEXT_PUBLIC_COMET_URL}`,
		tokenStrategy,
		headerStrategy,
		WebviewHandler()
	);

	return {
		setStore: (store?: Store<State>) => {
			tokenStrategy.setStore(store);
		},
		instance: apiClient.getApiClient(),
		processQueue: apiClient.processQueue,
	};
})();

export default PhoenixApiClient.instance;
