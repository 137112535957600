import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';
import { LoanApplication } from './model/loanApplication';
import { DealTemplate } from './model/dealTemplate';
import { Tranche } from './model/tranch';
import { ExpectedRepaymentSchedule, VirtualLoanExpectedRepaymentSchedule } from './model/repaymentSchedule';

export interface Deal {
	loanApplication?: LoanApplication;
	dealTemplate?: DealTemplate;
	tranche?: Tranche;
	expectedRepaymentSchedule?: ExpectedRepaymentSchedule;
	virtualLoanExpectedRepaymentSchedule?: VirtualLoanExpectedRepaymentSchedule;
}

export interface Peacock {
	fetched: boolean;
	deal?: Deal;
	error?: AlertCommonError;
	loading: boolean;
}

export const peacockInitialState: Peacock = {
	fetched: false,
	loading: true,
};

const peacockSlice = createSlice({
	name: 'peacock',
	initialState: peacockInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, peacockInitialState),
		setError: (state, action: PayloadAction<InAPICommonError>) => {
			state.error = convertInAPICommonErrorToAlertCommonError(action.payload);
		},
		resetError: (state) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchDealData: (state, _: PayloadAction<string>) => {
			state.error = undefined;
			state.fetched = false;
		},
		setDealData: (state, action: PayloadAction<Deal>) => {
			state.deal = action.payload;
			state.fetched = true;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
	},
});

export const { initialize, resetError, setError, fetchDealData, setDealData, setLoading } = peacockSlice.actions;

export default peacockSlice.reducer;
