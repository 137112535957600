import { actions } from '@peoplefund/actions';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { State } from '@peoplefund/reducers';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import LogRocketUtil from '@peoplefund/utils/logrocket.util';
import { EMPTY, Observable, of } from 'rxjs';
import { CometAjax } from './ajax.util';
import { fetchErrorJson, finishFetchErrorJson, initialize as commonInitialize } from '@peoplefund/slices/common';
import { initialize as loansAutopaySliceInitialize } from '@peoplefund/slices/loans-autopay';
import { initStateAll as authenticationSliceInitialize } from '@peoplefund/slices/authentication';
import { initialize as commonInvestingSliceInitialize } from '@peoplefund/slices/common-investing';
import { initialize as lsApplyJobInfoSliceInitialize } from '@peoplefund/slices/lsApplyJobInfoSlice';
import { initialize as mlInvestingSliceInitialize } from '@peoplefund/slices/ml-investing';
import { initialize as mlLoanSliceInitialize } from '@peoplefund/slices/ml-loan';
import { initialize as plInvestingSliceInitialize } from '@peoplefund/slices/pl-investing';
import { initialize as smsVerifySliceInitialize } from '@peoplefund/slices/sms-verify';
import { initialize as voteInitialize } from '@peoplefund/slices/vote';
import { initialize as twoPlusOneInitialize } from '@peoplefund/slices/two-plus-one-verify';
import { initialize as amlInitialize } from '@peoplefund/slices/aml';
import { initialize as peocockInitialize } from '@peoplefund/slices/peacock';
import { initialize as agreementInitialize } from '@peoplefund/slices/agreementSlice';
import { initialize as commonLoanInitialize } from '@peoplefund/slices/common-loan';
import { initialize as luxuryAssetLoanChicInitialize } from '@peoplefund/slices/luxury-asset-loans/chic';
import { initialize as stockLoansInitialize } from '@peoplefund/slices/stock-loans';
import { initialize as communityPreviewInitialize } from '@peoplefund/slices/community/preview';

interface ErrorCodeItem {
	title: string;
	message: string;
}
interface ErrorCodeItemByLanguage {
	[language: string]: ErrorCodeItem;
}
export interface ErrorCodeMap {
	[errorCode: string]: ErrorCodeItemByLanguage;
}

const initAuth: Epic<Action, Action, State> = (action$) =>
	action$.pipe(
		ofAction(actions.common.authInit),
		map(() => actions.account.init())
	);

const init: Epic<Action, Action, State> = (action$) =>
	action$.pipe(
		ofAction(actions.common.init),
		mergeMap(() => [
			commonInitialize(),
			actions.common.authInit(),
			amlInitialize(),
			loansAutopaySliceInitialize(),
			peocockInitialize(),
			twoPlusOneInitialize(),
			voteInitialize(),
			agreementInitialize(),
			authenticationSliceInitialize(),
			commonInvestingSliceInitialize(),
			commonLoanInitialize(),
			lsApplyJobInfoSliceInitialize(),
			mlInvestingSliceInitialize(),
			mlLoanSliceInitialize(),
			plInvestingSliceInitialize(),
			smsVerifySliceInitialize(),
			luxuryAssetLoanChicInitialize(),
			stockLoansInitialize(),
			communityPreviewInitialize(),
		])
	);

const logRocketIdentify: CometEpic = (action$) =>
	action$.pipe(
		ofAction(actions.common.logRocketIdentify),
		mergeMap(({ payload }) => {
			LogRocketUtil.identify(payload.userId, payload.traits);
			return EMPTY;
		})
	);

const logRocketTrack: CometEpic = (action$) =>
	action$.pipe(
		ofAction(actions.common.logRocketTrack),
		mergeMap(({ payload }) => {
			LogRocketUtil.track(payload.type, payload.properties);
			return EMPTY;
		})
	);

const fetchErrorJsonEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(fetchErrorJson),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { callbackAction },
				},
			]) => {
				return fetchErrorCodeJsonObservable(cometAjax).pipe(
					mergeMap((response) => {
						callbackAction({ json: response, networkStatus: 'success' });
						return of(finishFetchErrorJson());
					}),
					catchError(() => {
						callbackAction({ json: {}, networkStatus: 'fail' });
						return of(finishFetchErrorJson());
					})
				);
			}
		)
	);
};

const fetchErrorCodeJsonObservable = (cometAjax: CometAjax<ErrorCodeMap>): Observable<ErrorCodeMap> => {
	return cometAjax.pfCode.get('/errorCode.json').pipe(map((response) => response));
};

export default [initAuth, init, logRocketIdentify, logRocketTrack, fetchErrorJsonEpic];
