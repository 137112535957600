import { ofAction } from '@peoplefund/utils/redux.util';
import * as AmlSlice from '@peoplefund/slices/aml';
import { CometEpic } from '../constants.util';
import { catchError, concat, mergeMap, Observable, of, withLatestFrom, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { AmlEnumOptions, AmlField, OptionProps, SetDataParam } from '@peoplefund/slices/aml/index.model';
import { actions } from '@peoplefund/actions';
import { encryptObservable } from '@peoplefund/epics/account/index.util';
import { isKYCUpdateRequired } from '@peoplefund/slices/aml/index.util';

type NullableString = null | string;

type KycResponse = {
	readonly name: NullableString;
	readonly english_name: NullableString;
	readonly phone_number: NullableString;
	readonly country_code: NullableString;
	readonly residence_country_code: NullableString;
	readonly residence_address: NullableString;
	readonly residence_detail_address: NullableString;
	readonly residence_postal_code: NullableString;
	readonly job_code: NullableString;
	readonly risky_business_code: NullableString;
	readonly funds_source_code: NullableString;
	readonly funds_source_etc_detail: NullableString;
	readonly transaction_purpose_code: NullableString;
	readonly transaction_purpose_etc_detail: NullableString;
	readonly is_beneficial_owner: boolean;
};
const loadEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const fetchAmlInfo = (token: string): Observable<SetDataParam['info']> => {
		return cometAjax.inapi.get('/account/users/:user_id/v2/aml/kyc', { token }).pipe(
			map(
				({
					name,
					english_name,
					country_code,
					phone_number,
					residence_address,
					residence_country_code,
					residence_detail_address,
					residence_postal_code,
					job_code,
					risky_business_code,
					funds_source_code,
					funds_source_etc_detail,
					transaction_purpose_code,
					transaction_purpose_etc_detail,
					is_beneficial_owner,
				}: KycResponse) => ({
					name: name ?? '',
					englishName: english_name ?? '',
					countryCode: country_code ?? '',
					phoneNumber: phone_number ?? '',
					residenceCountryCode: residence_country_code ?? '',
					residenceAddress: residence_address ?? '',
					residenceDetailAddress: residence_detail_address ?? '',
					residencePostCode: residence_postal_code ?? '',
					jobCode: job_code ?? '',
					riskyBusinessCode: risky_business_code ?? '',
					fundsSourceCode: funds_source_code ?? '',
					fundsSourceEtcDetail: funds_source_etc_detail ?? '',
					transactionPurposeCode: transaction_purpose_code ?? '',
					transactionPurposeEtcDetail: transaction_purpose_etc_detail ?? '',
					isBeneficialOwner: Boolean(is_beneficial_owner) ? 'true' : 'false',
				})
			)
		);
	};

	const fetchEnum = (token: string): Observable<SetDataParam['enums']> => {
		return cometAjax.inapi.get('/account/users/v2/aml/choices', { token }).pipe(
			map(
				(response: {
					readonly choices: {
						readonly field_name: string;
						readonly field_type: string;
						readonly title: string;
						readonly values: OptionProps[];
					}[];
				}) => {
					const fieldMatcher = (fieldName: string): AmlField => {
						switch (fieldName) {
							case 'country_code': {
								return 'countryCode';
							}
							case 'job_code': {
								return 'jobCode';
							}
							case 'risky_business_code': {
								return 'riskyBusinessCode';
							}
							case 'funds_source_code': {
								return 'fundsSourceCode';
							}
							case 'transaction_purpose_code': {
								return 'transactionPurposeCode';
							}
							default: {
								throw `${fieldName} 키를 찾을 수 없습니다`;
							}
						}
					};

					const obj: Record<string, AmlEnumOptions> = {
						isBeneficialOwner: {
							field: 'isBeneficialOwner',
							label: '계정 실소유주 여부',
							options: [
								{
									key: 'true',
									content: '본인 직접 소유',
								},
								{
									key: 'false',
									content: '본인 직접 소유하지 않음',
								},
							],
						},
					};

					return (
						response.choices?.reduce((r, item) => {
							const field = fieldMatcher(item.field_name);
							const enumOption: AmlEnumOptions = {
								field,
								label: item.title,
								options: item.values.map((value) => value as OptionProps),
							};

							return {
								...r,
								[field]: enumOption,
							};
						}, obj) ?? obj
					);
				}
			)
		);
	};

	return action$.pipe(
		ofAction(AmlSlice.load),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
						amlStatus: { status },
					},
				},
			]) => {
				if (isKYCUpdateRequired(status)) {
					return zip(fetchAmlInfo(token), fetchEnum(token)).pipe(
						map(([info, enums]) => {
							return AmlSlice.setData({ info, enums });
						}),
						catchError((error) => {
							return of(AmlSlice.setError(error));
						})
					);
				} else {
					return of(AmlSlice.setInfoFetched());
				}
			}
		)
	);
};

type EXTAuthInfoResponse = {
	readonly user_id: number;
	readonly ext_auth_type: string;
	readonly fail_count: number;
	readonly create_datetime: string;
}[];

const checkVerifyTypeEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(AmlSlice.checkVerifyType),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					encryptObservable(cometAjax, {}, token).pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.get('/auth/v1/ext-auth/info', {
									headers,
									body: { eData },
									token,
								})
								.pipe(
									map((response: EXTAuthInfoResponse) => {
										const IsPinRegistered = response.some(({ ext_auth_type }) => ext_auth_type === 'pin');
										return AmlSlice.setVerifyType(IsPinRegistered ? 'PIN' : 'SMS');
									})
								)
						),
						catchError(() => of(AmlSlice.setVerifyType('SMS')))
					),
					of(actions.layout.endLoading())
				)
		)
	);
};

export default [loadEpic, checkVerifyTypeEpic];
