import { FOURTY_PERCENT, INVESTOR_STATUS, INVESTOR_TYPE } from '@peoplefund/constants/pl-investing';
import { CometAjax } from '@peoplefund/epics/ajax.util';
import { from, Observable, of, zip } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AlertCommonError, InAPICommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import ObjectUtil from '@peoplefund/utils/object.util';
import { encAesMessage, getEncryptMessage, randomCode } from '@peoplefund/utils/crypt.util';
import { FetchUserInfoResponse, GetUnsecuredInvestmentStatusResponse, TwoPlusOneStatusResponse } from './index.model';
import { AMLStatus, Gender, InvestorInfo, UserType } from '@peoplefund/reducers/account/index.model';
import { AmlStatusParam, FetchUserInfoResult } from '@peoplefund/actions/account';
import { getRRN7Only, parseDate } from '@peoplefund/utils/date.util';
import { apiFetchAMLStatus, FetchAMLStatusResponse } from '@peoplefund/services/apis/inapi.account';

export const convertPeoplefundMaxInvestableAmount = (amount: number | null): number => {
	if (amount === null) {
		return Number.MAX_SAFE_INTEGER;
	} else {
		return amount;
	}
};

export const getUnsecuredInvestmentStatus = (
	cometAjax: CometAjax<GetUnsecuredInvestmentStatusResponse>,
	token: string
): Observable<InvestorInfo> => {
	const updateGetUnsecuredInvestmentStatusResponse = ({
		investable_amount,
		investor_type,
		able_money,
		approval_status,
		virtual_account_bank_name,
		virtual_account_num_formatted,
		virtual_account_holder,
		investment_limit,
	}: GetUnsecuredInvestmentStatusResponse): InvestorInfo => {
		let productInvestLimit = investment_limit.same_borrower_max_investable_amount ?? 0;
		if (
			investor_type === INVESTOR_TYPE.CORPORATE ||
			investor_type === INVESTOR_TYPE.INSTITUTE ||
			investor_type === INVESTOR_TYPE.PROFESSIONAL
		) {
			productInvestLimit = FOURTY_PERCENT;
		}

		return {
			investorType: (investor_type as INVESTOR_TYPE) ?? INVESTOR_TYPE.NONE,
			investorStatus: (approval_status as INVESTOR_STATUS) ?? INVESTOR_STATUS.NONE,
			remainP2PInvestableAmount: convertPeoplefundMaxInvestableAmount(
				investable_amount.peoplefund_max_investable_amount
			),
			accountBalance: able_money,
			accountBank: virtual_account_bank_name,
			accountNumber: virtual_account_num_formatted,
			accountHolder: virtual_account_holder,
			productInvestLimit: productInvestLimit,
			tryFetchInvestorInfo: true,
		};
	};

	return cometAjax.inapi
		.get('/account/users/:user_id/unsecured-investment/status/', {
			token,
		})
		.pipe(
			map((response) => updateGetUnsecuredInvestmentStatusResponse(response)),
			catchError((error) => {
				if (error.code === 'INAPI:INVESTOR_REGISTRATION_DOES_NOT_EXIST') {
					return of({
						investorType: INVESTOR_TYPE.INDIVIDUAL,
						investorStatus: INVESTOR_STATUS.ACCOUNT_NOT_REGISTERED,
						remainP2PInvestableAmount: 0,
						accountBalance: 0,
						accountBank: '',
						accountNumber: '',
						accountHolder: '',
						productInvestLimit: 0,
						tryFetchInvestorInfo: true,
					});
				} else if (error?.code === PhoenixErrorCode.KFTC_SYSTEM_UNDER_MAINTENANCE) {
					// 에러로 판단하지 않도록
					return of({
						investorType: INVESTOR_TYPE.NONE,
						investorStatus: INVESTOR_STATUS.SYSTEM_UNDER_MAINTENANCE,
						remainP2PInvestableAmount: 0,
						accountBalance: 0,
						accountBank: '',
						accountNumber: '',
						accountHolder: '',
						productInvestLimit: 0,
						tryFetchInvestorInfo: true,
					});
				} else {
					throw error;
				}
			})
		);
};

export const convertFetchAMLStatus = (response: FetchAMLStatusResponse): Omit<AmlStatusParam, 'fetched'> => {
	//해당 함수의 로직이 변경되면 PHP의 js/aml/index.js의 로직도 동일하게 변경해줘야 합니다
	const checkAMLStatus = (response: FetchAMLStatusResponse): AMLStatus => {
		const {
			kyc_status,
			kyc_detail: { edd_expired_soon },
		} = response;

		switch (kyc_status) {
			case 'verified':
				if (edd_expired_soon) {
					return AMLStatus.KYC_EXPIRE_SOON;
				} else {
					return AMLStatus.SUCCESS;
				}
			case 'edd_expired':
				return AMLStatus.KYC_EXPIRED;
			case 'transaction_not_allowed':
				return AMLStatus.FAILED;
			case 'not_verified':
			case 're_verification_required':
			default:
				return AMLStatus.INITIAL;
		}
	};

	const {
		kyc_detail: { edd_expiration_date },
	} = response;

	return {
		status: checkAMLStatus(response),
		kycExpireDate: edd_expiration_date ? parseDate(edd_expiration_date, 'YYYY-MM-DD') : undefined,
	};
};

export const fetchAMLStatus = (token: string): Observable<Omit<AmlStatusParam, 'fetched'>> => {
	return from(apiFetchAMLStatus({ token })).pipe(mergeMap(({ data }) => of(convertFetchAMLStatus(data))));
};

export const fetchInvestorStatus = (
	cometAjax: CometAjax<FetchAMLStatusResponse & TwoPlusOneStatusResponse>,
	token: string
): Observable<
	{ investorStatus: INVESTOR_STATUS; amlStatus: AmlStatusParam['status'] } & Pick<AmlStatusParam, 'kycExpireDate'>
> => {
	const getAmlResponse = (amlStatus: AMLStatus): INVESTOR_STATUS => {
		switch (amlStatus) {
			case AMLStatus.SUCCESS:
				return INVESTOR_STATUS.AML_1_WLF_PASSED;
			case AMLStatus.FAILED:
				return INVESTOR_STATUS.AML_1_WLF_FAILED;
			case AMLStatus.KYC_EXPIRED:
				return INVESTOR_STATUS.AML_1_KYC_EXPIRED;
			default:
				return INVESTOR_STATUS.AML_CHECK_NEEDED;
		}
	};

	const getTwoPlusOneStatusResponse = (response: TwoPlusOneStatusResponse): INVESTOR_STATUS => {
		const { investor_unsecured_status, verification_identification, verification_account } = response;
		const INVESTOR_STATUS_AML_COMPLETE = '투자자등록완료';

		if (
			investor_unsecured_status !== INVESTOR_STATUS_AML_COMPLETE ||
			!verification_identification ||
			!verification_account
		) {
			return INVESTOR_STATUS.AML_2_TWO_PLUS_ONE_NEEDED;
		} else {
			return INVESTOR_STATUS.AML_ALL_PASSED;
		}
	};

	return fetchAMLStatus(token).pipe(
		mergeMap(({ status: amlStatus, kycExpireDate }) => {
			const investorStatus = getAmlResponse(amlStatus);
			const result = { amlStatus, investorStatus, kycExpireDate };

			if (investorStatus === INVESTOR_STATUS.AML_1_WLF_PASSED) {
				return cometAjax.inapi
					.get('/account/users/verification/status/', {
						token,
					})
					.pipe(
						map((response) => {
							return {
								...result,
								investorStatus: getTwoPlusOneStatusResponse(response),
							};
						})
					);
			} else {
				return of(result); // 바로 리턴
			}
		})
	);
};

export const checkInvestorRegisterAvailable = (status?: INVESTOR_STATUS): InAPICommonError | undefined => {
	let error: InAPICommonError | undefined = undefined;

	switch (status) {
		case INVESTOR_STATUS.ACCOUNT_NOT_REGISTERED: {
			error = new InAPICommonError('', PhoenixErrorCode.INVESTOR_STATUS_ACCOUNT_NOT_REGISTERED);
			break;
		}
		case INVESTOR_STATUS.AML_CHECK_NEEDED: {
			error = new InAPICommonError('', PhoenixErrorCode.INVESTOR_STATUS_AML_CHECK_NEEDED);
			break;
		}
		case INVESTOR_STATUS.AML_1_WLF_FAILED: {
			error = new AlertCommonError(
				'아쉽지만 저희 상품은 고객님께 적합하지 않아요.\n도움을 드리지 못해 죄송합니다.',
				PhoenixErrorCode.INVESTOR_STATUS_AML_1_WLF_FAILED
			);
			break;
		}
		case INVESTOR_STATUS.PENDING: {
			error = new AlertCommonError('투자자 등록이 진행중입니다.', PhoenixErrorCode.INVESTOR_REGISTERED);
			break;
		}
		case INVESTOR_STATUS.APPROVED: {
			error = new AlertCommonError('투자자 등록을 이미 완료하셨어요.', PhoenixErrorCode.INVESTOR_REGISTERED);
			break;
		}
	}
	return error;
};

export const getCsrfToken = (cometAjax: CometAjax<any>, token?: string): Observable<string> => {
	let targetUrl = null;

	if (token) {
		targetUrl = '/v1/security/user/csrf-token/';
	} else {
		targetUrl = '/v1/security/csrf-token/';
	}
	const csrfToken = cometAjax.pfSecure.get(
		targetUrl,
		ObjectUtil.filterUndefined({
			token,
		})
	);

	return csrfToken.pipe(
		map((response) => response.csrf_token),
		catchError(() => {
			return of('');
		})
	);
};

export interface EncryptObservableResponse {
	eData: string;
	headers: {
		'X-CSRF-TOKEN': string;
		'AES-ENCRYPT-KEY': string;
		'RSA-ENCRYPT-KEY-ID': string;
	};
}

// TODO: 정확한 타입 기입 필요.
export const encryptObservable = (
	cometAjax: CometAjax<any>,
	jsonData: any,
	token?: string
): Observable<EncryptObservableResponse> => {
	return zip(getCsrfToken(cometAjax, token), createEncryptInfo(cometAjax, jsonData)).pipe(
		mergeMap(([csrfToken, encryptedDataInfoObservable]) =>
			encryptedDataInfoObservable.pipe(
				mergeMap((encryptedDataInfo) =>
					of({
						eData: encryptedDataInfo.encryptedData,
						headers: {
							'X-CSRF-TOKEN': csrfToken,
							'AES-ENCRYPT-KEY': encryptedDataInfo.encSecretKey,
							'RSA-ENCRYPT-KEY-ID': `${encryptedDataInfo.publicKeyId}`,
						},
					})
				)
			)
		)
	);
};

// TODO: 정확한 타입 기입 필요.
const createEncryptInfo = (cometAjax: CometAjax<any>, jsonData: any) => {
	const getRsaPublicKey = (cometAjax: CometAjax<any>) => {
		let rsaPublicKeyMap = { next: { public_key: '', key_id: 0 }, current: { public_key: '', key_id: 0 } };
		let publicKey = '';
		let keyId = 0;

		const rsaPublicKey = cometAjax.secureRsa.get('/public_key_cache/publicKeyCache.json');

		return rsaPublicKey.pipe(
			map((response) => {
				rsaPublicKeyMap = response;
				try {
					publicKey = rsaPublicKeyMap.next.public_key;
					keyId = rsaPublicKeyMap?.next.key_id;
				} catch (e) {
					publicKey = rsaPublicKeyMap.current.public_key;
					keyId = rsaPublicKeyMap.current.key_id;
				}
				return {
					publicKey: publicKey,
					keyId: keyId,
				};
			})
		);
	};

	const getEncryptMessageObservable = (publicKey: string, message: string) => {
		return from(import('jsencrypt')).pipe(
			map((response) => {
				const JSEncrypt = response.default;
				return getEncryptMessage(JSEncrypt, publicKey, message);
			})
		);
	};

	return getRsaPublicKey(cometAjax).pipe(
		map((publicKeyInfo) => {
			const secretKey = randomCode();

			return getEncryptMessageObservable(publicKeyInfo.publicKey, secretKey).pipe(
				map((encSecretKey) => {
					const stringData = JSON.stringify(jsonData);
					const encData = encAesMessage(secretKey, stringData);

					return {
						encryptedData: encData,
						publicKeyId: publicKeyInfo.keyId,
						encSecretKey,
					};
				})
			);
		})
	);
};
export const getUserInfo = (cometAjax: CometAjax<any>, token: string): Observable<FetchUserInfoResult> => {
	return cometAjax.pfUser
		.get(`/user/v1/member/info`, {
			token,
		})
		.pipe(
			map((response: FetchUserInfoResponse) => {
				return convertUserInfoServerToUserInfoClient(response);
			})
		);
};

export function convertUserInfoServerToUserInfoClient(response: FetchUserInfoResponse): FetchUserInfoResult {
	return {
		userName: response.name,
		email: response.email,
		phoneNumber: response.phone_number,
		birthDate: response.birth_date,
		userFinanceStatus: {
			investorRegistrationStatus: response.user_finance_status.investor_registration_status || '',
			investorShowcaseStatus: response.user_finance_status.investor_showcase_status,
		},
		gender: (response.gender as Gender) ?? 'NONE',
		hasRRN: response.has_rrn,
		hasPassword: response.has_password,
		rrn6: response.birth_date ? response.birth_date.slice(2) : '',
		rrn7Only: response.birth_date ? getRRN7Only(response.birth_date, response.gender) : '',
		isKorean: Boolean(response.is_korean),
		userType: (response.user_type as UserType) ?? 'NONE',
		corporateInfo: response.biz_reg_number
			? {
					bizRegNumber: response.biz_reg_number,
			  }
			: undefined,
	};
}
