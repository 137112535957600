import { AlertCommonError } from '@peoplefund/constants/error/type';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AmlEnumOptions, AmlField, AmlInfo, SetDataParam } from './index.model';
import { isInfoComplete } from './index.util';

type AmlVerifyMethod = 'NONE' | 'SMS' | 'PIN';
export interface Aml {
	error?: AlertCommonError;
	info?: AmlInfo;
	infoIsComplete: boolean;
	enums?: Record<string, AmlEnumOptions>;
	infoFetched: boolean;
	verifyMethod: AmlVerifyMethod;
}

export const amlInitialState: Aml = {
	error: undefined,
	info: undefined,
	infoIsComplete: false,
	enums: undefined,
	infoFetched: false,
	verifyMethod: 'NONE',
};

const amlSlice = createSlice({
	name: 'aml',
	initialState: amlInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, amlInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		load: (state) => {
			state.error = undefined;
		},
		setData: (state, { payload: { info, enums } }: PayloadAction<SetDataParam>) => {
			state.info = info;
			state.infoIsComplete = isInfoComplete(info);
			state.enums = enums;
			state.infoFetched = true;
		},
		setInfoFetched: (state) => {
			state.infoFetched = true;
		},
		updateField: (state, { payload: { key, value } }: PayloadAction<{ key: AmlField; value: string }>) => {
			if (state.info) {
				const updateValue = {
					...state.info,
					[key]: value,
				};

				state.info = updateValue;
				state.infoIsComplete = isInfoComplete(updateValue);
			}
		},
		checkVerifyType: (state) => {
			state.error = undefined;
		},
		setVerifyType: (state, { payload }: PayloadAction<AmlVerifyMethod>) => {
			state.verifyMethod = payload;
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	load,
	setData,
	setInfoFetched,
	updateField,
	checkVerifyType,
	setVerifyType,
} = amlSlice.actions;

export default amlSlice.reducer;
