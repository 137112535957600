import { Spec } from 'immutability-helper';
import { LSLoanState } from '@peoplefund/reducers/ls-loan';
import { AlertCommonError } from '@peoplefund/constants/error/type';

export const setLoanError = (error?: AlertCommonError): Spec<LSLoanState, never> => {
	return {
		loan: { error: { $set: error } },
	};
};

export const setFPAError = (id: string, error?: AlertCommonError): Spec<LSLoanState, never> => {
	return {
		financialProductApplications: {
			[id]: { error: { $set: error } },
		},
	};
};
