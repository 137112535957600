import { TransferOCRResponse } from '@peoplefund/epics/twoPlusOneVerify/index.model';
import { DriversLicence } from '@peoplefund/components/common/DriversLicenseInput/index.model';
import { OCRResult } from '@peoplefund/slices/two-plus-one-verify/index.model';

export const convertTransferOCRResponse = (response: TransferOCRResponse): OCRResult => {
	const imageType = 'image/png';
	const { rrn, driver_license, register_date, masking_image } = response;
	const driverLicenseArray = driver_license?.split('-') ?? [];
	let driverLicense: DriversLicence | undefined = undefined;

	if (driverLicenseArray.length === 3) {
		const [areaCode, firstInfo, secondInfo] = driverLicenseArray;
		driverLicense = { areaCode: Number(areaCode), number: `${firstInfo}${secondInfo}` };
	}

	const registerDate = register_date ? register_date.replace(/(\d{4})(\d{2})(\d{2})/, `$1-$2-$3`) : '';

	const maskingImage = masking_image ? `data:${imageType};base64,${response.masking_image}` : '';

	return {
		rrn: rrn || '',
		driversLicence: driverLicense,
		registerDate,
		maskingImage,
	};
};

export const convertBase64UrlImageToBase64 = (base64Url: string): string => {
	return base64Url.split(';base64,')[1];
};
