import TokenStrategy from '@peoplefund/utils/axios/TokenStrategy';

export type Headers = Record<string, string>;

export default abstract class HeaderStrategy<ApiClientType, ApiResponseType> {
	constructor() {
		this.getHeaders = this.getHeaders.bind(this);
	}

	public abstract getHeaders(tokenStrategy: TokenStrategy<ApiClientType, ApiResponseType>, headers?: Headers): Headers;
}
