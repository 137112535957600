import { ExistingLoanResultServerProps } from '@peoplefund/epics/ls-apply-info.model';
import { CurrentLoanItem } from '@peoplefund/actions/ls-apply-info';

export const convertExistingLoanResult = (result: ExistingLoanResultServerProps[]): CurrentLoanItem[] =>
	result.map((item: ExistingLoanResultServerProps) => {
		const { id, balance, institution, institution_type, open_date, total_amount } = item;
		return {
			balance,
			id,
			directInfo: total_amount === null || institution?.name === null || open_date === null,
			financialSector: institution_type,
			organizationName: institution?.name || '',
			organizationLogoUrl: institution?.logo_url,
			totalAmount: total_amount || 0,
			openDate: open_date || '',
			unsecuredLoanApplicationId: 0,
			organizationCode: '',
		};
	});
