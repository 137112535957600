export enum LSLoanStatus {
	UNKNOWN = 'unknown',
	CREATED = 'created', //"생성됨"
	IN_PROGRESS_PRE_EVALUATION = 'in_progress_pre_evaluation', //"가조회 진행중"
	DONE_PRE_EVALUATION = 'done_pre_evaluation', //"가조회 완료"
	IN_PROGRESS_EVALUATION = 'in_progress_evaluation', //"본조회 중"
	DONE = 'done', //"완료"
	CANCELED = 'canceled', //"취소됨 - 클라에서 만든 상태"
	REJECTED = 'rejected', //"거절됨 - 클라에서 만든 상태"
}

export enum LSFinancialProductApplicationStatus {
	UNKNOWN = 'unknown',
	APPROVED_PRE_EVALUATION = 'approved_pre_evaluation', // "가조회 승인"
	REJECTED_PRE_EVALUATION = 'rejected_pre_evaluation', // "가조회 거절"
	REJECTED_EVALUATION = 'rejected_evaluation', // "본조회 거절"
	IN_PROGRESS_EVALUATION = 'in_progress_evaluation', // "본조회 진행중 - 클라에서 만든 상태",
	IN_PROGRESS_SUBMIT_DOCUMENT = 'in_progress_submit_document', // "서류 심사중"
	DONE = 'done', // "완료 - 제휴사 전화 대기 - 클라에서 만든 상태"
}
