import { combineEpics } from 'redux-observable';
import common from '@peoplefund/epics/common';
import account from '@peoplefund/epics/account';
import smsVerification from '@peoplefund/epics/sms-verification';
import lsCommon from '@peoplefund/epics/ls-common';
import lsAuthentication from '@peoplefund/epics/ls-authentication';
import lsApplyInfo from '@peoplefund/epics/ls-apply-info';
import lsLoan from '@peoplefund/epics/ls-loan';
import lsFinancialProduct from '@peoplefund/epics/ls-financial-product';
import lsFinancialProductApplication from '@peoplefund/epics/ls-financial-product-application';
import unsecuredInvestorRegistration from '@peoplefund/epics/unsecured-investor-registration';
import plInvesting from '@peoplefund/epics/pl-investing';
import mlInvesting from '@peoplefund/epics/ml-investing';
import peacock from '@peoplefund/epics/peacock';
import commonInvesting from '@peoplefund/epics/common-investing';
import mlLoan from './ml-loan';
import mlLS from './ml-ls';
import agreement from './agreement';
import loansAutopay from './loans-autopay';
import vote from './vote';
import twoPlusOneVerify from './twoPlusOneVerify';
import kyc from './aml';
import investing from './investing';

export default combineEpics(
	...common,
	...account,
	...smsVerification,
	...lsCommon,
	...lsAuthentication,
	...lsApplyInfo,
	...lsLoan,
	...lsFinancialProduct,
	...lsFinancialProductApplication,
	...unsecuredInvestorRegistration,
	...plInvesting,
	...mlInvesting,
	...peacock,
	...commonInvesting,
	...mlLoan,
	...agreement,
	...loansAutopay,
	...mlLS,
	...vote,
	...twoPlusOneVerify,
	...kyc,
	...investing
);
