import { AxiosInstance, AxiosResponse } from 'axios';
import TokenStrategy from '@peoplefund/utils/axios/TokenStrategy';
import { encAesMessage, randomCode } from '@peoplefund/utils/crypt.util';
import { Pipes } from '@peoplefund/epics/ajax.util';

export type RefreshResponse = { readonly access_token: string; readonly refresh_token: string };

export default abstract class AxiosTokenStrategy extends TokenStrategy<AxiosInstance, AxiosResponse<RefreshResponse>> {
	async refreshAccessToken(apiClient: AxiosInstance): Promise<AxiosResponse<RefreshResponse>> {
		// 실제 요청 처리
		return new Promise((resolve, reject) => {
			const { getRefreshToken } = this;
			const refreshToken = getRefreshToken();

			let body: any = '';

			// 디바운스를 적용하여 1초 후에 실행
			encryptDataWithCsrf(apiClient, { refresh_token: refreshToken })
				.then((res) => {
					body = res;
				})
				.finally(() => {
					return apiClient
						.post<RefreshResponse>(
							`/pipe/loanshot/auth/v1/token/refresh`,
							{ eData: body.eData },
							{
								headers: {
									...body.headers,
								},
							}
						)
						.then((response) => {
							return resolve(response);
						})
						.catch((error) => {
							return reject(error);
						});
				});
		});
	}
}

const getCsrfToken = async (apiClient: AxiosInstance, token?: string): Promise<string> => {
	const targetUrl = token ? '/v1/security/user/csrf-token/' : '/v1/security/csrf-token/';

	try {
		const response = await apiClient.get(`${Pipes.pfSecure.baseUrl}${targetUrl}`, {
			params: { token },
		});
		return response.data.csrf_token;
	} catch (error) {
		console.error('CSRF token fetch failed', error);
		return ''; // CSRF token을 받지 못한 경우 빈 문자열 반환
	}
};

const getRsaPublicKey = async (apiClient: AxiosInstance) => {
	try {
		const response = await apiClient.get(`${Pipes.secureRsa.baseUrl}/public_key_cache/publicKeyCache.json`);
		const rsaPublicKeyMap = response.data;

		const publicKey = rsaPublicKeyMap.next?.public_key || rsaPublicKeyMap.current.public_key;
		const keyId = rsaPublicKeyMap.next?.key_id || rsaPublicKeyMap.current.key_id;

		return { publicKey, keyId };
	} catch (error) {
		console.error('RSA public key fetch failed', error);
		throw new Error('Failed to fetch RSA public key');
	}
};

const getEncryptMessage = async (publicKey: string, message: string) => {
	const { default: JSEncrypt } = await import('jsencrypt'); // 동적 import
	const encryptor = new JSEncrypt();

	encryptor.setPublicKey(publicKey);
	return encryptor.encrypt(message);
};

const createEncryptInfo = async (apiClient: AxiosInstance, jsonData: any) => {
	try {
		const { publicKey, keyId } = await getRsaPublicKey(apiClient);
		const secretKey = randomCode(); // AES secret key 생성

		// 동적 import를 사용한 암호화 처리
		const encryptedSecretKey = await getEncryptMessage(publicKey, secretKey);
		const stringData = JSON.stringify(jsonData);
		const encryptedData = encAesMessage(secretKey, stringData); // AES로 데이터 암호화

		return {
			encryptedData,
			publicKeyId: keyId,
			encSecretKey: encryptedSecretKey,
		};
	} catch (error) {
		console.error('Encryption failed', error);
		throw new Error('Failed to encrypt data');
	}
};

export const encryptDataWithCsrf = async (apiClient: AxiosInstance, jsonData: any, token?: string) => {
	try {
		const csrfToken = await getCsrfToken(apiClient, token);
		const encryptInfo = await createEncryptInfo(apiClient, jsonData);

		return {
			eData: encryptInfo.encryptedData,
			headers: {
				'X-CSRF-TOKEN': csrfToken,
				'AES-ENCRYPT-KEY': encryptInfo.encSecretKey,
				'RSA-ENCRYPT-KEY-ID': `${encryptInfo.publicKeyId}`,
			},
		};
	} catch (error) {
		console.error('Encrypt and CSRF token fetch failed', error);
		throw new Error('Failed to encrypt data and fetch CSRF token');
	}
};
