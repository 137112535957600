import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertCommonError } from '@peoplefund/constants/error/type';

type Info = { readonly description: string; tip: string; title: string };

export type LoanProductApplication = {
	title: string;
	iconUrl: string;
	id: string;
	interestRate: number;
	applyAmount: number;
	maxAmount: number;
	//대출 상세 정보
	comments: string[];
	information: {
		common_information: Info[];
		detail_condition: Info[];
	};
};

export type CurrentContractPayload = {
	hashed_lpa_id: string;
};

const Unknown = '';
export interface ContractState {
	error?: AlertCommonError;
	loanApplication: {
		id: string;
	};
	loanProductApplication: LoanProductApplication;
}

export const initialContractState: ContractState = {
	loanApplication: {
		id: Unknown,
	},
	loanProductApplication: {
		title: '',
		iconUrl: '',
		id: Unknown,
		interestRate: 0,
		applyAmount: 0,
		maxAmount: 0,
		information: {
			common_information: [],
			detail_condition: [],
		},
		comments: [],
	},
};

const SLICE_NAME = 'mortgageLoanContractState';

const preScreening = createAction<{
	readonly brand: string; // 브랜드
	readonly category: string; // 카테고리
	readonly name: string; // 모델명
	readonly type: string; //사이즈/종류
	readonly grade: string; // 상품 등급
	readonly retailPrice: number; // 상품 가격
	readonly compatibilityAnswers: Record<string, string>;
}>(`${SLICE_NAME}/getProperties`);

const contractLoansSlice = createSlice({
	name: SLICE_NAME,
	initialState: initialContractState,
	reducers: {
		// reset 겸용
		initialize: (state) => {
			state.error = undefined;
			state.loanApplication = initialContractState.loanApplication;
			state.loanProductApplication = initialContractState.loanProductApplication;
		},
		setError: (state, action: PayloadAction<AlertCommonError | undefined>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setLoanApplicationId: (state, { payload }: PayloadAction<number>) => {
			state.loanApplication.id = `${payload}`;
		},
		setLoanProductApplication: (state, { payload }) => {
			state.loanProductApplication = payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(preScreening, (state) => {
			state.error = undefined;
		});
	},
});

export const { initialize, setLoanApplicationId, setLoanProductApplication, setError, resetError } =
	contractLoansSlice.actions;

export default contractLoansSlice.reducer;
