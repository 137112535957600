import dynamic from 'next/dynamic';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppContext, AppInitialProps, AppProps } from 'next/app';
import { NextComponentType } from 'next';
import { GlobalStyle, THROTTLE_KEYWORD } from '@peoplefund/styles/global';
import { CacheProvider, Global } from '@emotion/react';
import createCache from '@emotion/cache';
import Head from 'next/head';
import { actions } from '@peoplefund/actions';
import { PHOENIX_SESSION_ID } from '@peoplefund/constants/session-id';
import { useRouter } from 'next/router';
import { removeSessionCookie } from '@peoplefund/utils/cookie.util';
import TagManager from 'react-gtm-module';
import UtmFactory from '@peoplefund/utils/marketing-script/UtmModule';
import { registerHookForOverlayPreventScroll } from '@pfct/purple';
import { getJWTByCookie } from '@peoplefund/utils/jwt.util';
import TokenExpiredErrorBoundary from '@peoplefund/components/common/TokenExpiredErrorBoundary';
import { PhoenixApiClient } from '@peoplefund/utils/axios';
import Script from 'next/script';
import { getEpicMiddleWare, initializeStore, reducer, RootState, wrapper } from '@peoplefund/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import rootEpic from '@peoplefund/epics';
import { getAccessTokenFromCookies } from './appview/card/home';
import { AnyAction } from '@reduxjs/toolkit';
import { WebviewHandler } from '@peoplefund/utils/webview-handler/index.util';
import { AppRefreshAction, UpdateTokenAction } from '@peoplefund/utils/webview-handler/bridge.type';
import axios from 'axios';
import { FetchUserInfoResponse } from '@peoplefund/epics/account/index.model';
import { Pipes } from '@peoplefund/epics/ajax.util';
import { convertUserInfoServerToUserInfoClient } from '@peoplefund/epics/account/index.util';
const AppWrapper = dynamic(() => import('@peoplefund/components/_app'));
const KAKAO_VERSION = '2.7.2';
const INTEGRITY_VALUE = 'sha384-TiCUE00h649CAMonG018J2ujOgDKW/kVWlChEuu4jK2vxfAAD0eZxzCKakxg55G4';
const JAVASCRIPT_KEY = '6248922864195f17f270625134137262';
const gtmIds = ['GTM-K86PBTV' // RT 스크립트 설치용
];
export const WEBPAGE_TITLE = '크플 - 요즘 돈 버는 법';
const WEBPAGE_IMAGE = 'https://static.cple.co.kr/assets/share_banner_cple.png';
interface CustomAppProps extends AppProps {
  pageProps: {
    initialState?: RootState; // 여기에 추가
    [key: string]: any; // 기타 다른 props
  };
}
const PeopleFund: NextComponentType<AppContext, AppInitialProps, CustomAppProps> = ({
  Component,
  ...rest
}) => {
  const initialStateFromServer = rest.pageProps.initialState;
  const {
    store,
    props
  } = wrapper.useWrappedStore({
    ...rest,
    initialState: initialStateFromServer
  });
  const {
    pageProps
  } = props as CustomAppProps;
  const ogTagInfo = {
    title: pageProps?.ogTagInfo?.title || WEBPAGE_TITLE,
    description: pageProps?.ogTagInfo?.description || WEBPAGE_TITLE,
    image: pageProps?.ogTagInfo?.image || WEBPAGE_IMAGE
  };
  const router = useRouter();
  const cache = createCache({
    key: 'phoenix'
  });
  const isWebview = WebviewHandler().isWebview;
  useEffect(() => {
    if (!isWebview || !store) {
      return;
    }

    // fyi. 웹뷰를 위한 업데이트 토큰 함수 참고문서: https://www.notion.so/webview-native-74cf7a9e80ef4bcdb99a2d45e69ea9f9?pvs=4#b365326f797149f2b1a47545bcaaf6ca
    const handleUpdateTokenForApp = (event: Event) => {
      // 이벤트를 CustomEvent로 캐스팅
      const customEvent = event as CustomEvent<UpdateTokenAction>;
      console.log({
        customEvent
      });
      const {
        access_token,
        refresh_token
      } = customEvent.detail.data;
      PhoenixApiClient.processQueue({
        success: true,
        accessToken: access_token
      });
      store.dispatch(actions.account.signIn({
        accessToken: access_token,
        refreshToken: refresh_token,
        expiredAt: Number(new Date())
      }));
    };
    window.addEventListener('updateToken', handleUpdateTokenForApp);
    return () => {
      window.removeEventListener('updateToken', handleUpdateTokenForApp);
    };
  }, [isWebview, store]);
  useEffect(() => {
    const html = document.getElementsByTagName('html')?.[0];
    if (html) {
      const funcName = isWebview ? 'add' : 'remove';
      html.classList[funcName]('isWebview');
    }
  }, [isWebview]);
  useEffect(() => {
    if ((process as any).browser) {
      // 여러개 설치하기
      for (let idx = 0; idx < gtmIds.length; idx++) {
        TagManager.initialize({
          gtmId: gtmIds[idx]
        });
      }
    }
  }, []);
  useEffect(() => {
    // 여기서 세팅했으니깐 다른 곳은 꺼내 쓰기만 하면 됨
    const utmModule = UtmFactory.instance.setRouter(router);
    utmModule?.setQueryString(router.isReady);
  }, [router, router.isReady]);
  useEffect(() => {
    PhoenixApiClient.setStore(store);
    const signInResult = getJWTByCookie();
    if (signInResult) {
      store.dispatch(actions.account.signIn(signInResult));
    } else {
      removeSessionCookie(PHOENIX_SESSION_ID);
    }
  }, [store]);
  useEffect(() => {
    registerHookForOverlayPreventScroll({
      throttleKeyword: THROTTLE_KEYWORD,
      globalScrollTarget: document.body
    });
  }, []);
  useEffect(() => {
    if (isWebview) {
      const handlePullToRefresh = (event: Event) => {
        // 이벤트를 CustomEvent로 캐스팅
        const customEvent = event as CustomEvent<AppRefreshAction>;
        console.log({
          customEvent
        });
      };
      window.addEventListener('pullToRefresh', handlePullToRefresh);
      return () => {
        window.removeEventListener('pullToRefresh', handlePullToRefresh);
      };
    }
  }, [isWebview]);
  const queryClient = new QueryClient();
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
				<meta content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" name="viewport" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<title>{ogTagInfo.title}</title>
				<meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:locale" content="ko_KR" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:site_name" content="크플" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				<meta property="og:title" content={ogTagInfo.title} data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
				{ogTagInfo.description && <>
						<meta name="description" content={ogTagInfo.description} />
						<meta property="og:description" content={ogTagInfo.description} />
					</>}
				{ogTagInfo.image && <>
						<meta property="og:image" content={ogTagInfo.image} />
						<meta property="og:image:width" content="1200" />
						<meta property="og:image:height" content="630" />
					</>}
				<meta name="naver-site-verification" content="48d71d731ae17fb6fa9aca78a984c5084fff3c19" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
			</Head>
			<Provider store={store} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
				<CacheProvider value={cache} data-sentry-element="CacheProvider" data-sentry-source-file="_app.tsx">
					<QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
						<Global styles={GlobalStyle} data-sentry-element="Global" data-sentry-source-file="_app.tsx" />
						<TokenExpiredErrorBoundary data-sentry-element="TokenExpiredErrorBoundary" data-sentry-source-file="_app.tsx">
							<AppWrapper data-sentry-element="AppWrapper" data-sentry-source-file="_app.tsx">
								<Script src={`https://t1.kakaocdn.net/kakao_js_sdk/${KAKAO_VERSION}/kakao.min.js`} integrity={INTEGRITY_VALUE} crossOrigin="anonymous" onLoad={() => {
                if (Kakao && !Kakao.isInitialized()) {
                  Kakao.init(JAVASCRIPT_KEY);
                }
              }} data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
								<Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
							</AppWrapper>
						</TokenExpiredErrorBoundary>
						<ReactQueryDevtools initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
					</QueryClientProvider>
				</CacheProvider>
			</Provider>
		</>;
};
export default PeopleFund;
PeopleFund.getInitialProps = async ({
  ctx
}) => {
  if (typeof window === 'object') {
    return {
      pageProps: {
        initialState: undefined
      }
    };
  }
  const epicMiddleware = getEpicMiddleWare();
  const reduxStore = initializeStore({
    reducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false
    }).concat([epicMiddleware]),
    preloadedState: ctx.store
  });
  const cookie = ctx?.req?.headers.cookie ?? '';
  const cookieList = cookie.split(';').map(item => item.trim());
  const cookieMap: {
    [key: string]: string;
  } = {};
  cookieList.forEach(item => {
    const [key, value] = item.split('=');
    cookieMap[key] = decodeURIComponent(value);
  });
  const signInResult = getAccessTokenFromCookies(cookieMap);

  // 서버에서 signIn 액션을 디스패치
  if (!(isEmptyToken(signInResult.accessToken) || isEmptyToken(signInResult.refreshToken))) {
    reduxStore.dispatch(actions.account.setToken(signInResult) as AnyAction);
    reduxStore.dispatch(actions.account.signIn(signInResult) as AnyAction);
    reduxStore.dispatch(actions.account.signIn(signInResult) as AnyAction);
  }
  if (!isEmptyToken(signInResult.accessToken)) {
    try {
      const token = signInResult.accessToken ?? 'anonymous';
      const res = await axios.get<FetchUserInfoResponse>(`${Pipes['pfUser']['baseUrl']}/user/v1/member/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      reduxStore.dispatch(actions.account.fetchUserInfo.done({
        params: {},
        result: convertUserInfoServerToUserInfoClient(res.data)
      }) as AnyAction);
    } catch (e) {
      console.error(e);
    }
  }
  epicMiddleware.run(rootEpic);
  return {
    pageProps: {
      initialState: reduxStore.getState()
    }
  };
};
function isEmptyToken(value: string) {
  return !value || value === 'anonymous';
}