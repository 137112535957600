/**
 * @param options
 * @param options.floorUnit 내림할 단위. 예를 들어, value 13209802에 대해 unit 10000 이면 1,320만 원이 됩니다.
 * @param options.formatAllDigits true일경우, 천 단위도 format 합니다. 예를 들어: 1,234만 이면 천2백3십4만원 이 됩니다.
 * @param options.onlyLastFormat true일경우, 최대값 기준으로 표기합니다. 예를 들어: 12,345만 이면 1.2345억원 이 됩니다.
 * @param options.removeSpacing 금액 단위간 띄어쓰기 삭제 여부. true일경우, `1억5천만원` 이 됩니다.
 * @param options.omitWon 끝에 '원'을 제외시킬지 여부.
 */
interface Options {
	floorUnit?: number;
	formatAllDigits?: boolean;
	onlyLastFormat?: boolean;
	removeSpacing?: boolean;
	omitWon?: boolean;
}

export const numberFormatRemove = (value: string): string => value.replace(/,|\s|경|조|억|만|천|백|십|원/gi, '');

export const basicMoneyHelperText = (value: string, options?: Options): string => {
	if (!options?.removeSpacing) {
		options = { removeSpacing: true, ...options };
	}

	return value === '' ? '(만원)' : `(${formatToKRW(value, options)})`;
};

const _UNITS = ['', '십', '백', '천', '만', '십', '백', '천', '억', '십', '백', '천', '조', '십', '백', '천', '경'];

const chunk = (value: number, byDigits: number) => {
	const result = [];
	const source = String(value);

	for (let end = source.length; end >= 1; end = end - byDigits) {
		const start = Math.max(end - byDigits, 0);
		const slice = source.slice(start, end);

		result.push(Number(slice));
	}

	return result;
};

const formatThousands = (num: number) =>
	String(num)
		.split('')
		.reverse()
		.map((digit, index) => {
			if (digit === '0') {
				return '';
			} else if (digit === '1') {
				if (index % 3 === 0) {
					return `1${_UNITS[index]}`;
				} else {
					return `${_UNITS[index]}`;
				}
			} else {
				return `${digit}${_UNITS[index]}`;
			}
		})
		.reverse()
		.join('');

const createNumberFormatterBy = (formatter: (x: number) => number) => {
	return function formatNumber(value: number, unit: number) {
		if (unit < 1) {
			// 부동소수점 오류 때문에 unit < 1인 경우 특별 처리
			const reciprocal = 1 / unit;

			return formatter(value * reciprocal) / reciprocal;
		}

		return formatter(value / unit) * unit;
	};
};

const floorToUnit = createNumberFormatterBy(Math.floor);

/**
 * 가장 높은 한국 단위로만 표현하기 위한 함수입니다.
 * (항상 세트로 사용되기 때문에) 한국 원화, 한국 단위(+원)를 쌍으로 반환합니다.
 * 마치 formatToKRW(, {onlyLastFormat: true}) = formattedKrwAmount + formattedKrwUnit 버전.
 *
 * @param value
 * @returns [formattedKrwAmount, formattedKrwUnit]
 *
 */
export const seperatorHighestKrwAmountAndUnit = (value: number | string): [string, string] => {
	const flooredVal = floorToUnit(Number(value) ?? 0, 1);
	const valArray = chunk(flooredVal > 0 ? flooredVal : Math.abs(flooredVal), 4);

	let formattedKrwAmount;
	if (flooredVal === 0) {
		formattedKrwAmount = '0';
	} else {
		const val = flooredVal / Math.pow(10, (valArray.length - 1) * 4);
		formattedKrwAmount = `${Number(value) < 0 ? '-' : ''}${formatToNumberComma(val)}`;
	}

	const unit = _UNITS[(valArray.length - 1) * 4];
	const formattedKrwUnit = `${unit}원`;

	return [formattedKrwAmount, formattedKrwUnit];
};

/**
 * 숫자를 한국어 원으로 변환합니다. 예를 들어, 13209802 --> 1,320만 9,802원
 * 원이 필요하지 않을경우, formatToKoreanNumber를 사용하세요.
 * @param value 변환할 숫자
 *
 * [옵션간의 우선순위]
 * 1. options.floorUnit 으로 먼저 내림해줍니다.
 * 2. options.onlyLastFormat=true, 최대단위 기준으로 반환합니다.
 * 3. options.formatAllDigits=true, 각 자릿수별로 한글로 변환해 반환합니다.
 *
 * [기본으로 적용되는 옵션]
 * 1. 띄어쓰기가 있는 결과를 반환합니다. (원치 않는경우, options.removeSpacing=true 를 넘겨주세요)
 * 2. '원'이 끝에 붙습니다.  (원치 않는경우, options.omitWon=true 를 넘겨주세요)
 */
export const formatToKRW = (value: number | string, options?: Options): string => {
	let formattedKrNumber;

	const flooredVal = floorToUnit(Number(value) ?? 0, options?.floorUnit || 1);
	const valArray = chunk(flooredVal > 0 ? flooredVal : Math.abs(flooredVal), 4);

	if (flooredVal === 0) {
		formattedKrNumber = '0';
	} else if (options?.onlyLastFormat) {
		const unit = _UNITS[(valArray.length - 1) * 4];
		const val = flooredVal / Math.pow(10, (valArray.length - 1) * 4);
		formattedKrNumber = `${Number(value) < 0 ? '-' : ''}${val}${unit}`;
	} else {
		const fullVal = valArray
			.reduce((prevFormatted, currChunkNum, index) => {
				if (currChunkNum === 0) {
					return prevFormatted;
				}

				const val = options?.formatAllDigits ? formatThousands(currChunkNum) : formatToNumberComma(currChunkNum);
				const unit = _UNITS[index * 4];

				return options?.removeSpacing ? `${val}${unit}${prevFormatted}` : `${val}${unit} ${prevFormatted}`;
			}, '')
			.trim();
		formattedKrNumber = `${Number(value) < 0 ? '-' : ''}${fullVal}`;
	}

	return options?.omitWon ? formattedKrNumber : `${formattedKrNumber}원`;
};

export const formatToNumberComma = (value: number): string => {
	const [integer, fraction] = Number(value).toString().split('.');
	return integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (fraction ? `.${fraction}` : '');
};

export enum KRWUnit {
	'천' = 1000,
	'만' = 10000,
	'십만' = 10000 * 10,
	'백만' = 10000 * 100,
	'천만' = 10000 * 1000,
	'억' = 100000000,
}

type TimeFormat = 'HH:mm:ss' | 'mm:ss';

export const formatSecondsToTime = (timeInSeconds: number, format: TimeFormat): string => {
	const formatTime = (time: number) => (time < 10 ? '0' + time : time);
	const hours = Math.floor(timeInSeconds / 3600) % 24;
	const minutes = Math.floor(timeInSeconds / 60) % 60;
	const seconds = Math.floor(timeInSeconds) % 60;
	switch (format) {
		case 'HH:mm:ss':
			return `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
		case 'mm:ss':
			return `${formatTime(minutes)}:${formatTime(seconds)}`;
	}
};

/**
 *
 * @param val
 * @param position : 소수점 N 째 자리까지 노출합니다. (N+1 자리에서 반올림)
 * @returns 단, 정수/소수점 첫째자리까지일 경우 첫째자리까지 노출합니다.
 */
export const formatDecimalPoint = (val: number, position: number): string => {
	const [, decimal] = String(val).split('.');
	if (!decimal || decimal.length <= 1) {
		return val.toFixed(1);
	}
	return val.toFixed(position);
};

/**
 *
 * @param phoneNumber: 핸드폰 번호
 * @param delimiter : 구분자
 * @returns 구분자로 연결된 핸드폰 번호를 반환합니다.
 */
export const formatPhoneNumber = (phoneNumber: string, delimiter = ''): string => {
	return phoneNumber.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1${delimiter}$2${delimiter}$3`);
};
