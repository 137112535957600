import { ErrorWithJson, InAPICommonError } from '@peoplefund/constants/error/type';
import HttpMethod from '@peoplefund/constants/http-method';
import { from, Observable } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import apiClient from '@peoplefund/utils/axios';
import { AxiosError } from 'axios';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import ErrorMessageHandler from '@peoplefund/utils/errorMessageHandler';

enum PipeKey {
	inapi = 'inapi',
	css = 'css',
	loan = 'loan',
	mortgageUtils = 'mortgageUtils',
	auth = 'auth',
	user = 'user',
	pfSecure = 'pfSecure',
	pelican = 'pelican',
	investment = 'investment',
	static = 'static',
	newStatic = 'newStatic',
	terms = 'terms',
	secureRsa = 'secureRsa',
	pfUser = 'pfUser',
	phalcon = 'phalcon',
	pfCode = 'pfCode',
	cert = 'cert',
	point = 'point',
	investmentGateway = 'investmentGateway',
	million = 'million',
}

export const Pipes: Record<
	PipeKey,
	{
		baseUrl: string;
	}
> = {
	inapi: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/inapi`,
	},
	css: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/css`,
	},
	cert: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/cert`,
	},
	loan: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/loan`,
	},
	mortgageUtils: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/mortgage-utils`,
	},
	/** 기존에는 inapi auth에서 계정 관리를 했으나, pf_user로 통합되는 중임. pf_user가 먼저 반영되는 원앱/론샷에서 사용하기 위해 pipe를 추가 */
	auth: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/auth`,
	},
	user: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/user`,
	},
	pfSecure: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/pf-secure`,
	},
	pelican: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/pelican`,
	},
	investment: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/investment`,
	},
	investmentGateway: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/investment`,
	},
	static: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/static`,
	},
	newStatic: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/new-static`,
	},
	terms: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/loanshot/terms`,
	},
	secureRsa: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/secure-rsa`,
	},
	pfUser: {
		// TODO: 추후 위 user baseUrl 삭제 후 해당 키를 user로 변경한다.
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/user`,
	},
	phalcon: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/phalcon`,
	},
	pfCode: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/pf-code`,
	},
	point: {
		baseUrl: `${process.env.NEXT_PUBLIC_COMET_URL}/pipe/point`,
	},
	million: {
		baseUrl: `${process.env.NEXT_PUBLIC_MILLION_SERVER_URL}/api`,
	},
};

const ALLOWED_METHODS = ['get', 'post', 'patch', 'put', 'delete'] as const;
type AllowedHttpMethod = (typeof ALLOWED_METHODS)[number];

interface Header {
	[key: string]: string;
}

interface Headers {
	[key: string]: string;
}

type BodyValue = string | number | boolean | null | undefined;
type BodyObject = BodyValue | BodyObject[] | { [key: string]: BodyObject };
type BodyRoot = { [key: string]: BodyObject };
/**
 * RequestBody Root 에는 배열은 들어갈 수 없습니다.
 * 서버에서 배열을 받기에 꼭 써야 하면, arrays 라는 키로 그 안에 배열을 넣으세요.
 */
export type RequestBody = BodyRoot | undefined;

type Options = {
	body?: RequestBody;
	token?: string;
	headers?: Headers;
};

interface Func<ResponseType> {
	(subPath: string, options?: Options): Observable<ResponseType>; // 여기서, 최종적으로 cometAjax 메소드의 response 타입이 결정됨.
}

interface MethodFunc<ResponseType> {
	post: Func<ResponseType>;
	get: Func<ResponseType>;
	patch: Func<ResponseType>;
	put: Func<ResponseType>;
	[method: string]: Func<ResponseType>;
}

export interface CometAjax<ResponseType> {
	[pipe: string]: MethodFunc<ResponseType>;
}

export const mergeQueryBody = (querystring: string, body: RequestBody): string => {
	const query = new URLSearchParams(querystring);
	if (body) {
		let key: keyof typeof body;
		for (key in body) {
			if (!body.hasOwnProperty(key)) {
				continue;
			}
			const value = body[key];
			if (typeof value === 'undefined') {
				query.delete(key);
			} else if (typeof value === 'object') {
				if (Array.isArray(value)) {
					query.delete(key);
					value.forEach((v) => {
						if (typeof v === 'object') {
							throw new Error(`GET 호출의 body 처리가 불가능합니다.(${key})`);
						}
						query.append(String(key), String(v));
					});
				} else {
					throw new Error(`GET 호출의 body 처리가 불가능합니다.${key}`);
				}
			} else {
				query.set(key, String(value));
			}
		}
	}
	return query.toString();
};

const makeAjax = <ResponseType>(
	pipeKey: PipeKey,
	subPath: string,
	method: HttpMethod,
	{ token, body, headers }: Options = { token: undefined, body: undefined, headers: undefined }
) => {
	const pipe = Pipes[pipeKey];

	if (!subPath.startsWith('/')) {
		subPath = `/${subPath}`;
	}

	const _headers: Header = headers ?? {};
	if (Boolean(token)) {
		_headers['Authorization'] = `Bearer ${token}`;
	}

	if (Boolean(body)) {
		switch (method) {
			case HttpMethod.POST:
			case HttpMethod.PATCH:
			case HttpMethod.PUT:
				_headers['content-type'] = _headers['content-type'] ?? 'application/json';
				break;

			// body 를 querystring 으로 이동
			// TODO 굳이 안넣고, type 으로 body에 넣지 않도록 해야 할 듯
			case HttpMethod.GET:
				let query = '';
				const res = subPath.match(/^(.*)\?(.*)$/);
				if (res) {
					subPath = res[1];
					query = res[2];
				}
				subPath = `${subPath}?${mergeQueryBody(query, body)}`;
				break;

			default:
				break;
		}
	}

	return ajaxPipeline<ResponseType>(`${pipe.baseUrl}${subPath}`, method, _headers, body);
};

const ajaxPipeline = <ResponseType>(url: string, _method: HttpMethod, headers: Header, body: RequestBody) => {
	const method = _method.toUpperCase();
	return from(
		apiClient<
			ResponseType & {
				errmsg?: string;
				error_detail?: string;
			}
		>({
			method,
			url,
			responseType: 'json',
			headers,
			data: method !== 'GET' ? body : undefined,
		})
	).pipe(
		map(({ data, status }) => {
			if (status === 200 || status === 201) {
				if (data?.errmsg === 'fail') {
					throw new InAPICommonError(data.errmsg, data.error_detail);
				}
			}

			return data;
		}),
		catchError((error) => {
			if (error instanceof AxiosError && error.response) {
				const {
					data: { code, detail, data, message: msg },
				} = error.response;
				const message = detail || msg;

				const convertedError = new InAPICommonError(message, code ?? PhoenixErrorCode.UNKNOWN, data);
				return from(ErrorMessageHandler.getMessage(convertedError)).pipe(
					mergeMap((convertedMessage) => {
						const customError = new ErrorWithJson(
							convertedMessage.title,
							convertedMessage.message,
							convertedError.code
						);

						throw new InAPICommonError(customError);
					})
				);
			}
			throw new InAPICommonError(error);
		})
	);
};
export const cometAjax = (function <ResponseType>() {
	const pipeKeys = Object.keys(Pipes) as PipeKey[];

	return pipeKeys
		.map((pipe): { pipe: PipeKey; methodFunc: MethodFunc<ResponseType> } => ({
			pipe,
			methodFunc: ALLOWED_METHODS.map((method): { method: AllowedHttpMethod; func: Func<ResponseType> } => ({
				method,
				func: (subPath, options) => makeAjax<ResponseType>(pipe, subPath, method.toUpperCase() as HttpMethod, options),
			})).reduce((acc, { method, func }) => {
				acc[method] = func;
				return acc;
			}, {} as MethodFunc<ResponseType>),
		}))
		.reduce((acc: CometAjax<ResponseType>, { pipe, methodFunc }) => {
			acc[pipe] = methodFunc;
			return acc;
		}, {} as CometAjax<ResponseType>);
})();
