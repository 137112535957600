import { InputUnit, DEFAULT_INPUT_UNIT } from '@peoplefund/constants/pl-investing';
/**
 *
 * @param totalValue 분산할 총 금액
 * @param length 분산될 상품 개수
 * @returns 1바퀴 동안 균등 분배할 금액
 */
export const getDistributeValueByLength = (totalAmount: number, length: number) => {
	const defaultUnitValue = InputUnit[DEFAULT_INPUT_UNIT].value;
	return Math.floor(totalAmount / defaultUnitValue / length) * defaultUnitValue;
};

/**
 *
 * [총 투자금 적용하기]
 * TODO: 남은 금액은 어떻게 분배할지
 *
 * @param productInvestableAmount 상품별 투자가능 금액 = min(origin 상품별 한도, 잔여 모집금액)
 * @param investedAmount 현재까지 투자된 금액
 * @param minInvestmentAmount 최소 투자 한도
 * @returns (상품에 맞춰) 분산된 금액
 */
export const getDistributeValueByTotalAmount = (
	oneNth: number,
	productInvestableAmount: number,
	investedAmount: number,
	minInvestmentAmount: number
) => {
	if (Math.min(investedAmount + oneNth, productInvestableAmount) < minInvestmentAmount) {
		return [0, 0];
	}

	if (investedAmount + oneNth > productInvestableAmount) {
		return [productInvestableAmount, productInvestableAmount - investedAmount];
	} else {
		return [investedAmount + oneNth, oneNth];
	}
};

/**
 *
 * [같은 비율 적용하기]
 * @param rate 모집금액에 대해 투자할 금액 비율
 * @param targetAmount 모집 금액
 * @param productInvestableAmount 상품별 투자가능 금액
 * @returns (상품에 맞춰) 분산된 금액
 */
export const getDistributeValueByRate = (rate: number, targetAmount: number, productInvestableAmount: number) => {
	const caculatedAmountByRate = floorByDefaultUnit(Math.floor(targetAmount * rate));

	return Math.min(caculatedAmountByRate, productInvestableAmount);
};

/**
 *
 * @param value 53만원 (defaultUnitValue = 10만원)
 * @returns 50만원
 */
export const floorByDefaultUnit = (value: number) => {
	// example. 10만원
	const defaultUnitValue = InputUnit[DEFAULT_INPUT_UNIT].value;
	return Math.floor(value / defaultUnitValue) * defaultUnitValue;
};
