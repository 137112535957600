import { AjaxError } from 'rxjs/ajax';

export class InAPICommonError extends Error {
	readonly statusCode?: number;
	readonly code: string;
	readonly detail: string;
	readonly data: any; // type 제대로 정할 필요 있음. ex. nextPath, confirmText

	constructor(message: string | Error, code?: string, data?: unknown) {
		super();

		if (typeof message === 'string') {
			this.message = message;
			this.code = code ?? 'PHOENIX001';
		} else if (message instanceof Error) {
			// js 생태계에서 발생할 수 있는 오류인지?
			this.message = message.message;
			this.code = 'PHOENIX002';

			// 그 중에서도, Ajax 통신 오류인지?
			if (message instanceof AjaxError || message instanceof ErrorWithJson) {
				this.code = 'PHOENIX003';
				this.statusCode = message.status;

				// 그 중에서도, 응답이 있어서 파싱 가능한 형태인지?
				if (message.response) {
					const { code, detail, data, message: msg } = message.response;
					if (code) {
						this.code = code;
					}
					this.message = detail || msg;
					if (data) {
						this.data = data;
					}
				}
			}
		} else {
			this.message = '알 수 없는 오류가 발생했습니다.';
			this.code = 'PHOENIX004';
		}

		this.detail = this.message;
		this.data = this.data ?? data;
	}
}

export class AlertCommonError extends InAPICommonError {
	readonly title: string;

	constructor(message: string | Error, code?: string, title = '앗, 문제가 발생했습니다.', data?: unknown) {
		super(message, code, data);
		this.title = title;
	}
}

export const convertInAPICommonErrorToAlertCommonError = (error: InAPICommonError): AlertCommonError =>
	new AlertCommonError(error.detail, error.code);

interface ErrorWithJson extends Error {
	status: number;
	response: any;
}
interface ErrorWithJsonCtor {
	new (message: string, title: string, code: string): ErrorWithJson;
}

export const ErrorWithJson: ErrorWithJsonCtor = createErrorClass(
	() =>
		function ErrorWithJsonImpl(this: any, message: string, title: string, code: string) {
			this.message = message;
			this.name = 'ErrorWithJson';
			this.status = 0;

			this.response = {
				code: code,
				message: title,
				detail: message,
			};
		}
);

function createErrorClass<T>(createImpl: (_super: any) => any): T {
	const _super = (instance: any) => {
		Error.call(instance);
		instance.stack = new Error().stack;
	};

	const ctorFunc = createImpl(_super);
	ctorFunc.prototype = Object.create(Error.prototype);
	ctorFunc.prototype.constructor = ctorFunc;
	return ctorFunc;
}
