import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import { actions } from '@peoplefund/actions';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { CompanyInfoState, CurrentLoanItem } from '@peoplefund/actions/ls-apply-info';

export interface LSApplyInfoState {
	readonly error?: AlertCommonError;

	/** 대환 **/
	readonly existingLoan?: CurrentLoanItem[];
	readonly loansForRefinance?: CurrentLoanItem['id'][];

	/** 입력 정보 */
	readonly houseOwnership: string;
	readonly loanPurpose: string;
	readonly medicalInsurance: string;
	readonly carNumber: string;
	readonly partnerCode: string;

	/** 가조회시 동의한 목록들 (회원 가입 이후 처리) */
	readonly agreementKey: string;
	readonly agreedItems: string[];
	readonly disagreedItems: string[];
	readonly agreed: boolean;

	/** 직장 정보 검색 */
	readonly companySearching: boolean;
	readonly companyList: CompanyInfoState[];

	/** nice_report 요청 중 여부 */
	readonly calculating: boolean;

	/** 가심사를 위한 신청서 작성 가능 여부 */
	readonly applyAvailable: boolean;

	/** 기존 가심사 정보 활용 여부 */
	readonly reuseMaterial: boolean;
}

export const lsApplyInfoInitialState: LSApplyInfoState = {
	houseOwnership: '',
	loanPurpose: '',
	medicalInsurance: '',
	carNumber: '',
	partnerCode: '',
	agreementKey: '',
	agreedItems: [],
	disagreedItems: [],
	agreed: false,
	calculating: false,
	companySearching: false,
	companyList: [],
	applyAvailable: false,
	reuseMaterial: false,
};

export default {
	lsApplyInfo: reducerWithInitialState<LSApplyInfoState>(lsApplyInfoInitialState)
		.cases([actions.common.init, actions.lsApplyInfo.init], (state) =>
			Immutable(state, { $set: lsApplyInfoInitialState })
		)
		.case(actions.lsApplyInfo.resetError, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsApplyInfo.agree, (state, param) =>
			Immutable(state, {
				agreedItems: { $set: param.customAgreeList },
				disagreedItems: { $set: param.customDisagreeList },
				agreed: { $set: param.customAgreeList.length > 0 },
			})
		)
		.case(actions.lsCommon.fetchAgreements.started, (state, param) =>
			Immutable(state, {
				agreementKey: { $set: param },
			})
		)
		.case(actions.lsApplyInfo.updateExtraInfo, (state, payload) =>
			Immutable(state, {
				houseOwnership: { $set: payload.houseOwnership },
				loanPurpose: { $set: payload.loanPurpose },
				medicalInsurance: { $set: payload.medicalInsurance },
				partnerCode: { $set: payload.partnerCode },
				carNumber: { $set: payload.carNumber },
				reuseMaterial: { $set: payload.reuseMaterial },
			})
		)
		.case(actions.lsLoan.createLoan.started, (state) =>
			Immutable(state, {
				calculating: { $set: true },
			})
		)
		.case(actions.lsLoan.createLoan.done, (state) =>
			Immutable(state, {
				calculating: { $set: false },
			})
		)
		.case(actions.lsLoan.createLoan.failed, (state) =>
			Immutable(state, {
				calculating: { $set: false },
			})
		)
		.case(actions.lsApplyInfo.fetchExistingLoan.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsApplyInfo.fetchExistingLoan.done, (state, { result }) =>
			Immutable(state, {
				existingLoan: { $set: result.list },
			})
		)
		.case(actions.lsApplyInfo.fetchExistingLoan.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.cases([actions.lsApplyInfo.createExistingLoan.started, actions.lsApplyInfo.deleteExistingLoan.started], (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.cases(
			[actions.lsApplyInfo.createExistingLoan.failed, actions.lsApplyInfo.deleteExistingLoan.failed],
			(state, { error }) =>
				Immutable(state, {
					error: { $set: error },
				})
		)
		.case(actions.lsApplyInfo.updateRefinanceLoans, (state, { ids }) =>
			Immutable(state, {
				loansForRefinance: {
					$set: ids,
				},
			})
		)
		.case(actions.lsApplyInfo.getJobInfoFromCretop.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
				companySearching: { $set: true },
			})
		)
		.case(actions.lsApplyInfo.getJobInfoFromCretop.done, (state, { result }) =>
			Immutable(state, {
				companySearching: { $set: false },
				companyList: { $set: result.result },
			})
		)
		.case(actions.lsApplyInfo.getJobInfoFromCretop.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
				companySearching: { $set: false },
			})
		)
		.case(actions.lsApplyInfo.resetJobInfoFromCretop, (state) =>
			Immutable(state, {
				companyList: { $set: [] },
			})
		)
		.case(actions.lsApplyInfo.checkApplyAvailable.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
				applyAvailable: { $set: false },
			})
		)
		.case(actions.lsApplyInfo.checkApplyAvailable.done, (state) =>
			Immutable(state, {
				applyAvailable: { $set: true },
			})
		)
		.case(actions.lsApplyInfo.checkApplyAvailable.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.lsLoan.cancel.done, (state) =>
			Immutable(state, {
				applyAvailable: { $set: true },
				agreed: { $set: true },
			})
		)
		.case(actions.lsLoan.cancelDifferentFlow.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsLoan.cancelDifferentFlow.done, (state) =>
			Immutable(state, {
				applyAvailable: { $set: true },
			})
		)
		.case(actions.lsLoan.cancelDifferentFlow.failed, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsApplyInfo.loadReuseMaterial.started, (state) =>
			Immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.lsApplyInfo.loadReuseMaterial.failed, (state, { error }) =>
			Immutable(state, {
				error: { $set: error },
			})
		),
};
