import { actions } from '@peoplefund/actions';
import { catchError, delay, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, Observable, of, zip } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { convertFailedFPAResponse, convertFPAResponse, convertLoanResult } from '@peoplefund/epics/ls-loan.util';
import ObjectUtil from '@peoplefund/utils/object.util';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';
import { LogRocketEventType } from '@peoplefund/constants/log-rocket';
import { formatDate, parseDate } from '@peoplefund/utils/date.util';
import { getLoanResult } from '@peoplefund/epics/ls-common.util';
import { convertFinancialProductResponse } from '@peoplefund/epics/ls-financial-product.util';
import { FlowType, INCOME_TYPE_CODE } from '@peoplefund/constants/ls-loan-types';
import { Action } from 'redux';
import UtmFactory from '@peoplefund/utils/marketing-script/UtmModule';
import * as lsApplyJobInfoSlice from '@peoplefund/slices/lsApplyJobInfoSlice';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import PageUrls from '@peoplefund/constants/page-urls';
import { FailedFinancialProductApplication } from '@peoplefund/actions/ls-loan';

const createLoan: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.createLoan.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					lsCommon: { flowType, platformCode },
					account: {
						auth: { token = '' },
					},
					lsApplyInfo: {
						loanPurpose,
						medicalInsurance,
						houseOwnership,
						loansForRefinance,
						carNumber,
						partnerCode,
						reuseMaterial,
					},
					lsApplyJobInfo: { incomeType, employmentType, dateJoined, companyName, annualIncome, cretopInfo, sectorType },
				},
			]) => {
				const utmModule = UtmFactory.instance;
				const utmload = utmModule?.getUtm() || {};
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post('/v1/personal-loan/pre-screening', {
							body: ObjectUtil.filterUndefined({
								is_pf_only: flowType === FlowType.PFLOAN,
								date_joined: dateJoined ? formatDate(parseDate(dateJoined, 'YYYY.MM'), 'YYYY-MM') : undefined,
								company_name: companyName?.trim() || null,
								medical_insurance_type: medicalInsurance,
								income_type: incomeType,
								employment_type: employmentType,
								job:
									incomeType === INCOME_TYPE_CODE.ETC
										? undefined
										: {
												company_name: cretopInfo?.companyName || companyName || null,
												company_trade_name: cretopInfo?.companyTradeName || '',
												business_no: cretopInfo?.businessNo || '',
												company_contact_number: cretopInfo?.companyContactNumber || '',
												company_address: cretopInfo?.companyAddress || '',
												company_detail_address: cretopInfo?.companyDetailAddress || '',
												company_post_code: cretopInfo?.companyPostCode || '',
												company_jibun_address: cretopInfo?.companyJibunAddress || '',
												company_jibun_detail_address: cretopInfo?.companyJibunDetailAddress || '',
												company_jibun_post_code: cretopInfo?.companyJibunPostCode || '',
												laborer_sum: cretopInfo?.laborerSum || 0,
												kedcd: cretopInfo?.kedcd || '',
										  },
								existing_loan_ids: loansForRefinance ?? [],
								loan_purpose: loanPurpose,
								house_ownership_type: houseOwnership,
								annual_income: Number(annualIncome),
								partner_code: partnerCode,
								sector_type: sectorType,
								car_number: carNumber || undefined,
								reuse_material: reuseMaterial,
								platform_code: platformCode || undefined,
								...utmload,
							}),
							token,
						})
						.pipe(
							mergeMap((response) => [
								actions.lsLoan.createLoan.done({
									params: payload,
									result: convertLoanResult(response, false),
								}),
								actions.common.logRocketTrack({
									type: LogRocketEventType.CREATE_LOAN_APPLICATION,
									properties: {
										loanApplicationId: response.id,
									},
								}),
							]),
							catchError((error) => {
								return of(
									actions.lsLoan.createLoan.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								);
							})
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const checkPreScreeningInProgress: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.checkPreScreeningInProgress.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
					lsCommon: {
						flowType,
						mainPath,
						statistic: { totalLoanAmount },
					},
					lsLoan: {
						loan: { id, status },
					},
				},
			]) => {
				const generateMoveError = (routePath: string) => new AlertCommonError('', PhoenixErrorCode.MOVE, '', routePath);
				const generateMoveToMainError = () =>
					generateMoveError(mainPath || FlowType.PFLOAN ? PageUrls.personalLoans.INTRO : PageUrls.loansCompare.INTRO);

				const getLoanStatus = (): Observable<{ status: LSLoanStatus; id: string }> => {
					if (!token) {
						throw generateMoveToMainError();
					}

					if (id) {
						return of({ id, status });
					} else {
						return cometAjax.loan.get('/v1/personal-loan/loan-application/in-progress', { token }).pipe(
							map((response) => {
								const { loan_application } = response;

								if (loan_application) {
									const { id, status } = convertLoanResult(loan_application, false);
									return {
										id,
										status,
									};
								} else {
									throw generateMoveToMainError();
								}
							})
						);
					}
				};

				return getLoanStatus().pipe(
					mergeMap(({ id, status }) => {
						switch (status) {
							case LSLoanStatus.DONE_PRE_EVALUATION:
							case LSLoanStatus.IN_PROGRESS_EVALUATION: {
								throw generateMoveError(PageUrls.loansCompare.RESULT);
							}
							case LSLoanStatus.DONE:
							case LSLoanStatus.CANCELED:
							case LSLoanStatus.UNKNOWN: {
								throw generateMoveToMainError();
							}
							case LSLoanStatus.REJECTED: {
								throw generateMoveError(
									flowType === FlowType.PFLOAN ? PageUrls.personalLoans.REJECT : PageUrls.loansCompare.REJECT
								);
							}
						}

						const result: Action[] = [
							actions.lsLoan.checkPreScreeningInProgress.done({
								params: payload,
								result: {},
							}),
							actions.lsLoan.getLoanStatus.started({
								id,
							}),
						];

						if (flowType === FlowType.PFLOAN && totalLoanAmount < 0) {
							result.push(actions.lsCommon.intro.started({}));
						}

						return result;
					}),
					catchError((error) =>
						of(
							actions.lsLoan.checkPreScreeningInProgress.failed({
								params: payload,
								error,
							})
						)
					)
				);
			}
		)
	);

const getLoanStatus: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.getLoanStatus.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				cometAjax.loan.get(`/v2/loan-applications/${payload.id}/pre-screening-status`, { token }).pipe(
					delay(1000),
					mergeMap((response) => {
						let status: LSLoanStatus = LSLoanStatus.IN_PROGRESS_PRE_EVALUATION;

						const result: Action[] = [];

						switch (`${response.status}`.toLocaleLowerCase()) {
							case 'created':
							case 'started': {
								result.push(actions.lsLoan.getLoanStatus.started({ id: payload.id }));
								break;
							}
							case 'finished': {
								status = LSLoanStatus.DONE_PRE_EVALUATION;
								result.push(actions.lsApplyInfo.init(), lsApplyJobInfoSlice.initialize());
								break;
							}
							case 'error': {
								throw new AlertCommonError(
									'잠시후 다시 진행해주세요',
									PhoenixErrorCode.UNKNOWN,
									'일시적인 오류가 발생했어요'
								);
							}
							case 'credit_check_blocked': {
								throw new AlertCommonError(
									`신용조회차단 여부를 확인 후\n다시 진행해 주세요`,
									PhoenixErrorCode.CREDIT_CHECK_BLOCKED,
									'대출비교를 진행할 수 없어요'
								);
							}
						}

						result.push(
							actions.lsLoan.getLoanStatus.done({
								params: payload,
								result: {
									status,
									remainSecond: Number(response.remaining_time),
								},
							})
						);
						return result;
					}),
					catchError((error) => {
						const err: AlertCommonError =
							error instanceof AlertCommonError
								? error
								: new AlertCommonError('입력한 정보로 이어서 조회할께요', error.code, '일시적인 오류가 발생했어요');

						return of(
							actions.lsLoan.getLoanStatus.failed({
								params: payload,
								error: err,
							})
						);
					})
				)
		)
	);

const cancelLoan: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.cancel.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/loan-application/cancel`, {
							token,
							body: {
								loan_application_id: payload.id,
							},
						})
						.pipe(
							mergeMap(() =>
								cometAjax.user.get('/information/loan', { token }).pipe(
									mergeMap(() => {
										return of(
											actions.lsLoan.cancel.done({
												params: payload,
												result: {},
											})
										);
									})
								)
							),
							catchError((error) => {
								return of(
									actions.lsLoan.cancel.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								);
							})
						),
					of(actions.layout.endLoading())
				)
		)
	);

const cancelDifferentFlow: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.cancelDifferentFlow.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/loan-application/cancel`, {
							token,
							body: {
								loan_application_id: payload.id,
							},
						})
						.pipe(
							map(() =>
								actions.lsLoan.cancelDifferentFlow.done({
									params: payload,
									result: {},
								})
							),
							catchError((error) => {
								return of(
									actions.lsLoan.cancelDifferentFlow.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								);
							})
						),
					of(actions.layout.endLoading())
				)
		)
	);

const rejectAffiliate: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.fetchRejectAffiliate.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					cometAjax.loan
						.get(`/v1/loan_applications/${payload.id}/reject`, {
							token,
						})
						.pipe(
							map((response) =>
								actions.lsLoan.fetchRejectAffiliate.done({
									params: payload,
									result: {
										name: response.name ?? '',
										mobileUrl: response.mobile_url ?? '',
										pcUrl: response.pc_url ?? '',
									},
								})
							),
							catchError((error) => {
								return of(
									actions.lsLoan.fetchRejectAffiliate.failed({
										params: payload,
										error: convertInAPICommonErrorToAlertCommonError(error),
									})
								);
							})
						)
				)
		)
	);

const loadLoan: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.lsLoan.loadLoan.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return getLoanResult(cometAjax, token, 'true').pipe(
					mergeMap((response) => {
						const { fpa } = response;
						const fpaKeys = Object.keys(fpa);

						if (fpaKeys.length !== 1) {
							throw new AlertCommonError(`대출 상품 정보를 가져오는데 에러가 발생했습니다(${fpaKeys.length})`);
						}

						const fpaId = fpaKeys[0];
						return cometAjax.loan
							.get(`/v1/loan-product-applications/${fpaId}`, {
								token,
							})
							.pipe(
								map((fpaDetail) => {
									fpa[fpaId] = convertFPAResponse(fpaDetail);
									return actions.lsLoan.loadLoan.done({
										params: payload,
										result: response,
									});
								})
							);
					}),
					catchError((error) =>
						of(
							actions.lsLoan.loadLoan.failed({
								params: payload,
								error: error instanceof AlertCommonError ? error : convertInAPICommonErrorToAlertCommonError(error),
							})
						)
					)
				);
			}
		)
	);
};

const loadCompareResult: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.lsLoan.loadCompareResult.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return of(
						actions.lsLoan.loadCompareResult.failed({
							params: payload,
							error: new AlertCommonError('로그인을 해주세요.', PhoenixErrorCode.LOGIN_REQUIRED),
						})
					);
				} else {
					return getLoanResult(cometAjax, token, 'false').pipe(
						mergeMap((loanResult) => {
							return cometAjax.loan
								.get(`/v1/personal-loan/loan-applications/${loanResult.id}/pre-screening-failures`, {
									token,
								})
								.pipe(
									mergeMap((failedResponse) => {
										const resultAction: Action[] = [];

										const failedFPA: FailedFinancialProductApplication[] =
											failedResponse.pre_screening_failures?.map(convertFailedFPAResponse) ?? [];

										resultAction.push(
											actions.lsLoan.loadCompareResult.done({
												params: payload,
												result: {
													...loanResult,
													failedFPA,
												},
											})
										);

										return resultAction;
									})
								);
						}),
						catchError((error) =>
							of(
								actions.lsLoan.loadCompareResult.failed({
									params: payload,
									error: error instanceof AlertCommonError ? error : convertInAPICommonErrorToAlertCommonError(error),
								})
							)
						)
					);
				}
			}
		)
	);
};

const loadLoanWithFPA: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(actions.lsLoan.loadLoanWithFPA.started),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return of(
						actions.lsLoan.loadLoanWithFPA.failed({
							params: payload,
							error: new AlertCommonError('로그인을 해주세요.', PhoenixErrorCode.LOGIN_REQUIRED),
						})
					);
				} else {
					return concat(
						of(actions.layout.startLoading()),
						getLoanResult(cometAjax, token, 'false').pipe(
							mergeMap((loanResult) => {
								const { fpaId } = payload;

								const resultAction: Action[] = [];

								return zip(
									cometAjax.loan.get(`/v1/personal-loan/loan-products/${loanResult.fpa[fpaId].financialProductId}`, {
										token,
									}),
									cometAjax.loan.get(`/v1/loan-product-applications/${fpaId}`, {
										token,
									})
								).pipe(
									mergeMap((response) => {
										const [productInfo, fpaDetail] = response;
										loanResult.fpa[fpaId] = convertFPAResponse(fpaDetail);

										return [
											...resultAction,
											actions.lsLoan.loadLoanWithFPA.done({
												params: payload,
												result: loanResult,
											}),
											actions.lsFinancialProduct.setFinancialProductDetail(
												convertFinancialProductResponse(productInfo)
											),
										];
									})
								);
							}),
							catchError((error) =>
								of(
									actions.lsLoan.loadLoanWithFPA.failed({
										params: payload,
										error: error instanceof AlertCommonError ? error : convertInAPICommonErrorToAlertCommonError(error),
									})
								)
							)
						),
						of(actions.layout.endLoading())
					);
				}
			}
		)
	);
};

const additionalDocsAuthenticate: CometEpic = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.additionalDocsAuthenticate.started),
		mergeMap(({ payload }) =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.inapi
					.post(`/loan/v1/pf-credit-loan/loan-applications/_/documents/authenticate/`, {
						body: {
							url_token: payload.urlToken,
							rrn_first: payload.rrnFirst,
						},
					})
					.pipe(
						map((response) => {
							const scope = response.scope;
							if (scope === 'ID_SUBMISSION') {
								return actions.lsLoan.additionalDocsAuthenticate.done({
									params: payload,
									result: {
										loanId: response.loan_application_id,
										accessToken: response.access_token,
										valid: true,
									},
								});
							} else {
								throw new InAPICommonError('제출할 서류가 존재하지 않습니다');
							}
						}),
						catchError((error) => {
							return of(
								actions.lsLoan.additionalDocsAuthenticate.failed({
									params: payload,
									error: convertInAPICommonErrorToAlertCommonError(error),
								})
							);
						})
					),
				of(actions.layout.endLoading())
			)
		)
	);

const additionalDocsSubmit: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.additionalDocsSubmit.started),
		withLatestFrom(state$),
		mergeMap(([{ payload }]) =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.inapi
					.patch(`/loan/v1/pf-credit-loan/loan-applications/${payload.loanId}/documents/auth-upload/`, {
						body: {
							file: payload.file,
							access_token: payload.accessToken,
							name: payload.name,
						},
					})
					.pipe(
						map(() => {
							return actions.lsLoan.additionalDocsSubmit.done({
								params: payload,
								result: {},
							});
						}),
						catchError((error) =>
							of(
								actions.lsLoan.additionalDocsSubmit.failed({
									params: payload,
									error: convertInAPICommonErrorToAlertCommonError(error),
								})
							)
						)
					),
				of(actions.layout.endLoading())
			)
		)
	);

const checkHasLoans: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsLoan.checkHasLoans),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.get(`/borrowers/v1/:user_id/status`, {
							token,
						})
						.pipe(
							mergeMap((response) => {
								const { is_borrower } = response;
								return of(actions.layout.showLoansMyPageMenu(is_borrower));
							}),
							catchError(() => of(actions.layout.showLoansMyPageMenu(false)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

export default [
	createLoan,
	checkPreScreeningInProgress,
	getLoanStatus,
	cancelLoan,
	cancelDifferentFlow,
	loadLoan,
	loadCompareResult,
	loadLoanWithFPA,
	rejectAffiliate,
	additionalDocsAuthenticate,
	additionalDocsSubmit,
	checkHasLoans,
];
