import { AmlInfo } from '@peoplefund/slices/aml/index.model';
import { AMLStatus } from '@peoplefund/reducers/account/index.model';

export const isInfoComplete = (info?: AmlInfo): boolean => {
	if (!info) {
		return false;
	}

	const {
		name,
		englishName,
		phoneNumber,
		residenceCountryCode,
		residenceAddress,
		residencePostCode,
		jobCode,
		riskyBusinessCode,
		fundsSourceCode,
		fundsSourceEtcDetail,
		transactionPurposeCode,
		transactionPurposeEtcDetail,
		isBeneficialOwner,
	} = info;

	const nameIsValid = name.length > 0;
	const englishNameIsValid = englishName.length > 0;
	const phoneNumberIsValid = phoneNumber.length > 0;
	const residenceCountryCodeIsValid = residenceCountryCode.length > 0;
	const addressIsValid = residenceAddress.length > 0;
	const postCodeIsValid = residencePostCode.length > 0;
	const jobCodeIsValid = jobCode.length > 0;
	const riskyBusinessCodeIsValid = riskyBusinessCode.length > 0;
	const fundsSourceIsValid = fundsSourceCode.length > 0 || fundsSourceEtcDetail.length > 0;
	const transactionPurposeIsValid = transactionPurposeCode.length > 0 || transactionPurposeEtcDetail.length > 0;
	const isBeneficialOwnerIsValid = isBeneficialOwner.length > 0;

	return (
		nameIsValid &&
		englishNameIsValid &&
		englishNameIsValid &&
		phoneNumberIsValid &&
		residenceCountryCodeIsValid &&
		addressIsValid &&
		postCodeIsValid &&
		jobCodeIsValid &&
		riskyBusinessCodeIsValid &&
		fundsSourceIsValid &&
		transactionPurposeIsValid &&
		isBeneficialOwnerIsValid
	);
};

export const isKYCUpdateRequired = (status: AMLStatus): boolean => {
	switch (status) {
		case AMLStatus.FAILED:
		case AMLStatus.INITIAL:
		case AMLStatus.SUCCESS:
			return false;
		case AMLStatus.KYC_EXPIRE_SOON:
		case AMLStatus.KYC_EXPIRED:
			return true;
	}
};
