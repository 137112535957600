import account, { accountInitialState, AccountState } from '@peoplefund/reducers/account';
import smsVerification, { smsVerificationInitialState, SmsVerificationState } from './sms-verification';
import lsCommon, { lsCommonInitialState, LSCommonState } from '@peoplefund/reducers/ls-common';
import lsAuthentication, {
	lsAuthenticationInitialState,
	LsAuthenticationState,
} from '@peoplefund/reducers/ls-authentication';
import lsApplyInfo, { lsApplyInfoInitialState, LSApplyInfoState } from '@peoplefund/reducers/ls-apply-info';
import lsLoan, { lsLoanInitialState, LSLoanState } from '@peoplefund/reducers/ls-loan';
import lsFinancialProduct, {
	LSFinancialProduct,
	lsFinancialProductInitialState,
} from '@peoplefund/reducers/ls-financial-product';
import layout, { layoutInitialState, LayoutState } from '@peoplefund/reducers/layout';
import unsecuredInvestorRegistration, {
	unsecuredInvestorRegistrationInitalState,
	UnsecuredInvestorRegistrationState,
} from '@peoplefund/reducers/unsecured-investor-registration';
import plInvesting, { PLInvesting, plInvestingInitialState } from '@peoplefund/slices/pl-investing';
import lsApplyJobInfo, {
	lsApplyJobInfoInitialState,
	LSApplyJobInfoState,
} from '@peoplefund/slices/lsApplyJobInfoSlice';
import mlInvesting, { MLInvesting, mlInvestingInitialState } from '@peoplefund/slices/ml-investing';
import peacock, { Peacock, peacockInitialState } from '@peoplefund/slices/peacock';
import mlLoan, { MLLoan, mlLoanInitialState } from '@peoplefund/slices/ml-loan';
import agreementStore, { AgreementStore, AgreementStoreInitialState } from '@peoplefund/slices/agreementSlice';
import authentication, { Authentication, authenticationInitialState } from '@peoplefund/slices/authentication';
import commonInvesting, { CommonInvesting, commonInvestingInitialState } from '@peoplefund/slices/common-investing';
import smsVerify, { SmsVerify, smsVerifyInitialState } from '@peoplefund/slices/sms-verify';
import loansAutopay, { loanAutopaySliceInitialState, LoansAutopay } from '@peoplefund/slices/loans-autopay';
import vote, { Vote, voteInitialState } from '@peoplefund/slices/vote';
import twoPlusOneVerify, {
	TwoPlusOneVerify,
	twoPlusOneVerifyInitialState,
} from '@peoplefund/slices/two-plus-one-verify';
import aml, { Aml, amlInitialState } from '@peoplefund/slices/aml';
import commonLoan, { CommonLoan, commonLoanInitialState } from '@peoplefund/slices/common-loan';
import luxuryAssetLoansChic, {
	LuxuryAssetLoansChic,
	luxuryAssetLoansChicInitialState,
} from '@peoplefund/slices/luxury-asset-loans/chic';
import mortgageLoanDocumentScanningState, {
	mortgageLoanDocumentScanningInitState,
	MortgageLoanDocumentScanningState,
} from '@peoplefund/slices/mortgage-loans/document-scanning-request';
import mortgageLoanContractState, {
	ContractState,
	initialContractState,
} from '@peoplefund/slices/mortgage-loans/contract';
import stockLoans, { StockLoansState, stockLoansInitialState } from '@peoplefund/slices/stock-loans';
import common, { CommonState, commonInitialState } from '@peoplefund/slices/common';
import communityPreviews, {
	CommunityPreviewState,
	CommunityPreviewInitialState,
} from '@peoplefund/slices/community/preview';

// README: type 에 정의하는 변수와 export 에 지정하는 변수를 동일하게 맞추어 주세요.

export type State = {
	common: CommonState;
	account: AccountState;
	smsVerification: SmsVerificationState;
	lsCommon: LSCommonState;
	lsAuthentication: LsAuthenticationState;
	lsApplyInfo: LSApplyInfoState;
	lsLoan: LSLoanState;
	lsFinancialProduct: LSFinancialProduct;
	layout: LayoutState;
	lsApplyJobInfo: LSApplyJobInfoState;
	unsecuredInvestorRegistration: UnsecuredInvestorRegistrationState;
	plInvesting: PLInvesting;
	mlInvesting: MLInvesting;
	mlLoan: MLLoan;
	agreementStore: AgreementStore;
	authentication: Authentication;
	commonInvesting: CommonInvesting;
	peacock: Peacock;
	smsVerify: SmsVerify;
	loansAutopay: LoansAutopay;
	vote: Vote;
	twoPlusOneVerify: TwoPlusOneVerify;
	aml: Aml;
	commonLoan: CommonLoan;
	luxuryAssetLoansChic: LuxuryAssetLoansChic;
	mortgageLoanDocumentScanningState: MortgageLoanDocumentScanningState;
	mortgageLoanContractState: ContractState;
	stockLoans: StockLoansState;
	communityPreviews: CommunityPreviewState;
};

export type WithNetworkStatus<T> = {
	[K in keyof T]: T[K];
} & { networkStatus: string };

export const initialStates = {
	common: commonInitialState,
	account: accountInitialState,
	smsVerification: smsVerificationInitialState,
	lsCommon: lsCommonInitialState,
	lsAuthentication: lsAuthenticationInitialState,
	lsApplyInfo: lsApplyInfoInitialState,
	lsLoan: lsLoanInitialState,
	lsFinancialProduct: lsFinancialProductInitialState,
	layout: layoutInitialState,
	lsApplyJobInfo: lsApplyJobInfoInitialState,
	unsecuredInvestorRegistration: unsecuredInvestorRegistrationInitalState,
	plInvesting: plInvestingInitialState,
	mlInvesting: mlInvestingInitialState,
	mlLoan: mlLoanInitialState,
	agreementStore: AgreementStoreInitialState,
	authentication: authenticationInitialState,
	commonInvesting: commonInvestingInitialState,
	peacock: peacockInitialState,
	smsVerify: smsVerifyInitialState,
	loansAutopay: loanAutopaySliceInitialState,
	vote: voteInitialState,
	twoPlusOneVerify: twoPlusOneVerifyInitialState,
	aml: amlInitialState,
	commonLoan: commonLoanInitialState,
	luxuryAssetLoansChic: luxuryAssetLoansChicInitialState,
	mortgageLoanDocumentScanningState: mortgageLoanDocumentScanningInitState,
	mortgageLoanContractState: initialContractState,
	stockLoans: stockLoansInitialState,
	communityPreviews: CommunityPreviewInitialState,
};

export default {
	common,
	...account,
	...smsVerification,
	...lsCommon,
	...lsAuthentication,
	...lsApplyInfo,
	...lsLoan,
	...lsFinancialProduct,
	...layout,
	lsApplyJobInfo,
	...unsecuredInvestorRegistration,
	plInvesting: plInvesting,
	mlInvesting: mlInvesting,
	mlLoan: mlLoan,
	agreementStore,
	authentication,
	commonInvesting,
	peacock,
	smsVerify,
	loansAutopay,
	vote,
	twoPlusOneVerify,
	aml,
	commonLoan,
	luxuryAssetLoansChic,
	mortgageLoanDocumentScanningState,
	mortgageLoanContractState,
	stockLoans,
	communityPreviews,
};
