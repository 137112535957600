import { ActionTypes } from '@peoplefund/constants/action-types';
import { MobileServiceProviderCode } from '@peoplefund/constants/types';
import actionCreatorFactory from 'typescript-fsa';
import { InAPICommonError } from '@peoplefund/constants/error/type';

export interface SendParam {
	/** 이름 */
	readonly name: string;
	/** 생년월일+성별  */
	readonly rrn7Digits: string;
	/** 주민번호 13자리 */
	readonly rrnFull?: string;
	/** 이동통신사 코드 */
	readonly mobileServiceProviderCode: MobileServiceProviderCode;
	/** 휴대폰번호 */
	readonly mobilePhoneNumber: string;
}

interface SendSmsRrn7Param extends SendParam {
	readonly genderCodeFromRrn7Digits: string;
}

export interface SendResult {
	/** 거래고유번호 */
	readonly svcTxSeqno: string;
}

export interface VerifyParam {
	readonly smsVerificationCode: string;
}

const actionCreator = actionCreatorFactory('SMS_VERIFICATION');

export default {
	init: actionCreator(ActionTypes.VERIFICATION_INIT),
	smsSendRrn7: actionCreator.async<SendSmsRrn7Param, SendResult, InAPICommonError>(ActionTypes.VERIFICATION_SEND_RRN7),
	resetVerify: actionCreator(ActionTypes.VERIFICATION_RESET_VERIFY),
};
