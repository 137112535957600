import React from 'react';
import { Modal, ModalProps, SingleButtonProps } from '@pfct/purple';
export type BasicAlertType = ModalProps & {
  title: string;
  colorType: SingleButtonProps['colorType'];
  content?: string;
  confirmText?: string;
  onConfirm?: () => void;
};
const BasicAlert: React.FC<BasicAlertType> = props => {
  const {
    visible,
    title,
    content,
    children,
    confirmText = '확인',
    colorType,
    onConfirm,
    onMaskClick = onConfirm
  } = props;
  return <Modal visible={visible} onMaskClick={onMaskClick} data-sentry-element="Modal" data-sentry-component="BasicAlert" data-sentry-source-file="index.tsx">
			<Modal.Title title={title} subTitle={content} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
			<Modal.Body hasContentPadding={props.hasContentPadding} data-sentry-element="unknown" data-sentry-source-file="index.tsx">{children}</Modal.Body>
			<Modal.SingleButton onClick={onConfirm} purpose="main" colorType={colorType} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				{confirmText}
			</Modal.SingleButton>
		</Modal>;
};
export default BasicAlert;