import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AGREEMENT_SCENE_PRE_EVALUATION_PF_ONLY,
	AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT,
	AGREEMENT_SCENE_MORTGAGE_LOAN,
	AGREEMENT_SCENE_MORTGAGE_LOANSHOT,
} from '@peoplefund/constants/agreement';
import { IAgreement } from '@peoplefund/constants/agreement/type';
import { AlertCommonError } from '@peoplefund/constants/error/type';

const SLICE_NAME = 'agreementStoreSlice';

type SCENE_KEY =
	| typeof AGREEMENT_SCENE_PRE_EVALUATION_PF_ONLY
	| typeof AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT
	| typeof AGREEMENT_SCENE_MORTGAGE_LOAN
	| typeof AGREEMENT_SCENE_MORTGAGE_LOANSHOT;

// FYI. static data 만 불러오고, agreedItem 은 각 서비스 store 에 저장합니다.
export interface AgreementStore {
	error?: AlertCommonError;
	data: Record<SCENE_KEY, IAgreement[]>;
}

export const AgreementStoreInitialState: AgreementStore = {
	data: {
		[AGREEMENT_SCENE_PRE_EVALUATION_PF_ONLY]: [],
		[AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT]: [],
		[AGREEMENT_SCENE_MORTGAGE_LOAN]: [],
		[AGREEMENT_SCENE_MORTGAGE_LOANSHOT]: [],
	},
};

export const getAgreementsFromS3 = createAction<SCENE_KEY>(`${SLICE_NAME}/getAgreementsFromS3`);

const agreementStoreSlice = createSlice({
	name: SLICE_NAME,
	initialState: AgreementStoreInitialState,
	reducers: {
		// reset 겸용
		initialize: (state) => {
			state.data = AgreementStoreInitialState.data;
			state.error = undefined;
		},
		setAgreements: (
			state,
			action: PayloadAction<{
				sceneKey: SCENE_KEY;
				agreements: IAgreement[];
			}>
		) => {
			state.data[action.payload.sceneKey] = action.payload.agreements;
		},
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAgreementsFromS3, (state, action) => {
			// initialize
			state.data[action.payload] = [];
			state.error = undefined;
		});
	},
});

export const { initialize, setAgreements, setError } = agreementStoreSlice.actions;

export default agreementStoreSlice.reducer;
