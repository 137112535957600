import { LSFinancialProductApplicationStatus, LSLoanStatus } from '@peoplefund/constants/ls-loan-status';

type Status = LSLoanStatus | LSFinancialProductApplicationStatus;

const RejectedStatus: Status[] = [
	LSFinancialProductApplicationStatus.REJECTED_EVALUATION,
	LSFinancialProductApplicationStatus.REJECTED_PRE_EVALUATION,
];

const StatusManager = (() => {
	const rejected = (status: Status) => RejectedStatus.includes(status);
	return {
		rejected,
	};
})();

export default StatusManager;
