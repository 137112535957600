import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';
import {
	OCRResult,
	RequestBankAccountVerifyParam,
	RequestBankAccountVerifyParamV2,
	RequestIdCheckParam,
	RequestOCRResultParam,
	StartBankAccountVerifyParam,
	StartBankAccountVerifyParamV2,
} from '@peoplefund/slices/two-plus-one-verify/index.model';
import { VerifyType } from '@peoplefund/components/common/verifyTwoPlusOne/id-card/@common/index.model';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';

export type TwoPlusOneVerifyStatus =
	| 'initial'
	| 'ocr-requested'
	| 'ocr-completed'
	| 'id-card-verify-completed'
	| 'bank-account-requested'
	| 'bank-account-verify-completed';

export interface TwoPlusOneVerify {
	readonly state: TwoPlusOneVerifyStatus;
	readonly error?: AlertCommonError;
	readonly ocrInfo?: OCRResult;
	readonly verificationKey: string;
	readonly verificationType?: VerifyType;
	readonly bankVerifyInfo: {
		readonly verificationId: string;
	};
	readonly idCardInfo: {
		readonly verificationId: string;
	};
}

export const twoPlusOneVerifyInitialState: TwoPlusOneVerify = {
	state: 'initial',
	verificationKey: '',
	bankVerifyInfo: {
		verificationId: '',
	},
	idCardInfo: {
		verificationId: '',
	},
	error: undefined,
};

const twoPlusOneVerifySlice = createSlice({
	name: 'twoPlusOneVerify',
	initialState: twoPlusOneVerifyInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, twoPlusOneVerifyInitialState),
		setError: (state, action: PayloadAction<AlertCommonError | InAPICommonError>) => {
			const error = action.payload;
			if (error instanceof InAPICommonError) {
				state.error = convertInAPICommonErrorToAlertCommonError(error);
			} else {
				state.error = error;
			}
		},
		resetError: (state) => {
			state.error = undefined;
		},
		initializeIdCardVerify: (state) => {
			state.error = undefined;
			state.verificationType = undefined;
			state.ocrInfo = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestOCR: (state, action: PayloadAction<RequestOCRResultParam>) => {
			state.error = undefined;
			state.state = 'ocr-requested';
			state.verificationType = action.payload.verifyType;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestOCRV2: (state, action: PayloadAction<RequestOCRResultParam>) => {
			state.error = undefined;
			state.state = 'ocr-requested';
			state.verificationType = action.payload.verifyType;
		},
		setOCRResult: (state, action: PayloadAction<OCRResult | undefined>) => {
			if (action.payload) {
				state.state = 'ocr-completed';
				state.error = undefined;
				state.ocrInfo = action.payload;
			} else {
				state.state = 'initial';
				state.error = new AlertCommonError(
					'신분증 인증을 다시 진행해주세요',
					PhoenixErrorCode.VERIFY_ID_CARD_OCR_FAILED,
					'신분증 인증에 실패했습니다'
				);
				state.verificationType = undefined;
			}
		},
		setVerificationKey: (state, action: PayloadAction<string>) => {
			state.verificationKey = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestIdCheck: (state, _: PayloadAction<RequestIdCheckParam>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestIdCheckV2: (state, _: PayloadAction<RequestIdCheckParam>) => {
			state.error = undefined;
		},
		requestIdCheckResult: (state, action: PayloadAction<{ result: boolean; verificationId?: string }>) => {
			state.error = undefined;

			if (action.payload.result) {
				state.state = 'id-card-verify-completed';
			}
			if (action.payload.verificationId) {
				state.idCardInfo.verificationId = action.payload.verificationId;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startBankAccountVerify: (state, _: PayloadAction<StartBankAccountVerifyParam>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startBankAccountVerifyV2: (state, _: PayloadAction<StartBankAccountVerifyParamV2>) => {
			state.error = undefined;
		},
		setBankAccountVerifyStarted: (state, action: PayloadAction<string | undefined>) => {
			state.state = 'bank-account-requested';

			if (action.payload) {
				state.bankVerifyInfo.verificationId = action.payload;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestBankAccountVerify: (state, _: PayloadAction<RequestBankAccountVerifyParam>) => {
			state.error = undefined;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		requestBankAccountVerifyV2: (state, _: PayloadAction<RequestBankAccountVerifyParamV2>) => {
			state.error = undefined;
		},
		setBankAccountVerifyCompleted: (state) => {
			state.state = 'bank-account-verify-completed';
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	requestOCR,
	requestOCRV2,
	setOCRResult,
	requestIdCheck,
	requestIdCheckV2,
	requestIdCheckResult,
	startBankAccountVerify,
	startBankAccountVerifyV2,
	setBankAccountVerifyStarted,
	setVerificationKey,
	requestBankAccountVerify,
	requestBankAccountVerifyV2,
	setBankAccountVerifyCompleted,
	initializeIdCardVerify,
} = twoPlusOneVerifySlice.actions;

export default twoPlusOneVerifySlice.reducer;
