enum SessionId {
	PHP_SESSION_ID = 'SESSID',
	PHOENIX_SESSION_ID = 'SESSIDPT',
	WEB_UUID = 'WEB_UUID',
}

export const PHP_SESSION_ID = SessionId.PHP_SESSION_ID;
export const PHOENIX_SESSION_ID = SessionId.PHOENIX_SESSION_ID;

export default SessionId;
