export const LOAN_PURPOSE_REFINANCE_VALUE = 'LPT002'; // 기존 대출 전환
export const LOAN_PURPOSE_HOUSE = 'LPT004'; // 주택 구입
export const LOAN_PURPOSE_DEPOSIT = 'LPT006'; // 전월세보증금
export const LOAN_PURPOSE_TENANT_LEAVE = 'LPT011'; // 전월세보증금반환
export const MARKETING_AGREEMENT_VALUE = 'AG0001';

export const AGREEMENT_SCENE_PRE_EVALUATION_PF_ONLY = 'web_unsecured';
export const AGREEMENT_SCENE_PRE_EVALUATION_LOANSHOT = 'web_loanshot';
export const AGREEMENT_SCENE_MOBILE_LOGIN = 'web_mobile_login';
export const AGREEMENT_SCENE_SIGNUP_FOR_INVEST = 'web_invest';
export const NOTIFY_NEW_PRODUCT_AGREEMENT_VALUE = 'cple_inv_new_product_alarm';

export const AGREEMENT_SCENE_CMS_LOAN = 'web_cms';
export const CORP_INVESTOR_REGISTER = 'corp_investor_register';
export const WEB_CORP_INVESTOR_APPLY = 'web_corpinvest_apply';

export const AGREEMENT_SCENE_WEB_AML = 'web_aml';

export const LUXURY_ASSET_LOAN_CHIC = 'web_chic_loan';
