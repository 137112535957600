import { reducerWithInitialState } from 'typescript-fsa-reducers';
import Immutable from 'immutability-helper';
import { actions } from '@peoplefund/actions';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { FinancialProductApplications, Loan } from '@peoplefund/constants/ls-loan-types';
import { setFPAError, setLoanError } from '@peoplefund/reducers/ls-loan.util';
import { FailedFinancialProductApplication } from '@peoplefund/actions/ls-loan';

export interface LSLoanState {
	readonly failedFinancialProductApplications: FailedFinancialProductApplication[];
	readonly financialProductApplications: FinancialProductApplications;
	readonly loan: Loan;

	/** 가심사 결과 페이지에서 api 체크 완료용으로 쓸 플래그 */
	readonly loanLoaded: boolean;
}

export const lsLoanInitialState: LSLoanState = {
	failedFinancialProductApplications: [],
	financialProductApplications: {},
	loan: {
		id: '',
		status: LSLoanStatus.UNKNOWN,
		expire: '',
		rejectAffiliate: undefined,
		rejectAffiliateChecked: false,
		pfOnly: true,
		hasApprovedFPA: false,
		preScreening: {
			inProgress: false,
			remainSecond: 0,
		},
	},
	loanLoaded: false,
};

export default {
	lsLoan: reducerWithInitialState<LSLoanState>(lsLoanInitialState)
		.cases([actions.common.init, actions.lsLoan.init], (state) => Immutable(state, { $set: lsLoanInitialState }))
		.case(actions.lsLoan.resetError, (state) => Immutable(state, setLoanError()))
		.case(actions.lsLoan.createLoan.started, (state) => Immutable(state, { $set: lsLoanInitialState }))
		.case(actions.lsLoan.createLoan.done, (state, { result }) =>
			Immutable(state, {
				loan: {
					id: { $set: result.id },
					status: { $set: result.status },
					expire: { $set: result.expire },
				},
			})
		)
		.case(actions.lsLoan.createLoan.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsLoan.getLoanStatus.done, (state, { params, result }) =>
			Immutable(state, {
				loan: {
					id: { $set: params.id },
					status: { $set: result.status },
					preScreening: {
						remainSecond: { $set: result.remainSecond },
					},
				},
			})
		)
		.case(actions.lsLoan.getLoanStatus.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsLoan.cancel.started, (state) => Immutable(state, setLoanError()))
		.case(actions.lsLoan.cancel.done, (state) =>
			Immutable(state, {
				loan: {
					status: { $set: LSLoanStatus.CANCELED },
				},
			})
		)
		.case(actions.lsLoan.cancelDifferentFlow.done, (state) => Immutable(state, { $set: lsLoanInitialState }))
		.case(actions.lsLoan.cancel.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsLoan.fetchRejectAffiliate.started, (state) =>
			Immutable(state, {
				loan: {
					error: { $set: undefined },
					rejectAffiliateChecked: { $set: false },
				},
			})
		)
		.case(actions.lsLoan.fetchRejectAffiliate.done, (state, { result }) =>
			Immutable(state, {
				loan: {
					rejectAffiliate: { $set: result },
					rejectAffiliateChecked: { $set: true },
				},
			})
		)
		.case(actions.lsLoan.fetchRejectAffiliate.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsLoan.additionalDocsAuthenticate.started, (state) =>
			Immutable(state, {
				loan: {
					additionalDocs: { $set: undefined },
				},
			})
		)
		.case(actions.lsLoan.additionalDocsAuthenticate.done, (state, { result }) =>
			Immutable(state, {
				loan: {
					additionalDocs: {
						$set: { ...result, submitted: false },
					},
				},
			})
		)
		.case(actions.lsLoan.additionalDocsAuthenticate.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsLoan.additionalDocsSubmit.started, (state) =>
			Immutable(state, {
				loan: {
					additionalDocs: {
						submitted: { $set: false },
					},
					error: { $set: undefined },
				},
			})
		)
		.case(actions.lsLoan.additionalDocsSubmit.done, (state) =>
			Immutable(state, {
				loan: {
					additionalDocs: {
						submitted: { $set: true },
					},
				},
			})
		)
		.case(actions.lsLoan.additionalDocsSubmit.failed, (state, { error }) => Immutable(state, setLoanError(error)))
		.case(actions.lsFinancialProductApplication.resetApplyCompleted, (state, payload) =>
			Immutable(state, {
				financialProductApplications: {
					[payload.id]: { applyCompleted: { $set: false } },
				},
			})
		)
		.case(actions.lsFinancialProductApplication.apply.started, (state, payload) =>
			Immutable(state, {
				financialProductApplications: {
					[payload.id]: { applyCompleted: { $set: false }, error: { $set: undefined } },
				},
			})
		)
		.case(actions.lsFinancialProductApplication.apply.done, (state, { params, result }) =>
			Immutable(state, {
				financialProductApplications: {
					[params.id]: {
						amount: { $set: result.amount },
						processType: { $set: result.processType },
						externalContractUrl: { $set: result.externalContractUrl },
						applyCompleted: { $set: true },
					},
				},
			})
		)
		.case(actions.lsFinancialProductApplication.apply.failed, (state, { params, error }) =>
			Immutable(state, setFPAError(params.id, error))
		)
		.case(actions.lsFinancialProductApplication.resetError, (state, payload) =>
			Immutable(state, {
				financialProductApplications: {
					[payload.id]: { error: { $set: undefined } },
				},
			})
		)
		.cases(
			[
				actions.lsLoan.loadLoan.started,
				actions.lsLoan.loadCompareResult.started,
				actions.lsLoan.loadLoanWithFPA.started,
			],
			(state) =>
				Immutable(state, {
					loan: {
						error: { $set: undefined },
					},
					loanLoaded: { $set: false },
				})
		)
		.cases(
			[actions.lsLoan.loadLoan.done, actions.lsLoan.loadCompareResult.done, actions.lsLoan.loadLoanWithFPA.done],
			(state, { result }) =>
				Immutable(state, {
					loanLoaded: { $set: true },
					loan: {
						id: { $set: result.id },
						expire: { $set: result.expire },
						error: { $set: undefined },
						pfOnly: { $set: result.pfOnly },
						hasApprovedFPA: { $set: result.hasApprovedFPA },
						status: { $set: result.loanStatus },
					},
					financialProductApplications: { $set: result.fpa },
					failedFinancialProductApplications: { $set: result.failedFPA ?? [] },
				})
		)
		.cases(
			[actions.lsLoan.loadLoan.failed, actions.lsLoan.loadCompareResult.failed, actions.lsLoan.loadLoanWithFPA.failed],
			(state, { error }) => Immutable(state, setLoanError(error))
		)
		.case(actions.lsLoan.checkPreScreeningInProgress.started, (state) => Immutable(state, setLoanError(undefined)))
		.case(actions.lsLoan.checkPreScreeningInProgress.done, (state) =>
			Immutable(state, {
				loan: {
					preScreening: {
						$set: {
							inProgress: true,
							remainSecond: -1,
						},
					},
				},
			})
		)
		.case(actions.lsLoan.checkPreScreeningInProgress.failed, (state, { error }) =>
			Immutable(state, setLoanError(error))
		),
};
