import { Action, AnyAction, combineReducers, Middleware, Store } from 'redux';
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from '@peoplefund/epics';
import rootReducer, { initialStates, State } from '@peoplefund/reducers';
import { createLogger } from 'redux-logger';
import { cometAjax } from '@peoplefund/epics/ajax.util';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import LogRocket from 'logrocket';
import { SanitizingFields } from '@peoplefund/constants/log-rocket';
import { useDispatch, useSelector } from 'react-redux';

export const getEpicMiddleWare = () =>
	createEpicMiddleware<Action, Action, State>({
		dependencies: { cometAjax },
	});

export const makeStore: () => Store<State, Action> = () => {
	/** middleware 등록 */
	const epicMiddleware = getEpicMiddleWare();
	const middleware: Middleware[] = [epicMiddleware];
	if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
		middleware.push(createLogger({ collapsed: true }));
	}

	// LogRocket middleware 는 마지막에 넣어야 함
	interface RecursiveState {
		[key: string]: RecursiveState | Record<string, RecursiveState> | string;
	}
	const customSanitizer = (state: RecursiveState): RecursiveState => {
		const newState: RecursiveState = {};

		for (const key in state) {
			const subState = state[key];
			switch (typeof subState) {
				case 'object':
					newState[key] = customSanitizer(subState);
					break;

				default:
					if (SanitizingFields.includes(key) && subState) {
						newState[key] = '(sanitized)';
					} else {
						newState[key] = subState;
					}
					break;
			}
		}
		return newState;
	};
	const logRocketMiddleware = LogRocket.reduxMiddleware({
		stateSanitizer: customSanitizer,
		actionSanitizer: customSanitizer,
	});
	middleware.push(logRocketMiddleware);

	const store = initializeStore({
		reducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false, // TODO 액션은 직렬화 가능한 데이터만 주고 받아야 함
			}).concat(middleware),
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState: initialStates,
	});

	epicMiddleware.run(rootEpic);

	return store;
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export const wrapper = createWrapper(makeStore);
export const initializeStore = (options: ConfigureStoreOptions) => {
	return configureStore(options);
};

export const reducer = (state: State, action: AnyAction) => {
	if (action.type === HYDRATE) {
		return {
			...state,
			...action.payload,
		};
	}
	return combineReducers({ ...rootReducer })(state, action);
};
