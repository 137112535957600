export enum LogRocketEventType {
	CREATE_LOAN_APPLICATION = 'Create Loan Application',
}

export const SanitizingFields = [
	'rrnFull',
	'rrn',
	'rrn_7digits',
	'ci',
	'virtual_keyboard',
	'account_no',
	'account_pwd',
	'password',
];
