import { gtag, GtagEventCategory, GtagFuncType } from '@peoplefund/utils/marketing-script/gtag.util';
import { useMemo } from 'react';

const useGtagInvestment = (): GtagFuncType => {
	return useMemo(
		() => (eventName, eventParams) =>
			gtag('event', eventName, {
				event_category: GtagEventCategory.INVEST,
				...eventParams,
			}),
		[]
	);
};

export default useGtagInvestment;
