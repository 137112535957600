import LogRocket from 'logrocket';
import { LogRocketEventType, SanitizingFields } from '@peoplefund/constants/log-rocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

// user trait은 필요에 따라 추가할 수 있음
export type UserTraits = {
	accessToken?: string;
};

export type TrackEventProperties = {
	revenue?: number;
	[key: string]: string | number | boolean | string[] | number[] | boolean[] | undefined;
};

interface IRequest {
	reqId: string;
	url: string;
	headers: { [key: string]: string | undefined };
	body?: string;
	method: string;
	referrer?: string;
	mode?: string;
	credentials?: string;
}

interface IResponse {
	reqId: string;
	status?: number;
	headers: { [key: string]: string | undefined };
	body?: string;
	method: string;
}

const init = () => {
	const logRocketAppid = process.env.NEXT_PUBLIC_LOGROCKET_APPID;
	if (logRocketAppid) {
		const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(SanitizingFields);
		LogRocket.init(logRocketAppid, {
			network: {
				requestSanitizer: requestSanitizer as (request: IRequest) => null | IRequest,
				responseSanitizer: responseSanitizer as (response: IResponse) => null | IResponse,
			},
		});
	}
};

const identify = (userId: number, traits?: UserTraits): void => {
	init();
	LogRocket.identify(`${userId}`, traits);
};

const track = (eventType: LogRocketEventType, eventProperties?: TrackEventProperties): void => {
	init();
	LogRocket.track(eventType, eventProperties);
};

const endSession = () => {
	LogRocket.getSessionURL((sessionUrl) => {
		if (sessionUrl) {
			LogRocket.startNewSession();
		}
	});
};

export default {
	init,
	identify,
	track,
	endSession,
};
