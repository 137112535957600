import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { concat, Observable, of, zip } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { Deal, fetchDealData, setDealData, setError, setLoading } from '@peoplefund/slices/peacock';
import { getUserId } from '@peoplefund/utils/jwt.util';
import { DetailedLoanType, LoanApplication } from '@peoplefund/slices/peacock/model/loanApplication';

type LoadDealResult = Pick<
	Deal,
	'loanApplication' | 'expectedRepaymentSchedule' | 'virtualLoanExpectedRepaymentSchedule'
>;

const fetchDealDataEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(fetchDealData),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload: loanApplicationURL },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				const loadDeal = (): Observable<LoadDealResult> => {
					return cometAjax.phalcon
						.get(`/showcase/detailGetAjax/${loanApplicationURL}`, {
							token,
						})
						.pipe(
							mergeMap((response: { data: LoanApplication }) => {
								const phalconData = response.data;
								if (phalconData.detailed_loan_type === DetailedLoanType.가상채권) {
									return cometAjax.static
										.get(`/files/virtual/${phalconData.parent_loan_id}/expected_schedule.json?v=${Date.now()}`)
										.pipe(
											map((data) => {
												return {
													loanApplication: phalconData,
													virtualLoanExpectedRepaymentSchedule: data,
												};
											})
										);
								} else {
									const userId = getUserId(token ?? '');
									let queryString = `?`; // amount=${amount}
									if (userId) {
										queryString += `?user_id=${userId}`;
									}
									return cometAjax.inapi
										.get(
											`/secured/v1/loan_applications/${phalconData.loan_application_id}/expected_schedules/${queryString}`,
											{
												token,
											}
										)
										.pipe(
											map((data) => {
												return {
													loanApplication: phalconData,
													expectedRepaymentSchedule: data,
												};
											})
										);
								}
							})
						);
				};

				const loadDealTemplate = (): Observable<any> => {
					return cometAjax.pelican.get(`/gateway/deal/pf/${loanApplicationURL}/?v=${Date.now()}`);
				};

				const loadTranch = (): Observable<any> => {
					if (loanApplicationURL.startsWith('ta') || loanApplicationURL.startsWith('tb')) {
						return cometAjax.static.get(
							`/files/showcase/${loanApplicationURL}/tranche_${loanApplicationURL}.json?v=${Date.now()}`
						);
					} else {
						return of(null);
					}
				};

				return concat(
					of(setLoading(true)),
					zip(loadDeal(), loadDealTemplate(), loadTranch()).pipe(
						map((responses) => {
							const [dealData, templateData, tranchData] = responses;
							const { loanApplication, expectedRepaymentSchedule, virtualLoanExpectedRepaymentSchedule } = dealData;

							return setDealData({
								loanApplication: loanApplication,
								dealTemplate: templateData,
								tranche: tranchData,
								expectedRepaymentSchedule,
								virtualLoanExpectedRepaymentSchedule,
							});
						}),
						catchError((error) => of(setError(error)))
					),
					of(setLoading(false))
				);
			}
		)
	);
export default [fetchDealDataEpic];
