import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	INVEST_PRODUCT_STATE,
	PartialRecord,
	ProcessStatus,
	QUERY_KEY,
	TABLE_SORT_OPTIONS,
} from '@peoplefund/constants/pl-investing';
import {
	ProductSet,
	SetExpectedProfitParam,
	SetFilterParam,
	SetProductListParam,
	setProductStateParam,
	SetSortingParam,
	SetTableInputBoxParam,
	SetToggleCheckBoxParam,
	SetSummaryTableParam,
} from '@peoplefund/slices/pl-investing.model';
import { applySortToList } from '@peoplefund/utils/sort.util';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import {
	defaultFilterState,
	FilterField,
	FilterValue,
	defaultFilters,
} from '@peoplefund/components/investing/personal-loans/list/FilterBox/useFilterBoxData.util';
import { getUserId } from '@peoplefund/utils/jwt.util';
import { filterSaveKey } from '@peoplefund/epics/pl-investing.util';
import { PageData } from './common-investing';

export interface PLInvesting {
	productSet: ProductSet; // 아래 Product 참고
	checkedObj: Record<string, boolean>; // bottomSheet 요약용
	sorting: {
		targetKey: string;
		option: TABLE_SORT_OPTIONS; // 아래 참고
		data: PartialRecord<INVEST_PRODUCT_STATE, string[]>; // Sorting된 Product.id 모음
	};
	investCancleIdList: string[]; // 투자 취소 리스트용 (Product.id 모음)
	investCancelResult: {
		status: ProcessStatus;
		success: string[];
		fail: string[];
	};
	investApplyIdList: string[]; // 투자 신청 리스트용 (Product.id 모음)
	applyResult: {
		status: ProcessStatus;
		success: string[]; //Product.id 모음
		fail: string[];
	};
	// filter (필터 정보 저장, api 요청, 데이터 타입은 아래)
	filter: Record<FilterField, FilterValue>;
	// filter changed 저장값
	filterApplied: boolean;
	// 선택한 상품군
	productState: INVEST_PRODUCT_STATE;
	// 요약정보
	summary: {
		table: {
			// 총 상품 개수
			count: number;
			/**
			 * amount 는 productState 별로 아래의 값을 가지게 됩니다.
			 * (모집중): SUM of 잔여 모집금액(targetAmount- accumulatedAmount)
			 * (오픈예정) : SUM of targetAmount
			 * (모집마감) : SUM of targetAmount
			 * (취소가능) : SUM of investedAmount
			 */
			amount: number;
		};
		bottomSheet: {
			// checkedObj 기반으로 정보를 요약합니다.
			// TODO: 우선 '같은 금액 적용하기'만을 진행합니다.
			// 상품수 [개]
			count: number;
			// 총 투자금액 [만원]
			totalInvestAmount: number;
			// 수익률 (가중평균 수익률) [만원]
			interestRateWeightedAvg: number;
		};
	};

	expectedProfit: SetExpectedProfitParam;

	pagination: {
		page: number;
		count: number;
		next: string | null | undefined;
		previous: string | null | undefined;
	};

	pageDatas: PageData[];
	error?: AlertCommonError;
}

export const plInvestingInitialState: PLInvesting = {
	productSet: {
		[INVEST_PRODUCT_STATE.RUNNING]: {},
		[INVEST_PRODUCT_STATE.CLOSE]: {},
		[INVEST_PRODUCT_STATE.CANCLE_AVAILABLE]: {},
	},
	checkedObj: {},
	sorting: {
		targetKey: '',
		option: TABLE_SORT_OPTIONS.origin,
		data: {
			// FYI. 2가지 sorting 정보를 불러올 때가 있어서 우선 state 별로 저장.
			[INVEST_PRODUCT_STATE.RUNNING]: [],
			[INVEST_PRODUCT_STATE.CLOSE]: [],
			[INVEST_PRODUCT_STATE.CANCLE_AVAILABLE]: [],
		},
	},
	investCancleIdList: [],
	investApplyIdList: [],
	applyResult: {
		status: 'ready',
		success: [],
		fail: [],
	},
	investCancelResult: {
		status: 'ready',
		success: [],
		fail: [],
	},
	filter: defaultFilterState,
	filterApplied: false,
	productState: INVEST_PRODUCT_STATE.RUNNING,
	summary: {
		table: {
			count: 0,
			amount: 0,
		},
		bottomSheet: {
			count: 0,
			totalInvestAmount: 0,
			interestRateWeightedAvg: 0,
		},
	},
	expectedProfit: {
		totalInterest: 0,
		totalInterestPercent: 0,
		totalInterestWithTax: 0,
		totalInterestWithTaxPercent: 0,
		totalLossAmount: 0,
		totalPlatformFee: 0,
		totalTax: 0,
	},
	pagination: {
		page: 1, // FYI. API 요청용 (1부터 count) vs client 용 (0부터 count)
		count: 0,
		next: null,
		previous: null,
	},
	pageDatas: [],
};

export const plInvestingSlice = createSlice({
	name: 'plInvesting',
	initialState: plInvestingInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, plInvestingInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		resetFilter: (state) => {
			state.filter = { ...defaultFilterState };
		},
		setFilter: (state, action: PayloadAction<SetFilterParam>) => {
			const { payload } = action;

			state.filter[payload.field].start = payload.value.start;
			state.filter[payload.field].end = payload.value.end;
			state.filter[payload.field].changed = payload.changed;
		},
		setPageIndex: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			// TODO: epic 분리하며 페이지네이션 로직 수정하면서 다같이 가져갈 예정
			state.pagination.page = payload;
			state.error = undefined;
		},
		resetPageIndex: (state) => {
			state.pagination.page = 1;
		},
		applyFilter: (state) => {
			state.error = undefined;
		},
		loadFilter: (state, action: PayloadAction<string>) => {
			const userId = getUserId(action.payload);
			if (userId) {
				const savedFilter = localStorage.getItem(filterSaveKey(userId));

				// 혹시나 잘못 저장된 과거 값 - falsy 제거
				if (savedFilter) {
					const result: Record<FilterField, FilterValue> = JSON.parse(savedFilter);
					defaultFilters.forEach((item) => {
						if (!result[item.field].start) {
							result[item.field].start = `${item.rangeSteps[0].value}`;
						}
						if (!result[item.field].end) {
							result[item.field].end = `${item.rangeSteps[item.rangeSteps.length - 1].value}`;
						}
					});
					state.filter = result;
				}
			}
		},
		setFilterApplied: (state) => {
			const { filter } = state;

			const changed = defaultFilters.some(({ rangeSteps, field }) => {
				const steps = rangeSteps;
				const currentRange = filter[field];
				const currentRangeStart = steps.find((step) => step.value === currentRange.start);
				const currentRangeEnd = steps.find((step) => step.value === currentRange.end);
				const changed =
					currentRangeStart?.value !== steps[0].value || currentRangeEnd?.value !== steps[steps.length - 1].value;

				return changed;
			});
			state.filterApplied = changed;
		},
		fetchProductList: (state) => {
			state.error = undefined;
		},
		setProductList: (state, action: PayloadAction<SetProductListParam>) => {
			const { productState, data, count, next, previous, sortingIds } = action.payload;

			state.productSet[productState] = data;
			state.sorting.data[productState] = sortingIds;

			if (productState === INVEST_PRODUCT_STATE.CLOSE) {
				//TODO: Close Table List component를 다시 만들 때 epic 분리를 하면 좋을 듯 합니다.
				state.pagination.count = count ?? -1;
				state.pagination.next = next ?? undefined;
				state.pagination.previous = previous ?? undefined;
			} else {
				// 정렬 옵션 초기화
				state.sorting.targetKey = QUERY_KEY.ID;
				state.sorting.option = TABLE_SORT_OPTIONS.origin;
			}
			state.error = undefined;
		},
		setTableInputBox: (state, action: PayloadAction<SetTableInputBoxParam>) => {
			const productState = state.productState;
			const {
				payload: { id, value },
			} = action;
			const item = state.productSet[productState][id];

			item.investedAmount = value;

			// FYI. updateTableInputBoxEpic
		},

		setToggleCheckBox: (state, action: PayloadAction<SetToggleCheckBoxParam>) => {
			const {
				payload: { id, checked },
			} = action;

			if (typeof checked === 'boolean') {
				// checked 필드가 지정되어 있으면
				state.checkedObj[id] = checked;
			} else {
				state.checkedObj[id] = !state.checkedObj[id];
			}
			//TODO: 각각 눌렀을때 전체 선택되어 있으면 header 도 선택된걸로 바꾸기 (vice versa)
			// FYI. updateCheckBoxEpic
		},
		setToggleAllCheckBox: (state) => {
			const productState = state.productState;
			const products = state.productSet[productState];
			const headerCheckState = state.checkedObj['header'];

			// 전체 해제
			if (headerCheckState) {
				state.checkedObj = {};
			} else {
				// 전체 체크
				const ids = Object.keys(products);
				state.checkedObj['header'] = true;

				ids.forEach((id) => {
					state.checkedObj[id] = true;
				});
			}
			// FYI. updateAllCheckBoxEpic
		},
		setSortingList: (state, action: PayloadAction<SetSortingParam>) => {
			const productState = state.productState;
			const {
				payload: { targetKey },
			} = action;
			const prevSorting = state.sorting;
			const sortOption =
				targetKey === prevSorting.targetKey ? TABLE_SORT_OPTIONS[prevSorting.option] : TABLE_SORT_OPTIONS.decrease; // 새로운 필드이면 다시 처음, decrease 부터
			let sortedData = {};
			if (productState === INVEST_PRODUCT_STATE.RUNNING) {
				// 모집중이면 취소상품군도 함께 정렬해야함
				sortedData = {
					[INVEST_PRODUCT_STATE.RUNNING]: applySortToList(
						state.productSet[INVEST_PRODUCT_STATE.RUNNING],
						targetKey,
						sortOption
					),
					[INVEST_PRODUCT_STATE.CANCLE_AVAILABLE]: applySortToList(
						state.productSet[INVEST_PRODUCT_STATE.CANCLE_AVAILABLE],
						targetKey,
						sortOption
					),
				};
			} else {
				sortedData = {
					[productState]: applySortToList(state.productSet[productState], targetKey, sortOption),
				};
			}
			state.sorting = {
				targetKey,
				option: sortOption,
				data: sortedData,
			};
		},
		applySortingListForClose: (state, action: PayloadAction<SetSortingParam>) => {
			const {
				payload: { targetKey },
			} = action;
			const prevSorting = state.sorting;
			const sortOption =
				targetKey === prevSorting.targetKey ? TABLE_SORT_OPTIONS[prevSorting.option] : TABLE_SORT_OPTIONS.decrease; // 새로운 필드이면 다시 처음, decrease 부터

			state.sorting = {
				targetKey,
				option: sortOption,
				// FYI. 마감상품 외에는 applySortToList 로 client 에서 정렬했지만,
				// 마감상품은 epic 연결해서 data 채우기
				data: {},
			};
		},
		setProductState: (state, action: PayloadAction<setProductStateParam>) => {
			const {
				payload: { value },
			} = action;

			state.productState = value;

			// FYI. updateProductStateEpic;
		},
		setSuccessApplyResult: (state, action: PayloadAction<string>) => {
			state.applyResult.success = [...state.applyResult.success, action.payload];
		},
		setfailApplyResult: (state, action: PayloadAction<string>) => {
			state.applyResult.fail = [...state.applyResult.fail, action.payload];
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchSummary: (state, _action: PayloadAction<{ queryString: string; api: string }>) => {
			state.error = undefined;
		},
		setSummaryTable: (state, action: PayloadAction<SetSummaryTableParam>) => {
			state.summary.table = action.payload;
		},
		setSummaryBottomSheet: (state) => {
			const { productSet, productState, checkedObj } = state;
			const products = productSet[productState];
			const filterCheckedList = Object.keys(checkedObj).filter((id) => {
				if (id === 'header') {
					return false;
				}
				return checkedObj[id];
			});
			const { totalInvestAmount, interestRateWeightedSum } = filterCheckedList.reduce(
				(acc, curIdx) => {
					return {
						totalInvestAmount: acc.totalInvestAmount + products[curIdx].investedAmount,
						interestRateWeightedSum:
							acc.interestRateWeightedSum + products[curIdx].interestRate * products[curIdx].investedAmount,
					};
				},
				{
					totalInvestAmount: 0,
					interestRateWeightedSum: 0,
				}
			);

			const interestRateWeightedAvg =
				totalInvestAmount === 0 ? 0 : (interestRateWeightedSum / totalInvestAmount).toFixed(2);

			state.summary.bottomSheet = {
				count: filterCheckedList.length,
				totalInvestAmount: totalInvestAmount,
				interestRateWeightedAvg: Number(interestRateWeightedAvg),
			};
		},
		resetCheckedObj: (state) => {
			state.checkedObj = plInvestingInitialState.checkedObj;
		},
		resetSummaryBottomSheet: (state) => {
			state.summary.bottomSheet = plInvestingInitialState.summary.bottomSheet;
		},
		setInvestApplyIdList: (state) => {
			// FYI. 이때 주의할 점은, checkedList에 존재하더라도 investedAmount 는 0원일 수 있다
			// bottomSheet 는 checked = true 인 아이들을 기준으로 요약한다.
			// 신청쪽에서는 신경안쓰도록 신청 리스트를 따로 저장한다.
			// 1. headr key 삭제 2. investedAmount = 0 이면 삭제

			const { productSet, productState, checkedObj } = state;

			// 당연히 모집중인 상품만 누르겠지만, 혹시나해서 한번더 방어 로직
			if (productState !== INVEST_PRODUCT_STATE.RUNNING) {
				return;
			}

			const products = productSet[INVEST_PRODUCT_STATE.RUNNING];
			const investApplyIdList = Object.keys(checkedObj)
				.filter((id) => {
					if (id === 'header') {
						return false;
					} else if (products[id].investedAmount <= 0) {
						return false;
					}

					return Boolean(checkedObj[id]);
				})
				.map((id) => id);
			state.investApplyIdList = investApplyIdList;
		},
		resetInvestApplyIdList: (state) => {
			// Q. 얘도 리스트 페이지 접근시에만 초기화할지?
			state.investApplyIdList = [];
		},
		setInvestCancleIdList: (state) => {
			// FYI. 사실 investApplyIdList 랑 로직은 거의 비슷하지만, 저장되는 곳이 다르므로 분리함.
			// 1. headr key 삭제

			const { productState, checkedObj, productSet } = state;

			// 당연히 투자한 상품만 누르겠지만, 혹시나해서 한번더 방어 로직
			if (productState !== INVEST_PRODUCT_STATE.CANCLE_AVAILABLE) {
				return;
			}

			const products = productSet[productState];

			const investCancleIdList = Object.keys(checkedObj)
				.filter((id) => {
					if (id === 'header' || products[id].investmentId === '') {
						return false;
					}

					return Boolean(checkedObj[id]);
				})
				.map((id) => products[id].investmentId);
			state.investCancleIdList = investCancleIdList;
		},
		resetInvestCancleIdList: (state) => {
			// Q. 얘도 리스트 페이지 접근시에만 초기화할지?
			state.investCancleIdList = [];
		},
		startInvestCancel: (state, action: PayloadAction<{ init?: boolean }>) => {
			state.error = undefined;

			if (action.payload.init) {
				state.investCancelResult = {
					status: 'ready',
					success: [],
					fail: [],
				};
			}
		},
		setInvestCancelResult: (state, { payload }: PayloadAction<{ investmentId: string; success: boolean }>) => {
			const updatedCancelIds = state.investCancleIdList.filter((investmentId) => investmentId !== payload.investmentId);
			state.investCancleIdList = updatedCancelIds;
			state.investCancelResult.status = updatedCancelIds.length > 0 ? 'processing' : 'done';

			const target = payload.success ? 'success' : 'fail';
			state.investCancelResult[target].push(payload.investmentId);
		},
		resetInvestCancelResult: (state) => {
			state.investCancelResult = {
				status: 'processing',
				success: [],
				fail: [],
			};
		},
		startInvestApply: (state, action: PayloadAction<{ init?: boolean }>) => {
			state.error = undefined;

			if (action.payload.init) {
				state.applyResult = {
					status: 'ready',
					success: [],
					fail: [],
				};
			}
		},
		setInvestApplyResult: (state, { payload }: PayloadAction<{ id: string; success: boolean }>) => {
			const updatedApplyIds = state.investApplyIdList.filter((id) => id !== payload.id);
			state.investApplyIdList = updatedApplyIds;
			state.applyResult.status = updatedApplyIds.length > 0 ? 'processing' : 'done';

			const target = payload.success ? 'success' : 'fail';
			state.applyResult[target].push(payload.id);
		},
		fetchInvestProfitInfo: (state) => {
			state.error = undefined;
		},
		setInvestProfitInfo: (state, { payload }: PayloadAction<SetExpectedProfitParam>) => {
			state.expectedProfit = payload;
		},
		resetInvestApplyResult: (state) => {
			state.applyResult = {
				status: 'processing',
				success: [],
				fail: [],
			};
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchProduct: (state, _action: PayloadAction<{ loanApplicationId: number }>) => {
			state.error = undefined;
		},
		setPageDatas: (state, action: PayloadAction<{ pageDatas: PageData[] }>) => {
			state.pageDatas = action.payload.pageDatas;
		},
	},
});

export const {
	initialize,
	setError,
	resetError,
	resetFilter,
	setFilter,
	applyFilter,
	loadFilter,
	setTableInputBox,
	setToggleCheckBox,
	setToggleAllCheckBox,
	setSortingList,
	applySortingListForClose,
	setProductState,
	setSuccessApplyResult,
	setfailApplyResult,
	fetchSummary,
	setSummaryTable,
	setSummaryBottomSheet,
	resetCheckedObj,
	resetSummaryBottomSheet,
	fetchProductList,
	setProductList,
	setInvestApplyIdList,
	resetInvestApplyIdList,
	setInvestCancleIdList,
	resetInvestCancleIdList,
	startInvestCancel,
	setInvestCancelResult,
	resetInvestCancelResult,
	startInvestApply,
	setInvestApplyResult,
	fetchInvestProfitInfo,
	setInvestProfitInfo,
	resetInvestApplyResult,
	setFilterApplied,
	setPageIndex,
	resetPageIndex,
	fetchProduct,
	setPageDatas,
} = plInvestingSlice.actions;

export default plInvestingSlice.reducer;
