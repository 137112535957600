import { ofAction } from '@peoplefund/utils/redux.util';
import * as TwoPlusOneVerifySlice from '@peoplefund/slices/two-plus-one-verify';
import { CometEpic } from '../constants.util';
import { catchError, concat, mergeMap, Observable, of, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	BankRequestTransferResponse,
	OCRManualResponse,
	TransferOCRResponse,
	TransferOCRV2Response,
} from '@peoplefund/epics/twoPlusOneVerify/index.model';
import {
	VERIFY_TYPE,
	VerifyType,
	VerifyTypeToCode,
	VerifyTypeToCodeV2,
} from '@peoplefund/components/common/verifyTwoPlusOne/id-card/@common/index.model';
import { actions } from '@peoplefund/actions';
import {
	convertBase64UrlImageToBase64,
	convertTransferOCRResponse,
} from '@peoplefund/epics/twoPlusOneVerify/index.util';
import { encryptObservable, EncryptObservableResponse } from '@peoplefund/epics/account/index.util';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import { removeBase64Meta } from '@peoplefund/utils/image.util';

/**
 * @deprecated user 서버 작업이 마무리 되면 cert API 사용하는 V2 에픽으로 대체할 예정
 */
const requestOCREpic: CometEpic = (action$, state$, { cometAjax }) => {
	const ocrCheck = (token: string, image: string, type: VerifyType): Observable<TransferOCRResponse> =>
		cometAjax.inapi.post('/verification/jbbank/identification/transfer/', {
			token,
			body: {
				image,
				type: VerifyTypeToCode[type],
			},
		});

	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestOCR),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { base64Image, verifyType },
				},
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				const imageWithoutMeta = removeBase64Meta(base64Image);

				return ocrCheck(token, imageWithoutMeta, verifyType).pipe(
					map((response: TransferOCRResponse) =>
						TwoPlusOneVerifySlice.setOCRResult(convertTransferOCRResponse(response))
					),
					catchError((error) =>
						concat(of(TwoPlusOneVerifySlice.setOCRResult(undefined)), of(TwoPlusOneVerifySlice.setError(error)))
					)
				);
			}
		)
	);
};

/**
 * @deprecated user 서버 작업이 마무리 되면 cert API 사용하는 V2 에픽으로 대체할 예정
 */
const idCheckEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestIdCheck),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
						userInfo,
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.post(`/verification/jbbank/v2/identification/check/`, {
							body: {
								type: VerifyTypeToCode[payload.verifyType],
								rrn: payload.rrn,
								driver_license:
									payload.verifyType === VERIFY_TYPE.DRIVERS_LICENSE && payload.driversLicence
										? `${payload.driversLicence.areaCode}${payload.driversLicence.number}`
										: '000',
								register_date: payload.registerDate.replaceAll(/-/g, ''),
								address: payload.address,
								detail_address: payload.detailAddress,
								post_code: payload.postCode,
							},
							token,
						})
						.pipe(
							mergeMap(() => {
								if (userInfo?.hasRRN) {
									return of(TwoPlusOneVerifySlice.requestIdCheckResult({ result: true }));
								} else {
									return encryptObservable(
										cometAjax,
										{
											rrn_last: payload.rrn.slice(6),
										},
										token
									).pipe(
										mergeMap(({ eData, headers }) =>
											cometAjax.pfUser
												.patch('/user/v1/member/rrn', {
													headers,
													body: { eData },
													token,
												})
												.pipe(map(() => TwoPlusOneVerifySlice.requestIdCheckResult({ result: true })))
										)
									);
								}
							}),
							catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const requestOCRV2Epic: CometEpic = (action$, state$, { cometAjax }) => {
	const ocrCheck = (token: string, image: string, verification_key: string): Observable<TransferOCRV2Response> =>
		cometAjax.cert.post('/v1/idcard', {
			token,
			body: {
				verification_key,
				image,
				user_name: '대표자 이름을 입력해주세요',
			},
		});

	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestOCRV2),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { base64Image },
				},
				{
					account: {
						auth: { token = '' },
					},
					twoPlusOneVerify: { verificationKey },
				},
			]) => {
				return ocrCheck(token, removeBase64Meta(base64Image), verificationKey).pipe(
					mergeMap((response: TransferOCRV2Response) => {
						return [
							TwoPlusOneVerifySlice.setOCRResult({
								...convertTransferOCRResponse(response),
							}),
						];
					}),
					catchError((error) =>
						concat(of(TwoPlusOneVerifySlice.setOCRResult(undefined)), of(TwoPlusOneVerifySlice.setError(error)))
					)
				);
			}
		)
	);
};

const idCheckV2Epic: CometEpic = (action$, state$, { cometAjax }) => {
	const registerCorpForJbbank = (token: string, rrn: string, name: string): Observable<EncryptObservableResponse> =>
		encryptObservable(
			cometAjax,
			{
				name,
				rrn,
			},
			token
		);

	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestIdCheckV2),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
						userInfo,
					},
					twoPlusOneVerify,
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					registerCorpForJbbank(token, payload.rrn, payload.name ?? '').pipe(
						mergeMap(({ eData, headers }) =>
							cometAjax.pfUser
								.post('/user/v1/investor/cert/corp-register-jbbank', {
									token,
									headers,
									body: {
										eData,
									},
								})
								.pipe(
									mergeMap(() => {
										return cometAjax.cert
											.post(`/v1/idcard/manual`, {
												body: {
													verification_key: twoPlusOneVerify.verificationKey,
													image: convertBase64UrlImageToBase64(payload.maskingImage),
													user_name: payload.name,
													idcard_type: VerifyTypeToCodeV2[payload.verifyType],
													rrn: payload.rrn,
													driver_license_number:
														payload.verifyType === VERIFY_TYPE.DRIVERS_LICENSE && payload.driversLicence
															? `${payload.driversLicence.areaCode}${payload.driversLicence.number}`
															: '',
													register_date: payload.registerDate.replaceAll(/-/g, ''),
												},
												token,
											})
											.pipe(
												mergeMap((response: OCRManualResponse) => {
													const isCorporateUser =
														userInfo?.userType === 'CORPORATION' || userInfo?.userType === 'INSTITUTION';

													if (response.state === 'F') {
														throw new InAPICommonError(response.fail_message);
													}
													if (isCorporateUser || userInfo?.hasRRN) {
														return of(
															TwoPlusOneVerifySlice.requestIdCheckResult({
																result: true,
																verificationId: response.verification_id,
															})
														);
													} else {
														return encryptObservable(
															cometAjax,
															{
																rrn_last: payload.rrn.slice(6),
															},
															token
														).pipe(
															mergeMap(({ eData, headers }) =>
																cometAjax.pfUser
																	.patch('/user/v1/member/rrn', {
																		headers,
																		body: { eData },
																		token,
																	})
																	.pipe(map(() => TwoPlusOneVerifySlice.requestIdCheckResult({ result: true })))
															)
														);
													}
												}),
												catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
											);
									})
								)
						)
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

/**
 * @deprecated user 서버 작업이 마무리 되면 cert API 사용하는 V2 에픽으로 대체할 예정
 */
const startBankAccountVerifyEpic: CometEpic = (action$, state$, { cometAjax }) => {
	const checkAccountIsValid = (token: string, bankCode: string, accountNumber: string): Observable<boolean> =>
		cometAjax.inapi.post(`/verification/jbbank/1won_realnamecheck/transfer/priorfind/`, {
			body: {
				bank_code: bankCode,
				account_no: accountNumber,
				is_worker: false,
			},
			token,
		});

	const requestTransfer = (token: string, bankCode: string, accountNumber: string): Observable<boolean> =>
		cometAjax.inapi.post(`/verification/jbbank/1won_realnamecheck/transfer/`, {
			body: {
				bank_code: bankCode,
				account_no: accountNumber,
			},
			token,
		});

	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.startBankAccountVerify),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { accountNumber, bankCode },
				},
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					checkAccountIsValid(token, bankCode, accountNumber).pipe(
						mergeMap(() =>
							requestTransfer(token, bankCode, accountNumber).pipe(
								map(() => {
									return TwoPlusOneVerifySlice.setBankAccountVerifyStarted();
								})
							)
						),
						catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const startBankAccountVerifyEpicV2: CometEpic = (action$, state$, { cometAjax }) => {
	const requestTransfer = (
		token: string,
		params: {
			verificationKey: string;
			accountNumber: string;
			bankCode: string;
			bankName: string;
			name: string;
			rrn: string;
		}
	): Observable<BankRequestTransferResponse> => {
		const { verificationKey, accountNumber, bankCode, bankName, name, rrn } = params;
		return cometAjax.cert.post(`/v1/account/1won`, {
			body: {
				verification_key: verificationKey,
				account_no: accountNumber,
				bank_code: bankCode,
				bank_name: bankName,
				name,
				rrn,
				is_corp: true,
				is_korean: true,
			},
			token,
		});
	};

	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.startBankAccountVerifyV2),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { accountNumber, bankCode, bankName, name, rrn },
				},
				{
					account: {
						auth: { token = '' },
					},
					twoPlusOneVerify: { verificationKey },
				},
			]) => {
				const transferBody = {
					verificationKey,
					accountNumber,
					bankCode,
					bankName,
					name,
					rrn,
				};

				return concat(
					of(actions.layout.startLoading()),
					requestTransfer(token, transferBody).pipe(
						map((response) => {
							return TwoPlusOneVerifySlice.setBankAccountVerifyStarted(response.verification_id);
						}),
						catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

/**
 * @deprecated user 서버 작업이 마무리 되면 cert API 사용하는 V2 에픽으로 대체할 예정
 */
const requestBankAccountVerifyEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestBankAccountVerify),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { accountNumber, bankCode, authCode },
				},
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.inapi
						.post(`/verification/jbbank/1won_realnamecheck/authentication/`, {
							body: {
								auth_code: authCode,
								bank_code: bankCode,
								account_no: accountNumber,
								is_borrower_register: 'Y',
							},
							token,
						})
						.pipe(
							map(() => {
								return TwoPlusOneVerifySlice.setBankAccountVerifyCompleted();
							}),
							catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const requestBankAccountVerifyEpicV2: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(TwoPlusOneVerifySlice.requestBankAccountVerifyV2),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { authCode },
				},
				{
					account: {
						auth: { token = '' },
					},
					twoPlusOneVerify: { verificationKey, bankVerifyInfo, idCardInfo },
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					cometAjax.cert
						.post(`/v1/account/1won/verify`, {
							body: {
								verification_id: bankVerifyInfo.verificationId,
								verification_key: verificationKey,
								auth_no: authCode,
							},
							token,
						})
						.pipe(
							mergeMap((response) => {
								if (response.state === 'F') {
									throw new InAPICommonError(response.fail_message);
								}

								return encryptObservable(
									cometAjax,
									{
										verification_key: verificationKey,
										idcard_verification_id: idCardInfo.verificationId,
										account_verification_id: bankVerifyInfo.verificationId,
									},
									token
								).pipe(
									mergeMap(({ eData, headers }) =>
										cometAjax.pfUser
											.post('/user/v1/investor/cert/complete', {
												headers,
												body: { eData },
												token,
											})
											.pipe(map(() => TwoPlusOneVerifySlice.setBankAccountVerifyCompleted()))
									)
								);
							}),
							catchError((error) => of(TwoPlusOneVerifySlice.setError(error)))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

export default [
	requestOCREpic,
	idCheckEpic,
	startBankAccountVerifyEpic,
	requestBankAccountVerifyEpic,
	requestOCRV2Epic,
	idCheckV2Epic,
	startBankAccountVerifyEpicV2,
	requestBankAccountVerifyEpicV2,
];
