import { CompanyInfoState } from '@peoplefund/actions/ls-apply-info';
import { CretopServerProps } from '@peoplefund/epics/ls-info.model';

export const convertGetJobInfoFromCretop = (response: CretopServerProps[]): CompanyInfoState[] =>
	response.map((item) => ({
		kedcd: item.kedcd,
		companyName: item.company_name,
		companyEnglishName: item.company_english_name,
		companyTradeName: item.company_trade_name,
		businessNo: item.business_no,
		companyContactNumber: item.company_contact_number,
		companyAddress: item.company_address,
		companyDetailAddress: item.company_detail_address,
		companyPostCode: item.company_post_code,
		companyJibunAddress: item.company_jibun_address,
		companyJibunDetailAddress: item.company_jibun_detail_address,
		companyJibunPostCode: item.company_jibun_post_code,
		laborerSum: item.laborer_sum,
	}));
