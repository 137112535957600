import { ErrorCodeMap } from '@peoplefund/epics/common';
import apiClient from '@peoplefund/utils/axios';
import * as Sentry from '@sentry/nextjs';

const PF_ERRORJSON = 'PF_ERRORJSON';
const LAST_UPDATE = `${PF_ERRORJSON}_LU`;

const ErrorMessageHandler = (() => {
	const fetch = async (): Promise<ErrorCodeMap | null> => {
		const pfErrorJson: ErrorCodeMap = JSON.parse(localStorage.getItem(PF_ERRORJSON) ?? '{}');
		const lastUpdate = localStorage.getItem(LAST_UPDATE);
		const now = new Date();

		if (lastUpdate) {
			const lastUpdateDate = new Date(lastUpdate);
			const oneDay = 24 * 60 * 60 * 1000;
			if (now.getTime() - lastUpdateDate.getTime() < oneDay && Object.keys(pfErrorJson).length > 0) {
				return pfErrorJson;
			}
		}

		try {
			const { data } = await apiClient('/pipe/pf-code/errorCode.json');
			localStorage.setItem(PF_ERRORJSON, JSON.stringify(data));
			localStorage.setItem(LAST_UPDATE, now.toISOString());

			return data;
		} catch (e) {
			return null;
		}
	};

	const getMessage = async (
		error: {
			code?: string;
			message?: string;
		},
		showServerErrorMessage?: boolean
	): Promise<{
		readonly title: string;
		readonly message: string;
	}> => {
		const defaultError = { title: '에러가 발생했습니다', message: '' };
		try {
			const { code = '', message } = error;

			const data = await fetch();
			if (data) {
				const errorCodeJson = data[code]?.ko;
				if (errorCodeJson) {
					return errorCodeJson;
				}
			}

			if (showServerErrorMessage && message) {
				return {
					title: '에러가 발생했습니다',
					message,
				};
			}

			return defaultError;
		} catch (e) {
			Sentry.captureException(e);
			return defaultError;
		}
	};

	return {
		init: fetch,
		getMessage,
	};
})();

export default ErrorMessageHandler;
