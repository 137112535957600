import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { CommissionTypeCode } from '@peoplefund/constants/ls-loan-types';
import { FinancialProductDetail } from '@peoplefund/constants/ls-financial-detail-types';

export interface BlogArticle {
	link: string;
	thumbnail: string;
	title: string;
	date: string;
}

export interface ProductInformationItem {
	title: string;
	description: string;
}

export interface ProductCommissionInformation {
	blogArticles: BlogArticle[];
}

export type SetFinancialProductDetailParam = { id: string } & FinancialProductDetail;

const actionCreator = actionCreatorFactory('LS-FINANCIAL-PRODUCT');

export default {
	init: actionCreator(ActionTypes.LS_FINANCIAL_PRODUCT_INIT),
	resetError: actionCreator(ActionTypes.LS_FINANCIAL_PRODUCT_RESET_ERROR),
	getFinancialProductCommissionDetail: actionCreator.async<
		CommissionTypeCode,
		ProductCommissionInformation,
		AlertCommonError
	>(ActionTypes.LS_FINANCIAL_PRODUCT_GET_COMMISSION_DETAIL),
	setFinancialProductDetail: actionCreator<SetFinancialProductDetailParam>(ActionTypes.LS_FINANCIAL_PRODUCT_SET_DETAIL),
};
