import jwtDecode from 'jwt-decode';
import { SignInResult } from '@peoplefund/actions/account';
import Cookies from 'universal-cookie';
import { PHOENIX_SESSION_ID } from '@peoplefund/constants/session-id';

interface JwtPayload {
	user_id: number;
	parent_user_id: number;
	exp: number;
	iat: number;
}

const getTempExp = (): number => {
	const now = new Date().getTime();
	const tomorrow = now + 60 * 60 * 24 * 1000; //1일 뒤를 임의의 만료시간으로 적용
	return tomorrow / 1000; //jwt expire은 milliseconds이 아니라 second 임
};

export const decodeAsJwtPayload = (accessToken: string): JwtPayload | undefined => {
	try {
		return jwtDecode<JwtPayload>(accessToken);
	} catch (e) {}
};

export const getUserId = (accessToken: string): number | undefined => decodeAsJwtPayload(accessToken)?.user_id;

export const getSignInResult = (props: { access_token: string; refresh_token: string }): SignInResult => {
	const { access_token: accessToken, refresh_token: refreshToken } = props;
	const jwt = decodeAsJwtPayload(accessToken);

	console.log('jwt?.exp', jwt?.exp);
	return {
		expiredAt: jwt?.exp ?? 0,
		accessToken: accessToken,
		refreshToken: refreshToken,
	};
};

export const accessTokenExpired = (accessToken: string): boolean => {
	const expiredTime = decodeAsJwtPayload(accessToken)?.exp ?? 0;
	const now = new Date().getTime() / 1000;
	return expiredTime <= now;
};

export const getJWTByCookie = (): SignInResult | undefined => {
	const cookies = new Cookies();
	const signInResult: SignInResult = cookies.get(PHOENIX_SESSION_ID);

	if (signInResult?.accessToken && signInResult?.refreshToken) {
		const { accessToken, refreshToken } = signInResult;

		if (!accessTokenExpired(accessToken)) {
			return {
				expiredAt: signInResult?.expiredAt ?? getTempExp(),
				accessToken,
				refreshToken,
			};
		}
	}

	return;
};
