import keyMirror from 'keymirror';

export const ActionTypes = keyMirror({
	INIT: 'INIT',

	LAYOUT_INIT: 'GNB_COLOR_INIT',
	LAYOUT_START_LOADING: 'LAYOUT_START_LOADING',
	LAYOUT_END_LOADING: 'LAYOUT_END_LOADING',
	LAYOUT_TOKEN_EXPIRED: 'LAYOUT_TOKEN_EXPIRED',
	LAYOUT_SHOW_LOANS_MYPAGE_MENU: 'LAYOUT_SHOW_LOANS_MYPAGE_MENU',

	LOG_ROCKET_IDENTIFY: 'LOG_ROCKET_IDENTIFY',
	LOG_ROCKET_TRACK: 'LOG_ROCKET_TRACK',

	ACCOUNT_INIT: 'ACCOUNT_INIT',
	ACCOUNT_ERROR_RESET: 'ACCOUNT_ERROR_RESET',
	ACCOUNT_SET_TOKEN: 'ACCOUNT_SET_TOKEN',
	ACCOUNT_RESET_TOKEN: 'ACCOUNT_RESET_TOKEN',
	ACCOUNT_SIGN_IN: 'ACCOUNT_SIGN_IN',
	ACCOUNT_SIGN_IN_BY_EMAIL: 'ACCOUNT_SIGN_IN_BY_EMAIL',
	ACCOUNT_SIGN_OUT: 'ACCOUNT_SIGN_OUT',
	ACCOUNT_FETCH_USER_INFO: 'ACCOUNT_FETCH_USER_INFO',
	ACCOUNT_SET_AML_STATUS: 'ACCOUNT_SET_AML_STATUS',
	ACCOUNT_SET_CORPORATE_INFO: 'ACCOUNT_SET_CORPORATE_INFO',
	ACCOUNT_SET_EMAIL: 'ACCOUNT_SET_EMAIL',
	ACCOUNT_SET_INVESTOR_INFO: 'ACCOUNT_SET_INVESTOR_INFO',
	ACCOUNT_UPDATE_INVESTOR_INFO: 'ACCOUNT_UPDATE_INVESTOR_INFO',
	ACCOUNT_CHECK_INVESTOR_REGISTER_AVAILABLE: 'ACCOUNT_CHECK_INVESTOR_REGISTER_AVAILABLE',
	ACCOUNT_FETCH_AML_STATUS: 'ACCOUNT_FETCH_AML_STATUS',
	ACCOUNT_INIT_AML_FLOW: 'ACCOUNT_INIT_AML_FLOW',
	ACCOUNT_SET_PHONE_NUMBER: 'ACCOUNT_SET_PHONE_NUMBER',

	VERIFICATION_INIT: 'VERIFICATION_INIT',
	VERIFICATION_SEND_RRN7: 'VERIFICATION_SEND_RRN7',
	VERIFICATION_RESET_VERIFY: 'VERIFICATION_RESET_VERIFY',

	/** loanshot **/
	LS_AUTH_INIT: 'LS_AUTH_INIT',
	LS_AUTH_APPLY_INIT_VERIFY: 'LS_AUTH_APPLY_INIT_VERIFY',
	LS_AUTH_ERROR_RESET: 'LS_AUTH_ERROR_RESET',
	LS_AUTH_VERIFY_SEND_SMS: 'LS_AUTH_VERIFY_SEND_SMS',
	LS_AUTH_VERIFY_SIGNUP: 'LS_AUTH_VERIFY_SIGNUP',
	LS_AUTH_VERIFY_PARTNER_DONE: 'LS_AUTH_VERIFY_PARTNER_DONE',
	LS_AUTH_VERIFY_COMPLETE: 'LS_AUTH_VERIFY_COMPLETE',

	LS_COMMON_INIT: 'LS_COMMON_INIT',
	LS_COMMON_FETCH_ENUMS: 'LS_COMMON_FETCH_ENUMS',
	LS_COMMON_SET_FLOWTYPE: 'LS_COMMON_SET_FLOWTYPE',
	LS_COMMON_SET_MAIN_PATH: 'LS_COMMON_SET_MAIN_PATH',
	LS_COMMON_INTRO: 'LS_COMMON_INTRO',

	LS_COMMON_RESET_ERROR: 'LS_COMMON_RESET_ERROR',
	LS_COMMON_FETCH_AGREEMENTS: 'LS_COMMON_GET_AGREEMENT',
	LS_COMMON_SEND_AGREEMENTS: 'LS_COMMON_SEND_AGREEMENTS',

	LS_COMMON_FETCH_META: 'LS_COMMON_FETCH_META',
	LS_COMMON_LOAD_LOANS_COMPARE_INTRO: 'LS_COMMON_LOAD_LOANS_COMPARE_INTRO',
	LS_COMMON_RESET_CHANNEL_PLATFORM_CODE: 'LS_COMMON_RESET_CHANNEL_PLATFORM_CODE',
	LS_COMMON_SET_CHANNEL_PLATFORM_CODE: 'LS_COMMON_SET_CHANNEL_PLATFORM_CODE',

	LS_APPLY_INIT: 'LS_APPLY_INIT',
	LS_APPLY_RESET_ERROR: 'LS_APPLY_RESET_ERROR',
	LS_APPLY_AGREE: 'LS_APPLY_AGREE',
	LS_APPLY_UPDATE_EXTRA_INFO: 'LS_APPLY_UPDATE_EXTRA_INFO',
	LS_APPLY_UPDATE_PURPOSE: 'LS_APPLY_UPDATE_PURPOSE',
	LS_APPLY_GET_JOB_INFO_FROM_CRETOP: 'LS_APPLY_GET_JOB_INFO_FROM_CRETOP',
	LS_APPLY_RESET_JOB_INFO_FROM_CRETOP: 'LS_APPLY_RESET_JOB_INFO_FROM_CRETOP',
	LS_APPLY_FETCH_EXISTING_LOAN: 'LS_APPLY_FETCH_EXISTING_LOAN',
	LS_APPLY_CREATE_EXISTING_LOAN: 'LS_APPLY_CREATE_EXISTING_LOAN',
	LS_APPLY_DELETE_EXISTING_LOAN: 'LS_APPLY_DELETE_EXISTING_LOAN',
	LS_APPLY_UPDATE_REFINANCE_LOANS: 'LS_APPLY_UPDATE_REFINANCE_LOANS',
	LS_APPLY_CHECK_APPLY_AVAILABLE: 'LS_APPLY_CHECK_APPLY_AVAILABLE',
	LS_APPLY_LOAD_REUSE_MATERIAL: 'LS_APPLY_LOAD_REUSE_MATERIAL',

	LS_LOAN_INIT: 'LS_LOAN_INIT',
	LS_LOAN_RESET_ERROR: 'LS_LOAN_RESET_ERROR',
	LS_LOAN_CREATE_LOAN: 'LS_LOAN_CREATE_LOAN',
	LS_LOAN_CANCEL: 'LS_LOAN_CANCEL',
	LS_LOAN_CANCEL_DIFFERENT_FLOW: 'LS_LOAN_CANCEL_DIFFERENT_FLOW',
	LS_LOAN_REJECT_AFFILIATE: 'LS_LOAN_REJECT_AFFILIATE',
	LS_LOAN_GET_LOAN_STATUS: 'LS_LOAN_GET_LOAN_STATUS',
	LS_LOAN_ADDITIONAL_DOCS_AUTHENTICATE: 'LS_LOAN_ADDITIONAL_DOCS_AUTHENTICATE',
	LS_LOAN_ADDITIONAL_DOCS_SUBMIT: 'LS_LOAN_ADDITIONAL_DOCS_SUBMIT',

	LS_LOAN_CHECK_PRE_SCREENING_IN_PROGRESS: 'LS_LOAN_CHECK_PRE_SCREENING_IN_PROGRESS',
	LS_LOAN_LOAD_LOAN: 'LS_LOAN_LOAD_LOAN',
	LS_LOAN_LOAD_COMPARE_RESULT: 'LS_LOAN_LOAD_COMPARE_RESULT',
	LS_LOAN_LOAD_LOAN_WITH_FPA: 'LS_LOAN_LOAD_LOAN_WITH_FPA',
	LS_LOAN_CHECK_HAS_LOANS: 'LS_LOAN_CHECK_HAS_LOANS',

	LS_FINANCIAL_PRODUCT_INIT: 'LS_FINANCIAL_PRODUCT_INIT',
	LS_FINANCIAL_PRODUCT_RESET_ERROR: 'LS_FINANCIAL_PRODUCT_RESET_ERROR',
	LS_FINANCIAL_PRODUCT_GET_COMMISSION_DETAIL: 'LS_FINANCIAL_PRODUCT_GET_COMMISSION_DETAIL',
	LS_FINANCIAL_PRODUCT_SET_DETAIL: 'LS_FINANCIAL_PRODUCT_SET_DETAIL',

	LS_FINANCIAL_PRODUCT_APPLICATION_INIT: 'LS_FINANCIAL_PRODUCT_APPLICATION_INIT',
	LS_FINANCIAL_PRODUCT_APPLICATION_APPLY: 'LS_FINANCIAL_PRODUCT_APPLICATION_APPLY',
	LS_FINANCIAL_PRODUCT_APPLICATION_RESET_APPLY_COMPLETED: 'LS_FINANCIAL_PRODUCT_APPLICATION_RESET_APPLY_COMPLETED',
	LS_FINANCIAL_PRODUCT_APPLICATION_RESET_ERROR: 'LS_FINANCIAL_PRODUCT_APPLICATION_RESET_ERROR',

	UNSECURED_INVESTOR_REGISTRATION_INIT: 'UNSECURED_INVESTOR_REGISTRATION_INIT',
	UNSECURED_INVESTOR_REGISTRATION_SEND: 'UNSECURED_INVESTOR_REGISTRATION_SEND',
	UNSECURED_INVESTOR_REGISTRATION_ERROR: 'UNSECURED_INVESTOR_REGISTRATION_ERROR',
	UNSECURED_INVESTOR_REGISTRATION_RESET_ERROR: 'UNSECURED_INVESTOR_REGISTRATION_RESET_ERROR',
});
