import { LSFinancialProductApplicationStatus } from '@peoplefund/constants/ls-loan-status';
import { CommonFinancialProductApplication, FinancialProduct } from '@peoplefund/constants/ls-loan-types';
import { MLLoan } from '@peoplefund/slices/ml-loan';

export type MortgageLoanApplication = Pick<
	MLLoan['la']['data'],
	'id' | 'status' | 'expireTime' | 'hasPfloanInLSProgress'
>;

export type FPAForMortgageLoan = Omit<
	CommonFinancialProductApplication,
	| 'financialProductId'
	| 'integratedInterestRate'
	| 'applyCompleted'
	| 'processType'
	| 'hasSpecialInterestRate'
	| 'applyInProcess'
	| 'error'
	| 'badges'
	| 'interestRateDiscount'
> & {
	loanTerm: number;
	repaymentType: string;
	loanProduct: Omit<FinancialProduct, 'processType' | 'contractInfo'>;
	tooltipContent: {
		commissionRate: string[];
		conditionChange: string[];
	};
	rejectReason: string;
	isAdjustedForAmount: boolean;
	nextPath: string /** 우선 론샷상품에서만 사용. 다음 이동할 화면 */;
};

export const initFPAForMortgageLoan: FPAForMortgageLoan = {
	id: '',
	amount: 0,
	maxAmount: 0,
	commissionRate: 0,
	interestRate: 0,
	loanTerm: 0,
	repaymentType: '',
	loanProduct: {
		id: '',
		name: '',
		code: '',
		institution: {
			logoUrl: '',
			name: '',
		},
		information: [],
	},
	tooltipContent: {
		commissionRate: [],
		conditionChange: [],
	},
	notification: null,
	status: LSFinancialProductApplicationStatus.UNKNOWN,
	rejectReason: '',
	isAdjustedForAmount: false,
	pfloan: false,
	// ---- 서버에서 주지 않지만, 추가 가공 데이터 ----
	nextPath: '',
};

export interface PropertyServerProps {
	real_estate_no: string;
	dong: string | null;
	ho: string | null;
	building_name: string;
}

export type SearchAddressResponse = {
	result: {
		address: {
			kb_land_name: string;
			kb_land_no: number;
			kb_jibun_address_name: string;
			kb_road_address_name: string;
		};
		highlight: string[];
	}[];
};

export type SearchDetailAddressResponse = {
	result: {
		dong: string;
		ho: { ho: number; real_estate_no: string }[];
	}[];
};

export interface InProgressServerProps {
	// FYI. 우선 필요한 것만 가져왔습니다.
	loan_application: {
		id: string;
		is_pf_only: boolean;
		lpa_in_progress?: {
			id: string;
		};
		pre_screening_in_progress: boolean;
		type: 'mortgage' | 'personal';
		expire: string | null;
	};
}

export type LoanStatusResult = {
	la: MortgageLoanApplication;
	fpa: {
		id: string;
	};
};

export type LoanStatusResponse = {
	inProgressRes: InProgressServerProps;
	nextStepRes?: {
		next_step: string;
	};
};

export type TenantLeaveInfoApplyProps = {
	lesseeMoveOutDueDate: string;
	isMonthlyRentExists: boolean;
};
