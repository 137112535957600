const BaseUrl = `/loans/compare-mortgage-loans`;

const LoansMortgageLoansUrls = {
	AGREEMENTS: `${BaseUrl}/agreements`,
	SMS_SEND: `${BaseUrl}/sms-verify`,
	PURPOSE: `${BaseUrl}/purpose`,
	AMOUNT: `${BaseUrl}/amount`,
	JOB_SELECT: `${BaseUrl}/job-select`,
	JOB_SALARY: `${BaseUrl}/job-salary`,
	PROPERTY_SELECT: `${BaseUrl}/select`,
	ADDRESS_SEARCH: `${BaseUrl}/search`,
	ADDRESS_DONG_SEARCH: `${BaseUrl}/search-dong`,
	ADDRESS_HO_SEARCH: `${BaseUrl}/search-ho`,
	ADDRESS_SEARCH_FAIL: `${BaseUrl}/search-fail`,
	ADDRESS_CONFIRM: `${BaseUrl}/address-confirm`,
	TENANT_CONFIRM: `${BaseUrl}/tenant-confirm`,
	INQUIRE: `${BaseUrl}/inquire`,
	RESULT: `${BaseUrl}/result`,
	PRODUCT: `${BaseUrl}/product`,
	BRIDGE: `${BaseUrl}/bridge`,
	APP_DOWNLOAD: `${BaseUrl}/app-download`,
	REJECT: `${BaseUrl}/reject`,
	/* 주담대론샷 only */
	BUSINESS_NUMBER: `${BaseUrl}/business-number`,
} as const;

export default LoansMortgageLoansUrls;
