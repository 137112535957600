import { EncryptPasswordInfoType } from '@peoplefund/components/authentication/common/EncryptPasswordInput';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { WithNetworkStatus } from '@peoplefund/reducers';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

const SLICE_NAME = 'authentication';
type FETCH_STATUS = 'idle' | 'loading' | 'success' | 'fail';

export interface CompletedInformation {
	accountInvestBankName: string;
	accountInvestNumFormatted: string;
	accountInvestHolder: string;
}

export interface Authentication {
	/** 이메일 찾기 페이지 */
	foundEmail: {
		userEmails: string[];
		hasUserId: boolean;
		status: FETCH_STATUS;
	};
	/** 비밀번호 찾기 (초기화) 페이지 */
	initPassword: {
		status: FETCH_STATUS;
	};
	/** 비밀번호 변경 페이지 */
	changePassword: {
		status: FETCH_STATUS;
	};
	/** 비밀번호 설정 페이지 */
	setPassword: {
		status: FETCH_STATUS;
	};
	/** 임시 비밀번호 변경 대상자 여부 */
	checkTemporaryPasswordUsed: {
		temporaryPasswordUsed: boolean;
		status: FETCH_STATUS;
	};
	/** 외부 유저 여부 */
	checkExternalUser: {
		integrationUser: boolean;
		status: FETCH_STATUS;
	};
	marketingAgreementConfig: {
		sms: boolean;
		email: boolean;
		updatedDateTime: string;
		status: FETCH_STATUS;
	};
	/** 마케팅 설정 변경 */
	changeMarketingAgreementConfig: {
		status: FETCH_STATUS;
	};
	/** 탈퇴 확인 */
	checkResignable: {
		resignable: boolean;
		status: FETCH_STATUS;
	};
	/** 탈퇴 */
	resign: {
		status: FETCH_STATUS;
	};
	/** 이메일 수정 */
	setEmail: {
		status: FETCH_STATUS;
	};
	/** 비밀번호 확인 */
	verifyPassword: {
		status: FETCH_STATUS;
	};
	/** 핸드폰 번호 변경 */
	changePhoneNumber: {
		status: FETCH_STATUS;
	};
	signupForInvesting: {
		email: string;
		password: EncryptPasswordInfoType;
		promotionCode: string;
		agreed: boolean;
		code: string;
		agreements: string[];
		disagreements: string[];
		status: FETCH_STATUS;
	};
	pinVerify: {
		status: 'idle' | 'verified' | 'error';
	};
	pinRegister: {
		status: 'idle' | 'verified' | 'error';
	};
	corporateSignup: {
		agentInfo: {
			name: string;
			phoneNumber: string;
			representativePhoneNumber: string;
			email: string;
		};
		code: string;
		agreements: string[];
		disagreements: string[];
		status: FETCH_STATUS;
	};
	error?: AlertCommonError;
}

export const authenticationInitialState: Authentication = {
	foundEmail: {
		userEmails: [],
		hasUserId: false,
		status: 'idle',
	},
	initPassword: {
		status: 'idle',
	},
	changePassword: {
		status: 'idle',
	},
	setPassword: {
		status: 'idle',
	},
	checkTemporaryPasswordUsed: {
		temporaryPasswordUsed: false,
		status: 'idle',
	},
	checkExternalUser: {
		integrationUser: false,
		status: 'idle',
	},
	marketingAgreementConfig: {
		sms: false,
		email: false,
		updatedDateTime: '',
		status: 'idle',
	},
	changeMarketingAgreementConfig: {
		status: 'idle',
	},
	checkResignable: {
		resignable: false,
		status: 'idle',
	},
	resign: {
		status: 'idle',
	},
	setEmail: {
		status: 'idle',
	},
	verifyPassword: {
		status: 'idle',
	},
	changePhoneNumber: {
		status: 'idle',
	},
	signupForInvesting: {
		email: '',
		password: { encrypted: false, value: '' },
		promotionCode: '',
		agreed: false,
		code: '',
		agreements: [],
		disagreements: [],
		status: 'idle',
	},
	corporateSignup: {
		agentInfo: {
			name: '',
			phoneNumber: '',
			representativePhoneNumber: '',
			email: '',
		},
		code: '',
		agreements: [],
		disagreements: [],
		status: 'idle',
	},
	pinVerify: {
		status: 'idle',
	},
	pinRegister: {
		status: 'idle',
	},
};

type CorporateSignUp = Authentication['corporateSignup'];

export const findEmail = createAction<{ name: string; mobileNumber: string }>(`${SLICE_NAME}/findEmail`);
export const initPassword = createAction<{ email: string }>(`${SLICE_NAME}/initPassword`);
export const changePasswordAction = createAction<{ password: string; newPassword: string }>(
	`${SLICE_NAME}/changePasswordAction`
);
export const setPasswordAction = createAction<{ password: string }>(`${SLICE_NAME}/setPasswordAction`);
export const checkExternalUser = createAction(`${SLICE_NAME}/checkExternalUser`);
export const verifyPasswordAction = createAction<{ password: string }>(`${SLICE_NAME}/verifyPasswordAction`);
export const changePhoneNumberAction = createAction<{
	phoneNumber: string;
	transactionNumber: string;
	verificationCode: string;
}>(`${SLICE_NAME}/changePhoneNumberAction`);
export const signupForInvestingAction = createAction<{
	email: string;
	password: string;
	promotionCode: string;
	phoneNumber: string;
	transactionNumber: string;
	verificationCode: string;
	agreed: boolean;
	agreements: string[];
	disagreements: string[];
}>(`${SLICE_NAME}/signupForInvestingAction`);

export const setLoginInfoAction = createAction<{
	readonly email: string;
	readonly password: string;
	readonly promotionCode?: string;
}>(`${SLICE_NAME}/setLoginInfoAction`);
export const checkResignableAction = createAction(`${SLICE_NAME}/checkResignableAction`);
export const requestPinVerify = createAction<string>(`${SLICE_NAME}/requestPinVerify`);
export const requestPinRegister = createAction<string>(`${SLICE_NAME}/requestPinRegister`);
export const corporateSignupAction = createAction<{ email: string; password: string }>(
	`${SLICE_NAME}/corporateSignupAction`
);
export const finishCompletedInformationAction = createAction(`${SLICE_NAME}/finishCompletedInformationAction`);

const authenticationSlice = createSlice({
	name: SLICE_NAME,
	initialState: authenticationInitialState,
	reducers: {
		initStateAll: (state) => Object.assign(state, authenticationInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setFoundEmail: (
			state,
			action: PayloadAction<{ userEmails: string[]; hasUserId: boolean; status: FETCH_STATUS }>
		) => {
			const { userEmails, hasUserId, status } = action.payload;
			state.foundEmail.userEmails = userEmails;
			state.foundEmail.hasUserId = hasUserId;
			state.foundEmail.status = status;
		},
		getInitializingPasswordResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			state.initPassword.status = action.payload.status;
		},
		getChangingPasswordResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			state.changePassword.status = action.payload.status;
		},
		getSettingPasswordResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			state.setPassword.status = action.payload.status;
		},
		getCheckingExternalUserResult: (
			state,
			action: PayloadAction<{ integrationUser: boolean; status: FETCH_STATUS }>
		) => {
			const { integrationUser, status } = action.payload;
			state.checkExternalUser.integrationUser = integrationUser;
			state.checkExternalUser.status = status;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startMarketingAgreementConfig: (state) => {
			state.marketingAgreementConfig.status = 'loading';
		},
		getMarketingAgreementConfigResult: (
			state,
			action: PayloadAction<{ sms: boolean; email: boolean; updatedDateTime: string; status: FETCH_STATUS }>
		) => {
			const { sms, email, status, updatedDateTime } = action.payload;

			state.marketingAgreementConfig.sms = sms;
			state.marketingAgreementConfig.email = email;
			state.marketingAgreementConfig.status = status;
			state.marketingAgreementConfig.updatedDateTime = updatedDateTime;
		},
		startChangingMarketingAgreementConfig: (
			state,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_: PayloadAction<{ smsAgreement: boolean; emailAgreement: boolean }>
		) => {
			state.changeMarketingAgreementConfig.status = 'loading';
		},
		getChangingMarketingAgreementConfigResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.changeMarketingAgreementConfig.status = status;
		},
		startResigning: (state) => {
			state.resign.status = 'loading';
		},
		getResigningResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.resign.status = status;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		startSettingEmail: (state, _: PayloadAction<{ email: string }>) => {
			state.setEmail.status = 'loading';
		},
		getSettingEmailResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.setEmail.status = status;
		},
		getVerifyingPasswordResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.verifyPassword.status = status;
		},
		getChangingPhoneNumberResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.changePhoneNumber.status = status;
		},
		setSignupForInvestingParamsAction: (
			state,
			action: PayloadAction<{
				email?: string;
				password?: EncryptPasswordInfoType;
				promotionCode?: string;
				agreed?: boolean;
				code?: string;
				agreements?: string[];
				disagreements?: string[];
			}>
		) => {
			const { email, password, promotionCode, code, agreed, agreements, disagreements } = action.payload;
			const {
				email: currentEmail,
				password: currentPassword,
				promotionCode: currentPromotionCode,
				agreed: currentAgreed,
				agreements: currentAgreements,
				disagreements: currentDisagreements,
				code: currentAgreeCode,
			} = state.signupForInvesting;

			state.signupForInvesting.email = email ?? currentEmail;
			state.signupForInvesting.password = password ?? currentPassword;
			state.signupForInvesting.promotionCode = promotionCode ?? currentPromotionCode;
			state.signupForInvesting.agreed = agreed ?? currentAgreed;
			state.signupForInvesting.code = code ?? currentAgreeCode;
			state.signupForInvesting.agreements = agreements ?? currentAgreements;
			state.signupForInvesting.disagreements = disagreements ?? currentDisagreements;
		},
		getSignupForInvestingResult: (state, action: PayloadAction<{ status: FETCH_STATUS }>) => {
			const { status } = action.payload;
			state.signupForInvesting.status = status;
		},
		getCheckResignableResult: (state, action: PayloadAction<{ resignable: boolean; status: FETCH_STATUS }>) => {
			const { status, resignable } = action.payload;
			state.checkResignable.resignable = resignable;
			state.checkResignable.status = status;
		},
		getTemporaryPasswordUsedResult: (
			state,
			action: PayloadAction<{ temporaryPasswordUsed: boolean; status: FETCH_STATUS }>
		) => {
			state.checkTemporaryPasswordUsed.temporaryPasswordUsed = action.payload.temporaryPasswordUsed;
			state.checkTemporaryPasswordUsed.status = action.payload.status;
		},

		fetchCompletedInformation: (
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			_,
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			__: PayloadAction<{ action: (props: WithNetworkStatus<CompletedInformation>) => void }>
		) => {
			return;
		},
		setPinVerifyResult: (state, action: PayloadAction<{ status: Authentication['pinVerify']['status'] }>) => {
			state.pinVerify.status = action.payload.status;
		},
		setPinRegisterResult: (state, action: PayloadAction<{ status: Authentication['pinRegister']['status'] }>) => {
			state.pinRegister.status = action.payload.status;
		},
		setCorporateSignupParametersAction: (state, action: PayloadAction<Partial<CorporateSignUp>>) => {
			const { agentInfo, code, agreements, disagreements } = action.payload;

			const {
				agentInfo: {
					name: currentName,
					phoneNumber: currentPhoneNumber,
					representativePhoneNumber: currentRepresentativePhoneNumber,
					email: currentAgentEmail,
				},
				code: currentCode,
				agreements: currentAgreements,
				disagreements: currentDisagreements,
			} = state.corporateSignup;

			state.corporateSignup.agentInfo.email = agentInfo?.email ?? currentAgentEmail;
			state.corporateSignup.agentInfo.name = agentInfo?.name ?? currentName;
			state.corporateSignup.agentInfo.phoneNumber = agentInfo?.phoneNumber ?? currentPhoneNumber;
			state.corporateSignup.agentInfo.representativePhoneNumber =
				agentInfo?.representativePhoneNumber ?? currentRepresentativePhoneNumber;
			state.corporateSignup.code = code ?? currentCode;
			state.corporateSignup.agreements = agreements ?? currentAgreements;
			state.corporateSignup.disagreements = disagreements ?? currentDisagreements;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(findEmail, (state) => {
				state.foundEmail.userEmails = [];
				state.foundEmail.hasUserId = false;
				state.foundEmail.status = 'loading';
			})
			.addCase(initPassword, (state) => {
				state.initPassword.status = 'loading';
			})
			.addCase(changePasswordAction, (state) => {
				state.changePassword.status = 'loading';
			})
			.addCase(setPasswordAction, (state) => {
				state.setPassword.status = 'loading';
			})
			.addCase(checkExternalUser, (state) => {
				state.checkExternalUser.status = 'loading';
			})
			.addCase(verifyPasswordAction, (state) => {
				state.verifyPassword.status = 'loading';
			})
			.addCase(signupForInvestingAction, (state) => {
				state.signupForInvesting.status = 'loading';
			})
			.addCase(checkResignableAction, (state) => {
				state.checkResignable.status = 'loading';
			})
			.addCase(requestPinVerify, (state) => {
				state.pinVerify.status = 'idle';
			})
			.addCase(corporateSignupAction, (state) => {
				state.corporateSignup.status = 'loading';
			})
			.addCase(finishCompletedInformationAction, () => {
				return;
			});
	},
});

export const {
	initStateAll,
	setError,
	resetError,
	setFoundEmail,
	startMarketingAgreementConfig,
	getMarketingAgreementConfigResult,
	startChangingMarketingAgreementConfig,
	getChangingMarketingAgreementConfigResult,
	startResigning,
	getResigningResult,
	startSettingEmail,
	getSettingEmailResult,
	getInitializingPasswordResult,
	getChangingPasswordResult,
	getSettingPasswordResult,
	getCheckingExternalUserResult,
	getVerifyingPasswordResult,
	getChangingPhoneNumberResult,
	fetchCompletedInformation,
	setSignupForInvestingParamsAction,
	getSignupForInvestingResult,
	getCheckResignableResult,
	getTemporaryPasswordUsedResult,
	setPinVerifyResult,
	setPinRegisterResult,
	setCorporateSignupParametersAction,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
