import { actions } from '@peoplefund/actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { ofAction } from '@peoplefund/utils/redux.util';
import { CometEpic } from '@peoplefund/epics/constants.util';
import { covertFPCommissionDetailResult } from './ls-financial-product.util';
import { convertInAPICommonErrorToAlertCommonError } from '@peoplefund/constants/error/type';

const getFinancialProductCommissionDetail: CometEpic = (action$, _state$, { cometAjax }) =>
	action$.pipe(
		ofAction(actions.lsFinancialProduct.getFinancialProductCommissionDetail.started),
		mergeMap(({ payload }) =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.loan.get(`/v1/financial_products/info?code=${payload}`).pipe(
					map((response) => {
						return actions.lsFinancialProduct.getFinancialProductCommissionDetail.done({
							params: payload,
							result: covertFPCommissionDetailResult(response),
						});
					}),
					catchError((error) => {
						return of(
							actions.lsFinancialProduct.getFinancialProductCommissionDetail.failed({
								params: payload,
								error: convertInAPICommonErrorToAlertCommonError(error),
							})
						);
					})
				),
				of(actions.layout.endLoading())
			)
		)
	);

export default [getFinancialProductCommissionDetail];
