import React from 'react';
import CardHome, { CardHomeProps, HomeBannerServerResponse } from '@peoplefund/components/appview/card/home';
import { GetServerSideProps } from 'next';
import { SignInResult } from '@peoplefund/actions/account';
import SessionId from '@peoplefund/constants/session-id';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios, { AxiosResponse } from 'axios';
dayjs.extend(utc);
dayjs.extend(timezone);
export default function (props: CardHomeProps) {
  return <CardHome {...props} />;
}
export const getAccessTokenFromCookies = (cookies: Partial<{
  [key: string]: string;
}>) => {
  const signInResult: SignInResult = JSON.parse(cookies[SessionId.PHOENIX_SESSION_ID] ?? '{}') as SignInResult;
  if (signInResult?.accessToken && signInResult?.refreshToken) {
    const {
      accessToken,
      refreshToken
    } = signInResult;
    return {
      expiredAt: signInResult?.expiredAt ?? '',
      accessToken,
      refreshToken
    };
  }
  return {
    expiredAt: signInResult?.expiredAt ?? '',
    accessToken: '',
    refreshToken: ''
  };
};
export const getServerSideProps: GetServerSideProps = async ({
  req
}) => {
  const {
    cookies
  } = req;
  const {
    accessToken: token
  } = getAccessTokenFromCookies(cookies);
  let bannerInformation: CardHomeProps['bannerInformation'] = null;
  let hasError: CardHomeProps['hasError'] = false;
  await axios // fyi. 현재 apiClient는 서버사이드렌더링에서 사용할 수 없어서 임의로 axios 모듈 자체를 불러옴
  .get(`${process.env.NEXT_PUBLIC_MILLION_SERVER_URL}/api/v1/home/banners`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then((res: AxiosResponse<HomeBannerServerResponse>) => {
    const {
      items: itemsFromServer
    } = res.data;
    bannerInformation = itemsFromServer.length ? itemsFromServer.map(item => {
      const {
        key,
        type,
        content,
        button,
        image,
        condition
      } = item;
      const {
        title,
        sub_title,
        overline,
        caption,
        deeplink
      } = content;
      return {
        key,
        type,
        content: {
          title,
          subTitle: sub_title,
          overline,
          caption,
          deeplink: deeplink ?? null
        },
        image,
        button,
        condition: condition ? {
          openTime: condition.open_time,
          fieldName: condition.field_name
        } : null
      };
    })[0] : null;
  }).catch(() => {
    hasError = true;
  });
  const now = dayjs().tz('Asia/Seoul');
  const condition = (bannerInformation as CardHomeProps['bannerInformation'])?.condition;
  const [hour, minute, second] = condition ? condition.openTime.split(':').map(Number) : [0, 0, 0];
  const targetTime = dayjs().tz('Asia/Seoul').hour(hour).minute(minute).second(second);
  const diffInSeconds = targetTime.diff(now, 'second');
  const isOverTime = diffInSeconds <= 0;
  return {
    props: {
      hasError,
      bannerInformation,
      isOverTime
    } // 페이지 컴포넌트에 props로 전달됩니다
  };
};