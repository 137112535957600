import SessionId from '@peoplefund/constants/session-id';
import { CookieStorage } from '../marketing-script/Storage';
import { SignInResult } from '@peoplefund/actions/account';

const KEY_IS_WEB_VIEW = 'isWebview';
const User_Agent = 'User-Agent';

export const KEY_PF_CLIENT_ID = 'Pf-Client-ID';
export const KEY_PFCT_DEVICE_ID = 'Pfct-Device-ID';

const PFClientIDMap = {
	Million: 'Million',
	Peoplefund: 'Peoplefund',
	PFWeb: 'PFWeb',
};
const appVersionRegex = /\b\w+\.\w+\.\w+\b/;

export type AppType = keyof typeof PFClientIDMap;
export const cookieParser = (
	cookieStorage: CookieStorage
): {
	isWebview: boolean;
	appType: AppType;
	appVersion: string;
	pfctDeviceId: string;
	tokens?: SignInResult;
} => {
	const isWebview = Boolean(cookieStorage.get(KEY_IS_WEB_VIEW));
	const userAgent: string = cookieStorage.get(User_Agent) ?? '';

	const pfClientId = cookieStorage.get(KEY_PF_CLIENT_ID);
	const pfctDeviceId: string = cookieStorage.get(KEY_PFCT_DEVICE_ID) ?? '';
	const appType = pfClientId ? pfClientId : PFClientIDMap['PFWeb'];

	const matches = userAgent.match(appVersionRegex) ?? [''];
	const appVersion = matches[0];

	let tokens: SignInResult | undefined = undefined;
	if (cookieStorage.get(SessionId.PHOENIX_SESSION_ID)) {
		tokens = cookieStorage.get(SessionId.PHOENIX_SESSION_ID) as SignInResult;
	}
	return {
		isWebview,
		appType,
		appVersion,
		pfctDeviceId,
		tokens,
	};
};
