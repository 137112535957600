import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { MLLoanApplyMaterial } from '@peoplefund/slices/ml-loan.model';
import { InProgressServerProps, LoanStatusResponse, LoanStatusResult } from './ml-loan.model';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import { convertMortgagePFInLSProgress } from './ml-ls.util';
import PageUrls from '@peoplefund/constants/page-urls';
import { CometAjax } from '@peoplefund/epics/ajax.util';
import { map, Observable, of } from 'rxjs';
import { AlertCommonError, InAPICommonError } from '@peoplefund/constants/error/type';
import { mergeMap } from 'rxjs/operators';

export const hasMortgageLPA = (la?: InProgressServerProps['loan_application']): boolean => {
	// 논의 내용 : https://pfcoworkspace.slack.com/archives/C0248UU6XU6/p1662623075819969?thread_ts=1662609788.931209&cid=C0248UU6XU6
	// 개신대 대출 있어도 별개로 신청 가능 거절의 경우 새로 신청받도록

	return la?.type === 'mortgage';
};
export const getFpaIdFromInProgress = (res: InProgressServerProps) => {
	if (res?.loan_application?.lpa_in_progress?.id) {
		return res?.loan_application?.lpa_in_progress?.id as string;
	} else {
		return '';
	}
};

export const convertPreScreeningStatus = (response: { status: string }): LSLoanStatus => {
	const { status } = response;
	switch (status) {
		case 'created':
			return LSLoanStatus.CREATED;
		case 'started':
			return LSLoanStatus.IN_PROGRESS_PRE_EVALUATION;
		case 'finished':
			return LSLoanStatus.DONE_PRE_EVALUATION;
		default:
			return LSLoanStatus.UNKNOWN;
	}
};

export const checkPartnerFlowAvailable = (
	cometAjax: CometAjax<any>,
	token: string
): Observable<MLLoanApplyMaterial> => {
	const checkLogin = (token: string): Observable<null> => {
		if (!token) {
			throw new InAPICommonError('로그인을 해주세요', PhoenixErrorCode.LOGIN_REQUIRED, {
				nextPath: PageUrls.mortgageLoans.PARTNERS_VERIFY,
			});
		}

		return of(null);
	};

	const getApplyMaterial = (token: string): Observable<MLLoanApplyMaterial> => {
		return cometAjax.loan.get('/v1/mortgage-loan/pre-screening-material', { token }).pipe(
			map((response) => {
				if (!response.pre_screening_material) {
					throw new InAPICommonError('진행중인 제휴사 대출 상품이 없어요', PhoenixErrorCode.EMPTY_APPLY_MATERIAL, {
						nextPath: PageUrls.mortgageLoans.INTRO,
					});
				}

				const material = response.pre_screening_material;
				return {
					platformCode: material.platform,
					annualIncome: material.annual_income,
					loanPurpose: material.loan_purpose,
					jobCode: material.job_code,
					amount: material.applied_amount,
				};
			})
		);
	};

	const getInProgressLPA = (token: string): Observable<string> => {
		return cometAjax.loan.get('/v1/loan-applications/in-progress', { token }).pipe(
			mergeMap((response: InProgressServerProps) => {
				const { loan_application } = response;
				const hasInProgressLPA = hasMortgageLPA(loan_application);
				const fpaId = getFpaIdFromInProgress(response);
				if (hasInProgressLPA && fpaId) {
					const nextPath = loan_application.is_pf_only
						? `${PageUrls.mortgageLoans.PRODUCT}/${fpaId}`
						: `${PageUrls.loansCompareMortgage.PRODUCT}/${fpaId}`;

					throw new InAPICommonError('진행중인 대출 상품이 있어요', PhoenixErrorCode.HAS_LOAN_APPLICATION_IN_PROGRESS, {
						nextPath,
					});
				}

				return of(getFpaIdFromInProgress(response));
			})
		);
	};

	return checkLogin(token).pipe(
		mergeMap(() => {
			return getInProgressLPA(token).pipe(
				mergeMap(() => {
					return getApplyMaterial(token).pipe(map((material) => material));
				})
			);
		})
	);
};

export const getMortgageLoanStatusWithNextStep = (
	cometAjax: CometAjax<any>,
	token: string
): Observable<LoanStatusResult> => {
	const convertLoanStatusResult = ({ inProgressRes, nextStepRes }: LoanStatusResponse): LoanStatusResult => {
		const la = inProgressRes?.loan_application;
		const nextStep = nextStepRes?.next_step;
		let status: LSLoanStatus = LSLoanStatus.UNKNOWN;

		if (!hasMortgageLPA(la)) {
			return {
				la: {
					id: '',
					status,
					expireTime: '',
					hasPfloanInLSProgress: false,
				},
				fpa: {
					id: '',
				},
			};
		}

		if (!la.is_pf_only) {
			throw new AlertCommonError(
				'취소하고 AI대출비교 서비스를 이용하시겠어요?',
				PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW,
				'진행중인 대출이 있어요!',
				{
					nextPath: convertMortgagePFInLSProgress(inProgressRes)
						? PageUrls.loansCompareMortgage.APP_DOWNLOAD
						: PageUrls.loansCompareMortgage.RESULT,
					laId: la.id || '',
				}
			);
		}

		if (la.pre_screening_in_progress) {
			status = LSLoanStatus.IN_PROGRESS_PRE_EVALUATION;
		} else if (Boolean(la.lpa_in_progress) && Boolean(nextStep)) {
			if (nextStep === 'step.mortgage.pre_evaluation.approved') {
				status = LSLoanStatus.DONE_PRE_EVALUATION;
			} else {
				status = LSLoanStatus.IN_PROGRESS_EVALUATION;
			}
		}

		return {
			la: {
				id: la.id,
				status,
				expireTime: '',
				hasPfloanInLSProgress: convertMortgagePFInLSProgress(inProgressRes),
			},
			fpa: {
				id: la?.lpa_in_progress?.id || '',
			},
		};
	};

	return cometAjax.loan.get('/v1/loan-applications/in-progress', { token }).pipe(
		mergeMap((inProgressRes) => {
			const fpaId = getFpaIdFromInProgress(inProgressRes);
			if (fpaId) {
				return cometAjax.loan.get(`/v2/loan-product-applications/${fpaId}/next-step`, { token }).pipe(
					map((nextStepRes) =>
						convertLoanStatusResult({
							inProgressRes,
							nextStepRes,
						})
					)
				);
			} else {
				return of(convertLoanStatusResult({ inProgressRes }));
			}
		})
	);
};

export const checkLPAIsInProgress = ({
	laStatus,
	fpaId,
}: {
	laStatus: LSLoanStatus;
	fpaId: string;
}): AlertCommonError | undefined => {
	switch (laStatus) {
		case LSLoanStatus.IN_PROGRESS_PRE_EVALUATION: {
			return new AlertCommonError('', '', '신청중인 대출이 있어요\n이어서 계속할까요?', {
				nextPath: PageUrls.mortgageLoans.INQUIRE,
				confirmText: '계속하기',
			});
		}
		case LSLoanStatus.DONE_PRE_EVALUATION: {
			return new AlertCommonError('', '', '신청중인 대출이 있어요\n이어서 계속할까요?', {
				nextPath: `${PageUrls.mortgageLoans.PRODUCT}/${fpaId}`,
				confirmText: '계속하기',
			});
		}
		case LSLoanStatus.IN_PROGRESS_EVALUATION: {
			return new AlertCommonError('', '', '신청중인 대출이 있어요\n앱에서 이어서 진행해주세요', {
				nextPath: PageUrls.mortgageLoans.APP_DOWNLOAD,
				confirmText: '이어서 계속하기',
			});
		}
		default:
			return undefined;
	}
};
