export type TokenPayload = { readonly accessToken: string; readonly refreshToken: string };

export default abstract class TokenStrategy<ApiClientType, ApiResponseType> {
	public defaultAccessToken?: string;
	public abstract getAccessToken(): string | undefined;
	public abstract getRefreshToken(): string | undefined;
	protected abstract setToken(payload: TokenPayload): void;
	protected abstract resetToken(): void;
	public abstract refreshAccessToken(apiClient: ApiClientType): Promise<ApiResponseType>;
	public abstract refreshSuccessHandler(payload: TokenPayload): void;
	public abstract refreshFailHandler(): void;

	constructor(defaultAccessToken?: string) {
		this.defaultAccessToken = defaultAccessToken;

		this.getAccessToken = this.getAccessToken.bind(this);
		this.getRefreshToken = this.getRefreshToken.bind(this);
		this.setToken = this.setToken.bind(this);
		this.resetToken = this.resetToken.bind(this);
		this.refreshAccessToken = this.refreshAccessToken.bind(this);
		this.refreshSuccessHandler = this.refreshSuccessHandler.bind(this);
		this.refreshFailHandler = this.refreshFailHandler.bind(this);
	}
}
