import { catchError, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import {
	applyMortgageLSLPA,
	checkMortgageLSPreScreeingStatus,
	checkValidBusinessNumber,
	createMortgageLS,
	getMortgageLSFPA,
	getMortgageLSFPAList,
	getMortgageLSStatus,
	getNextPath,
	setError,
	setFPAList,
	setFPAListFetchStatusSuccess,
	setFPANextPath,
	setMortgageFPA,
	setMortgageLoanApplicationLoadingStatus,
	setMortgageLoanApplications,
	setMortgageLoanApplicationStatus,
	setValidBusinessNumber,
} from '@peoplefund/slices/ml-loan';
import { CometEpic } from './constants.util';
import { concat, EMPTY, map, of, zip } from 'rxjs';
import { actions } from '@peoplefund/actions';
import {
	convertFailedFPAMortgageLSResponse,
	convertFinancialProductResult,
	convertFPAMortgageLSResponse,
	convertNextStepResponse,
} from './ml-ls.util'; /* 주담대 론샷용 */
import { FailedFPAForMortgageLS } from './ml-ls.model';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import UtmFactory from '@peoplefund/utils/marketing-script/UtmModule';
import { CometAjax } from './ajax.util';
import PageUrls from '@peoplefund/constants/page-urls';
import { LOAN_PURPOSE_HOUSE } from '@peoplefund/constants/ls-select-options';
import { Action } from 'redux';
import StatusManager from '@peoplefund/utils/ls-status.util';
import { InProgressServerProps, MortgageLoanApplication } from '@peoplefund/epics/ml-loan.model';
import { hasMortgageLPA } from '@peoplefund/epics/ml-loan.util';
import { FlowType } from '@peoplefund/constants/ls-loan-types';

// 불리는 쪽에서 에러 처리
const getMortgageLoanStatus = (cometAjax: CometAjax<any>, token: string) =>
	cometAjax.loan.get('/v1/loan-applications/in-progress', { token }).pipe(
		mergeMap((inProgressRes) => {
			const convertLoanStatus = ({
				inProgressRes,
			}: {
				inProgressRes: InProgressServerProps;
			}): MortgageLoanApplication => {
				const la = inProgressRes?.loan_application;
				let status: LSLoanStatus = LSLoanStatus.UNKNOWN;

				if (!hasMortgageLPA(la)) {
					// 논의 내용 : https://pfcoworkspace.slack.com/archives/C0248UU6XU6/p1662623075819969?thread_ts=1662609788.931209&cid=C0248UU6XU6
					// 개신대 대출 있어도 별개로 신청 가능
					// 거절의 경우 새로 신청받도록
					return { id: '', status, expireTime: '', hasPfloanInLSProgress: false };
				}

				if (la.is_pf_only) {
					throw new Error(PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW, {
						cause: {
							laStatus: status,
							laId: la.id || '',
							fpaId: la?.lpa_in_progress?.id || '',
						},
					});
				}

				const hasPfloanInLSProgress = Boolean(la?.lpa_in_progress?.id);

				if (la.pre_screening_in_progress) {
					status = LSLoanStatus.IN_PROGRESS_PRE_EVALUATION;
				} else if (hasPfloanInLSProgress) {
					status = LSLoanStatus.IN_PROGRESS_EVALUATION; // 론샷가심사 > 신청중 PF 있으면 앱다운로드 유도
				} else {
					// fyi. 그 외 바로 리스트페이지로 보냅니다.
					status = LSLoanStatus.DONE_PRE_EVALUATION;
				}

				return {
					id: la.id,
					status,
					expireTime: la.expire || '',
					hasPfloanInLSProgress,
				};
			};

			return of(
				convertLoanStatus({
					inProgressRes,
				})
			);
		})
	);

const alertNoHasLoan = (mainPath: string) =>
	new AlertCommonError('', '', '신청한 대출이 없습니다', {
		nextPath: mainPath || PageUrls.loansCompare.INTRO,
	});

const createAlert = (laStatus: LSLoanStatus) => {
	switch (laStatus) {
		case LSLoanStatus.IN_PROGRESS_PRE_EVALUATION: {
			return of(
				setError(
					new AlertCommonError('', '', '신청중인 대출이 있어요\n이어서 계속할까요?', {
						nextPath: PageUrls.loansCompareMortgage.INQUIRE,
						confirmText: '계속하기',
					})
				)
			);
		}
		case LSLoanStatus.DONE_PRE_EVALUATION: {
			return of(
				setError(
					new AlertCommonError('', '', '신청중인 대출이 있어요\n이어서 계속할까요?', {
						nextPath: PageUrls.loansCompareMortgage.RESULT,
						confirmText: '계속하기',
					})
				)
			);
		}
		case LSLoanStatus.IN_PROGRESS_EVALUATION: {
			return of(
				setError(
					new AlertCommonError('', '', '신청중인 대출이 있어요\n앱에서 이어서 진행해주세요', {
						nextPath: PageUrls.loansCompareMortgage.APP_DOWNLOAD,
						confirmText: '계속하기',
					})
				)
			);
		}
		default:
			return EMPTY;
	}
};

// fyi. convertLoanStatus 에서 throw Error
const createDifferentFlowAlert = (e: Error) => {
	const errorCause = e.cause as {
		laStatus: LSLoanStatus;
		laId: string;
		fpaId: string;
	};
	if (e.message === PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW) {
		let nextPath: string = PageUrls.mortgageLoans.INQUIRE;
		if (errorCause.fpaId) {
			nextPath = `${PageUrls.mortgageLoans.PRODUCT}/${errorCause.fpaId}`;
		}
		return of(
			setError(
				new AlertCommonError(
					'취소하고 주택담보대출 서비스를 이용하시겠어요?',
					PhoenixErrorCode.HAS_LOAN_APPLICATION_DIFFERENT_FLOW,
					'진행중인 대출이 있어요!',
					{
						nextPath,
						laId: errorCause.laId,
					}
				)
			)
		);
	} else {
		return of(
			setError(
				new AlertCommonError('잠시후 다시 시도해주세요', '', '앗, 문제가 발생했습니다.', {
					nextPath: PageUrls.loansCompare.INTRO,
				})
			)
		);
	}
};

// with alert >> apply 구간에만 사용함 (약관 ~ 주택 확인)
const getMortgageLoanStatusEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(getMortgageLSStatus),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return EMPTY;
				}
				return getMortgageLoanStatus(cometAjax, token).pipe(
					mergeMap((inProgressLoan) => {
						return concat(of(setMortgageLoanApplications(inProgressLoan)), createAlert(inProgressLoan.status)); // getMortgageLoanStatus 재호출하지 않음.
					}),
					catchError((e) => {
						return createDifferentFlowAlert(e);
					})
				);
			}
		)
	);

const applyPreScreeningEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(createMortgageLS),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					mlLoan: {
						apply: {
							property: { id: realEstateNo, resident: isApplicantLiving },
							loanPurpose,
							amount: appliedAmount,
							jobCode,
							annualIncome,
							deposit,
							businessNumber,
							firstHousing,
						},
					},
				},
			]) => {
				const utmModule = UtmFactory.instance;
				const utmload = utmModule?.getUtm() || {};

				const jobOrBusnessNumber = (() => {
					return {
						...(Boolean(businessNumber) && { corporate_business_number: String(businessNumber) }),
						job_code: jobCode,
					};
				})();

				const relatedToHouseFields = (() => {
					if (loanPurpose === LOAN_PURPOSE_HOUSE) {
						return {
							is_first_time_home_buyer: firstHousing,
						};
					} else {
						return {
							is_applicant_living: isApplicantLiving,
							security_deposit: deposit,
						};
					}
				})();

				const body = {
					is_pf_only: false,
					loan_purpose: loanPurpose,
					real_estate_no: realEstateNo,
					applied_amount: appliedAmount,
					annual_income: annualIncome,
					...jobOrBusnessNumber,
					...relatedToHouseFields,
					...utmload,
				};

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan.post('/v1/mortgage-loan/loan-applications/pre-screening', { token, body }).pipe(
						mergeMap((res) =>
							concat([
								setMortgageLoanApplications({
									id: res.loan_application.id,
									status: res.loan_application.status,
									expireTime: res.loan_application.expire || '',
									hasPfloanInLSProgress: false,
								}),
							])
						),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요.'))))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

// with alert >> 가심사 조회 중
const checkPreScreeingStatusEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(checkMortgageLSPreScreeingStatus),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					lsCommon: { flowType, mainPath: defaultMainPath },
					mlLoan: {
						la: {
							data: { id: laId },
						},
					},
				},
			]) => {
				const mainPath =
					defaultMainPath || flowType === FlowType.PFLOAN ? PageUrls.mortgageLoans.INTRO : PageUrls.loansCompare.INTRO;

				if (!token) {
					return of(
						setError(
							new AlertCommonError('', PhoenixErrorCode.LOGIN_REQUIRED, '로그인이 필요한 기능입니다.', {
								nextPath: PageUrls.auth.LOGIN,
							})
						)
					);
				} else if (!laId) {
					// laId 가 없으면 바로 가심사 조회 화면으로 들어온 경우이므로, 최신론 갱신하고 다시 조회하기
					return getMortgageLoanStatus(cometAjax, token).pipe(
						mergeMap((inProgressLoan) => {
							const nextAction: Action[] = [setMortgageLoanApplications(inProgressLoan)];
							const hasLoan = inProgressLoan.id;

							if (hasLoan) {
								nextAction.push(checkMortgageLSPreScreeingStatus()); // 다시 호출
							} else {
								nextAction.push(setError(alertNoHasLoan(mainPath)));
							}
							return nextAction;
						}),
						catchError((e) => {
							return createDifferentFlowAlert(e);
						})
					);
				} else {
					return concat(
						cometAjax.loan.get(`/v2/loan-applications/${laId}/pre-screening-status`, { token }).pipe(
							mergeMap((res) => {
								const convertPreScreeningStatus = (response: { status: string }): LSLoanStatus => {
									const { status } = response;
									switch (status) {
										case 'created':
											return LSLoanStatus.CREATED;
										case 'started':
											return LSLoanStatus.IN_PROGRESS_PRE_EVALUATION;
										case 'finished':
											return LSLoanStatus.DONE_PRE_EVALUATION;
										default:
											return LSLoanStatus.UNKNOWN;
									}
								};

								return concat(
									of(
										setMortgageLoanApplicationLoadingStatus({
											status: convertPreScreeningStatus(res),
											remainSeconds: res.remaining_time,
										})
									)
								);
							}),
							catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
						)
					);
				}
			}
		)
	);

// with alert >> 가심사 결과 리스트 조회
const getFPAListEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(getMortgageLSFPAList),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					lsCommon: { flowType, mainPath: defaultMainPath },
					mlLoan: {
						la: {
							data: { id: laId, status: laStatus },
						},
					},
				},
			]) => {
				const mainPath =
					defaultMainPath || flowType === FlowType.PFLOAN ? PageUrls.mortgageLoans.INTRO : PageUrls.loansCompare.INTRO;

				if (!token) {
					return of(
						setError(
							new AlertCommonError('', PhoenixErrorCode.LOGIN_REQUIRED, '로그인이 필요한 기능입니다.', {
								nextPath: PageUrls.auth.LOGIN,
							})
						)
					);
				} else if (!laId) {
					// laId 가 없으면 바로 가심사 결과화면 으로 들어온 경우이므로, 최신론 갱신하고 다시 조회하기
					return getMortgageLoanStatus(cometAjax, token).pipe(
						mergeMap((inProgressLoan) => {
							const nextAction: Action[] = [setMortgageLoanApplications(inProgressLoan)];
							const hasLoan = inProgressLoan.id;

							if (hasLoan) {
								nextAction.push(getMortgageLSFPAList()); // 다시 호출
							} else {
								nextAction.push(setError(alertNoHasLoan(mainPath)));
							}
							return nextAction;
						}),
						catchError((e) => {
							return createDifferentFlowAlert(e);
						})
					);
				} else if (laStatus !== LSLoanStatus.DONE_PRE_EVALUATION) {
					return createAlert(laStatus);
				} else {
					const getPassOrRejectFPA = () =>
						cometAjax.loan
							.get(`/v1/mortgage-loan/loan-applications/${laId}/loan-product-applications`, { token })
							.pipe(map((res) => convertFinancialProductResult(res))); //MOCK_FPA_LIST

					const getFailedFPA = () =>
						cometAjax.loan
							.get(`/v1/loan-applications/${laId}/pre-screening-failures`, {
								token,
							})
							.pipe(
								map((failedResponse) => {
									const failedFPA: FailedFPAForMortgageLS[] =
										failedResponse.pre_screening_failures?.map(convertFailedFPAMortgageLSResponse) ?? [];

									return failedFPA;
								})
							);

					return zip(getMortgageLoanStatus(cometAjax, token), getPassOrRejectFPA(), getFailedFPA()).pipe(
						mergeMap(([inProgressLoan, passOrRejectFPA, failedFPA]) => {
							const nextAction: Action[] = [];

							const pass = Object.values(passOrRejectFPA).filter((fpa) => !StatusManager.rejected(fpa.status));
							const rejected = Object.values(passOrRejectFPA).filter((fpa) => StatusManager.rejected(fpa.status));

							if (pass.length === 0 && failedFPA.length === 0) {
								// 승인 혹은 조회 실패된건이 한개도 없으면 거절페이지로 이동합니다.

								nextAction.push(setMortgageLoanApplicationStatus(LSLoanStatus.REJECTED));
							} else {
								nextAction.push(
									...[
										setMortgageLoanApplications(inProgressLoan),
										setFPAList({
											pass,
											rejected,
											failed: failedFPA,
										}),
									]
								);
							}

							nextAction.push(setFPAListFetchStatusSuccess()); // 완료시켜야 inquire 에서 화면 넘어감

							return nextAction;
						}),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
					);
				}
			}
		)
	);

// with alert >> 가심사 결과 상세 조회
const getFPAEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(getMortgageLSFPA),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { fpaId, baseUrls },
				},
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.LOGIN_REQUIRED, '로그인이 필요한 기능입니다.')));
				} else {
					const getNextStep = () =>
						cometAjax.loan
							.get(`/v2/loan-product-applications/${fpaId}/next-step`, { token })
							.pipe(map((res) => res?.next_step || ''));

					const getFPAInfo = () => cometAjax.loan.get(`/v1/loan-product-applications/${fpaId}`, { token });

					return zip(getFPAInfo(), getNextStep()).pipe(
						mergeMap(([rawFPAInfo, nextStep]) => {
							return of(setMortgageFPA(convertFPAMortgageLSResponse(rawFPAInfo, nextStep, baseUrls)));
						}),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
						// 500 존재하지 않는 론인 경우 다르게 처리하면 어떨까?
					);
				}
			}
		)
	);

const applyLPAEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(applyMortgageLSLPA),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { lpaId },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!lpaId) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.EMPTY_LOANS, '신청한 대출이 없습니다.')));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/mortgage-loan/loan-product-applications/${lpaId}/apply`, {
							token, // fyi. 제휴사 상품은 신청 금액을 보내지 않습니다.
						})
						.pipe(
							mergeMap(() =>
								of(
									getNextPath({ lpaId, baseUrls: PageUrls.loansCompareMortgage }) // next_step 을 이용한 nextPath 최신화
								)
							), // 200 인경우 바로 상태 업데이트
							catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const getNextPathEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(getNextPath),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { lpaId, baseUrls },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!lpaId) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.EMPTY_LOANS, '신청한 대출이 없습니다.')));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan.get(`/v2/loan-product-applications/${lpaId}/next-step`, { token }).pipe(
						mergeMap((res) =>
							of(
								setFPANextPath(convertNextStepResponse(res?.next_step || '', baseUrls).nextPath) // next_step 을 이용한 nextPath 최신화
							)
						), // 200 인경우 바로 상태 업데이트
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);

const checkValidBusinessNumberEpic: CometEpic = (action$, state$, { cometAjax }) =>
	action$.pipe(
		ofAction(checkValidBusinessNumber),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload: businessNumber },
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.LOGIN_REQUIRED, '로그인이 필요한 기능입니다.')));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.get(`/v1/corporations/operating-status?business_number=${businessNumber}`, {
							token,
						})
						.pipe(
							mergeMap((res) => of(setValidBusinessNumber(res?.operating_status || ''))),
							catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);

// cancelLoanEpic 은 ml-loan 사용

export default [
	getMortgageLoanStatusEpic,
	applyPreScreeningEpic,
	getFPAListEpic,
	getFPAEpic,
	checkPreScreeingStatusEpic,
	applyLPAEpic,
	checkValidBusinessNumberEpic,
	getNextPathEpic,
];
