type Obj = Record<string, unknown>;
const filterUndefined = <T>(obj: Obj): T => {
	return Object.keys(obj).reduce((result, key) => {
		const value = obj[key];
		if (value !== undefined) {
			return { ...result, [key]: obj[key] };
		} else {
			return result;
		}
	}, {} as T);
};

const filterFalsyValue = <T>(obj: Obj): T => {
	return Object.keys(obj).reduce((result, key) => {
		const value = obj[key];
		if (value || (Array.isArray(value) && value.length)) {
			return { ...result, [key]: obj[key] };
		} else {
			return result;
		}
	}, {} as T);
};
const deepClone = <T>(obj: T): T => {
	// 객체가 null이거나 값이 원시 타입일 경우 바로 반환
	if (obj === null || typeof obj !== 'object') {
		return obj;
	}

	// 배열일 경우 재귀적으로 복사
	if (Array.isArray(obj)) {
		return obj.map(deepClone) as unknown as T;
	}

	// 객체일 경우 재귀적으로 복사
	const result: any = {}; // 동적 할당을 위해 any 타입 사용

	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			result[key] = deepClone((obj as any)[key]);
		}
	}

	return result as T;
};

const ObjectUtil = {
	filterUndefined,
	filterFalsyValue,
	deepClone,
};

export default ObjectUtil;
