import { BlogArticle } from '@peoplefund/actions/ls-financial-product';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import {
	FPAForMortgageLoan,
	initFPAForMortgageLoan,
	MortgageLoanApplication,
	TenantLeaveInfoApplyProps,
} from '@peoplefund/epics/ml-loan.model';
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	Address,
	BUSINESS_OPERATION_STATUS,
	DetailAddress,
	DetailAddressHo,
	FETCH_STATUS,
	MLLoanApplyMaterial,
	MORTGAGE_LOAN_TYPE,
	Property,
} from './ml-loan.model';
import { FailedFPAForMortgageLS, FPACommonForMortgageLS } from '@peoplefund/epics/ml-ls.model';
import { MLBaseUrlType } from '@peoplefund/constants/page-urls';

const SLICE_NAME = 'mlLoanSlice';
export interface MLLoan {
	inflowChannel: string;
	error?: AlertCommonError;
	loanStatus: MORTGAGE_LOAN_TYPE;
	intro: {
		blogArticles: BlogArticle[];
		statistic: {
			totalLoanAmount: number;
			totalLoanCount: number;
			interestSavings: number;
		};
	};
	/* 소유한 담보물 리스트 */
	properties: {
		status: FETCH_STATUS;
		data: Property[];
	};

	/* 주소 검색 */
	searchAddress: {
		mainFetchStatus: FETCH_STATUS;
		detailAddressFetchStatus: FETCH_STATUS;
		addressCheckStatus: FETCH_STATUS;
		addressCheckFailCount: number;
		// dong, ho 기반이 될 데이터
		detailAddress: DetailAddress[];
		page: {
			// 페이지에서 뿌려질 데이터
			address: Address[];
			dong: string[];
			ho: DetailAddressHo[];
			// dong pass flag
			onlyHo: boolean;
		};
		selected: {
			kbLandNo: string;
			// 주소 컨펌창
			name: string;
			dong: string;
			ho: string;
			// 대출 받을 수 있는 주소 여부: 직접 입력한 경우 검증이 필요함
			isAddressConfirmed: boolean;
		};
	};
	apply: {
		code: string;
		agreements: string[];
		disagreements: string[];
		agreementSent: boolean;
		property: {
			id: string; // 대출받으려는 아파트
			resident: boolean | null; // 실제 거주 여부
		};
		loanPurpose: string; // 대출 목적
		amount: number; // 필요 금액
		jobCode: string; // 직업
		annualIncome: number; // 연소득
		deposit: number; // 세입자 보증금
		/** TODO: Will be deprecated */
		platformCode: string; //연계 업체 구분 코드
		summaryFetched: boolean; // 기존 입력 정보 확인 여부

		/* ============== 전세퇴거자금 ============== */
		lesseeMoveOutDueDate?: string;
		isMonthlyRentExists?: boolean;
		/* ============== 주담대론샷용 ============== */
		businessNumber: number; // 사업자 번호
		firstHousing: boolean | null; // 생애 최초 구매 여부
	};
	la: {
		status: FETCH_STATUS;
		data: {
			id: string;
			status: LSLoanStatus;
			expireTime: string; // 주담대론샷 에서만 표시
			hasPfloanInLSProgress: boolean;
			remainSeconds: number; // 조회 완료까지 걸리는 시간
		};
	};
	fpa: {
		status: FETCH_STATUS;
		data: FPAForMortgageLoan;
	};
	fpaList: {
		status: FETCH_STATUS;
		data: {
			pass: FPACommonForMortgageLS[];
			rejected: FPACommonForMortgageLS[];
			failed: FailedFPAForMortgageLS[];
		};
	};
	/* ============== 주담대론샷용 ============== */
	validBusinessNumber: {
		status: FETCH_STATUS;
		data: {
			number: number;
			operating: BUSINESS_OPERATION_STATUS;
		};
	};
	agreementsLatestHistory: {
		termsCode: string;
	};
}

export const mlLoanInitialState: MLLoan = {
	inflowChannel: '',
	error: undefined,
	loanStatus: '',
	intro: {
		blogArticles: [],
		statistic: {
			totalLoanAmount: 0,
			totalLoanCount: 0,
			interestSavings: 0,
		},
	},
	properties: {
		status: 'idle',
		data: [],
	},
	searchAddress: {
		mainFetchStatus: 'idle',
		detailAddressFetchStatus: 'idle',
		addressCheckStatus: 'idle',
		addressCheckFailCount: 0,
		detailAddress: [],
		page: {
			address: [],
			dong: [],
			ho: [],
			onlyHo: false,
		},
		selected: {
			kbLandNo: '',
			name: '',
			dong: '',
			ho: '',
			isAddressConfirmed: false,
		},
	},
	apply: {
		code: '',
		agreements: [],
		disagreements: [],
		agreementSent: false,
		property: {
			id: '',
			resident: null,
		},
		loanPurpose: '',
		amount: 0,
		jobCode: '',
		annualIncome: 0, // 무소득자도 받음
		deposit: 0, // 0이면, 세입자 없음
		platformCode: '',
		summaryFetched: false,

		/* ============== 주담대론샷용 ============== */
		businessNumber: 0,
		firstHousing: null,
	},
	la: {
		status: 'idle',
		data: {
			id: '',
			status: LSLoanStatus.UNKNOWN,
			expireTime: '',
			hasPfloanInLSProgress: false,
			remainSeconds: -1,
		},
	},
	fpa: {
		// 상세 화면 (주담대PF + 주담대론샷 겸용)
		status: 'idle',
		data: initFPAForMortgageLoan,
	},
	fpaList: {
		// 리스트 화면 (주담대론샷)
		status: 'idle',
		data: {
			pass: [],
			rejected: [],
			failed: [],
		},
	},
	validBusinessNumber: {
		status: 'idle',
		data: {
			number: 0,
			operating: '',
		},
	},
	agreementsLatestHistory: {
		termsCode: '',
	},
};

export const getProperties = createAction(`${SLICE_NAME}/getProperties`);
export const searchAddress = createAction<{ query: string }>(`${SLICE_NAME}/searchAddress`);
export const resetSearchAddress = createAction(`${SLICE_NAME}/resetSearchAddress`);
export const initError = createAction(`${SLICE_NAME}/initError`);
export const initDetailAddressFetchStatus = createAction(`${SLICE_NAME}/initDetailAddressFetchStatus`);
export const searchDetailAddress = createAction<{ kbLandNo: string; name: string; resetSelectedAddress: boolean }>(
	`${SLICE_NAME}/searchDetailAddress`
);
export const checkUserAddressIsAvailable = createAction(`${SLICE_NAME}/checkUserAddressIsAvailable`);
export const initAddressCheckStatus = createAction(`${SLICE_NAME}/initAddressCheckStatus`);
export const initMortgageLoanApplyInfo = createAction(`${SLICE_NAME}/initMortgageLoanApplyInfo`);
export const getMortgageLoanStatus = createAction(`${SLICE_NAME}/getMortgageLoanStatus`); // TODO: getMortgageLoanStatus -> getMortgagePFStatus
export const getApplyMaterials = createAction(`${SLICE_NAME}/getApplyMaterials`);
export const getFPAIdOnly = createAction(`${SLICE_NAME}/getFPAIdOnly`);
/** fyi. 주담대PF > 가심사 조회 > getMortgageLoanStatus 를 쓰고 싶으나, createAlert 얽혀있어서 우선 따로 호출*/
export const getMortgageLSStatus = createAction(`${SLICE_NAME}/getMortgageLSStatus`);
export const getMortgageLSFPA = createAction<{ fpaId: string; baseUrls: MLBaseUrlType }>(
	`${SLICE_NAME}/getMortgageLSFPA`
);
export const getMortgageLSFPAList = createAction(`${SLICE_NAME}/getMortgageLSFPAList`);
export const checkPreScreeingStatus = createAction(`${SLICE_NAME}/checkPreScreeingStatus`);
export const checkMortgageLSPreScreeingStatus = createAction(`${SLICE_NAME}/checkMortgageLSPreScreeingStatus`);
export const sendAgreements = createAction(`${SLICE_NAME}/sendAgreements`);
export const createLoan = createAction(`${SLICE_NAME}/createLoan`); // 가심사 요청 - la 생성
export const createMortgageLS = createAction(`${SLICE_NAME}/createMortgageLS`);
export const applyLPA = createAction<{ appliedAmount: number; lpaId: string; baseUrls: MLBaseUrlType }>(
	`${SLICE_NAME}/applyLPA`
); // 상품 신청
export const applyMortgageLSLPA = createAction<{ lpaId: string }>(`${SLICE_NAME}/applyMortgageLSLPA`);
export const cancelLoan = createAction<{ laId: string }>(`${SLICE_NAME}/cancelLoan`); //  la 취소
export const checkValidBusinessNumber = createAction<number>(`${SLICE_NAME}/checkValidBusinessNumber`);
export const getNextPath = createAction<{ lpaId: string; baseUrls: MLBaseUrlType }>(`${SLICE_NAME}/getNextPath`);

export const mlLoanSlice = createSlice({
	name: SLICE_NAME,
	initialState: mlLoanInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, mlLoanInitialState),
		setError: (state, action: PayloadAction<AlertCommonError>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setApplyAgreements: (
			state,
			action: PayloadAction<{ code: string; customAgreeList: string[]; customDisagreeList: string[] }>
		) => {
			state.apply.code = action.payload.code;
			state.apply.agreements = action.payload.customAgreeList;
			state.apply.disagreements = action.payload.customDisagreeList;
		},
		setApplyAgreementSent: (state) => {
			state.apply.agreementSent = true;
		},
		setApplyAmount: (state, action: PayloadAction<number>) => {
			state.apply.amount = action.payload;
		},
		setApplyLoanPurpose: (state, action: PayloadAction<string>) => {
			state.apply.loanPurpose = action.payload;
		},
		setApplyJob: (state, action: PayloadAction<string>) => {
			state.apply.jobCode = action.payload;
		},
		setApplyAnnualIncome: (state, action: PayloadAction<number>) => {
			state.apply.annualIncome = action.payload;
		},
		setApplyPropertyId: (state, action: PayloadAction<{ propertyId: string }>) => {
			state.apply.property.id = action.payload.propertyId;
			state.searchAddress.selected.isAddressConfirmed = true;
		},
		setApplyPropertyResident: (state, action: PayloadAction<boolean>) => {
			const resident = action.payload;
			state.apply.property.resident = resident;

			// 과거입력값이 혹시나 남아있을까해서 정확히 넘겨줄 값만 계산해서 넘겨주기
			// 쓰레드 https://pfcoworkspace.slack.com/archives/C0248UU6XU6/p1666066921694759?thread_ts=1666065434.125909&cid=C0248UU6XU6
			if (resident) {
				state.apply.deposit = 0;
			}
		},
		setSearchAddress: (state, action: PayloadAction<Address[]>) => {
			state.searchAddress.mainFetchStatus = 'success';
			state.searchAddress.page.address = action.payload;
		},
		setSearchDetailAddressResult: (
			state,
			{
				payload: { detailAddress, name, kbLandNo, resetSelectedAddress },
			}: PayloadAction<{
				readonly detailAddress: DetailAddress[];
				readonly kbLandNo: string;
				readonly name: string;
				readonly resetSelectedAddress: boolean;
			}>
		) => {
			state.searchAddress.detailAddress = detailAddress;
			state.searchAddress.page.onlyHo = detailAddress.length === 1;

			if (resetSelectedAddress) {
				state.searchAddress.selected = {
					kbLandNo,
					name,
					dong: '',
					ho: '',
					isAddressConfirmed: true,
				};
				state.searchAddress.addressCheckFailCount = 0;
				state.searchAddress.detailAddressFetchStatus = 'success';
			} else {
				state.searchAddress.detailAddressFetchStatus = 'idle';
			}
		},
		setDetailAddressForDong: (
			state,
			{ payload: { value, isAddressConfirmed } }: PayloadAction<{ value: string; isAddressConfirmed: boolean }>
		) => {
			state.searchAddress.selected.isAddressConfirmed = isAddressConfirmed;
			state.searchAddress.selected.dong = value;
			state.searchAddress.selected.ho = '';
			state.searchAddress.page.ho = state.searchAddress.detailAddress.find((item) => item.dong === value)?.ho || [];
		},
		setDetailAddressForHo: (
			state,
			{
				payload: { value, realEstateNo, isAddressConfirmed },
			}: PayloadAction<{ realEstateNo?: string | null; value: string; isAddressConfirmed: boolean }>
		) => {
			if (realEstateNo) {
				state.apply.property.id = realEstateNo;
			}

			state.searchAddress.selected.ho = value;
			state.searchAddress.selected.isAddressConfirmed = isAddressConfirmed;
		},
		setCheckUserAddressIsAvailableResult: (
			state,
			action: PayloadAction<
				| {
						readonly isAvailable: true;
						readonly realEstateNo: string;
				  }
				| {
						readonly isAvailable: false;
				  }
			>
		) => {
			const { isAvailable } = action.payload;
			if (isAvailable) {
				const { realEstateNo } = action.payload;
				state.searchAddress.selected.isAddressConfirmed = true;
				state.apply.property.id = realEstateNo;
				state.searchAddress.addressCheckStatus = 'success';
			} else {
				state.searchAddress.addressCheckStatus = 'fail';
				state.searchAddress.addressCheckFailCount += 1;
			}
		},
		setApplyDeposit: (state, action: PayloadAction<number>) => {
			state.apply.deposit = action.payload;
		},
		filterDetailAddressForDong: (state, action: PayloadAction<string>) => {
			const keyword = action.payload;
			let dong: string[] = [];
			if (keyword) {
				dong = state.searchAddress.detailAddress
					.map((item) => item.dong)
					.filter((item) => item && item.includes(keyword)) as string[];
			}
			state.searchAddress.page.dong = dong;
		},
		filterDetailAddressForHo: (state, { payload: keyword }: PayloadAction<string>) => {
			const onlyHo = state.searchAddress.page.onlyHo;
			const isAddressConfirmed = state.searchAddress.selected.isAddressConfirmed;
			let ho: DetailAddressHo[] = [];
			if (keyword) {
				let basicHo: DetailAddressHo[] = [];
				if (onlyHo && isAddressConfirmed) {
					basicHo = state.searchAddress.detailAddress[0]?.ho || [];
				} else {
					const selectedDong = state.searchAddress.selected.dong;
					basicHo = state.searchAddress.detailAddress.find((item) => item.dong === selectedDong)?.ho || [];
				}
				ho = basicHo.filter((item) => item.ho.includes(keyword));
			}
			state.searchAddress.page.ho = ho;
		},
		setProperties: (state, action: PayloadAction<Property[]>) => {
			state.properties = {
				status: 'success',
				data: action.payload,
			};
		},
		getAgreementsLatestHistory: (state, action: PayloadAction<{ termsCode: string }>) => {
			state.error = undefined;
			state.agreementsLatestHistory = action.payload;
		},
		setMortgaggeFPAId: (state, action: PayloadAction<string>) => {
			state.fpa.data.id = action.payload;
		},
		setMortgageLoanApplications: (state, action: PayloadAction<MortgageLoanApplication>) => {
			state.la.status = 'success';
			state.la.data.id = action.payload.id;
			state.la.data.status = action.payload.status;
			state.la.data.expireTime = action.payload.expireTime;
			state.la.data.hasPfloanInLSProgress = action.payload.hasPfloanInLSProgress;
		},
		setMortgageLoanApplicationStatus: (state, action: PayloadAction<LSLoanStatus>) => {
			state.la.status = 'success';
			state.la.data.status = action.payload;
		},
		setMortgageLoanApplicationLoadingStatus: (
			state,
			action: PayloadAction<{
				status: LSLoanStatus;
				remainSeconds: number;
			}>
		) => {
			state.la.status = 'success';
			state.la.data.status = action.payload.status;
			state.la.data.remainSeconds = action.payload.remainSeconds;
		},
		setMortgageFPA: (state, action: PayloadAction<FPAForMortgageLoan>) => {
			state.fpa.status = 'success';
			state.fpa.data = action.payload;
		},
		// ============== 전세퇴거자금 ==============
		setTenantLeaveInfo: (state, action: PayloadAction<TenantLeaveInfoApplyProps>) => {
			state.apply.lesseeMoveOutDueDate = action.payload.lesseeMoveOutDueDate;
			state.apply.isMonthlyRentExists = action.payload.isMonthlyRentExists;
		},
		// ============== 주담대론샷용 ==============
		setApplyBusinessNumber: (state, action: PayloadAction<number>) => {
			state.apply.businessNumber = action.payload;
		},
		setApplyFirstHousing: (state, action: PayloadAction<boolean>) => {
			state.apply.firstHousing = action.payload;
		},
		setFPAList: (state, action: PayloadAction<Pick<MLLoan['fpaList']['data'], 'pass' | 'rejected' | 'failed'>>) => {
			state.fpaList.status = 'success';
			state.fpaList.data = action.payload;
		},
		setValidBusinessNumber: (state, action: PayloadAction<BUSINESS_OPERATION_STATUS>) => {
			state.validBusinessNumber.status = 'success';
			state.validBusinessNumber.data.operating = action.payload;
		},
		resetValidBusinessNumber: (state) => {
			state.validBusinessNumber = mlLoanInitialState.validBusinessNumber;
		},
		setFPANextPath: (state, action: PayloadAction<string>) => {
			state.fpa.data.nextPath = action.payload;
		},
		setFPAListFetchStatusSuccess: (state) => {
			state.fpaList.status = 'success';
		},
		setApplyMaterials: (state, action: PayloadAction<MLLoanApplyMaterial>) => {
			state.apply.jobCode = action.payload.jobCode;
			state.apply.annualIncome = action.payload.annualIncome;
			state.apply.loanPurpose = action.payload.loanPurpose;
			state.apply.platformCode = action.payload.platformCode;
			state.apply.amount = action.payload.amount;
			state.apply.summaryFetched = true;
		},
		partnerVerifyPostAction: (
			state,
			action: PayloadAction<{ code: string; customAgreeList: string[]; customDisagreeList: string[] }>
		) => {
			state.error = undefined;
			state.apply.code = action.payload.code;
			state.apply.agreements = action.payload.customAgreeList;
			state.apply.disagreements = action.payload.customDisagreeList;
			state.apply.agreementSent = true;
		},
		setInflowChannel: (state, action: PayloadAction<string>) => {
			state.inflowChannel = action.payload;
		},
		resetInflowChannel: (state) => {
			state.inflowChannel = '';
		},
	},
	extraReducers(builder) {
		builder.addCase(getProperties, (state) => {
			state.properties = {
				status: 'idle',
				data: mlLoanInitialState.properties.data,
			};
		});
		builder.addCase(initDetailAddressFetchStatus, (state) => {
			state.searchAddress.detailAddressFetchStatus = mlLoanInitialState.searchAddress.detailAddressFetchStatus;
		});
		builder.addCase(searchAddress, (state) => {
			state.searchAddress.mainFetchStatus = 'loading';
			state.searchAddress.page = mlLoanInitialState.searchAddress.page;
		});
		builder.addCase(resetSearchAddress, (state) => {
			state.searchAddress.mainFetchStatus = 'idle';
			state.searchAddress.page = mlLoanInitialState.searchAddress.page;
		});
		builder.addCase(searchDetailAddress, (state) => {
			state.searchAddress.detailAddressFetchStatus = 'loading';
			state.searchAddress.detailAddress = mlLoanInitialState.searchAddress.detailAddress;
			state.searchAddress.page = mlLoanInitialState.searchAddress.page;
		});
		builder.addCase(checkUserAddressIsAvailable, (state) => {
			state.error = undefined;
			state.searchAddress.addressCheckStatus = 'loading';
		});
		builder.addCase(initAddressCheckStatus, (state) => {
			state.searchAddress.addressCheckStatus = 'idle';
		});
		builder.addCase(initMortgageLoanApplyInfo, (state) => {
			state.apply = mlLoanInitialState.apply;
		});
		// ============== 주담대론샷용 ==============
		builder.addCase(getMortgageLSFPAList, (state) => {
			state.fpaList.status = 'loading';
			state.fpaList = mlLoanInitialState.fpaList;
			state.fpa = mlLoanInitialState.fpa;
		});
		builder.addCase(getMortgageLSFPA, (state) => {
			state.fpa.status = 'loading';
			state.fpa = mlLoanInitialState.fpa;
		});
		builder.addCase(checkValidBusinessNumber, (state, action) => {
			state.validBusinessNumber.status = 'loading';
			state.validBusinessNumber.data.number = action.payload;
			state.validBusinessNumber.data.operating = '';
		});
	},
});

export const {
	initialize,
	setError,
	resetError,
	setProperties,
	setSearchAddress,
	setSearchDetailAddressResult,
	setCheckUserAddressIsAvailableResult,
	setApplyAgreements,
	setApplyAgreementSent,
	setApplyAmount,
	setApplyJob,
	setApplyAnnualIncome,
	setApplyLoanPurpose,
	setApplyPropertyId,
	setApplyPropertyResident,
	setApplyDeposit,
	setDetailAddressForDong,
	setDetailAddressForHo,
	setMortgaggeFPAId,
	setMortgageLoanApplications,
	setMortgageLoanApplicationStatus,
	setMortgageLoanApplicationLoadingStatus,
	setMortgageFPA,
	filterDetailAddressForDong,
	filterDetailAddressForHo,
	setApplyMaterials,
	partnerVerifyPostAction,
	setInflowChannel,
	resetInflowChannel,
	// ============== 전세퇴거자금 ==============
	setTenantLeaveInfo,
	// ============== 주담대론샷용 ==============
	setApplyBusinessNumber,
	setApplyFirstHousing,
	setFPAList,
	setValidBusinessNumber,
	resetValidBusinessNumber,
	setFPANextPath,
	setFPAListFetchStatusSuccess,
	getAgreementsLatestHistory,
} = mlLoanSlice.actions;

export default mlLoanSlice.reducer;
