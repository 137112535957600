import actionCreatorFactory from 'typescript-fsa';
import { ActionTypes } from '@peoplefund/constants/action-types';
import { AlertCommonError } from '@peoplefund/constants/error/type';

interface FPAParam {
	readonly id: string;
}

interface ApplyParam extends FPAParam {
	readonly amount: number;
}

interface ApplyResult extends FPAParam {
	readonly amount: number;
	readonly processType: 'external' | 'internal';
	readonly externalContractUrl?: string;
}

const actionCreator = actionCreatorFactory('LS-FINANCIAL-PRODUCT-APPLICATION');

export default {
	init: actionCreator<FPAParam>(ActionTypes.LS_FINANCIAL_PRODUCT_APPLICATION_INIT),
	apply: actionCreator.async<ApplyParam, ApplyResult, AlertCommonError>(
		ActionTypes.LS_FINANCIAL_PRODUCT_APPLICATION_APPLY
	),
	resetApplyCompleted: actionCreator<FPAParam>(ActionTypes.LS_FINANCIAL_PRODUCT_APPLICATION_RESET_APPLY_COMPLETED),
	resetError: actionCreator<FPAParam>(ActionTypes.LS_FINANCIAL_PRODUCT_APPLICATION_RESET_ERROR),
};
