import { actions } from '@peoplefund/actions';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import immutable from 'immutability-helper';
import { InAPICommonError } from '@peoplefund/constants/error/type';
import {
	AMLStatus,
	AuthInfo,
	CorporateInfo,
	initialCommonInvestorStatus,
	initialInvestorInfo,
	InvestorInfo,
	UserInfo,
	UserInvestorStatus,
} from './index.model';
import { getSignInResult } from '@peoplefund/utils/jwt.util';
import { setSessionCookie } from '@peoplefund/utils/cookie.util';
import { PHOENIX_SESSION_ID } from '@peoplefund/constants/session-id';

export interface AccountState {
	readonly error?: InAPICommonError;
	readonly auth: AuthInfo;
	readonly userInfo?: UserInfo;
	readonly amlStatus: {
		readonly fetched: boolean;
		readonly status: AMLStatus;
		readonly kycExpireDate?: Date;
	};
	readonly investorInfo: InvestorInfo;
	readonly investorRegisterAvailable: boolean;
	readonly commonInvestorStatus?: UserInvestorStatus;

	/** 사업자 정보 */
	readonly corporateInfo?: CorporateInfo;
}

export const accountInitialState: AccountState = {
	auth: {
		loggedIn: false,
		token: undefined,
		refreshToken: undefined,
		expiredAt: undefined,
	},
	amlStatus: {
		fetched: false,
		status: AMLStatus.INITIAL,
	},
	commonInvestorStatus: initialCommonInvestorStatus,
	investorInfo: initialInvestorInfo,
	investorRegisterAvailable: false,
	corporateInfo: undefined,
};

export default {
	account: reducerWithInitialState<AccountState>(accountInitialState)
		.case(actions.account.errorReset, (state) =>
			immutable(state, {
				error: { $set: undefined },
			})
		)
		.cases([actions.common.init, actions.account.init], (state) =>
			immutable(state, {
				$set: accountInitialState,
			})
		)
		.case(actions.account.signInByEmail.started, (state) =>
			immutable(state, {
				auth: { loggedIn: { $set: false } },
				error: { $set: undefined },
			})
		)
		.case(actions.account.signIn, (state, result) =>
			immutable(state, {
				auth: {
					loggedIn: { $set: true },
					token: { $set: result.accessToken },
					refreshToken: { $set: result.refreshToken },
					expiredAt: { $set: result.expiredAt },
				},
			})
		)
		.case(actions.account.signInByEmail.failed, (state, { error }) =>
			immutable(state, {
				auth: { loggedIn: { $set: false } },
				error: { $set: error },
			})
		)
		.case(actions.account.fetchUserInfo.started, (state) =>
			immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.account.fetchUserInfo.done, (state, { result: { userFinanceStatus, ...userInfo } }) =>
			immutable(state, {
				userInfo: { $set: userInfo },
				commonInvestorStatus: {
					$set: userFinanceStatus,
				},
			})
		)
		.case(actions.account.fetchUserInfo.failed, (state, { error }) =>
			immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.account.setCorporateInfo, (state, payload) =>
			immutable(state, {
				corporateInfo: { $set: payload },
			})
		)
		.case(actions.account.setEmail, (state, payload) =>
			immutable(state, {
				userInfo: {
					email: { $set: payload },
				},
			})
		)
		.case(actions.account.setPhoneNumber, (state, payload) =>
			immutable(state, {
				userInfo: {
					phoneNumber: { $set: payload },
				},
			})
		)
		.case(actions.account.setInvestorInfo, (state, investorInfo) =>
			immutable(state, {
				investorInfo: { $set: investorInfo },
			})
		)
		.case(actions.account.updateInvestorInfo.started, (state) =>
			immutable(state, {
				error: { $set: undefined },
				investorInfo: {
					tryFetchInvestorInfo: { $set: false },
				},
			})
		)
		.case(actions.account.updateInvestorInfo.done, (state, { result }) =>
			immutable(state, {
				investorInfo: {
					accountBalance: { $set: result.accountBalance },
					tryFetchInvestorInfo: { $set: true },
				},
			})
		)
		.case(actions.account.updateInvestorInfo.failed, (state, { error }) =>
			immutable(state, {
				error: { $set: error },
				investorInfo: {
					accountBalance: { $set: 0 },
				},
			})
		)
		.case(actions.account.checkInvestorRegisterAvailable.started, (state) =>
			immutable(state, {
				error: { $set: undefined },
				investorRegisterAvailable: { $set: false },
			})
		)
		.case(actions.account.checkInvestorRegisterAvailable.done, (state) =>
			immutable(state, {
				investorRegisterAvailable: { $set: true },
			})
		)
		.case(actions.account.checkInvestorRegisterAvailable.failed, (state, { error }) =>
			immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.account.fetchAMLStatus.started, (state) =>
			immutable(state, {
				error: { $set: undefined },
			})
		)
		.case(actions.account.fetchAMLStatus.done, (state, { result }) =>
			immutable(state, {
				amlStatus: {
					status: { $set: result.status },
					fetched: { $set: result.fetched },
					kycExpireDate: { $set: result.kycExpireDate },
				},
			})
		)
		.case(actions.account.fetchAMLStatus.failed, (state, { error }) =>
			immutable(state, {
				error: { $set: error },
			})
		)
		.case(actions.account.initAMLFlow, (state) => {
			return immutable(state, {
				amlStatus: {
					$set: {
						fetched: false,
						status: AMLStatus.INITIAL,
						kycExpireDate: undefined,
					},
				},
			});
		})
		.case(actions.account.setAmlStatus, (state, payload) =>
			immutable(state, {
				amlStatus: {
					status: { $set: payload.status },
					fetched: { $set: payload.fetched },
				},
			})
		)
		.case(actions.account.setToken, (state, payload) => {
			const signInResult = getSignInResult({ access_token: payload.accessToken, refresh_token: payload.refreshToken });
			setSessionCookie(PHOENIX_SESSION_ID, signInResult);

			return immutable(state, {
				auth: {
					loggedIn: { $set: true },
					token: { $set: signInResult.accessToken },
					refreshToken: { $set: signInResult.refreshToken },
					expiredAt: { $set: signInResult.expiredAt },
				},
			});
		})
		.case(actions.account.resetToken, (state) => {
			return immutable(state, {
				auth: {
					loggedIn: { $set: false },
					token: { $set: undefined },
					refreshToken: { $set: undefined },
					expiredAt: { $set: undefined },
				},
			});
		}),
};
