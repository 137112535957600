import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import {
	AlertCommonError,
	convertInAPICommonErrorToAlertCommonError,
	InAPICommonError,
} from '@peoplefund/constants/error/type';
import {
	FetchInfoParam,
	SetClosedListResultPayload,
	SetInfoParam,
	SetOngoingListResultPayload,
	SubmitVoteParam,
	VoteItem,
} from './index.model';
import { FETCH_STATUS } from '@peoplefund/slices/ml-loan.model';
import { PaginationProps } from 'components/common/Pagination';

// 	const totalPaginationLength = Math.ceil(pagination.count / LIMIT_PAGE_SIZE);
export interface Vote {
	/** 투표 가능 채권 */
	readonly ongoingList: VoteItem[];
	/** 투표 종료 채권 */
	readonly closedList: VoteItem[];
	/** 투표 종료 채권 페이징 */
	readonly closedListPage: {
		/** 총 갯수 */
		readonly count: number;
		/** 현재 페이지 */
		readonly page: number;
		/** 한 페이지당 몇 개 보여줄 것 인지 */
		readonly limit: number;
		/** 페이지 선택지 개수 */
		readonly pageSize: PaginationProps['paginationLength'];
	};
	/** 상세 페이지 조회 아이템 */
	readonly detailItem?: VoteItem;
	/** 투표 결과 */
	readonly submitResult: boolean;

	/** 서버 통신 관련 값 */
	readonly error?: AlertCommonError;
	readonly fetchingCount: number;
	readonly fetchState: FETCH_STATUS;
}

export const voteInitialState: Vote = {
	fetchingCount: 0,
	fetchState: 'idle',
	error: undefined,
	submitResult: false,
	ongoingList: [],
	closedList: [],
	closedListPage: { count: 0, page: 0, limit: 10, pageSize: 5 },
};

const updateFetchState = (state: Draft<Vote>, loading: boolean) => {
	if (loading) {
		state.fetchingCount++;
	} else {
		state.fetchingCount--;
	}
	state.fetchState = state.fetchingCount === 0 ? 'idle' : 'loading';
};

const vote = createSlice({
	name: 'vote',
	initialState: voteInitialState,
	reducers: {
		initialize: (state) => Object.assign(state, voteInitialState),
		setError: (state, action: PayloadAction<AlertCommonError | InAPICommonError>) => {
			updateFetchState(state, false);

			const error = action.payload;
			if (error instanceof InAPICommonError) {
				state.error = convertInAPICommonErrorToAlertCommonError(error);
			} else {
				state.error = error;
			}
		},
		resetError: (state) => {
			state.error = undefined;
		},
		fetchOngoingList: (state) => {
			state.error = undefined;
			updateFetchState(state, true);
		},
		setOngoingListResult: (state, action: PayloadAction<SetOngoingListResultPayload>) => {
			state.error = undefined;
			state.ongoingList = action.payload.result;
			updateFetchState(state, false);
		},
		setPage: (state, action: PayloadAction<{ page: number }>) => {
			state.closedListPage.page = action.payload.page;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchClosedList: (state, _: PayloadAction<{ page: number }>) => {
			state.error = undefined;
			updateFetchState(state, true);
		},
		setClosedListResult: (state, action: PayloadAction<SetClosedListResultPayload>) => {
			state.error = undefined;
			state.closedList = action.payload.result;
			state.closedListPage.count = Math.ceil(action.payload.count / state.closedListPage.pageSize);
			updateFetchState(state, false);
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		fetchInfo: (state, _: PayloadAction<FetchInfoParam>) => {
			state.error = undefined;
			updateFetchState(state, true);
		},
		setInfoResult: (state, action: PayloadAction<SetInfoParam>) => {
			updateFetchState(state, false);

			const { isEnd, id, info } = action.payload;
			const target = isEnd ? state.closedList : state.ongoingList;
			const item = target.find((item) => item.id === id);

			if (item) {
				item.info = info;
			}

			if (state.detailItem) {
				state.detailItem.info = info;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		submitVote: (state, _: PayloadAction<SubmitVoteParam>) => {
			state.error = undefined;
			state.submitResult = false;
			updateFetchState(state, true);
		},
		setSubmitVoteResult: (state, action: PayloadAction<boolean>) => {
			state.submitResult = action.payload;
			updateFetchState(state, false);
		},
		setDetail: (state, action: PayloadAction<VoteItem>) => {
			state.detailItem = action.payload;
		},
		resetVoteResult: (state) => {
			state.submitResult = false;
		},
		resetDetailItem: (state) => {
			state.detailItem = undefined;
		},
	},
});

export const {
	initialize,
	resetError,
	setError,
	fetchOngoingList,
	setOngoingListResult,
	setPage,
	fetchClosedList,
	setClosedListResult,
	fetchInfo,
	setInfoResult,
	submitVote,
	setSubmitVoteResult,
	resetVoteResult,
	setDetail,
	resetDetailItem,
} = vote.actions;

export default vote.reducer;
