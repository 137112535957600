import { FetchListServerProps, VoteDetailServerProps } from '@peoplefund/epics/vote/index.model';
import { VoteAnswer, VoteCondition, VoteItem } from '@peoplefund/slices/vote/index.model';
import dayjs from 'dayjs';

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

export const convertVoteItem = (response: FetchListServerProps): VoteItem[] => {
	const formatTime = (time: string) =>
		dayjs(time)
			.format('YY.MM.DD (ddd) HH:00')
			.replace('Mon', '월')
			.replace('Tue', '화')
			.replace('Wed', '수')
			.replace('Thu', '목')
			.replace('Fri', '금')
			.replace('Sat', '토')
			.replace('Sun', '일');

	const formatVoteTime = (time: string) => dayjs(time).format('YYYY년 MM월 DD일 HH시 mm분');

	const getRemainDay = (end_datetime: string): number => {
		const end = dayjs(end_datetime);
		const today = dayjs().startOf('day').toDate();
		return end.diff(today, 'day');
	};

	return response.results.map((item) => {
		const {
			id,
			vote_sub_loan_id,
			answer,
			modified,
			is_vote,
			vote_sub_loan: {
				start_datetime,
				end_datetime,
				is_completed,
				is_canceled,
				title,
				category,
				default_response,
				message = '',
			},
		} = item;

		const hasVoted = is_vote;

		return {
			id,
			subId: vote_sub_loan_id,
			startDatetime: formatTime(start_datetime),
			endDatetime: formatTime(end_datetime),
			hasVoted,
			remainDay: getRemainDay(end_datetime),
			isEnd: is_completed,
			title,
			category,
			defaultResponse: default_response,
			answer: hasVoted ? answer : undefined,
			answerDateTime: hasVoted ? formatVoteTime(modified) : undefined,
			message,
			isCancelled: Boolean(is_canceled),
		} as VoteItem;
	});
};

export const convertVoteInfo = ({
	conditions: { title, header, body },
	answers: { title: answerTitle, options },
	footnotes: disclaimer,
	callout,
	product_page_url,
}: VoteDetailServerProps): VoteCondition => {
	return {
		tableData: {
			title,
			header: header.map((item) => item.content),
			body: body.map(({ label, help_text, current, after }) => ({
				field: label,
				tooltip: help_text,
				current,
				after,
			})),
		},
		answer: {
			title: answerTitle,
			options: options.map(({ key, content, is_default }) => ({
				key: key as VoteAnswer,
				content,
				isDefault: Boolean(is_default),
			})),
		},
		disclaimer,
		callout,
		productPageUrl: product_page_url || '',
	};
};
