import { LSFinancialProductApplicationStatus } from '@peoplefund/constants/ls-loan-status';
import {
	FailedFPAForMortgageLS,
	FPACommonForMortgageLS,
	FPAListForMortgageLS,
	FPAMortgageLSServerProps,
} from './ml-ls.model';
import {
	FailedFinancialProductApplicationServerProps,
	FinancialProductApplicationResponse,
	FinancialProductApplicationServerProps,
} from '@peoplefund/epics/ls-loan.model';
import { Badge } from '@peoplefund/constants/ls-loan-types';
import { FPAForMortgageLoan, InProgressServerProps } from './ml-loan.model';
import { MLBaseUrlType } from '@peoplefund/constants/page-urls';
import { hasMortgageLPA } from '@peoplefund/epics/ml-loan.util';
import { uuid } from '@peoplefund/utils/uuid.util';

export const convertMortgagePFInLSProgress = (res: InProgressServerProps) => {
	const la = res?.loan_application;

	const pfloan = la?.is_pf_only;
	const pfInProgress = la?.lpa_in_progress;

	return Boolean(!pfloan && hasMortgageLPA(la) && pfInProgress?.id);
	// 론샷일 경우 적합성 이후의 lpa이면서 PF 상품만 내려줍니다
	// https://pfcoworkspace.slack.com/archives/C028M74GRFW/p1669716909605369?thread_ts=1669716678.209459&cid=C028M74GRFW
};

export const convertNextStepResponse = (nextStep: string, baseUrls: MLBaseUrlType) => {
	let fpaStatus: LSFinancialProductApplicationStatus;
	let nextPath = '';

	switch (nextStep) {
		case 'step.mortgage.pre_evaluation.approved':
			fpaStatus = LSFinancialProductApplicationStatus.APPROVED_PRE_EVALUATION;
			nextPath = ''; // 제휴사상품의 경우 본심사 신청 후 업데이트 필요 (대기를 위해 빈값 넣기)
			break;
		case 'step.mortgage.evaluation.application_applied':
			fpaStatus = LSFinancialProductApplicationStatus.DONE;
			nextPath = baseUrls.BRIDGE;
			break;
		default: // 그 외에는 모두 앱다운로드 유도
			fpaStatus = LSFinancialProductApplicationStatus.IN_PROGRESS_EVALUATION;
			nextPath = nextPath = baseUrls.APP_DOWNLOAD;
			break;
	}

	return {
		fpaStatus,
		nextPath,
	};
};

// 주담대PF, 주담대론샷 공용으로 썼으면 함.
export const convertFPAMortgageLSResponse = (
	rawFPA: FPAMortgageLSServerProps,
	nextStep: string,
	baseUrls: MLBaseUrlType
): FPAForMortgageLoan => {
	const {
		applied_amount,
		is_applied_amount_adjusted_from_pre_screening,
		commission_rate,
		id: fpaId,
		interest_rate,
		loan_product,
		loan_term,
		repayment_type,
		maximum_amount,
		pf_pre_screening,
	} = rawFPA;

	const { fpaStatus, nextPath } = convertNextStepResponse(nextStep, baseUrls);

	return {
		id: fpaId,
		amount: !isNaN(Number(applied_amount)) ? Number(applied_amount) : 0,
		maxAmount: maximum_amount,
		commissionRate: commission_rate,
		interestRate: interest_rate,
		loanTerm: loan_term,
		repaymentType: repayment_type,
		loanProduct: {
			id: loan_product.id,
			name: loan_product.name,
			code: loan_product.code,
			institution: {
				logoUrl: loan_product.institution.image_url,
				name: loan_product.institution.name,
			},
			information: loan_product.info.informations,
		},
		tooltipContent: {
			commissionRate: pf_pre_screening?.commission_rate_tooltip || [],
			conditionChange: pf_pre_screening?.condition_tooltip || [],
		},
		notification: loan_product.notification,
		status: fpaStatus,
		rejectReason: '',
		isAdjustedForAmount: is_applied_amount_adjusted_from_pre_screening,
		pfloan: loan_product.is_pf,
		nextPath,
	};
};

export const convertFailedFPAMortgageLSResponse = (
	fpa: FailedFinancialProductApplicationServerProps
): FailedFPAForMortgageLS => {
	const {
		failure_type,
		description,
		loan_product: {
			name,
			institution: { name: institution, image_url },
		},
	} = fpa;

	return {
		id: uuid(),
		name,
		institution,
		iconUrl: image_url,
		failureType: failure_type,
		description: description || '',
	};
};

export const convertFinancialProductResult = ({
	loan_product_applications,
}: FinancialProductApplicationResponse): Omit<FPAListForMortgageLS, 'loanStatus'> => {
	const _convertFPAInFPAList = (fpa: FinancialProductApplicationServerProps): FPACommonForMortgageLS => {
		const {
			id,
			loan_product,
			loan_product: { is_pf, notification },
			applied_amount,
			interest_rate,
			interest_rate_special_discount,
			commission_rate,
			integrated_interest_rate,
			maximum_amount,
			status: { status },
			badges,
			stage_display,
			pf_pre_screening,
		} = fpa;

		const pfloan = is_pf;

		return {
			id,
			pfloan,
			financialProductId: loan_product.id, // fp 가 안에 들어오면서 필요 없어짐
			status: status as LSFinancialProductApplicationStatus,
			amount: applied_amount,
			maxAmount: maximum_amount,
			commissionRate: commission_rate,
			interestRate: interest_rate,
			integratedInterestRate: Number(integrated_interest_rate),
			interestRateDiscount: Number(interest_rate_special_discount),
			applyCompleted: false,
			processType: pfloan ? 'internal' : 'external',
			badges: badges as Badge[],
			applyInProcess: Boolean(stage_display),
			notification,
			interestRateInfo: pf_pre_screening
				? {
						label: pf_pre_screening.interest_rate_label,
						toolTip: pf_pre_screening.interest_rate_tooltip,
						tip: pf_pre_screening.interest_rate_tip,
						disclaimer: pf_pre_screening.additional_descriptions,
						integratedToolTip: pf_pre_screening.integrated_interest_rate_tooltip,
				  }
				: undefined,
			fp: {
				id: loan_product.id,
				name: loan_product.name,
				code: loan_product.code,
				institution: {
					logoUrl: loan_product.institution.image_url,
					name: loan_product.institution.name,
				},
				information: [],
			},
		};
	};

	const fpaList: FPAListForMortgageLS = {};

	loan_product_applications.forEach((item) => {
		fpaList[item.id] = _convertFPAInFPAList(item);
	});

	return fpaList;
};
