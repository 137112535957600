import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { BaseLoanApplication, Unknown } from '@peoplefund/slices/loans/type';

export enum ChicLoanApplicationStatus {
	모름 = '',
	가심사완료 = 'approve_pre_screening', //서버: compatibility_approved
	배송신청중 = 'shipping_in_progress', //서버: scraping_documents_in_review
	본심사대기중 = 'screening_in_progress', //서버: scraping_documents_in_review + 배송정보, manual_screening_in_progress +
	온라인계약중 = 'contract_in_progress', //서버: screening_approved + contract_in_progress
	입금대기중 = 'deposit_in_progress', //서버: final_document_review_in_progress + contract_completed
}

export enum LogisticStatus {
	모름 = '',
	발송불가 = 'not_available', //담보물비용대기중, 보관비용결제완료, 검수비비용대기중, 검수비결제완료
	발송가능 = 'available',
	배송반환요청완료 = 'completed',
}

type LoanProductApplication = BaseLoanApplication<ChicLoanApplicationStatus> & {
	//물건 배송 방법
	shipping: {
		dueDate: string; //포멧팅 되어서 내려옴
		logisticStatus: string | null;
	};
};

export interface LuxuryAssetLoansChic {
	error?: AlertCommonError;
	loanApplication: {
		id: string;
	};
	loanProductApplication: LoanProductApplication;
	returnInformation: {
		status: LogisticStatus;
		address: string;
		detailAddress: string;
	};
}

export const luxuryAssetLoansChicInitialState: LuxuryAssetLoansChic = {
	loanApplication: {
		id: Unknown,
	},
	loanProductApplication: {
		title: '',
		iconUrl: '',
		id: Unknown,
		status: ChicLoanApplicationStatus.모름,
		interestRate: 0,
		applyAmount: 0,
		maxAmount: 0,
		shipping: {
			dueDate: '',
			logisticStatus: '',
		},
		condition: {
			featured: [],
			common: [],
			detail: [],
			comments: [],
		},
	},
	returnInformation: {
		status: LogisticStatus.모름,
		address: '',
		detailAddress: '',
	},
};

const SLICE_NAME = 'luxuryAssetLoansChic';

const luxuryAssetLoansChicSlice = createSlice({
	name: SLICE_NAME,
	initialState: luxuryAssetLoansChicInitialState,
	reducers: {
		// reset 겸용
		initialize: (state) => {
			state.error = undefined;
			state.loanApplication = luxuryAssetLoansChicInitialState.loanApplication;
			state.loanProductApplication = luxuryAssetLoansChicInitialState.loanProductApplication;
			state.returnInformation = luxuryAssetLoansChicInitialState.returnInformation;
		},
		setError: (state, action: PayloadAction<AlertCommonError | undefined>) => {
			state.error = action.payload;
		},
		resetError: (state) => {
			state.error = undefined;
		},
		setLoanApplicationId: (state, { payload }: PayloadAction<number>) => {
			state.loanApplication.id = `${payload}`;
		},
		setLoanProductApplication: (state, { payload }: PayloadAction<LoanProductApplication>) => {
			state.loanProductApplication = payload;
		},
		setReturnInformation: (state, { payload }: PayloadAction<LuxuryAssetLoansChic['returnInformation']>) => {
			state.returnInformation = payload;
		},
	},
});

export const {
	initialize,
	setLoanApplicationId,
	setLoanProductApplication,
	setReturnInformation,
	setError,
	resetError,
} = luxuryAssetLoansChicSlice.actions;

export default luxuryAssetLoansChicSlice.reducer;
