import React, { useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import * as Styled from './index.styled';
import { BasicColorsKey, Headline3, Headline5, Headline6, IconWithImage, SingleButton, Spacing, Title3, basicColors } from '@pfct/purple';
import { PartialSnakeCase } from '@peoplefund/utils/types';
import dayjs from 'dayjs';
import useGtagInvestment from '@peoplefund/hooks/useGtagInvestment';
import LOG_EVENT_NAME from '@peoplefund/constants/log-event';
import { WebviewHandler } from '@peoplefund/utils/webview-handler/index.util';
export interface HomeBannerServerResponse {
  items: HomeBannerItemServerResponse[];
}
interface TextField {
  label: string;
  color: BasicColorsKey;
  position?: 'main' | 'sub';
}
interface HomeBannerItemServerResponse {
  type: 'event' | 'banner' | 'other';
  key: string;
  content: {
    title: {
      origin: TextField;
      next: TextField | null;
    } | null;
    sub_title: {
      origin: TextField;
      next: TextField | null;
    } | null;
    overline: {
      origin: TextField;
      next: TextField | null;
    } | null;
    caption: {
      origin: TextField;
      next: TextField | null;
    } | null;
    deeplink: {
      origin: string;
      next: string;
    } | null;
  };
  image: {
    url: string;
    width: number;
    height: number;
  } | null;
  button: {
    origin: {
      label: string;
      deeplink: string;
    };
    next: {
      label: string;
      deeplink: string;
    } | null;
  } | null;
  condition: {
    open_time: string; // HH:MM:SS
    field_name: ConditionFieldName;
  } | null;
}
export type ConditionFieldName = 'title' | 'sub_title' | 'overline' | 'caption';
export type HomeBannerServerResponseClientProps = {
  items: PartialSnakeCase<Required<HomeBannerItemServerResponse>>[];
};
export interface CardHomeProps {
  bannerInformation: HomeBannerServerResponseClientProps['items'][0] | null;
  hasError: boolean;
  isOverTime: boolean;
}
function CardHome(props: CardHomeProps) {
  const {
    hasError,
    bannerInformation
  } = props;
  const Children = useMemo(() => {
    if (bannerInformation?.type === 'banner') {
      return TemplateSmall;
    }
    if (bannerInformation?.content.title) {
      return TemplateLarge;
    }
    if (bannerInformation?.image) {
      return TemplateImage;
    }
    return TemplateSmall;
  }, [bannerInformation]);
  if (!bannerInformation || hasError) {
    return <></>;
  }
  return <Styled.Wrapper data-sentry-element="unknown" data-sentry-component="CardHome" data-sentry-source-file="index.tsx">
			<Children {...props} data-sentry-element="Children" data-sentry-source-file="index.tsx" />
		</Styled.Wrapper>;
}
export default CardHome;
function TemplateSmall({
  bannerInformation
}: CardHomeProps) {
  const {
    onClick
  } = useOnClickManager();
  if (!bannerInformation) {
    return <></>;
  }
  const {
    key,
    content,
    image,
    button
  } = bannerInformation;
  const {
    title,
    subTitle
  } = content;
  return <Styled.TemplateSmallWrapper onClick={() => onClick({
    url: button?.origin.deeplink ?? '',
    key
  })} data-sentry-element="unknown" data-sentry-component="TemplateSmall" data-sentry-source-file="index.tsx">
			<IconWithImage width={image?.width ?? 1} srcPath={image?.url ?? ''} data-sentry-element="IconWithImage" data-sentry-source-file="index.tsx" />
			<Styled.TemplateSmallWrapperTextSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Headline5 color={basicColors[title?.origin.color ?? 'black']} data-sentry-element="Headline5" data-sentry-source-file="index.tsx">{title?.origin.label}</Headline5>
				<Title3 color={basicColors[subTitle?.origin.color ?? 'black']} data-sentry-element="Title3" data-sentry-source-file="index.tsx">{subTitle?.origin.label}</Title3>
			</Styled.TemplateSmallWrapperTextSection>
		</Styled.TemplateSmallWrapper>;
}
function TemplateLarge({
  bannerInformation,
  isOverTime
}: CardHomeProps) {
  const {
    onClick
  } = useOnClickManager();
  const [remainingTime, setRemainingTime] = useState('');
  const textLevel = isOverTime || remainingTime === '0' ? 'next' : 'origin';
  const textLabel = (text: 'title' | 'subTitle' | 'overline' | 'caption') => {
    if (textLevel === 'origin' && condition?.fieldName === text) {
      return remainingTime;
    }
    return content?.[text]?.[textLevel]?.label;
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (!bannerInformation) {
        return;
      }
      const {
        condition
      } = bannerInformation;
      const now = dayjs();
      const [hour, minute, second] = condition?.openTime.split(':').map(Number) ?? [0, 0, 0];
      const targetTime = dayjs().hour(hour).minute(minute).second(second);
      const diffInSeconds = targetTime.diff(now, 'second');
      if (diffInSeconds > 0) {
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor(diffInSeconds % 3600 / 60);
        const seconds = diffInSeconds % 60;
        let formattedTime = '';
        if (hours > 0) {
          formattedTime += `${String(hours)}시간`;
        }
        if (minutes > 0) {
          formattedTime += ` ${String(minutes)}분`;
        }
        if (seconds > 0) {
          formattedTime += ` ${String(seconds)}초`;
        }
        setRemainingTime(`${formattedTime} 남음`);
      } else {
        setRemainingTime('0');
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [bannerInformation]);
  if (!bannerInformation) {
    return <></>;
  }
  const {
    key,
    content,
    image,
    button,
    condition
  } = bannerInformation;
  const {
    title,
    subTitle,
    overline,
    caption
  } = content;
  return <div onClick={() => onClick({
    url: button?.[textLevel]?.deeplink ?? '',
    key
  })} data-sentry-component="TemplateLarge" data-sentry-source-file="index.tsx">
			<Styled.OverlineCaptionSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Headline6 color={basicColors[overline?.[textLevel]?.color ?? 'black']} data-sentry-element="Headline6" data-sentry-source-file="index.tsx">{textLabel('overline')}</Headline6>
				<Headline6 color={basicColors[caption?.[textLevel]?.color ?? 'black']} data-sentry-element="Headline6" data-sentry-source-file="index.tsx">{textLabel('caption')}</Headline6>
			</Styled.OverlineCaptionSection>
			<Spacing spaceNumber={8} data-sentry-element="Spacing" data-sentry-source-file="index.tsx" />
			<Headline3 color={basicColors[title?.[textLevel]?.color ?? 'black']} data-sentry-element="Headline3" data-sentry-source-file="index.tsx">{textLabel('title')}</Headline3>
			<Spacing spaceNumber={8} data-sentry-element="Spacing" data-sentry-source-file="index.tsx" />
			<Title3 color={basicColors[subTitle?.[textLevel]?.color ?? 'black']} data-sentry-element="Title3" data-sentry-source-file="index.tsx">{textLabel('subTitle')}</Title3>
			<Spacing spaceNumber={20} data-sentry-element="Spacing" data-sentry-source-file="index.tsx" />
			<Styled.TemplateImageImageSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Styled.TemplateImageImage src={image?.url ?? ''} width={image?.width} height={image?.height} data-sentry-element="unknown" data-sentry-source-file="index.tsx" />
			</Styled.TemplateImageImageSection>
			{button && <>
					<Spacing spaceNumber={20} />
					<SingleButton colorType="lime" purpose="main">
						{button?.[textLevel]?.label}
					</SingleButton>
				</>}
		</div>;
}
function TemplateImage({
  bannerInformation
}: CardHomeProps) {
  const {
    onClick
  } = useOnClickManager();
  if (!bannerInformation) {
    return <></>;
  }
  const {
    image,
    button,
    key
  } = bannerInformation;
  return <div onClick={() => {
    onClick({
      url: button?.origin.deeplink ?? '',
      key
    });
  }} data-sentry-component="TemplateImage" data-sentry-source-file="index.tsx">
			<Styled.TemplateImageImageSection data-sentry-element="unknown" data-sentry-source-file="index.tsx">
				<Image src={image?.url ?? ''} alt="Banner" width={image?.width} height={image?.height} layout="responsive" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
			</Styled.TemplateImageImageSection>
			{button && <>
					<Spacing spaceNumber={20} />
					<SingleButton colorType="lime" purpose="main">
						{button?.origin.label}
					</SingleButton>
				</>}
		</div>;
}
const useOnClickManager: () => {
  onClick: (props: {
    url: string;
    key: string;
  }) => void;
} = () => {
  const gtag = useGtagInvestment();
  const onClick = ({
    url,
    key
  }: {
    url: string;
    key: string;
  }) => {
    WebviewHandler().bridge.nextAction('deeplink', {
      url
    });
    gtag(LOG_EVENT_NAME.home_main___ck_topbanner, {
      key
    });
  };
  return {
    onClick
  };
};