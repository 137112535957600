import { SignInResult } from '@peoplefund/actions/account';
import { PHOENIX_SESSION_ID, PHP_SESSION_ID } from '@peoplefund/constants/session-id';
import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookieOptions: CookieSetOptions = {
	path: '/',
	secure: process.env.NODE_ENV === 'production',
	// httpOnly: true,
	sameSite: 'strict',
};

/**
 *
 * @param key
 * @param value
 * @param cookieProvider express 서버에서 클라이언트로 보낼 쿠키를 설정하는 경우, req.universalCookies를 인자로 전달해야함.
 * 	다른 값을 전달하면 추가된 쿠키를 클라이언트에서 받지 못함. 이 경우가 아니라면 기본값 사용해도 됨.
 */
export const setSessionCookie = (key: string, value: SignInResult, cookieProvider: Cookies = new Cookies()): void => {
	cookieProvider.set(key, value, cookieOptions);
};

/**
 *
 * @param key
 * @param cookieProvider express 서버에서 클라이언트로 보낼 쿠키를 삭제하는 경우, req.universalCookies를 인자로 전달해야함.
 * 	다른 값을 전달하면 삭제한 쿠키를 클라이언트에서 그대로 받음. 이 경우가 아니라면 기본값 사용해도됨.
 */
export const removeSessionCookie = (key: string, cookieProvider: Cookies = new Cookies()): void => {
	cookieProvider.remove(key, cookieOptions);
	if (key === PHOENIX_SESSION_ID) {
		cookieProvider.remove(PHP_SESSION_ID, cookieOptions);
	}
};
