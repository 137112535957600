const BaseUrl = `/loans/stock-loans`;

export default {
	INTRO: `${BaseUrl}`,
	STATUS: `${BaseUrl}/status`,
	APPLY: `${BaseUrl}/apply`,
	APPLY_RESULT: `${BaseUrl}/apply-result`,
	REJECT: `${BaseUrl}/reject`,
	CONDITION: `${BaseUrl}/condition`,
	DOCUMENT_UPLOAD: `${BaseUrl}/document-upload`,
	CONTRACT: `${BaseUrl}/contract`,
	PENDING: `${BaseUrl}/pending`,
} as const;
