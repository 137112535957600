import { TokenPayload } from '@peoplefund/utils/axios/TokenStrategy/index';
import ReduxTokenStrategy from '@peoplefund/utils/axios/TokenStrategy/ReduxTokenStrategy';
import { Store } from 'redux';
import { State } from '@peoplefund/reducers';

export default class PhoenixTokenStrategy extends ReduxTokenStrategy {
	constructor(defaultAccessToken?: string, store?: Store<State>) {
		super(defaultAccessToken, store);
	}

	refreshSuccessHandler(payload: TokenPayload): void {
		super.refreshSuccessHandler(payload);
	}

	refreshFailHandler(): void {
		super.refreshFailHandler();
	}
}
