import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { EmptyParam, EmptyResult } from '@peoplefund/actions/common';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { FinancialProductApplications, FinancialProducts } from '@peoplefund/constants/ls-loan-types';

export interface FinancialProductApplicationResult {
	fpa: FinancialProductApplications;
	fp: FinancialProducts;
	loanStatus: LSLoanStatus;
}

export interface FailedFinancialProductApplication {
	id: string;
	name: string;
	institution: string;
	iconUrl: string;
	failureType: string;
	description: string;
}

export type LoadLoanResult = LoanResult &
	FinancialProductApplicationResult & {
		failedFPA?: FailedFinancialProductApplication[];
	};

interface LoanIdParam {
	id: string;
}
type FPAIdParam = {
	fpaId: string;
};

export interface LoanResult {
	id: string;
	status: LSLoanStatus;
	expire: string;
	pfOnly: boolean;
	hasApprovedFPA: boolean;
}

interface GetLoanStatusResult {
	status: LSLoanStatus;
	remainSecond: number;
}

export interface RejectAffiliate {
	name: string;
	mobileUrl: string;
	pcUrl: string;
}

interface AdditionalDocsAuthenticateParam {
	urlToken: string;
	rrnFirst: string;
}

export interface AdditionalDocsResultParam {
	loanId: string;
	accessToken: string;
	valid: boolean;
}

type AdditionalDocsSubmitParam = Omit<AdditionalDocsResultParam, 'valid'> & {
	file: string;
	name: string;
};

const actionCreator = actionCreatorFactory('LS-LOAN');

export default {
	init: actionCreator(ActionTypes.LS_LOAN_INIT),
	resetError: actionCreator(ActionTypes.LS_LOAN_RESET_ERROR),
	createLoan: actionCreator.async<EmptyParam, LoanResult, AlertCommonError>(ActionTypes.LS_LOAN_CREATE_LOAN),
	getLoanStatus: actionCreator.async<LoanIdParam, GetLoanStatusResult, AlertCommonError>(
		ActionTypes.LS_LOAN_GET_LOAN_STATUS
	),
	cancel: actionCreator.async<LoanIdParam, EmptyResult, AlertCommonError>(ActionTypes.LS_LOAN_CANCEL),
	cancelDifferentFlow: actionCreator.async<LoanIdParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_LOAN_CANCEL_DIFFERENT_FLOW
	),
	fetchRejectAffiliate: actionCreator.async<LoanIdParam, RejectAffiliate, AlertCommonError>(
		ActionTypes.LS_LOAN_REJECT_AFFILIATE
	),
	additionalDocsAuthenticate: actionCreator.async<
		AdditionalDocsAuthenticateParam,
		AdditionalDocsResultParam,
		AlertCommonError
	>(ActionTypes.LS_LOAN_ADDITIONAL_DOCS_AUTHENTICATE),
	additionalDocsSubmit: actionCreator.async<AdditionalDocsSubmitParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_LOAN_ADDITIONAL_DOCS_SUBMIT
	),
	checkPreScreeningInProgress: actionCreator.async<EmptyParam, EmptyResult, AlertCommonError>(
		ActionTypes.LS_LOAN_CHECK_PRE_SCREENING_IN_PROGRESS
	),
	loadLoan: actionCreator.async<EmptyParam, LoadLoanResult, AlertCommonError>(ActionTypes.LS_LOAN_LOAD_LOAN),
	loadCompareResult: actionCreator.async<EmptyParam, LoadLoanResult, AlertCommonError>(
		ActionTypes.LS_LOAN_LOAD_COMPARE_RESULT
	),
	loadLoanWithFPA: actionCreator.async<FPAIdParam, LoadLoanResult, AlertCommonError>(
		ActionTypes.LS_LOAN_LOAD_LOAN_WITH_FPA
	),
	checkHasLoans: actionCreator(ActionTypes.LS_LOAN_CHECK_HAS_LOANS),
};
