import { catchError, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ofAction } from '@peoplefund/utils/redux.util';
import {
	applyLPA,
	cancelLoan,
	checkPreScreeingStatus,
	checkUserAddressIsAvailable,
	createLoan,
	getApplyMaterials,
	getFPAIdOnly,
	getMortgageLoanStatus,
	getProperties,
	partnerVerifyPostAction,
	searchAddress,
	searchDetailAddress,
	setApplyMaterials,
	setCheckUserAddressIsAvailableResult,
	setError,
	setFPANextPath,
	setMortgageLoanApplicationLoadingStatus,
	setMortgageLoanApplications,
	setMortgageLoanApplicationStatus,
	setMortgaggeFPAId,
	setProperties,
	setSearchAddress,
	setSearchDetailAddressResult,
} from '@peoplefund/slices/ml-loan';
import { CometEpic } from './constants.util';
import { concat, EMPTY, of } from 'rxjs';
import { actions } from '@peoplefund/actions';
import {
	checkLPAIsInProgress,
	checkPartnerFlowAvailable,
	convertPreScreeningStatus,
	getMortgageLoanStatusWithNextStep,
} from './ml-loan.util';
import { LSLoanStatus } from '@peoplefund/constants/ls-loan-status';
import { AlertCommonError } from '@peoplefund/constants/error/type';
import { PhoenixErrorCode } from '@peoplefund/constants/error/code';
import UtmFactory from '@peoplefund/utils/marketing-script/UtmModule';
import PageUrls from '@peoplefund/constants/page-urls';
import { Action } from 'redux';
import ObjectUtil from '@peoplefund/utils/object.util';
import { MARKETING_AGREEMENT_VALUE } from '@peoplefund/constants/ls-select-options';
import { startChangingMarketingAgreementConfig } from '@peoplefund/slices/authentication';
import {
	PropertyServerProps,
	SearchAddressResponse,
	SearchDetailAddressResponse,
} from '@peoplefund/epics/ml-loan.model';

const getPropertiesEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getProperties),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),

					cometAjax.loan.post('/v1/mortgage-loan/loan-applications/inquire-owned-real-estates', { token }).pipe(
						map((response: { owned_real_estates: PropertyServerProps[] }) => {
							const { owned_real_estates } = response;

							return setProperties(
								owned_real_estates.map((item) => ({
									realEstateNo: item.real_estate_no,
									dong: item.dong ? item.dong : '',
									ho: item.ho ? item.ho : '',
									buildingName: item.building_name,
								}))
							);
						}),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const searchAddressEpic: CometEpic = (action$, _, { cometAjax }) => {
	return action$.pipe(
		ofAction(searchAddress),
		debounceTime(1000),
		mergeMap(({ payload: { query } }) =>
			cometAjax.mortgageUtils
				.get('/api/v1/kb/search-address', {
					body: { search_query: query },
				})
				.pipe(
					map(({ result }: SearchAddressResponse) => {
						return setSearchAddress(
							result.map((item) => ({
								kbLandName: item.address.kb_land_name,
								kbLandNo: `${item.address.kb_land_no}`,
								jibunAddressName: item.address.kb_jibun_address_name,
								roadAddressName: item.address.kb_road_address_name,
								highlight: item.highlight,
							}))
						);
					}),
					catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
				)
		)
	);
};

const searchDetailAddressEpic: CometEpic = (action$, _, { cometAjax }) => {
	return action$.pipe(
		ofAction(searchDetailAddress),
		mergeMap(({ payload: { kbLandNo, name, resetSelectedAddress } }) =>
			concat(
				of(actions.layout.startLoading()),
				cometAjax.mortgageUtils
					.get('/api/v1/kb/detail-address', {
						body: { kb_land_no: kbLandNo },
					})
					.pipe(
						map(({ result }: SearchDetailAddressResponse) => {
							return setSearchDetailAddressResult({
								kbLandNo,
								name,
								detailAddress: result.map(({ dong, ho }) => ({
									dong: dong || null,
									ho: ho.map(({ ho, real_estate_no }) => ({
										ho: `${ho}`,
										realEstateNo: real_estate_no,
									})),
								})),
								resetSelectedAddress,
							});
						}),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
					),
				of(actions.layout.endLoading())
			)
		)
	);
};

const checkUserAddressIsAvailableEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(checkUserAddressIsAvailable),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					mlLoan: {
						searchAddress: {
							selected: { kbLandNo, dong, ho, name },
						},
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.mortgageUtils
						.post('/api/v1/kb/household', {
							body: { kb_land_no: kbLandNo, dong, ho },
							token,
						})
						.pipe(
							mergeMap(({ real_estate_no }: { real_estate_no: string }) => {
								if (!real_estate_no) {
									return of(
										setCheckUserAddressIsAvailableResult({
											isAvailable: false,
										})
									);
								}

								return concat(
									of(searchDetailAddress({ kbLandNo, name, resetSelectedAddress: false })),
									of(
										setCheckUserAddressIsAvailableResult({
											isAvailable: true,
											realEstateNo: real_estate_no,
										})
									)
								);
							}),
							catchError(() =>
								of(
									setCheckUserAddressIsAvailableResult({
										isAvailable: false,
									})
								)
							)
						),
					of(actions.layout.endLoading())
				)
		)
	);
};

const getMortgageLoanStatusEpic: CometEpic = (action$, state$, { cometAjax }) => {
	// with alert >> apply 구간에만 사용함 (약관 ~ 주택 확인)
	return action$.pipe(
		ofAction(getMortgageLoanStatus),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return EMPTY;
				}

				return getMortgageLoanStatusWithNextStep(cometAjax, token).pipe(
					mergeMap(({ la, fpa }) => {
						const resultAction: Action[] = [setMortgageLoanApplications(la), setMortgaggeFPAId(fpa.id)];

						const error = checkLPAIsInProgress({
							laStatus: la.status,
							fpaId: fpa.id,
						});

						if (error) {
							resultAction.push(setError(error));
						}

						return resultAction;
					}),
					catchError((e) => of(setError(e)))
				);
			}
		)
	);
};

const partnerVerifyPostActionEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(partnerVerifyPostAction),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload: agreedItems },
				{
					lsCommon: { loanPurposeType, incomeType },
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					checkPartnerFlowAvailable(cometAjax, token).pipe(
						mergeMap((material) => {
							const result: Action[] = [setApplyMaterials(material), getProperties()];

							// 다음페이지에서 필요한 정보 미리 fetch
							if (!loanPurposeType || !incomeType) {
								result.push(actions.lsCommon.fetchEnums.started({}));
							}

							const marketingAgreed = Boolean(
								agreedItems.customAgreeList.find((item) => item === MARKETING_AGREEMENT_VALUE)
							);
							result.push(
								startChangingMarketingAgreementConfig({
									smsAgreement: marketingAgreed,
									emailAgreement: marketingAgreed,
								})
							);

							// TODO: actions.lsAuthentication.verifySignUp 제거하고 없애기
							result.push(actions.lsAuthentication.verifyPartnerDone());

							return result;
						}),
						catchError((e) => of(setError(e)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const getApplyMaterialEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getApplyMaterials),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					lsCommon: { loanPurposeType, incomeType },
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				return concat(
					of(actions.layout.startLoading()),
					checkPartnerFlowAvailable(cometAjax, token).pipe(
						mergeMap((material) => {
							const result: Action[] = [setApplyMaterials(material)];

							if (!loanPurposeType || !incomeType) {
								result.push(actions.lsCommon.fetchEnums.started({}));
							}

							return result;
						}),
						catchError((e) => of(setError(e)))
					),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const applyPreScreeningEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(createLoan),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token },
					},
					lsCommon: { platformCode },
					mlLoan: {
						apply: {
							property: { id: realEstateNo, resident: isApplicantLiving },
							loanPurpose,
							amount: appliedAmount,
							jobCode,
							annualIncome,
							deposit,
							platformCode: loanApplicationPlatform,
							lesseeMoveOutDueDate,
							isMonthlyRentExists,
						},
					},
				},
			]) => {
				const utmload = UtmFactory.instance?.getUtm() || {};

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post('/v1/mortgage-loan/loan-applications/pre-screening', {
							token,
							body: ObjectUtil.filterUndefined({
								loan_purpose: loanPurpose,
								real_estate_no: realEstateNo,
								is_applicant_living: isApplicantLiving,
								applied_amount: appliedAmount,
								job_code: jobCode,
								annual_income: annualIncome,
								security_deposit: deposit,
								loan_application_platform: loanApplicationPlatform || undefined,
								lessee_move_out_due_date: lesseeMoveOutDueDate,
								is_monthly_rent_exists: isMonthlyRentExists,
								platform_code: platformCode || undefined,
								...utmload,
							}),
						})
						.pipe(
							mergeMap((res) =>
								concat([
									setMortgageLoanApplications({
										id: res.loan_application.id,
										status: res.loan_application.status,
										expireTime: '',
										hasPfloanInLSProgress: false,
									}),
								])
							),
							catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요.'))))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const checkPreScreeningStatusEpic: CometEpic = (action$, state$, { cometAjax }) => {
	// with alert >> 가심사 조회 중
	return action$.pipe(
		ofAction(checkPreScreeingStatus),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
					lsCommon: { mainPath },
					mlLoan: {
						la: {
							data: { id: laId, status: laStatus },
						},
						fpa: {
							data: { id: fpaId },
						},
					},
				},
			]) => {
				if (!token) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.LOGIN_REQUIRED, '로그인이 필요한 기능입니다.')));
				}

				if (!laId) {
					// laId 가 없으면 바로 가심사 조회 화면으로 들어온 경우이므로, 최신론 갱신하고 다시 조회하기
					return getMortgageLoanStatusWithNextStep(cometAjax, token).pipe(
						mergeMap((inProgressLoan) => {
							const nextAction: Action[] = [
								setMortgageLoanApplications(inProgressLoan.la),
								setMortgaggeFPAId(inProgressLoan.fpa.id),
							];

							const hasLoan = inProgressLoan.la.id;
							if (hasLoan) {
								nextAction.push(checkPreScreeingStatus()); // 다시 호출
							} else {
								nextAction.push(
									setError(
										new AlertCommonError('', '', '신청한 대출이 없습니다', {
											nextPath: mainPath || PageUrls.loansCompare.INTRO,
										})
									)
								);
							}
							return nextAction;
						}),
						catchError((e) => of(setError(e)))
					);
				}

				if (laStatus !== LSLoanStatus.IN_PROGRESS_PRE_EVALUATION) {
					const error = checkLPAIsInProgress({
						laStatus,
						fpaId,
					});

					if (error) {
						return of(setError(error));
					}
				}

				return concat(
					cometAjax.loan.get(`/v2/loan-applications/${laId}/pre-screening-status`, { token }).pipe(
						map((res) => {
							return setMortgageLoanApplicationLoadingStatus({
								status: convertPreScreeningStatus(res),
								remainSeconds: res.remaining_time,
							});
						}),
						catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
					)
				);
			}
		)
	);
};

const getFPAIdOnlyEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(getFPAIdOnly),
		withLatestFrom(state$),
		mergeMap(
			([
				,
				{
					account: {
						auth: { token = '' },
					},
				},
			]) => {
				if (!token) {
					return EMPTY;
				}

				return getMortgageLoanStatusWithNextStep(cometAjax, token).pipe(
					map((inProgressLoan) => {
						const hasLoan = Boolean(inProgressLoan.la.id); // la = null
						if (hasLoan) {
							return setMortgaggeFPAId(inProgressLoan.fpa.id);
						} else {
							return setMortgageLoanApplicationStatus(LSLoanStatus.REJECTED);
						}
					}),
					catchError((e) => of(setError(e)))
				);
			}
		)
	);
};

const applyLPAEpic: CometEpic = (action$, state$, { cometAjax }) => {
	// 가심사결과조회 > 론샷거 사용 getMortgageLSFPA
	return action$.pipe(
		ofAction(applyLPA),
		withLatestFrom(state$),
		mergeMap(
			([
				{
					payload: { appliedAmount, lpaId, baseUrls },
				},
				{
					account: {
						auth: { token },
					},
				},
			]) => {
				if (!lpaId) {
					return of(setError(new AlertCommonError('', PhoenixErrorCode.EMPTY_LOANS, '신청한 대출이 없습니다.')));
				}

				return concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/mortgage-loan/loan-product-applications/${lpaId}/apply`, {
							token,
							body: {
								applied_amount: appliedAmount,
							},
						})
						.pipe(
							map(() => setFPANextPath(baseUrls.APP_DOWNLOAD)), // 200 인경우 바로 업데이트
							catchError(() => of(setError(new AlertCommonError('잠시후 다시 시도해주세요'))))
						),
					of(actions.layout.endLoading())
				);
			}
		)
	);
};

const cancelLoanEpic: CometEpic = (action$, state$, { cometAjax }) => {
	return action$.pipe(
		ofAction(cancelLoan),
		withLatestFrom(state$),
		mergeMap(
			([
				{ payload },
				{
					account: {
						auth: { token = '' },
					},
				},
			]) =>
				concat(
					of(actions.layout.startLoading()),
					cometAjax.loan
						.post(`/v1/loan-application/cancel`, {
							token,
							body: {
								loan_application_id: payload.laId,
							},
						})
						.pipe(
							map(() => {
								return setMortgageLoanApplications({
									id: '',
									status: LSLoanStatus.CANCELED,
									expireTime: '',
									hasPfloanInLSProgress: false,
								});
							}),
							catchError((error) => of(setError(error)))
						),
					of(actions.layout.endLoading())
				)
		)
	);
};

export default [
	getPropertiesEpic,
	searchAddressEpic,
	searchDetailAddressEpic,
	checkUserAddressIsAvailableEpic,
	getMortgageLoanStatusEpic,
	partnerVerifyPostActionEpic,
	getApplyMaterialEpic,
	applyPreScreeningEpic,
	applyLPAEpic,
	checkPreScreeningStatusEpic,
	cancelLoanEpic,
	getFPAIdOnlyEpic,
];
