import { BankInfo } from '@peoplefund/slices/common-investing';
import { BankCodeInfoServerResponse } from '@peoplefund/epics/common-investing';
import { IMAGE_DEFAULT_BANK_LOGO, URL_FOR_BANK_CODE } from '@peoplefund/epics/bankCode.constant';
import { CometAjax } from './ajax.util';
import { map, Observable } from 'rxjs';

export const fetchBankListObservable = (
	cometAjax: CometAjax<BankCodeInfoServerResponse[]>,
	filterByActive: boolean
	// fyi. https://pfcoworkspace.slack.com/archives/C04ERBN1G9G/p1673332129043939?thread_ts=1673317100.084589&cid=C04ERBN1G9G
): Observable<BankInfo[]> => {
	const body = filterByActive ? { is_active: true } : {};
	return cometAjax.inapi.get(URL_FOR_BANK_CODE, { body }).pipe(
		map((response) => {
			const list: BankInfo[] =
				response?.map((item) => ({
					bankCode: item.bank_code,
					bankLogo: item.logo_url ?? IMAGE_DEFAULT_BANK_LOGO,
					bankName: item.full_name,
					bankType: item.bank_type,
				})) ?? [];

			return list;
		})
	);
};
