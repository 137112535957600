import { AgreementItemServerProps, AgreementsServerProps } from '@peoplefund/epics/agreement.model';
import { IAgreement } from '@peoplefund/constants/agreement/type';

export const convertAgreementsResult = (
	agreements: AgreementsServerProps | AgreementItemServerProps[]
): IAgreement[] => {
	const _convert = ({ text, code, is_optional, url, category }: AgreementItemServerProps) => {
		return {
			text: text.replace(/<\/?h[^>]*>/g, '@@'),
			type: code,
			link: url,
			required: !Boolean(is_optional),
			category,
		};
	};
	return agreements.map((item: unknown) => {
		/**
		 * 단일 아이템으로만 구성되어있는 경우도 있음.
		 */
		const categorySItem = item as AgreementItemServerProps;

		if (categorySItem.category === 'S') {
			return _convert(categorySItem);
		}

		const categoryMItem = item as AgreementsServerProps;
		/**
		 * TODO: 왜 안되는지 확인 필요
		 */

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const { text, children, category } = categoryMItem;

		if (children.length === 1) {
			// TODO: category=M/S 에 따라 nested 구분 가능
			return _convert({ ...children[0], text: text || children[0].text });
		} else {
			return {
				text,
				category,
				list: children.map(_convert),
			};
		}
	});
};
