import { ActionTypes } from '@peoplefund/constants/action-types';
import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory('LAYOUT');

export default {
	init: actionCreator(ActionTypes.LAYOUT_INIT),
	startLoading: actionCreator(ActionTypes.LAYOUT_START_LOADING),
	endLoading: actionCreator(ActionTypes.LAYOUT_END_LOADING),
	tokenExpired: actionCreator(ActionTypes.LAYOUT_TOKEN_EXPIRED),
	showLoansMyPageMenu: actionCreator<boolean>(ActionTypes.LAYOUT_SHOW_LOANS_MYPAGE_MENU),
};
