import { INVESTOR_STATUS, INVESTOR_TYPE } from '@peoplefund/constants/pl-investing';

export type Gender = 'MALE' | 'FEMALE' | 'NONE';
export type UserType = 'PERSONAL' | 'CORPORATION' | 'INSTITUTION' | 'TESTER' | 'STAFF' | 'NONE';

export interface AuthInfo {
	readonly loggedIn: boolean;
	readonly token?: string;
	readonly refreshToken?: string;
	readonly expiredAt?: number;
}

export interface UserInfo {
	/** 이름 */
	readonly userName: string;
	/** 이름 */
	readonly email: string;
	/** 전화번호 */
	readonly phoneNumber: string;
	/** 성별 */
	readonly gender: Gender;
	/** 생년월일(YYYYMMDD) */
	readonly birthDate: string;
	/** 주민등록번호 앞자리 */
	readonly rrn6: string;
	/** 주민등록번호 뒷자리 */
	/** TODO: 암호화 필요 */
	readonly rrn7Only: string;
	/** rrn full 여부 */
	readonly hasRRN: boolean;
	/** 외국인여부 */
	readonly isKorean: boolean;
	/** 유저 타입 */
	readonly userType: UserType;
	/** 비밀번호 설정 여부 */
	readonly hasPassword: boolean;
}

export type CorporateInfo = {
	/** 사업자 번호 */
	readonly bizRegNumber: string;
};

export interface InvestorInfo {
	// 갱신여부,
	readonly tryFetchInvestorInfo: boolean;
	//투자자 타입
	readonly investorType: INVESTOR_TYPE;
	//투자자 등록 상태
	readonly investorStatus: INVESTOR_STATUS;
	//잔여 투자가능한도액 (=연계투자한도), 무한대(Number.MAX_SAFE_INTEGER)값 사용
	readonly remainP2PInvestableAmount: number;
	//가상계좌 잔액
	readonly accountBalance: number;
	//계좌 은행
	readonly accountBank: string;
	//계좌 번호
	readonly accountNumber: string;
	//계좌주
	readonly accountHolder: string;
	//동일 차입자(상품별) 투자한도, 전문/기관 투자자 -1 (40% 계산 필요)
	readonly productInvestLimit: number;
}

export const initialInvestorInfo: InvestorInfo = {
	tryFetchInvestorInfo: false,
	remainP2PInvestableAmount: 0,
	investorType: INVESTOR_TYPE.NONE,
	investorStatus: INVESTOR_STATUS.NONE,
	accountBalance: 0,
	accountBank: '',
	accountNumber: '',
	accountHolder: '',
	productInvestLimit: 0,
};

export interface UserInvestorStatus {
	readonly investorRegistrationStatus: string;
	readonly investorShowcaseStatus: string;
}

export const initialCommonInvestorStatus: UserInvestorStatus = {
	investorRegistrationStatus: '',
	investorShowcaseStatus: '',
};

export enum AMLStatus {
	// 초기 상태
	INITIAL = 'initial',
	// AML 모두 통과
	SUCCESS = 'success',
	// AML 진행했으나 실패(WLF 부적합, 외국인)
	FAILED = 'failed',
	// EDD 재이행 필요 또는 진행 필요
	KYC_EXPIRED = 'expired',
	KYC_EXPIRE_SOON = 'expire_soon',
}
